import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useFlag } from '@openfeature/react-sdk'

import { createIsSomeEnum } from '../../../utils/typeHelpers'
import {
  FEATURE_FLAG_KEYS,
  HISTORICAL_BOOKKEEPING_FEE_COHORTS,
  useSetOpenFeatureTargetingKey,
} from '../../OpenFeature'
import {
  fetchHistoricalBookkeepingPrices,
  getBookkeepingMonthsThisYearForPricing,
} from '../../../constants/pricingConstants'
import { useReselector } from '../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getAnnualTaxFilingForYearSelector } from '../../Taxes/AnnualTaxes/annualTaxFilings.selector'

const isCohort = createIsSomeEnum(HISTORICAL_BOOKKEEPING_FEE_COHORTS)

export const useGetHistoricalBookkeepingCohort = () => {
  const [searchParams] = useSearchParams()
  const cohortSearchParam = searchParams.get('c')

  useSetOpenFeatureTargetingKey()

  const cohortFlag = useFlag(
    FEATURE_FLAG_KEYS.historicalBookkeepingFee,
    HISTORICAL_BOOKKEEPING_FEE_COHORTS.none
  )

  return useMemo(() => {
    // Prioritize flag, this is based on email and should be the source of truth
    if (
      isCohort(cohortFlag.value) &&
      cohortFlag.value !== HISTORICAL_BOOKKEEPING_FEE_COHORTS.none
    ) {
      return cohortFlag.value
    }

    // Fallback to query param.  If the user didn't use the email used to communicate with sales with they should
    // use the link sent to them by sales.  The query parameter value associated with the cohort
    switch (cohortSearchParam) {
      case '7575':
        return HISTORICAL_BOOKKEEPING_FEE_COHORTS.cohort1
      case '9999':
        return HISTORICAL_BOOKKEEPING_FEE_COHORTS.cohort2
      default:
        return HISTORICAL_BOOKKEEPING_FEE_COHORTS.none
    }
  }, [cohortFlag.value, cohortSearchParam])
}

export const useGetHistoricalBookkeepingCostPerMonthWithTaxes = () => {
  const cohort = useGetHistoricalBookkeepingCohort()

  const historicalBookkeepingPrice = fetchHistoricalBookkeepingPrices()

  switch (cohort) {
    case HISTORICAL_BOOKKEEPING_FEE_COHORTS.cohort1:
      return historicalBookkeepingPrice.withTaxes.cohort1.costPerMonth
    case HISTORICAL_BOOKKEEPING_FEE_COHORTS.cohort2:
      return historicalBookkeepingPrice.withTaxes.cohort2.costPerMonth
    case HISTORICAL_BOOKKEEPING_FEE_COHORTS.none:
      return undefined
    default:
      return cohort satisfies never
  }
}

// Determines how much a user should pay for the historical bookkeeping price
export const useHistoricalBookkeepingPrice = () => {
  const cohort = useGetHistoricalBookkeepingCohort()

  const historicalBookkeepingPrice = fetchHistoricalBookkeepingPrices()

  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const taxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentTaxYear
  )

  const includePriorYearTaxes = Boolean(taxFiling?.isLateJoiner)

  // We don't want to show the fee if the user has no historical bookkeeping months
  if (
    getBookkeepingMonthsThisYearForPricing() === 0 &&
    !includePriorYearTaxes
  ) {
    return undefined
  }

  const priceAttribute = includePriorYearTaxes ? 'withTaxes' : 'withoutTaxes'

  switch (cohort) {
    case HISTORICAL_BOOKKEEPING_FEE_COHORTS.cohort1:
      return historicalBookkeepingPrice[priceAttribute].cohort1
    case HISTORICAL_BOOKKEEPING_FEE_COHORTS.cohort2:
      return historicalBookkeepingPrice[priceAttribute].cohort2
    case HISTORICAL_BOOKKEEPING_FEE_COHORTS.none:
      return undefined
    default:
      return cohort satisfies never
  }
}
