import { Grid, Divider, Button } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { useParams } from 'react-router-dom'
import {
  Alert,
  GridRowColumn,
  Modal,
  Text,
  Button as HeardButton,
} from '../../../BaseComponents'
import { VirtualStatementData } from '../../../../actions/admin/accountReconciliationActions'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import CurrencyFormatLabel from '../../../shared/CurrencyFormatLabel'
import { centsToDollars } from '../../../../utils/currencyHelpers'
import { VirtualStatementTransactionTable } from './VirtualStatementTransactionTable'
import { FinancialAccountWithAdminInfo } from '../../../../reducers/admin/financialAccountsReducer'
import { getUserFinancialAccountById } from '../../../../selectors/financeSelectors'
import { useReselector } from '../../../../utils/sharedHooks'

export const VirtualStatement = ({
  toggleOpen,
  data,
  onReconcileClick,
}: {
  toggleOpen: () => void
  data: VirtualStatementData | null
  onReconcileClick: (
    formType: string,
    account: FinancialAccountWithAdminInfo,
    reconType: 'auto' | 'manual'
  ) => void
}) => {
  const { userId } = useParams<{ userId: string }>()

  const userFinancialAccount = useReselector(
    getUserFinancialAccountById,
    userId,
    data?.reconciliation?.financialAccount.id
  )

  if (!data) return null

  const { reconciliation, statement, statementUrl } = data
  const {
    endingBalanceDate,
    endingBalanceInCents,
    startingBalanceDate,
    startingBalanceInCents,
    status,
    financialAccount,
  } = reconciliation

  const formattedStartDate = DateTime.fromISO(startingBalanceDate).toFormat(
    DATE_FORMATS_LUXON.DISPLAY_SHORT
  )

  const formattedEndDate = DateTime.fromISO(endingBalanceDate).toFormat(
    DATE_FORMATS_LUXON.DISPLAY_SHORT
  )

  const openStatement = () => {
    if (statementUrl) {
      window.open(statementUrl, '_blank')
    }
  }

  const isComplete = status === 'complete'

  const handleManualReconcile = () => {
    if (userFinancialAccount) {
      onReconcileClick(reconciliation.status, userFinancialAccount, 'manual')
      toggleOpen()
    }
  }

  return (
    <Modal
      open={Boolean(data)}
      onClose={toggleOpen}
      closeIcon
      size="fullscreen"
    >
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="h2">
              {isComplete
                ? 'Auto-reconciliation complete'
                : 'Auto-reconciliation failed'}
            </Text>
          </GridRowColumn>
          <Grid.Row>
            <Grid.Column width={5}>
              <Grid.Column>
                <Text> {financialAccount.plaidInstitutionName} </Text>
                <Text>
                  <b>{financialAccount.name}</b>: {financialAccount.mask}
                </Text>
                <Text>
                  {financialAccount.type} -- {financialAccount.subtype}
                </Text>
              </Grid.Column>
            </Grid.Column>
            <Grid.Column width={11}>
              {isComplete ? (
                <Alert type="success">
                  <Text color="green">Successfully auto-reconciled</Text>
                </Alert>
              ) : (
                <Alert type="error">
                  <Grid stackable>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <Text color="red">Auto-reconciliation failed</Text>
                        <Text color="red" as="bodySm">
                          {!statementUrl
                            ? 'Please manually reconcile against the bank statement.'
                            : 'Please manually reconcile against the bank statement once it becomes available.'}
                        </Text>
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <HeardButton
                          variant="secondary"
                          onClick={openStatement}
                          disabled={!statementUrl}
                        >
                          View Statement
                        </HeardButton>
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Button color="blue" onClick={handleManualReconcile}>
                          Manually Reconcile
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Alert>
              )}
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn>
            <Divider style={{ margin: 0 }} />
          </GridRowColumn>
          <GridRowColumn>
            <Text as="h2"> Virtual bank statement </Text>
          </GridRowColumn>
          <Grid.Row>
            <Grid.Column width={6}>
              <Text> Starting Heard Balance ({formattedStartDate}) </Text>
              <Text style={{ marginTop: 4 }}>
                <b>
                  <CurrencyFormatLabel
                    value={centsToDollars(startingBalanceInCents)}
                  />
                </b>
              </Text>
            </Grid.Column>
            <Grid.Column width={6}>
              <Text> Ending Plaid Balance ({formattedEndDate}) </Text>
              <Text
                style={{ marginTop: 4 }}
                color={!isComplete ? 'red' : undefined}
              >
                <b>
                  <CurrencyFormatLabel
                    value={centsToDollars(endingBalanceInCents)}
                  />
                </b>
              </Text>
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn>
            {Boolean(statement?.length) && (
              <VirtualStatementTransactionTable statement={statement} />
            )}
          </GridRowColumn>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}
