import { useCallback } from 'react'

import { Button, Modal, Text } from '../../components/BaseComponents'
import { putCloseTicket } from './actions'
import { useAppDispatch } from '../../utils/typeHelpers'

const ArchiveConversationModal = ({
  convoId,
  open,
  close,
}: {
  convoId?: string | number
  open: boolean
  close: () => void
}) => {
  const dispatch = useAppDispatch()

  const closeConversation = useCallback(() => {
    if (convoId) {
      dispatch(putCloseTicket(convoId))
    }
    close()
  }, [close, convoId, dispatch])

  return (
    <Modal open={open} closeIcon onClose={close} size="small">
      <Modal.Header>Did we answer your question?</Modal.Header>
      <Modal.Content>
        <Text as="bodyLg">
          If you feel satisfied, you can archive the conversation. There’s
          always the option to reopen the conversation by sending a new message.
        </Text>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button onClick={closeConversation}>Archive</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ArchiveConversationModal
