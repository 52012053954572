import React, { useEffect, useState } from 'react'
import { useReselector } from '../../../utils/sharedHooks'
import { selectBookkeepingReportsById } from '../../../selectors/bookkeepingReportsSelectors'
import moment from 'moment'
import { fetchTransactionCounts } from '../../../actions/adminActions'
import { Card, Header, Loader } from 'semantic-ui-react'
import {
  selectQueriedPendingTransactions,
  selectRequestedClarificationTransactions,
  selectUncategorizedTransactions,
} from '../../../features/Transactions/transactions.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'

const TransactionCountsCard = ({
  loading,
  userId,
  reportId,
}: {
  userId: string
  loading: boolean
  reportId: string
}) => {
  const dispatch = useAppDispatch()
  const [counts, setCounts] = useState<{
    pendingCount: number
    uncategorizedCount: number
    requestedClarificationCount: number
  }>()
  const report = useReselector(selectBookkeepingReportsById, reportId)

  // These represent the queried transaction state but we actually want to count everything that isn't on the current page too
  // We also need to know WHEN to refetch so the use the local state and refresh if the count changes
  const pendingTransactions = useReselector(selectQueriedPendingTransactions)
  const uncategorizedTransactions = useReselector(
    selectUncategorizedTransactions
  )
  const requestedClarificationTransactions = useReselector(
    selectRequestedClarificationTransactions
  )

  useEffect(() => {
    const fetch = async () => {
      if (!report?.id) {
        return
      }

      const startDate = moment(report.startDate, 'YYYY-MM').startOf('month')
      const endDate = moment(report.endDate, 'YYYY-MM').endOf('month')
      const counts = await fetchTransactionCounts({
        userId,
        startDate,
        endDate,
      })(dispatch)
      if (counts) {
        setCounts(counts)
      }
    }

    fetch()
  }, [
    dispatch,
    report?.endDate,
    report?.id,
    report?.startDate,
    userId,
    pendingTransactions.length,
    uncategorizedTransactions.length,
    requestedClarificationTransactions.length,
  ])

  return (
    <Card fluid>
      <Card.Content>
        <Header as="h5">Transaction Counts</Header>

        {loading && <Loader active inline />}

        {!loading && counts && (
          <div>
            <p>
              <b>Needs Clarification: </b>
              {counts.requestedClarificationCount}
            </p>
            <p>
              <b>Pending: </b>
              {counts.pendingCount}
            </p>
            <p>
              <b>Uncategorized: </b>
              {counts.uncategorizedCount}
            </p>
          </div>
        )}
      </Card.Content>
    </Card>
  )
}

export default TransactionCountsCard
