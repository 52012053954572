import { SemanticCOLORS } from 'semantic-ui-react'
import { User } from '../reducers/auth/userReducer'
import { useCallback } from 'react'
import { loginGodviewUser } from '../actions/authActions'
import { useAppDispatch } from './typeHelpers'
import { UserWithAdminInfo } from '../reducers/admin/v2/allUsersReducerV2'
import { useAnalyticsSetUserId } from '../features/Amplitude'

export const MASS_MESSAGE_ADMIN = (
  currentUser: Pick<User, 'email' | 'admin'> | null
) => {
  const permittedAdminEmails = [
    'victoria+finances@joinheard.com',
    'andrew@joinheard.com',
    'forrest@joinheard.com',
    'nadine@joinheard.com',
    'nicole@joinheard.com',
  ]

  if (process.env.VITE_IS_PROD === 'false') {
    permittedAdminEmails.push('kiya@joinheard.com')
    permittedAdminEmails.push('dane@joinheard.com')
    if (currentUser?.email) {
      permittedAdminEmails.push(currentUser.email)
    }
  }

  return Boolean(
    currentUser?.admin &&
      permittedAdminEmails.includes(currentUser?.email.toLowerCase())
  )
}

export const USER_STATUS_LABEL_COLOR: { [status: string]: SemanticCOLORS } = {
  active: 'green',
  unpaid: 'red',
}

export const QA_PAGE_ENABLED = () => process.env.VITE_ENV !== 'production'

export const useGodViewLogin = () => {
  const dispatch = useAppDispatch()
  const setUserId = useAnalyticsSetUserId()

  return useCallback(
    async (user: Pick<UserWithAdminInfo, 'id' | 'email' | 'uuid'>) => {
      const res = await dispatch(loginGodviewUser(user.id, user.email))

      if (res?.status === 200) {
        // Set the uuid for amplitude tracking to use the godviewed user only in dev and staging environments for use of QA users
        // We want to keep using the admin account in production as audit protection against god view actions
        if (
          process.env.VITE_ENV === 'development' ||
          process.env.VITE_ENV === 'staging'
        ) {
          setUserId(user.uuid)
        }
        window.location.reload()
      }
    },
    [dispatch, setUserId]
  )
}
