import { useCallback, useState } from 'react'
import { List } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { TransactionOrSplit } from '../../../../../reducers/admin/allTransactions.slice'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectDocuments } from '../../../../UserDocuments/userDocuments.selector'
import {
  Icon,
  Link,
  Popup,
  Button,
} from '../../../../../components/BaseComponents'
import FileUploadModal, {
  UploadedFile,
} from '../../../../../components/FileUpload/FileUploadModal'
import {
  destroySingleUserDocument,
  UserDocument,
} from '../../../../UserDocuments/userDocuments.slice'
import { UploadDocumentType } from '../../../../../constants/businessConstants'
import { useAppDispatch } from '../../../../../utils/typeHelpers'

const DisplayFile = ({
  receipt,
  deleteFile,
}: {
  receipt: UserDocument | UploadedFile
  deleteFile: (id: number) => void
}) => {
  const documents = useReselector(selectDocuments)
  const doc = documents[receipt.id]
  if (!doc) {
    return null
  }

  return (
    <List.Item key={receipt.id}>
      <Link
        href={doc?.signedUrl || ''}
        newPage
        size="small"
        style={{ marginRight: 6 }}
        as="secondaryLink"
      >
        <Popup
          position="top center"
          content={`File Name: ${doc?.id} ${doc?.fileDescription}`}
          trigger={doc?.fileDescription}
        />
      </Link>
      <Icon
        icon={regular('trash-can')}
        onClick={() => deleteFile(receipt.id)}
      />
    </List.Item>
  )
}

const TransactionReceiptsModal = ({
  transaction,
  receiptIds,
  setReceiptIds,
}: {
  transaction: TransactionOrSplit | null
  receiptIds: number[]
  setReceiptIds: (ids: number[]) => void
}) => {
  const dispatch = useAppDispatch()

  const [fileUploadOpen, setFileUploadOpen] = useState(false)
  const [filesToDisplay, setFilesToDisplay] = useState<
    (UserDocument | UploadedFile)[] | undefined
  >(transaction?.receipts)

  const deleteFile = async (fileId: number) => {
    await destroySingleUserDocument(fileId)(dispatch)
    setReceiptIds(receiptIds.filter((id) => id !== fileId))
  }

  const setUploadedFile = useCallback(
    (uploadedFile: UploadedFile) => {
      setFilesToDisplay((currentFiles) =>
        currentFiles ? [...currentFiles, uploadedFile] : [uploadedFile]
      )

      setReceiptIds([...receiptIds, uploadedFile.id])
    },
    [receiptIds, setReceiptIds]
  )

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => setFileUploadOpen(true)}
        style={{ marginBottom: 16 }}
      >
        <Icon icon={regular('upload')} style={{ marginRight: 6 }} />
        Upload Receipt
      </Button>
      <FileUploadModal
        open={fileUploadOpen}
        close={() => setFileUploadOpen(false)}
        documentType={UploadDocumentType.RECEIPT}
        userFacing
        extraPayload={{ transactionId: Number(transaction?.id) }}
        setUploadedFile={setUploadedFile}
      />
      <List>
        {filesToDisplay?.map((receipt) => (
          <DisplayFile
            key={receipt.id}
            receipt={receipt}
            deleteFile={deleteFile}
          />
        ))}
      </List>
    </>
  )
}

export default TransactionReceiptsModal
