import React, { Dispatch, FC, SetStateAction, SyntheticEvent } from 'react'
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'

interface UserSearchProps {
  allUsers: { [key: string]: UserWithAdminInfo }
  searchedUserId: number | undefined
  setSearchedUserId: Dispatch<SetStateAction<number | undefined>>
}

const AnnualTaxFilingUserSearch: FC<UserSearchProps> = ({
  allUsers,
  searchedUserId,
  setSearchedUserId,
}) => {
  const USER_OPTIONS = (): DropdownItemProps[] => {
    return Object.values(allUsers).map((user) => {
      return {
        key: user.id,
        text: `${user.firstName} ${user.lastName}`,
        value: user.id,
      }
    })
  }

  const handleUserSearch = (e: SyntheticEvent, data: DropdownProps) => {
    setSearchedUserId(data.value as number)
  }
  return (
    <div>
      <Dropdown
        search
        selection
        clearable
        placeholder="Search for User"
        value={searchedUserId}
        options={USER_OPTIONS()}
        onChange={handleUserSearch}
      />
    </div>
  )
}

export default AnnualTaxFilingUserSearch
