import { useMemo, useState, useEffect } from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import {
  getCategoryData,
  filterChartAPIResponse,
  processDataForChart,
  ChartAPIResponse,
  getInsightsDateRange,
  INSIGHTS_TYPE,
  filterChartCategories,
  insightsCopyConstants,
  insightsTrackDefaultFilters,
} from './utils'
import { InsightsChart } from './InsightsChart'
import {
  fetchInsightsBarchartData,
  FETCH_INSIGHTS_BARCHART_DATA_KEY,
} from './financeActions'
import { InsightsDividedCard } from './InsightsDividedCard'
import { GridRowColumn, Text, Dropdown, Loader } from '../../BaseComponents'
import {
  useGetEndDateForFinancialInsights,
  useReselector,
} from '../../../utils/sharedHooks'
import { getFetchError, selectIsFetchingForKeys } from '../../../reducers/fetch'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { isEmpty } from 'lodash'
import { useAppDispatch } from '../../../utils/typeHelpers'

export const TrackInsightsSection = ({
  inputDate,
  type,
}: {
  inputDate?: string
  type: INSIGHTS_TYPE
}) => {
  const dispatch = useAppDispatch()

  const [responseData, setResponseData] = useState<ChartAPIResponse[]>()
  const [filter, setFilter] = useState('all')

  const currentDate = useMemo(
    () =>
      inputDate
        ? DateTime.fromFormat(inputDate, DATE_FORMATS_LUXON.INPUT)
        : DateTime.now(),
    [inputDate]
  )

  const getEndDate = useGetEndDateForFinancialInsights(currentDate)

  const dateRange = useMemo(() => {
    return getInsightsDateRange(getEndDate())
  }, [getEndDate])

  useEffect(() => {
    const fetchData = async (range: [string, string]) => {
      const [startDate, endDate] = range
      const data = await fetchInsightsBarchartData(
        type,
        startDate,
        endDate
      )(dispatch)
      setResponseData(data)
    }
    if (dateRange) {
      fetchData(dateRange)
    }
  }, [dispatch, dateRange, type])

  const { chartCategories, topCategories } = useMemo(() => {
    if (!responseData)
      return {
        chartCategories: null,
        topCategories: [],
      }
    return getCategoryData(responseData)
  }, [responseData])

  const filteredData = useMemo(() => {
    if (!responseData) return []

    return filterChartAPIResponse(responseData, filter, topCategories)
  }, [responseData, filter, topCategories])

  const filteredChartCategories = useMemo(() => {
    if (!chartCategories) return {}
    return filterChartCategories(chartCategories, filter, topCategories)
  }, [chartCategories, filter, topCategories])

  const chartData = useMemo(() => {
    return processDataForChart(filteredData, dateRange, type)
  }, [filteredData, dateRange, type])

  const dropdownOptions = useMemo(() => {
    const options = [...insightsTrackDefaultFilters[type]]
    if (!chartCategories) return options
    Object.entries(chartCategories).forEach(([identifier, category]) => {
      options.push({
        text: category.category_name,
        value: identifier,
      })
    })
    return options
  }, [chartCategories, type])

  const dateRangeString = useMemo(() => {
    if (!dateRange) return ''
    const [startDate, endDate] = dateRange
    const start = DateTime.fromFormat(
      startDate,
      DATE_FORMATS_LUXON.TIMESTAMP
    ).toFormat(DATE_FORMATS_LUXON.MONTH_YEAR)
    const end = DateTime.fromFormat(
      endDate,
      DATE_FORMATS_LUXON.TIMESTAMP
    ).toFormat(DATE_FORMATS_LUXON.MONTH_YEAR)
    return `${start} - ${end}`
  }, [dateRange])

  const queryLoading = useReselector(selectIsFetchingForKeys, [
    FETCH_INSIGHTS_BARCHART_DATA_KEY,
  ])

  const insightsBarchartDataError = useReselector(
    getFetchError,
    FETCH_INSIGHTS_BARCHART_DATA_KEY
  )

  const isChartDataEmpty = isEmpty(filteredData)

  return (
    <>
      <GridRowColumn>
        <Text as="h2" style={{ marginBottom: 12 }}>
          {insightsCopyConstants[type].trackPageTitle}
        </Text>
        <Text color="darkGray">
          {insightsCopyConstants[type].trackPageDescription}
        </Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Divider style={{ padding: 0, margin: 0 }} />
      </GridRowColumn>
      {queryLoading ? (
        <GridRowColumn>
          <Loader loading />
        </GridRowColumn>
      ) : (
        <>
          <Grid.Row
            className="short"
            verticalAlign="middle"
            style={{ paddingLeft: 12 }}
          >
            <Grid.Column style={{ minWidth: 350 }}>
              <InsightsDividedCard
                columns={[
                  {
                    content: (
                      <Text as="bodySm" style={{ padding: 3 }}>
                        Last 12 months
                      </Text>
                    ),
                  },
                  {
                    content: (
                      <Text as="bodySm" style={{ padding: 3 }}>
                        {dateRangeString}
                      </Text>
                    ),
                  },
                ]}
              />
            </Grid.Column>
            <Grid.Column width={6} style={{ minWidth: 180 }}>
              {type !== INSIGHTS_TYPE.PROFIT && (
                <Dropdown
                  variant="checked"
                  value={filter}
                  options={dropdownOptions}
                  onChange={setFilter}
                  dividerAfter={type === INSIGHTS_TYPE.EXPENSES ? [1] : [0]}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="short" verticalAlign="middle" />
          <GridRowColumn>
            {insightsBarchartDataError ? (
              <GridRowColumn>
                <Grid>
                  <GridRowColumn style={{ textAlign: 'center' }}>
                    <Text as="h3">Something Went Wrong</Text>
                  </GridRowColumn>
                  <GridRowColumn style={{ textAlign: 'center' }}>
                    <Text color="darkGray">Please try again in a bit.</Text>
                  </GridRowColumn>
                </Grid>
              </GridRowColumn>
            ) : (
              <InsightsChart
                data={chartData}
                categories={chartCategories || {}}
                filteredCategories={filteredChartCategories || {}}
                filter={filter}
                isChartDataEmpty={isChartDataEmpty}
                type={type}
              />
            )}
          </GridRowColumn>
        </>
      )}
    </>
  )
}
