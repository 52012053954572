import { useState, useEffect } from 'react'
import { Dimmer, Grid, Loader } from 'semantic-ui-react'
import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import { fetchPlaidItemsIfNeeded } from '../../../actions/plaidItemActions'
import {
  useAnalyticsTrack,
  useAnalyticsView,
} from '../../../features/Amplitude'
import {
  Container,
  GridRowColumn,
  Text,
  Button,
  Link,
} from '../../BaseComponents'
import {
  activateCancelledAccount,
  DOWNLOAD_ACCOUNT_ZIP_KEY,
  downloadAccountInformation,
} from '../../../actions/userActions'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import { useNavigate } from 'react-router-dom'
import { sendUserCancellationMessageZap } from '../../../actions/zapierActions'
import { selectIsFetchingForKeys } from '../../../reducers/fetch'
import AccountDownloadModal from './AccountDownloadModal'
import { useAppDispatch } from '../../../utils/typeHelpers'

const CanceledAccount = () => {
  const dispatch = useAppDispatch()
  const [fetching, setFetching] = useState(true)
  const pageView = useAnalyticsView()
  const track = useAnalyticsTrack()
  const navigate = useNavigate()
  const currentUser = useReselector(getCurrentUser)
  const [downloadModalOpen, setDownloadModalOpen] = useState(false)
  const downloadingFiles = useReselector(selectIsFetchingForKeys, [
    DOWNLOAD_ACCOUNT_ZIP_KEY,
  ])

  useEffect(() => {
    pageView('account-canceled')
  }, [pageView])

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchFinancialAccountsIfNeeded())
      await dispatch(fetchPlaidItemsIfNeeded())
      setFetching(false)
    }

    fetch()
  }, [dispatch])

  const downloadZipFile = async () => {
    track('download account zip file — canceled already')

    const result = await downloadAccountInformation(currentUser?.id)(dispatch)
    if (result) {
      setDownloadModalOpen(true)
    }
  }

  /**
   * User is reactivating a canceled account.
   *
   * We:
   * - Call canceled_account_reactivation endpoint, which sets user state to reactivating (update this comment in CORE-253)
   * - Navigate user to sign up flow with a reactivation param (user needs to re-sign up)
   */
  const canceledToReactivate = async () => {
    const result = await activateCancelledAccount(currentUser?.id)(dispatch)
    // reactivated, nav to sign up
    if (result) {
      sendUserCancellationMessageZap(
        currentUser,
        'reactivated_cancelled_account'
      )
      navigate('/signup/about-your-practice')
    }
  }

  return (
    <>
      <Container fluid className="canceledAccount">
        {fetching && (
          <Dimmer active inverted>
            <Loader inverted>Loading account</Loader>
          </Dimmer>
        )}
        <Grid centered>
          <GridRowColumn />
          <Grid.Row>
            <Grid.Column width={10} textAlign="center">
              <Text as="h2">You’ve canceled your Heard account</Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center" width={10}>
              <Text as="bodyLg">
                You’re unable to use Heard without a paid account. Don’t worry,
                you’ll still be able to download your financial information and
                documents if needed.
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <Button
                variant="primary"
                fullWidth
                onClick={() => canceledToReactivate()}
              >
                Reactivate Account
              </Button>
              <br />
              {/* </Link> */}
            </Grid.Column>
            <Grid.Column width={5}>
              <Button
                variant="secondary"
                fullWidth
                onClick={downloadZipFile}
                loading={downloadingFiles}
                disabled={downloadingFiles}
              >
                Download Account Information
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Text>
                Is this a mistake? Contact us via email at{' '}
                <Link as="secondaryLink" href="mailto:support@joinheard.com">
                  support@joinheard.com
                </Link>
                .
              </Text>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <AccountDownloadModal
        open={downloadModalOpen}
        close={() => setDownloadModalOpen(false)}
      />
    </>
  )
}

export default CanceledAccount
