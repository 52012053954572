import { User } from '../reducers/auth/userReducer'

const isProd = process.env.VITE_IS_PROD === 'true'

export const sendOnboardingZap = (data: Record<string, unknown>) => () => {
  if (isProd) {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', 'https://hooks.zapier.com/hooks/catch/5656350/b69vady/')
    xhr.send(JSON.stringify(data))
  }
}

export const sendUserCRMCreationZap = (
  user: Pick<
    User,
    'firstName' | 'lastName' | 'email' | 'createdAt' | 'financialProfile'
  >
) => {
  if (isProd) {
    const xhr = new XMLHttpRequest()
    const data = {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      startDate: user.createdAt,
      financialProfile: user.financialProfile,
    }
    xhr.open('POST', 'https://hooks.zapier.com/hooks/catch/5656350/bh2imxr')
    xhr.send(JSON.stringify(data))
  }
}

export const sendUserCancellationMessageZap = (
  user: User | null,
  event: string
) => {
  if (isProd) {
    const xhr = new XMLHttpRequest()

    let msg
    switch (event) {
      case 'submit_cancel':
        msg = 'Submitted Self Serve Cancellation Request'
        break
      case 'manual_cancel':
        msg = 'Submitted Cancellation Request'
        break
      case 'clicked_canceled':
        msg = 'Clicked into Self Serve Cancellation Modal: Check in'
        break
      case 'reactivated_cancelled_account':
        msg = 'Clicked to Reactivate Cancelled Account'
        break
      case 'reactivated_cancelling_account':
        msg = 'Clicked to Reactivate a Cancelling Account'
        break
      default:
        msg = event
        break
    }
    const data = {
      name: `${user?.firstName} ${user?.lastName}`,
      email: user?.email,
      startDate: user?.createdAt,
      financialProfile: user?.financialProfile,
      message: msg,
    }
    xhr.open('POST', 'https://hooks.zapier.com/hooks/catch/5656350/3cwnuoe/')
    xhr.send(JSON.stringify(data))
  }
}
