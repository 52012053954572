import React, { useState } from 'react'
import { Table, Confirm } from 'semantic-ui-react'
import { Button } from '../../BaseComponents'
import Moment from 'react-moment'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import {
  adminDeleteUserActionItem,
  UserActionItem,
} from '../../../features/Dashboard/UserActionItems/userActionItems.slice'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'

const UserActionItemRow = ({
  userActionItem,
  user,
}: {
  userActionItem: UserActionItem
  user: UserWithAdminInfo
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const dispatch = useAppDispatch()
  const removeAction = async () => {
    const res = await adminDeleteUserActionItem(
      userActionItem.id,
      user.id
    )(dispatch)
    if (res) {
      setConfirmOpen(false)
    }
  }

  return (
    <Table.Row>
      <Table.Cell>{userActionItem.id}</Table.Cell>
      <Table.Cell>{userActionItem.actionItem?.description}</Table.Cell>
      <Table.Cell>
        {userActionItem.actionItem?.actionItemCategory?.title}
      </Table.Cell>
      <Table.Cell>{userActionItem.actionItem.actionLink}</Table.Cell>
      <Table.Cell>
        {userActionItem.createdAt && (
          <Moment format={DATE_FORMATS.DISPLAY_SHORT}>
            {userActionItem.createdAt}
          </Moment>
        )}
      </Table.Cell>
      <Table.Cell error={!userActionItem.completedAt}>
        {userActionItem.completedAt && (
          <Moment format={DATE_FORMATS.DISPLAY_SHORT}>
            {userActionItem.completedAt}
          </Moment>
        )}
      </Table.Cell>
      <Table.Cell>
        <Button variant="link" onClick={() => setConfirmOpen(true)}>
          Remove
        </Button>
        <Confirm
          size="mini"
          cancelButton="Cancel"
          confirmButton="Remove Todo"
          content={
            "Are you sure you want to remove this action item from the User's Dashboard?"
          }
          open={confirmOpen}
          onCancel={() => setConfirmOpen(false)}
          onConfirm={() => removeAction()}
        />
      </Table.Cell>
    </Table.Row>
  )
}
export const UserActionItemsTable = ({
  actionItems,
  user,
}: {
  actionItems: UserActionItem[]
  user: UserWithAdminInfo
}) => {
  return (
    <Table striped compact="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Id</Table.HeaderCell>
          <Table.HeaderCell>Todo Name</Table.HeaderCell>
          <Table.HeaderCell>Action Category</Table.HeaderCell>
          <Table.HeaderCell>Action Link</Table.HeaderCell>
          <Table.HeaderCell>Action Assigned On</Table.HeaderCell>
          <Table.HeaderCell>Completed On</Table.HeaderCell>
          <Table.HeaderCell>Delete</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {actionItems.map((item, key) => {
          return (
            <UserActionItemRow userActionItem={item} user={user} key={key} />
          )
        })}
      </Table.Body>
    </Table>
  )
}
