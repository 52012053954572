import { ReactNode, useEffect } from 'react'
import { Grid, Container, Divider, Image } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  FormikLabelError,
  FormikRadioButton,
  FormikRadioToggleButton,
  getFieldName,
  GridRowColumn,
  Icon,
  makeReqStringSchema,
  Text,
} from '../../../components/BaseComponents'
import SignupHeader from '../SignupHeader'
import { updateFinancialProfile } from '../../../actions/financialProfileActions'
import {
  getCurrentUser,
  getFinancialProfile,
} from '../../../selectors/user.selectors'
import Popup from '../../../components/BaseComponents/Popup'
import { SIGNUP_PATHS, C_CORP_TAX_ENTITY } from '../helpers'
import { useSignupPageUpdate } from '../../../services/onboardingService'
import { TAX_ENTITY_TYPES_TYPE } from '../../Taxes/taxConstants'
import { useAnalyticsView } from '../../Amplitude'
import {
  useNavigateWithPersistParams,
  useReselector,
  useScrollRef,
} from '../../../utils/sharedHooks'
import {
  ReferralStatus,
  updateUserReferralStatusIfExists,
} from '../../Referrals/referrals.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'

const validationSchema = yup.object({
  taxEntityType: makeReqStringSchema({}),
  businessEntity: makeReqStringSchema({}),
  accountsSeparate: yup.boolean().nullable().required(),
  willingAccountsSeparate: yup
    .boolean()
    .nullable()
    .when('accountsSeparate', ([accountsSeparate], schema) =>
      accountsSeparate === false ? yup.boolean().nullable().required() : schema
    ),
  multipleOwners: yup.boolean().nullable().required(),
  over10Employees: yup.boolean().nullable().required(),
  fileInternationalTaxes: yup.boolean().nullable().required(),
})

const QuestionPopup = ({ popupContent }: { popupContent: ReactNode }) => (
  <Popup
    style={{ cursor: 'pointer' }}
    content={popupContent}
    trigger={
      <Icon
        icon={regular('question-circle')}
        style={{ marginLeft: 10 }}
        color="mediumGray"
        size="sm"
      />
    }
    position="bottom right"
  />
)

const QuestionRow = ({
  name,
  label,
  popupContent,
}: {
  name: string
  label: string
  popupContent: ReactNode
}) => (
  <>
    <GridRowColumn>
      <FormikLabelError
        name={name}
        label={label}
        required
        afterLabel={<QuestionPopup popupContent={popupContent} />}
      />
    </GridRowColumn>
    <Grid.Row className="short">
      <Grid.Column width={3}>
        <FormikRadioToggleButton name={name} value fullWidth>
          Yes
        </FormikRadioToggleButton>
      </Grid.Column>
      <Grid.Column width={3}>
        <FormikRadioToggleButton name={name} value={false} fullWidth>
          No
        </FormikRadioToggleButton>
      </Grid.Column>
    </Grid.Row>
  </>
)

const AboutYourPractice = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigateWithPersistParams()
  const financialProfile = useReselector(getFinancialProfile)
  const pageView = useAnalyticsView()
  const { scrollRef, scrollToRef } = useScrollRef({ autoScroll: true })
  const currentUser = useReselector(getCurrentUser)
  const { email } = currentUser || {}
  useEffect(() => {
    if (email) {
      updateUserReferralStatusIfExists({
        recipientEmail: email,
        status: ReferralStatus.account_created,
      })(dispatch)
    }
  }, [email, dispatch])

  useEffect(() => {
    pageView('sign up about your practice')
  }, [pageView])

  useEffect(() => {
    scrollToRef()
  }, [scrollToRef])

  useSignupPageUpdate(SIGNUP_PATHS.aboutYourPractice)

  const formik = useFormik({
    initialValues: {
      taxEntityType: financialProfile?.taxEntityType as
        | TAX_ENTITY_TYPES_TYPE
        | 'other',
      businessEntity: financialProfile?.businessEntity || undefined,
      accountsSeparate: financialProfile?.accountsSeparate,
      willingAccountsSeparate: financialProfile?.willingAccountsSeparate,
      multipleOwners: null as boolean | null,
      over10Employees: null as boolean | null,
      becomeSCorp: null as boolean | null,
      fileInternationalTaxes: financialProfile?.fileInternationalTaxes,
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: async ({
      taxEntityType,
      businessEntity,
      accountsSeparate,
      willingAccountsSeparate,
      multipleOwners,
      over10Employees,
      fileInternationalTaxes,
      becomeSCorp,
    }) => {
      if (
        (taxEntityType === C_CORP_TAX_ENTITY && !becomeSCorp) ||
        (!accountsSeparate && !willingAccountsSeparate) ||
        multipleOwners ||
        fileInternationalTaxes
      ) {
        navigate(SIGNUP_PATHS.disqualified, {
          hasInternationalTaxes: fileInternationalTaxes ? 'true' : 'false',
          multipleOwners: multipleOwners ? 'true' : 'false',
          ccorp: taxEntityType === C_CORP_TAX_ENTITY ? 'true' : 'false',
          sharedAccounts:
            !accountsSeparate && !willingAccountsSeparate ? 'true' : 'false',
        })
      } else if (over10Employees) {
        navigate(SIGNUP_PATHS.waitlist)
      } else {
        const taxEntityValue =
          taxEntityType === 'other' ? 'schedule_c' : taxEntityType
        const res = await updateFinancialProfile(financialProfile?.id, {
          accountsSeparate,
          willingAccountsSeparate,
          businessEntity,
          taxEntityType: taxEntityValue,
          fileInternationalTaxes,
        })(dispatch)

        if (res) {
          navigate(SIGNUP_PATHS.practiceJourney)
        }
      }
    },
  })

  const { values, submitForm, isSubmitting, isValid } = formik

  return (
    <Container style={{ paddingTop: 64 }}>
      <FormikProvider value={formik}>
        <Grid stackable>
          <span ref={scrollRef} />
          <SignupHeader currentStep={1} />
          <Grid.Row>
            <Grid.Column width={10}>
              <Grid>
                <GridRowColumn>
                  <Text as="h1">Tell us about your practice</Text>
                </GridRowColumn>
                <GridRowColumn>
                  <FormikLabelError
                    name={getFieldName<typeof values>('businessEntity')}
                    label="Your practice is incorporated as a..."
                    required
                  />
                  <FormikRadioButton
                    name={getFieldName<typeof values>('businessEntity')}
                    value="sprop"
                    label="Sole Proprietor"
                    style={{ marginTop: 8 }}
                  />
                  <br />
                  <FormikRadioButton
                    name={getFieldName<typeof values>('businessEntity')}
                    value="llc_single"
                    label="LLC"
                    style={{ marginTop: 8 }}
                  />
                  <br />
                  <FormikRadioButton
                    name={getFieldName<typeof values>('businessEntity')}
                    value="pllc_single"
                    label="PLLC"
                    style={{ marginTop: 8 }}
                  />
                  <br />
                  <FormikRadioButton
                    name={getFieldName<typeof values>('businessEntity')}
                    value="c_corp"
                    label="C-Corp"
                    style={{ marginTop: 8 }}
                  />
                  <br />
                  <FormikRadioButton
                    name={getFieldName<typeof values>('businessEntity')}
                    value="other"
                    label="Other"
                    style={{ marginTop: 8 }}
                  />
                </GridRowColumn>
                <GridRowColumn>
                  <FormikLabelError
                    name={getFieldName<typeof values>('taxEntityType')}
                    afterLabel={
                      <QuestionPopup
                        popupContent={
                          <>
                            <Text as="h3">Tax entities</Text>
                            <br />
                            <Text>
                              <b>Sole Proprietor</b> - You own your business by
                              yourself.
                            </Text>
                            <Text>
                              <b>S Corporation</b> - Your business&apos;s
                              corporate income, losses, deductions, and credit
                              are passed through shareholders for federal tax
                              purposes
                            </Text>
                            <Text>
                              <b>C-Corp</b> - Your business&apos;s owners, or
                              shareholders, are taxed separately from the
                              entity.
                            </Text>
                            <Divider />
                            <Text>
                              If none of the above applies or if you aren&apos;t
                              sure, select <b>Other</b> and we’ll help with this
                              later.
                            </Text>
                          </>
                        }
                      />
                    }
                    label="Your practice is taxed as a..."
                    required
                  />
                  <FormikRadioButton
                    name={getFieldName<typeof values>('taxEntityType')}
                    value="form_1040"
                    label="Sole Proprietor"
                    style={{ marginTop: 8 }}
                  />
                  <br />
                  <FormikRadioButton
                    name={getFieldName<typeof values>('taxEntityType')}
                    value="form_1120_s"
                    label="S Corporation"
                    style={{ marginTop: 8 }}
                  />
                  <br />
                  <FormikRadioButton
                    name={getFieldName<typeof values>('taxEntityType')}
                    value="form_1120"
                    label="C-Corporation"
                    style={{ marginTop: 8 }}
                  />
                  <br />
                  <FormikRadioButton
                    name={getFieldName<typeof values>('taxEntityType')}
                    label="Other"
                    style={{ marginTop: 8 }}
                    value="other"
                  />
                </GridRowColumn>
                <br />
                {values.taxEntityType === C_CORP_TAX_ENTITY && (
                  <QuestionRow
                    name={getFieldName<typeof values>('becomeSCorp')}
                    label="Did you intend on becoming an S corporation for the 2023 tax year?"
                    popupContent={
                      <>
                        <Text as="h3">Becoming an S corporation</Text>
                        <br />
                        <Text>
                          If you plan on transitioning to an S corporation,
                          select <b>Yes</b>.
                        </Text>
                      </>
                    }
                  />
                )}
                <>
                  <GridRowColumn>
                    <Text as="h1">
                      Do any of these situations apply to you?
                    </Text>
                  </GridRowColumn>
                  <GridRowColumn>
                    <FormikLabelError
                      name={getFieldName<typeof values>('accountsSeparate')}
                      label="How do you pay for business expenses?"
                      required
                      afterLabel={
                        <QuestionPopup
                          popupContent={
                            <>
                              <Text as="h3">Commingled banking activity</Text>
                              <br />
                              <Text>
                                If you have personal and business transactions
                                in the same bank account, select{' '}
                                <b>Personal bank account</b>.
                              </Text>
                            </>
                          }
                        />
                      }
                    />
                  </GridRowColumn>
                  <Grid.Row className="short">
                    <Grid.Column computer={6} tablet={6} mobile={8}>
                      <FormikRadioToggleButton
                        name={getFieldName<typeof values>('accountsSeparate')}
                        value
                        fullWidth
                      >
                        Business bank account
                      </FormikRadioToggleButton>
                    </Grid.Column>
                    <Grid.Column computer={6} tablet={6} mobile={8}>
                      <FormikRadioToggleButton
                        name={getFieldName<typeof values>('accountsSeparate')}
                        value={false}
                        fullWidth
                      >
                        Personal bank account
                      </FormikRadioToggleButton>
                    </Grid.Column>
                  </Grid.Row>
                </>
                {values.accountsSeparate === false && (
                  <QuestionRow
                    name={getFieldName<typeof values>(
                      'willingAccountsSeparate'
                    )}
                    label="Are you willing to separate your personal and business banking activity?"
                    popupContent={
                      <>
                        <Text as="h3">
                          Separating your personal and business banking activity
                        </Text>
                        <br />
                        <Text>
                          Paying for business and personal expenses from the
                          same account puts your practice at risk.
                        </Text>
                        <br />
                        <Text>
                          We&apos;ll guide you through separating your
                          transactions after you’ve signed up.
                        </Text>
                      </>
                    }
                  />
                )}
                <QuestionRow
                  name={getFieldName<typeof values>('multipleOwners')}
                  label="Does your practice have multiple owners, for e.g. another practitioner, spouse?"
                  popupContent={
                    <>
                      <Text as="h3">Multiple owners</Text>
                      <br />
                      <Text>
                        If you and another practitioner or your spouse own the
                        practice together, select <b>Yes</b>.
                      </Text>
                    </>
                  }
                />
                <QuestionRow
                  name={getFieldName<typeof values>('over10Employees')}
                  label="Does your practice have more than 10 employees?"
                  popupContent={
                    <>
                      <Text as="h3">Number of employees</Text>
                      <br />
                      <Text>
                        If you have more than 10 full-time employees, select{' '}
                        <b>Yes</b>.
                      </Text>
                    </>
                  }
                />
                <QuestionRow
                  name={getFieldName<typeof values>('fileInternationalTaxes')}
                  label="Does your practice need to pay international taxes?"
                  popupContent={
                    <>
                      <Text as="h3">International taxes</Text>
                      <br />
                      <Text>
                        If your practice pays taxes to countries other than the
                        United States, select <b>Yes.</b>. (This includes U.S.
                        territories).
                      </Text>
                    </>
                  }
                />
                <Grid.Row />
              </Grid>
            </Grid.Column>
            <Grid.Column width={6} verticalAlign="middle">
              <Image
                src="https://heard-images.s3.us-west-1.amazonaws.com/assets/signup/about_situations.png"
                style={{ height: 453 }}
              />
            </Grid.Column>
          </Grid.Row>

          <Divider />
          <Grid.Row />
          <GridRowColumn spacer={14} width={2}>
            <Button
              fullWidth
              onClick={submitForm}
              disabled={!isValid || isSubmitting}
              id="btn-signup-about-practice"
              loading={isSubmitting}
            >
              Continue
            </Button>
          </GridRowColumn>
          <Grid.Row />
        </Grid>
      </FormikProvider>
    </Container>
  )
}

export default AboutYourPractice
