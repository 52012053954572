import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import { times } from 'lodash'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Alert,
  Card,
  FormikDateInput,
  FormikDropdown,
  FormikInput,
  FormikLocationSearchInput,
  getFieldNames,
  GridRowColumn,
  Icon,
  IconButton,
  Link,
  makeDateSchema,
  makeEinSchema,
  makeReqPhoneNumberSchema,
  makeReqStringSchema,
  Popup,
  Text,
} from '../../components/BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import {
  getCurrentUser,
  getFinancialProfile,
  selectIsCurrentUserScorp,
} from '../../selectors/user.selectors'
import { fetchUserDocuments } from '../UserDocuments/userDocuments.slice'
import { fetchUserDocumentCategoriesIfNeeded } from '../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { BUSINESS_ENTITY_OPTIONS } from '../../constants/onboardingConstants'
import { STATES } from '../../constants/locationConstants'
import { LabelDescription } from '../../components/BaseComponents/Input'
import ChangeEmailModal from '../../components/Finances/Accounts/ChangeEmailModal'
import {
  CP261Tooltip,
  EinLetterTooltip,
  Form2553Tooltip,
  IncorporationDocTooltip,
  StateRegistrationTooltip,
} from './PracticeProfileTooltips'
import { UploadDocumentType } from '../../constants/businessConstants'
import FileUploadFormInput from '../../components/FileUpload/FileUploadFormInput'
import {
  UPDATE_FINANCIAL_PROFILE_KEY,
  updateFinancialProfile,
} from '../../actions/financialProfileActions'
import { UPDATE_USER_KEY, updateUser } from '../../actions/userActions'
import PracticeProfileFormCta from './PracticeProfileFormCta'
import { selectErrorsForKeys } from '../../reducers/fetch'
import { markUserActionItemCompleteIfExists } from '../Dashboard/UserActionItems/service'
import { PostOnboardingTaskIdents } from '../Onboarding/config'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import PageHeader from '../../components/shared/PageHeader'
import { useAnalyticsTrack } from '../Amplitude'
import { useAppDispatch } from '../../utils/typeHelpers'
import { UserDocumentCategoryIdentifier } from '../Admin/UserDocumentCategories/userDocumentCategory.constants'

const YourBusinessForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const track = useAnalyticsTrack()
  const user = useReselector(getCurrentUser)
  const fp = useReselector(getFinancialProfile)
  const isScorp = useReselector(selectIsCurrentUserScorp)
  const [changeEmailModalOpen, setChangeEmailModalOpen] = useState(false)
  const errors = useReselector(selectErrorsForKeys, [
    UPDATE_USER_KEY,
    UPDATE_FINANCIAL_PROFILE_KEY(fp?.id),
  ])

  useEffect(() => {
    dispatch(fetchUserDocuments())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      businessName: fp?.businessName,
      businessEntity: fp?.businessEntity,
      einNumber: fp?.einNumber,
      yearPracticeStarted: fp?.yearPracticeStarted,
      businessAddress: fp?.businessAddress,
      homeState: fp?.homeState,
      scorpRegistrationState: fp?.scorpRegistrationState,
      entityChangeElectionDate:
        fp?.entityChangeElectionDate &&
        moment.utc(fp?.entityChangeElectionDate).format(DATE_FORMATS.INPUT),
      phoneNumber: user?.phoneNumber,
    },

    onSubmit: async ({
      businessName,
      businessEntity,
      einNumber,
      yearPracticeStarted,
      businessAddress,
      homeState,
      scorpRegistrationState,
      entityChangeElectionDate,
      phoneNumber,
    }) => {
      const fpUpdateRes = await dispatch(
        updateFinancialProfile(fp?.id, {
          businessName,
          businessEntity,
          einNumber,
          yearPracticeStarted,
          businessAddress,
          homeState,
          scorpRegistrationState,
          entityChangeElectionDate,
        })
      )

      const userUpdateRes = await dispatch(
        updateUser(user?.id, { phoneNumber })
      )

      await markUserActionItemCompleteIfExists(
        PostOnboardingTaskIdents.FILL_OUT_BUSINESS_PROFILE,
        (event, properties) => track(event, properties)
      )

      if (fpUpdateRes && userUpdateRes) {
        navigate('/practice/profile')
      }
    },
  })

  const { submitForm, isSubmitting } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <Grid>
      <GridRowColumn>
        <Link to="/practice/profile">
          <Icon icon={regular('arrow-left')} style={{ marginRight: 8 }} />
          Back to Practice Profile
        </Link>
      </GridRowColumn>
      <GridRowColumn>
        <PageHeader header="Your Business" />
      </GridRowColumn>
      <GridRowColumn>
        <Card type="subsection" backgroundColor="stone40">
          <FormikProvider value={formik}>
            <Grid>
              {Boolean(errors?.length) && (
                <GridRowColumn>
                  <Alert type="error">{errors[0].message}</Alert>
                </GridRowColumn>
              )}
              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikInput
                  name={fieldNames.businessName}
                  label="Business Name"
                  fullWidth
                  schema={makeReqStringSchema({ field: 'business name' })}
                />
              </GridRowColumn>
              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikDropdown
                  name={fieldNames.businessEntity}
                  label="Business Entity"
                  fullWidth
                  options={BUSINESS_ENTITY_OPTIONS}
                  schema={yup.string().nullable()}
                />
              </GridRowColumn>
              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikInput
                  name={fieldNames.einNumber}
                  label="Business EIN Number"
                  fullWidth
                  format="##-#######"
                  componentType="pattern"
                  schema={makeEinSchema({ required: false })}
                />
              </GridRowColumn>
              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikDropdown
                  label="Year Practice Started"
                  placeholder="Select Year"
                  name={fieldNames.yearPracticeStarted}
                  // Gets a list of years between now and 100 years ago
                  optionValues={times(100).map((value) =>
                    (moment().year() - value).toString()
                  )}
                  fullWidth
                  schema={makeReqStringSchema({
                    field: 'year practice started',
                  })}
                />
              </GridRowColumn>
              <Grid.Row />
              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikLocationSearchInput
                  label="Business Address"
                  placeholder="Street address, city, state, zip code"
                  name={fieldNames.businessAddress}
                  schema={makeReqStringSchema({ field: 'business address' })}
                />
              </GridRowColumn>
              <GridRowColumn computer={3} tablet={3} mobile={10}>
                <FormikDropdown
                  label="Home State"
                  placeholder="Select state"
                  name={fieldNames.homeState}
                  selectOnBlur={false}
                  options={STATES}
                  fullWidth
                  schema={makeReqStringSchema({ field: 'home state' })}
                />
              </GridRowColumn>

              {isScorp && (
                <>
                  <GridRowColumn computer={3} tablet={3} mobile={10}>
                    <FormikDropdown
                      label="Business Registration State"
                      placeholder="Select state"
                      name={fieldNames.scorpRegistrationState}
                      selectOnBlur={false}
                      options={STATES}
                      fullWidth
                      schema={makeReqStringSchema({
                        field: 'business registration state',
                      })}
                    />
                  </GridRowColumn>

                  <GridRowColumn computer={4} tablet={4} mobile={10}>
                    <FormikDateInput
                      label="S Corp Election Date"
                      fullWidth
                      name={fieldNames.entityChangeElectionDate}
                      schema={makeDateSchema()}
                    />
                  </GridRowColumn>
                </>
              )}

              <Grid.Row />

              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikInput
                  name={fieldNames.phoneNumber}
                  label="Business Phone"
                  fullWidth
                  componentType="phone"
                  schema={makeReqPhoneNumberSchema()}
                />
              </GridRowColumn>

              <GridRowColumn>
                <LabelDescription label="Business Email" />
                <Text as="bodyLg" style={{ marginTop: 20 }}>
                  {user?.email}
                  <IconButton
                    icon={regular('pen-to-square')}
                    onClick={() => setChangeEmailModalOpen(true)}
                    style={{ marginLeft: 8 }}
                  />
                </Text>
              </GridRowColumn>

              <Grid.Row />

              {isScorp ? (
                <>
                  <GridRowColumn>
                    <FileUploadFormInput
                      label="Form 2553"
                      afterLabel={<Popup content={<Form2553Tooltip />} />}
                      documentCat={UserDocumentCategoryIdentifier.form2553}
                    />
                  </GridRowColumn>
                  <GridRowColumn>
                    <FileUploadFormInput
                      label="CP261 Letter"
                      afterLabel={<Popup content={<CP261Tooltip />} />}
                      documentCat={UserDocumentCategoryIdentifier.cp261Notice}
                    />
                  </GridRowColumn>
                  <GridRowColumn>
                    <FileUploadFormInput
                      label="Incorporation Document"
                      afterLabel={
                        <Popup content={<IncorporationDocTooltip />} />
                      }
                      documentCat={
                        UserDocumentCategoryIdentifier.businessIncorporation
                      }
                      documentType={UploadDocumentType.INCORPORATION}
                    />
                  </GridRowColumn>
                </>
              ) : (
                <>
                  <GridRowColumn>
                    <FileUploadFormInput
                      label="EIN Letter"
                      afterLabel={<Popup content={<EinLetterTooltip />} />}
                      documentCat={UserDocumentCategoryIdentifier.einDocuments}
                    />
                  </GridRowColumn>
                  <GridRowColumn>
                    <FileUploadFormInput
                      label="State Registration"
                      afterLabel={
                        <Popup content={<StateRegistrationTooltip />} />
                      }
                      documentCat={
                        UserDocumentCategoryIdentifier.stateRegistration
                      }
                      documentType={UploadDocumentType.INCORPORATION}
                    />
                  </GridRowColumn>
                </>
              )}
              <Grid.Row />

              <PracticeProfileFormCta
                submitForm={submitForm}
                isValid
                isSubmitting={isSubmitting}
              />
            </Grid>
          </FormikProvider>
        </Card>
      </GridRowColumn>
      <ChangeEmailModal
        open={changeEmailModalOpen}
        close={() => setChangeEmailModalOpen(false)}
      />
    </Grid>
  )
}

export default YourBusinessForm
