import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { orderBy } from 'lodash'

import { ReduxState } from '../../utils/typeHelpers'
import { selectIsCurrentUserScorp } from '../../selectors/user.selectors'

export interface TicketComment {
  id: number
  authorUserId: number | null
  htmlBody: string
  body: string
  public: boolean
  createdAt: string
}

export enum TicketReviewStatus {
  inReview = 'in-review',
  yourTurn = 'customers-turn',
}

export const FORM_TYPE_FIELD_NAME = 'taxFormType'
export enum TicketFormType {
  FORM_1120_S = 'form_1120_s',
  FORM_1040 = 'form_1040',
}

export const DRAFT_STATUS_FIELD_NAME = 'taxDraftStatus'
export enum TaxDraftStatus {
  review = 'ready-for-review',
  accepted = 'accepted',
  rejected = 'rejected',
}

export enum ZendeskTicketStatus {
  new = 'new',
  open = 'open',
  pending = 'pending',
  hold = 'hold',
  solved = 'solved',
  closed = 'closed',
}

export interface Ticket {
  id: number
  comments?: number[]
  reviewStatus: TicketReviewStatus | null
  subject: string | null
  topic: string
  assigneeUserId?: number
  userId: number
  archivedAt?: string
  createdAt: string
  updatedAt: string
  status: ZendeskTicketStatus
  merged: boolean
  customFields: Array<{
    name: string
    value: string | null
    id: number
  }>
}

export const Payroll_TICKET_TOPIC = 'payroll'
export const OTHER_TICKET_TOPIC = 'other'
export const BOOKKEEPING_TICKET_TOPIC = 'bookkeeping'
export interface TicketTopic {
  identifier: string
  name: string
  order: number
  zendeskTicketForm: {
    name: string
    customFields: Record<string, unknown>
  }
}

interface ConversationState {
  tickets: {
    [key: number]: Ticket
  }
  ticketComments: {
    [key: number]: TicketComment
  }
  ticketTopics: { [key: string]: TicketTopic }
  yourTurnCount: number
}

const initialState: ConversationState = {
  tickets: {},
  ticketComments: {},
  ticketTopics: {},
  yourTurnCount: 0,
}

const getYourTurnCount = (tickets: { [key: number]: Ticket }) =>
  Object.values(tickets).filter(
    (ticket) =>
      ticket.reviewStatus === TicketReviewStatus.yourTurn && !ticket.archivedAt
  ).length

const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setTickets: (state, action: PayloadAction<{ [key: number]: Ticket }>) => {
      state.tickets = { ...state.tickets, ...action.payload }
      state.yourTurnCount = getYourTurnCount(state.tickets)
    },
    clearTickets: (state) => {
      state.tickets = {}
      state.ticketComments = {}
    },
    setTicketComments: (
      state,
      action: PayloadAction<{ [key: number]: TicketComment }>
    ) => {
      state.ticketComments = { ...state.ticketComments, ...action.payload }
    },
    setTicketTopics: (
      state,
      action: PayloadAction<{ [key: string]: TicketTopic }>
    ) => {
      state.ticketTopics = action.payload
    },
    updateTicket: (
      state,
      action: PayloadAction<Partial<Ticket> & { id: number }>
    ) => {
      // Don't update add a partial ticket to redux state
      if (!state.tickets[action.payload.id]) {
        return
      }
      state.tickets[action.payload.id] = {
        ...state.tickets[action.payload.id],
        ...action.payload,
      }
      state.yourTurnCount = getYourTurnCount(state.tickets)
    },
    updateYourTurnCount: (state, action: PayloadAction<number>) => {
      state.yourTurnCount = action.payload
    },
  },
})

export default conversationSlice.reducer

export const {
  setTickets,
  clearTickets,
  setTicketComments,
  setTicketTopics,
  updateTicket,
  updateYourTurnCount,
} = conversationSlice.actions

const selectConversationState = (state: ReduxState) => state.conversations

const selectTickets = createSelector(
  selectConversationState,
  (convoState) => convoState.tickets
)

const selectTicketList = createSelector(selectTickets, (tickets) =>
  Object.values(tickets)
)

const selectTicketTopicState = createSelector(
  selectConversationState,
  (convoState) => convoState.ticketTopics
)

export const selectTicketById = createSelector(
  selectTickets,
  (_: unknown, id?: string | number) => id,
  (tickets, id) => (id && tickets[Number(id)] ? tickets[Number(id)] : undefined)
)

export const selectInProgressTickets = createSelector(
  selectTicketList,
  (tickets) => tickets.filter((ticket) => !ticket.archivedAt)
)

export const selectYourTurnTickets = createSelector(
  selectInProgressTickets,
  (tickets) =>
    tickets.filter(
      (ticket) => ticket.reviewStatus === TicketReviewStatus.yourTurn
    )
)

export const selectArchivedTickets = createSelector(
  selectTicketList,
  (tickets) => tickets.filter((ticket) => ticket.archivedAt)
)

const selectTicketComments = createSelector(
  selectConversationState,
  (convoState) => convoState.ticketComments
)

export const selectTicketCommentById = createSelector(
  selectTicketComments,
  (_: unknown, id?: string | number) => id,
  (comments, id) =>
    id && comments[Number(id)] ? comments[Number(id)] : undefined
)

// Scorps have an additional payroll option
export const selectTicketTopics = createSelector(
  selectTicketTopicState,
  selectIsCurrentUserScorp,
  (ticketTopicState, isScorp) => {
    const sortedTickets = orderBy(ticketTopicState, 'order')

    return isScorp
      ? sortedTickets
      : sortedTickets.filter(
          (option) => option.identifier !== Payroll_TICKET_TOPIC
        )
  }
)

// Show button if ticket hasn't been archived yet
export const selectAllowCloseConversation = createSelector(
  selectTickets,
  (_: unknown, id?: string | number) => id,
  (tickets, id) => id && tickets[Number(id)] && !tickets[Number(id)]?.archivedAt
)

export const selectYourTurnNumber = createSelector(
  selectConversationState,
  (convoState) => convoState.yourTurnCount
)

export const selectCustomField = createSelector(
  selectTickets,
  (_: unknown, ticketId: number | string | undefined) => ticketId,
  (_: unknown, _a: unknown, customFieldName: string) => customFieldName,
  (tickets, ticketId, customFieldName) => {
    return tickets[Number(ticketId)]?.customFields.find(
      (field) => field.name === customFieldName
    )
  }
)

export const AUTO_CAT_HEADER = 'Auto-categorization Rule Request'
export const LIMITED_BANK_ACCESS_HEADER = 'Limited Bank Access Request'
export const selectDefaultTicketFields = createSelector(
  (state: ReduxState) => state.transactions.byId,
  (
    _: unknown,
    params: {
      recatTransactionId?: string | null
      transactionId?: string | null
      limitedBankAccess?: boolean
    }
  ) => params,
  (
    transactionsById,
    { recatTransactionId, transactionId, limitedBankAccess }
  ) => {
    if (recatTransactionId) {
      const transaction = transactionsById[recatTransactionId]

      if (transaction) {
        return {
          topicIden: BOOKKEEPING_TICKET_TOPIC,
          subject: `${AUTO_CAT_HEADER} - ${transaction.description.substring(
            0,
            50
          )}`,
          body: `Automatically categorize recurring transactions with this description:\n${transaction.description}\n\nCategorize as:\n\nShare any additional notes:`,
          htmlBody: `<p><b>Automatically categorize recurring transactions with this description:</b><br/>${transaction.description}<br/><br/>Categorize as:<br/><br/>Share any additional notes:</p>`,
        }
      }
    }
    if (transactionId) {
      const transaction = transactionsById[transactionId]

      if (transaction) {
        return {
          topicIden: BOOKKEEPING_TICKET_TOPIC,
          subject: `Transaction - ${transaction.description.substring(0, 50)}`,
          body: '',
          htmlBody: '',
        }
      }
    }
    if (limitedBankAccess) {
      return {
        topicIden: OTHER_TICKET_TOPIC,
        subject: LIMITED_BANK_ACCESS_HEADER,
        body: 'Hi,\nI would like to set up Heard with limited bank access to my business bank account. Can we start the process?',
        htmlBody:
          '<p>Hi,<br/>I would like to set up Heard with limited bank access to my business bank account. Can we start the process?</p>',
        tags: ['read-only-requested'],
      }
    }

    return {
      topicIden: BOOKKEEPING_TICKET_TOPIC,
      subject: '',
      body: '',
      htmlBody: '',
    }
  }
)
