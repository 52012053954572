import { useMemo } from 'react'
import moment from 'moment'
import { Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Text,
  Icon,
  GridRowColumn,
} from '../../../../../components/BaseComponents'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import DeadlineLabel from '../DeadlineLabel'
import { DATE_FORMATS } from '../../../../../utils/dateHelpers'
import { UserTaxEstimate } from '../../userTaxEstimates.slice'
import SafeHarborPaymentCard from './SafeHarborPaymentCard'
import FederalSafeHarborCard from './FederalSafeHarborCard'
import StateSafeHarborCard from './StateSafeHarborCard'

const UserJoinedTooLateV2 = ({
  activeQuarterText,
  federalTaxEstimate,
}: {
  activeQuarterText: string
  federalTaxEstimate: UserTaxEstimate | null
}) => {
  const activeQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )

  const federalContent = useMemo(() => {
    if (!activeQuarterDetails || !federalTaxEstimate) {
      return null
    }
    if (
      federalTaxEstimate.estimateInCents >= 0 &&
      federalTaxEstimate.safeHarborType
    ) {
      return <SafeHarborPaymentCard />
    } else {
      return <FederalSafeHarborCard />
    }
  }, [federalTaxEstimate, activeQuarterDetails])

  return (
    <Grid>
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={15}>
          <Text as="h2">{activeQuarterText} Estimates</Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ padding: '0' }}>
        <Grid.Column>
          <DeadlineLabel
            icon={
              <Icon
                style={{ paddingRight: 8 }}
                icon={regular('square-dollar')}
              />
            }
            text={'IRS payment due'}
            prominentText={moment
              .utc(activeQuarterDetails?.irsPaymentDueAt)
              .format(DATE_FORMATS.DISPLAY_LONG)}
          />
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn>{federalContent}</GridRowColumn>
      <GridRowColumn>
        <StateSafeHarborCard />
      </GridRowColumn>
    </Grid>
  )
}

export default UserJoinedTooLateV2
