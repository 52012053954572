import styled from 'styled-components'
import { Grid } from 'semantic-ui-react'
import moment from 'moment'

import { useReselector } from '../../../utils/sharedHooks'
import {
  selectTicketById,
  selectTicketCommentById,
} from '../conversation.slice'
import { Card, GridRowColumn, Text } from '../../../components/BaseComponents'
import { getCurrentUser } from '../../../selectors/user.selectors'
import TeamMemberIcon from '../TeamMemberIcon'
import { Fonts } from '../../../styles/theme'
import { selectTaxTeamMemberById } from '../../../reducers/taxTeam.slice'
import { DEFAULT_HEARD_ADMIN_NAME, getRoleNameText } from '../helpers'
import { DATE_FORMATS } from '../../../utils/dateHelpers'

const TimeStamp = ({
  time,
  textAlign,
}: {
  time: string
  textAlign: 'right' | 'left'
}) => (
  <Text as="eyebrow" textAlign={textAlign} color="mediumGray">
    SENT AT{' '}
    {moment(time).format(`${DATE_FORMATS.TIME} ${DATE_FORMATS.MONTH_DAY}`)}
  </Text>
)

export const StyledConversationMessage = styled(
  ({ className, htmlBody }: { className?: string; htmlBody?: string }) => (
    <div
      // skipcq: JS-0440 - This is a trusted source
      dangerouslySetInnerHTML={{ __html: htmlBody || '' }}
      className={className}
    />
  )
)({
  ...Fonts.bodyMd,
  p: {
    ...Fonts.bodyMd,
  },
  'ol, ul': {
    margin: 0,
  },
})

const Message = ({
  messageId,
  fullWidth,
}: {
  messageId: number
  fullWidth?: boolean
}) => {
  const message = useReselector(selectTicketCommentById, messageId)
  const teamMember = useReselector(
    selectTaxTeamMemberById,
    message?.authorUserId
  )
  const currentUser = useReselector(getCurrentUser)

  if (!message) {
    return null
  }

  if (message.authorUserId === currentUser?.id) {
    return (
      <>
        <Grid.Row />
        <GridRowColumn spacer={fullWidth ? undefined : 7}>
          <Card backgroundColor="natural">
            <StyledConversationMessage htmlBody={message.htmlBody} />
          </Card>
          <TimeStamp time={message.createdAt} textAlign="right" />
        </GridRowColumn>
      </>
    )
  }

  const name = teamMember
    ? `${teamMember?.firstName}, ${getRoleNameText(teamMember.roleName)}`
    : DEFAULT_HEARD_ADMIN_NAME

  return (
    <>
      <Grid.Row />
      <GridRowColumn
        width={fullWidth ? 16 : 9}
        columnStyle={{ paddingLeft: '2rem' }}
      >
        <Card backgroundColor="stone40">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 13,
            }}
          >
            <TeamMemberIcon id={teamMember?.id} />
            <Text color="forest" style={{ marginLeft: 4 }}>
              {name}
            </Text>
          </div>
          <StyledConversationMessage htmlBody={message.htmlBody} />
        </Card>
        <TimeStamp time={message.createdAt} textAlign="left" />
      </GridRowColumn>
    </>
  )
}

const ConversationMessages = ({
  id,
  fullWidth,
}: {
  id?: string | number
  fullWidth?: boolean
}) => {
  const ticket = useReselector(selectTicketById, id)

  return (
    <Grid>
      {(ticket?.comments || []).map((messageId) => (
        <Message
          key={`message-item-${messageId}`}
          messageId={messageId}
          fullWidth={fullWidth}
        />
      ))}
    </Grid>
  )
}

export default ConversationMessages
