import { createSelector } from 'reselect'
import { isNil, orderBy, uniqBy } from 'lodash'
import moment, { Moment } from 'moment'
import { DateTime } from 'luxon'

import { ReduxState } from '../../../utils/typeHelpers'
import {
  OnboardingTaskIdents,
  scheduleOnboardingItemsFilter,
  setupYourAccountItemsFilter,
} from '../../Onboarding/config'
import {
  getAnnualTaxActionItemIdentifiersForYear,
  getQuarterlyTaxActionItemIdentifiersForQuarter,
  UserActionItemActionItemIdentifiers,
} from './userActionItems.slice'
import { ActionItemCategoryInternalName } from '../../Admin/ActionItems/actionItemCategories.slice'

const ACTIVE_UPPER_BOUND_MONTHS = 1
const INCOMPLETE_LOWER_BOUND_MONTHS = 1
const INCOMPLETE_UPPER_BOUND_MONTHS = 6
const COMPLETED_LOWER_BOUND_DAYS = 60

export const selectUserActionItems = (state: ReduxState) =>
  state.userActionItems

export const selectUserActionItemList = createSelector(
  selectUserActionItems,
  (userActionItems) => Object.values(userActionItems)
)

export const selectUserActionItemById = createSelector(
  selectUserActionItems,
  (_: unknown, id: number) => id,
  (userActionItems, id) => {
    return userActionItems[id] ? userActionItems[id] : undefined
  }
)

export const getNonDeletedActionItems = createSelector(
  selectUserActionItemList,
  (userActionItems) =>
    userActionItems.filter(
      (userActionItem) =>
        !userActionItem.deletedAt && !userActionItem.actionItem.deletedAt
    )
)

export const getUserIdNonDeletedActionItems = createSelector(
  [getNonDeletedActionItems, (_, userId: number) => userId],
  (userActionItems, userId) =>
    userActionItems.filter((userActionItem) => userActionItem.userId === userId)
)

export const getUserActionItemByActionItemIdentifier = createSelector(
  [selectUserActionItemList, (_: unknown, identifier: string) => identifier],
  (userActionItems, identifier) =>
    userActionItems.find(
      (userActionItem) => userActionItem.actionItem.identifier === identifier
    )
)

export const selectEoyActionItemByYear = createSelector(
  selectUserActionItemList,
  (_: unknown, year: string) => year,
  (userActionItems, year) => {
    const identifier = UserActionItemActionItemIdentifiers.eoyReviewSurvey({
      year,
    })

    return userActionItems.find(
      (userActionItem) => userActionItem.actionItem.identifier === identifier
    )
  }
)

export const eoyReviewReviewPeriodNotActive = createSelector(
  [selectUserActionItemList, (_: unknown, year: string) => year],
  (userActionItems, year) => {
    const userEoyActionItem = userActionItems.find(
      (a) =>
        a.actionItem.identifier ===
        UserActionItemActionItemIdentifiers.eoyReviewSurvey({ year })
    )
    const NOW = moment()
    const eoyStartDate = moment(userEoyActionItem?.startsAt)
    const eoyEndDate = moment(userEoyActionItem?.endsAt)

    return (
      !userEoyActionItem ||
      NOW.isBefore(eoyStartDate) ||
      NOW.isAfter(eoyEndDate) ||
      userEoyActionItem?.completedAt
    )
  }
)

export const getUserActionItemsWithAnEndDate = createSelector(
  [selectUserActionItemList],
  (userActionItems) =>
    userActionItems.filter((userActionItem) => userActionItem.actionItem.endsAt)
)

export const getCompletedUserActionItems = createSelector(
  [selectUserActionItemList],
  (userActionItems) =>
    userActionItems.filter((userActionItem) => userActionItem.completedAt)
)

export const getUserActionItemsWithoutAnEndDate = createSelector(
  [selectUserActionItemList],
  (userActionItems) =>
    userActionItems.filter(
      (userActionItem) => !userActionItem.actionItem.endsAt
    )
)

export const getUserAnnualTaxActionItems = createSelector(
  [selectUserActionItemList],
  (userActionItems) =>
    userActionItems.filter(
      (userActionItem) =>
        userActionItem.actionItem.actionItemCategory?.internalName ===
        ActionItemCategoryInternalName.annual_taxes
    )
)

const generateSelectUserActionItemsWhereEndsAtBetween = (args: {
  startDateParam: Moment
  endDateParam: Moment
  count: number | null
}) =>
  createSelector(
    [getNonDeletedActionItems, getUserActionItemsWithAnEndDate],
    (_, withEndDate) => {
      const ordered = orderBy(
        withEndDate,
        (d) => d.actionItem.endsAt && new Date(d.actionItem.endsAt),
        ['asc']
      )

      const filtered = ordered.filter((d) => {
        const startDateParamMoment = moment.utc(args.startDateParam)
        const endDateParamMoment = moment.utc(args.endDateParam).endOf('d')
        const userActionItemMoment = moment.utc(d.actionItem.endsAt)

        return userActionItemMoment.isBetween(
          startDateParamMoment,
          endDateParamMoment,
          undefined,
          '[]'
        )
      })

      if (args.count !== null) {
        return filtered.slice(0, args.count)
      }

      return filtered
    }
  )

const generateSelectUserActionItemsWhereCompletedAtBetween = (args: {
  startDateParam: Moment
  endDateParam: Moment
  count: number | null
}) =>
  createSelector(
    [getNonDeletedActionItems, getCompletedUserActionItems],
    (_, completedItems) => {
      const filtered = completedItems.filter((item) =>
        moment
          .utc(item.completedAt)
          .isBetween(args.startDateParam, args.endDateParam, undefined, '[]')
      )

      if (args.count !== null) {
        return filtered.slice(0, args.count)
      }

      return filtered
    }
  )

const generateSelectUserActionItemsWhereEndsAtOnOrBefore = (args: {
  onOrBeforeDate: Moment
}) =>
  createSelector(
    [getNonDeletedActionItems, getUserActionItemsWithAnEndDate],
    (_, userActionItems) => {
      const ordered = orderBy(
        userActionItems,
        (d) => d.actionItem.endsAt && new Date(d.actionItem.endsAt),
        ['asc']
      )

      return ordered.filter((d) => {
        const onOrBeforeMoment = moment.utc(args.onOrBeforeDate).endOf('day')
        const userActionItemMoment = moment.utc(d.actionItem.endsAt)
        return userActionItemMoment.isSameOrBefore(onOrBeforeMoment)
      })
    }
  )

/* 
  Returns non-deleted, incomplete user action items which
    a) Have userActionItem.actionItem.endsAt <= 1 month into future
    b) Do not have a userActionItem.actionItem.endsAt at all

  These are sorted by userActionItem.actionItem.endsAt ASC, nulls last
*/
export const getAllActiveUserActionItems = createSelector(
  generateSelectUserActionItemsWhereEndsAtOnOrBefore({
    onOrBeforeDate: moment().add(ACTIVE_UPPER_BOUND_MONTHS, 'months'),
  }),
  getUserAnnualTaxActionItems,
  getUserActionItemsWithoutAnEndDate,
  (
    userActionItemsEnsAtOnOrBefore,
    userTaxActionItems,
    userActionItemsWithoutEndsAt
  ) => {
    let userActionItems = userActionItemsEnsAtOnOrBefore.filter(
      ({ completedAt }) => !completedAt
    )

    /* Override — always show upcoming tax action items */
    for (const userActionItem of userTaxActionItems) {
      if (!userActionItem.completedAt) {
        userActionItems.push(userActionItem)
      }
    }
    // Add any items with null .endsAt
    for (const userActionItem of userActionItemsWithoutEndsAt) {
      if (!userActionItem.completedAt) {
        userActionItems.push(userActionItem)
      }
    }

    const currentDate = DateTime.now()

    // Filter out items with hideFromUserAt before current date
    userActionItems = userActionItems.filter(
      ({ actionItem }) =>
        !actionItem.hideFromUserAt ||
        DateTime.fromISO(actionItem.hideFromUserAt) >= currentDate
    )

    return uniqBy(userActionItems, ({ id }) => id)
  }
)

/*
  Returns non-deleted, incomplete user action items where
    a) (1 month + 1 day) <= userActionItem.actionItem.endsAt <= (6 months from today)
*/
export const getAllUpcomingUserActionItems = createSelector(
  generateSelectUserActionItemsWhereEndsAtBetween({
    startDateParam: moment()
      .add(INCOMPLETE_LOWER_BOUND_MONTHS, 'months')
      .add(1, 'day'),
    endDateParam: moment()
      .add(INCOMPLETE_UPPER_BOUND_MONTHS, 'months')
      .endOf('d'),
    count: null,
  }),
  (userActionItems) => userActionItems.filter(({ completedAt }) => !completedAt)
)

/*
  Returns non-deleted, completed user action items where
    a) (60 days ago) <= userActionItem.completedAt <= (today)
*/
export const getAllCompletedUserActionItems = createSelector(
  generateSelectUserActionItemsWhereCompletedAtBetween({
    startDateParam: moment().subtract(COMPLETED_LOWER_BOUND_DAYS, 'days'),
    endDateParam: moment().utc().endOf('d'),
    count: null,
  }),
  (results) =>
    orderBy(results, (r) => r.completedAt && new Date(r.completedAt), ['desc'])
)

export const getOnboardingActionItems = createSelector(
  [selectUserActionItemList],
  (userActionItems) =>
    userActionItems
      .filter(
        (item) =>
          item.actionItem.actionItemCategory.internalName ===
          ActionItemCategoryInternalName.onboarding
      )
      // put Connect your Bank Accounts action item first in the list
      .sort((item) =>
        item.actionItem.identifier ===
        OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT
          ? -1
          : 0
      )
)

export const getIncompleteEoyActionItems = createSelector(
  [selectUserActionItemList],
  (userActionItems) =>
    userActionItems.filter((item) => {
      return (
        item.actionItem.actionItemCategory.internalName ===
          ActionItemCategoryInternalName.yearEndDocuments &&
        item.completedAt === null
      )
    })
)

export const getIncompleteAnnualTaxActionItemsForYear = createSelector(
  [selectUserActionItemList, (_: ReduxState, taxYear: string) => taxYear],
  (userActionItems, taxYear) => {
    const actionItemIdentifiers =
      getAnnualTaxActionItemIdentifiersForYear(taxYear)
    return userActionItems.filter(
      (item) =>
        item.actionItem.actionItemCategory.internalName ===
          ActionItemCategoryInternalName.annual_taxes &&
        item.actionItem.identifier &&
        actionItemIdentifiers.includes(item.actionItem.identifier) &&
        !item.completedAt
    )
  }
)

export const getIncompleteQuarterlyTaxActionItemsForYearAndQuarter =
  createSelector(
    [
      selectUserActionItemList,
      (
        _: ReduxState,
        timeframe: {
          taxYear?: string
          taxQuarter?: string
        }
      ) => timeframe,
    ],
    (userActionItems, { taxYear, taxQuarter }) => {
      const actionItemIdentifiers =
        getQuarterlyTaxActionItemIdentifiersForQuarter({ taxYear, taxQuarter })
      return userActionItems.filter(
        (item) =>
          item.actionItem.actionItemCategory.internalName ===
            ActionItemCategoryInternalName.quarterly_taxes &&
          item.actionItem.identifier &&
          actionItemIdentifiers.includes(item.actionItem.identifier) &&
          !item.completedAt
      )
    }
  )

export const getSetupYourAccountActionItems = createSelector(
  [getOnboardingActionItems],
  (onboardingActionItems) =>
    onboardingActionItems.filter(setupYourAccountItemsFilter)
)

export const getSetupYourAccountNumCompleted = createSelector(
  [getSetupYourAccountActionItems],
  (setupAccountActionItems) =>
    setupAccountActionItems.filter((a) => !isNil(a.completedAt)).length
)

export const getScheduleOnboardingActionItems = createSelector(
  [getOnboardingActionItems],
  (onboardingActionItems) =>
    onboardingActionItems.filter(scheduleOnboardingItemsFilter)
)

export const getScheduleOnboardingNumCompleted = createSelector(
  [getScheduleOnboardingActionItems],
  (scheduleOnboardingActionItems) =>
    scheduleOnboardingActionItems.filter((a) => !isNil(a.completedAt)).length
)
