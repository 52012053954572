import { Grid } from 'semantic-ui-react'
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Colors } from '../../../styles/theme'
import {
  Button,
  GridRowColumn,
  Icon,
  Link,
  Text,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import {
  selectAllowCloseConversation,
  selectTicketById,
} from '../conversation.slice'
import { DEFAULT_SUBJECT } from '../helpers'
import {
  DeviceWidth,
  DeviceWidthCombo,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'

const ConversationHeader = ({
  id,
  setCloseConvoModalOpen,
}: {
  id?: string
  setCloseConvoModalOpen: (open: boolean) => void
}) => {
  const ticket = useReselector(selectTicketById, id)
  const isDesktop = useIsDeviceWidth(DeviceWidthCombo.desktop)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const closeConversationButtonVisible = useReselector(
    selectAllowCloseConversation,
    ticket?.id
  )
  return (
    <Grid
      style={{
        backgroundColor: Colors.natural,
        paddingBottom: 0,
        boxShadow: '2px 2px 4px 0px rgba(32, 78, 81, 0.06)',
      }}
    >
      <Grid.Row>
        <Grid.Column computer={5} tablet={5} mobile={5}>
          <Link
            to="/conversations"
            style={{ marginTop: 15, marginLeft: isMobile ? 26 : 8 }}
          >
            <Icon icon={regular('angle-left')} style={{ marginRight: 6 }} />
            <Button variant="secondaryLink">
              {isDesktop ? 'Back to Conversations' : 'Back'}
            </Button>
          </Link>
        </Grid.Column>
        {ticket && (
          <Grid.Column only="computer tablet" textAlign="center" width={6}>
            <Text as="h2" style={{ marginTop: 10 }}>
              {ticket?.subject || DEFAULT_SUBJECT}
            </Text>
          </Grid.Column>
        )}
        {closeConversationButtonVisible && (
          <Grid.Column
            only="mobile"
            width={4}
            textAlign="center"
            floated="right"
            style={{ marginRight: 26, padding: 0, marginTop: 5 }}
          >
            <Button
              variant="secondary"
              fullWidth
              onClick={() => setCloseConvoModalOpen(true)}
            >
              <Icon icon={light('archive')} />
              &nbsp;Archive
            </Button>
          </Grid.Column>
        )}
      </Grid.Row>

      {ticket && (
        <GridRowColumn
          only="mobile"
          short
          columnStyle={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Text as="h2">{ticket?.subject || DEFAULT_SUBJECT}</Text>
        </GridRowColumn>
      )}
      <Grid.Row only="computer tablet" className="short" />
    </Grid>
  )
}

export default ConversationHeader
