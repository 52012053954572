import { Grid, Image } from 'semantic-ui-react'
import {
  Accordion,
  Card,
  GridRowColumn,
  Text,
} from '../../components/BaseComponents'
import { Colors } from '../../styles/theme'
import { CSSProperties, ReactNode } from 'react'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'

const CARD_STYLE = {
  maxWidth: 750,
  margin: '0 auto',
  padding: '16px 8px',
  borderRadius: 8,
  height: '100%',
}

const OnboardingAccordionTitle = ({
  imageSrc,
  title,
  subtitle,
}: {
  imageSrc: string
  title: string
  subtitle: string
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  return (
    <Grid style={{ width: '100%' }}>
      <Grid.Row verticalAlign="middle">
        {!isMobile && (
          <Grid.Column width={3}>
            <Image style={{ maxWidth: 100 }} src={imageSrc} />
          </Grid.Column>
        )}
        <Grid.Column width={!isMobile ? 13 : 16}>
          <Text as="h2">{title}</Text>
          <Text
            style={{
              color: Colors.darkGray,
              marginTop: 8,
            }}
          >
            {subtitle}
          </Text>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const OnboardingAccordion = ({
  content,
  disabled,
  imageSrc,
  initialOpen,
  rowStyle,
  subtitle,
  title,
}: {
  content: ReactNode
  disabled?: boolean
  imageSrc: string
  initialOpen?: boolean
  rowStyle?: CSSProperties
  subtitle: string
  title: string
}) => (
  <GridRowColumn style={rowStyle}>
    <Card style={CARD_STYLE}>
      <Accordion
        initialOpen={Boolean(initialOpen)}
        disabled={disabled}
        style={{ backgroundColor: Colors.white }}
        title={
          <OnboardingAccordionTitle
            imageSrc={imageSrc}
            subtitle={subtitle}
            title={title}
          />
        }
        content={content}
      />
    </Card>
  </GridRowColumn>
)

export default OnboardingAccordion
