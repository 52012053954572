import { CSSProperties } from 'react'
import { Image } from 'semantic-ui-react'

import { useReselector } from '../../utils/sharedHooks'
import { selectTaxTeamMemberById } from '../../reducers/taxTeam.slice'
import { DEFAULT_HEARD_ADMIN_ICON } from './helpers'

const TeamMemberIcon = ({
  id,
  style,
  width = 24,
}: {
  id?: number
  style?: CSSProperties
  width?: number
}) => {
  const teamMember = useReselector(selectTaxTeamMemberById, id)

  return (
    <Image
      src={teamMember?.avatarUrl || DEFAULT_HEARD_ADMIN_ICON}
      rounded
      size="mini"
      style={{ borderRadius: width / 2, height: width, width, ...style }}
    />
  )
}

export default TeamMemberIcon
