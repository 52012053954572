import { ReactNode } from 'react'
import { GridRowColumn, Text } from '../../components/BaseComponents'
import { RecipientDetails } from './referrals.slice'
import PlaceholderParagraph from '../../components/shared/PlaceholderParagraph'

const MessageContent = ({
  header,
  body,
}: {
  header: string
  body: string | ReactNode
}) => (
  <>
    <GridRowColumn width={8}>
      <Text as="h1">{header}</Text>
    </GridRowColumn>
    <GridRowColumn width={8}>
      <Text>{body}</Text>
    </GridRowColumn>
  </>
)

const ReferralWelcomeMessage = ({
  recipient,
  recipientLoading,
  freeTrialEnabled,
}: {
  recipient?: RecipientDetails
  recipientLoading: boolean
  freeTrialEnabled: boolean
}) => (
  <>
    {recipientLoading && (
      <GridRowColumn>
        <PlaceholderParagraph
          style={{
            display: 'block',
            width: 400,
          }}
        />
      </GridRowColumn>
    )}
    {recipient?.responseCode === 'details_not_found' && (
      <MessageContent
        header={'This invitation cannot be found'}
        body={'Please double-check the link you clicked and try again.'}
      />
    )}
    {recipient?.responseCode === 'already_accepted_invitation' && (
      <MessageContent
        header={'This invitation has already been redeemed'}
        body={`${recipient.firstName}, it looks like you've already signed up using this invitation.`}
      />
    )}
    {recipient?.responseCode === 'success' && (
      <MessageContent
        header={`Welcome to Heard, ${recipient.firstName}!`}
        body={
          <>
            {freeTrialEnabled
              ? `We're so happy that ${recipient.senderFirstName} referred you to
        us! Let's get you started with your free 30 day trial. Once you
        complete your trial and become a Heard customer, we'll send you
        a `
              : `We're so happy that ${recipient.senderFirstName} referred you to us! Once you've been a member with us for 30 days, we'll send you a `}
            <b>
              ${process.env.VITE_REFERRAL_FACTORY_REWARD_DOLLARS_RECIPIENT} gift
              card
            </b>{' '}
            as a thank you for joining our community.
          </>
        }
      />
    )}
  </>
)

export default ReferralWelcomeMessage
