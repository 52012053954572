import { useEffect, useState, useCallback } from 'react'
import { Loader } from 'semantic-ui-react'
import { useSearchParams } from 'react-router-dom'

import { fetchAnnualTaxFormTypesIfNeeded } from '../annualTaxFormType.slice'
import { fetchAnnualTaxFilingStatusTypesIfNeeded } from '../annualTaxFilingStatusType.slice'
import { fetchAnnualTaxFilingsIfNeeded } from '../annualTaxFilings.slice'
import './AnnualTaxOverview.scss'
import { Tab } from '../../../../components/BaseComponents'
import AnnualTaxYearPanel from './AnnualTaxYearPanel'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { fetchUserDocuments } from '../../../UserDocuments/userDocuments.slice'
import {
  fetchSubscriptions,
  selectIsBasicPlan,
} from '../../../../reducers/subscription.slice'
import { useReselector } from '../../../../utils/sharedHooks'
import UpgradeToPremiumModal from '../../../../components/shared/UpgradeToPremium/UpgradeToPremiumModal'
import PageHeader from '../../../../components/shared/PageHeader'
import { fetchAnnualTaxFilingFormsIfNeeded } from '../annualTaxFilingForms.slice'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import PastReturnsPanel from './PastReturnsPanel'
import { fetchTaxUserDocumentsIfNeeded } from '../taxUserDocuments.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'

enum TAB_PANES {
  active = 'active',
  past = 'past',
}

const AnnualTaxesPanel = () => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const [fetching, setFetching] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()

  //todo check where this is used
  const tabParam = searchParams.get('tab')
  const [activeTabIndex, setActiveTabIndex] = useState(
    !tabParam || tabParam === TAB_PANES.active ? 0 : 1
  )

  useEffect(() => {
    async function batchFetch() {
      await Promise.all([
        dispatch(fetchAnnualTaxFormTypesIfNeeded()),
        dispatch(fetchUserDocuments()),
        dispatch(fetchTaxUserDocumentsIfNeeded()),
        dispatch(fetchAnnualTaxFilingStatusTypesIfNeeded()),
        dispatch(fetchAllAnnualTaxDetailsIfNeeded()),
        dispatch(fetchSubscriptions()),
        dispatch(fetchUserDocumentCategoriesIfNeeded()),
        // Force refetch of these because form needs and submissions may have happened in other flows
        dispatch(fetchAnnualTaxFilingFormsIfNeeded(true)),
        dispatch(fetchAnnualTaxFilingsIfNeeded(true)),
      ])
      setFetching(false)
    }
    batchFetch()
  }, [dispatch])

  const updateTab = useCallback(
    (newIndex: number) => {
      setSearchParams(
        { tab: newIndex ? TAB_PANES.past : TAB_PANES.active },
        { replace: true }
      )
      setActiveTabIndex(newIndex)
    },
    [setSearchParams]
  )

  const userHasBasicPlan = useReselector(selectIsBasicPlan)

  return (
    <>
      <PageHeader header="Tax Center" />
      {fetching ? (
        <Loader active inline />
      ) : (
        <Tab
          activeIndex={activeTabIndex}
          defaultActiveIndex={activeTabIndex}
          onTabClick={updateTab}
          panes={[
            {
              menuItem: taxYear,
              render: () => (
                <Tab.Pane>
                  <AnnualTaxYearPanel year={taxYear} />
                  {userHasBasicPlan && (
                    <UpgradeToPremiumModal feature="annual-taxes" />
                  )}
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Past Returns',
              render: () => (
                <Tab.Pane>
                  <PastReturnsPanel />
                </Tab.Pane>
              ),
            },
          ]}
        />
      )}
    </>
  )
}
export default AnnualTaxesPanel
