import { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DateTime } from 'luxon'

import { useReselector } from '../../../../utils/sharedHooks'
import { BorderedIcon } from '../../../../components/BaseComponents/Icon'
import {
  Card,
  GridRowColumn,
  Text,
  Button,
} from '../../../../components/BaseComponents'
import AnnualTaxFaq from './AnnualTaxFaq'
import AnnualTaxImportantDateCard from './AnnualTaxImportantDateCard'
import { selectTaxDetailsByYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../utils/dateHelpers'
import TaxSurveyOptInModal from '../components/TaxSurveyOptInModal'
import {
  DeviceWidthCombo,
  useIsDeviceWidth,
} from '../../../../utils/deviceWidthHelpers'

const NoTaxServicesPanel = ({ year }: { year: string }) => {
  const isMobileOrTablet = useIsDeviceWidth(DeviceWidthCombo.mobileOrTablet)

  const [optInModalOpen, setOptInModalOpen] = useState(false)
  const annualTaxDetails = useReselector(selectTaxDetailsByYear, year)

  const canReOptIn =
    annualTaxDetails?.taxSeasonKickoffDueAt &&
    DateTime.now() < isoToUTCDateTime(annualTaxDetails.taxSeasonKickoffDueAt)

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={isMobileOrTablet ? 16 : 9}>
          <Card type="section">
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BorderedIcon
                      wrapperColor="forest"
                      height={30}
                      icon={regular('check')}
                      color="white"
                    />
                    <Text as="h2" style={{ marginLeft: '10px' }}>
                      You’ve opted out of Heard doing your {year} taxes
                    </Text>
                  </div>
                </Grid.Column>
              </Grid.Row>

              <GridRowColumn short>
                {canReOptIn && (
                  <Text>
                    Changed your mind? You have until{' '}
                    <b>
                      {annualTaxDetails?.taxSeasonKickoffDueAt &&
                        isoToUTCDateTime(
                          annualTaxDetails.taxSeasonKickoffDueAt
                        ).toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}
                    </b>{' '}
                    to{' '}
                    <Button
                      variant="link"
                      onClick={() => setOptInModalOpen(true)}
                    >
                      opt back in
                    </Button>
                    .
                  </Text>
                )}
              </GridRowColumn>
            </Grid>
          </Card>
        </Grid.Column>
        <Grid.Column width={isMobileOrTablet ? 16 : 7}>
          <AnnualTaxImportantDateCard year={year} />
          <br />
          <AnnualTaxFaq />
        </Grid.Column>
      </Grid.Row>
      <TaxSurveyOptInModal
        open={optInModalOpen}
        close={() => setOptInModalOpen(false)}
        year={year}
      />
    </Grid>
  )
}
export default NoTaxServicesPanel
