import { useCallback } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { Button, Card, Modal, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { OnboardingTaskIdents } from '../../Onboarding/config'
import { UploadPrevMonthStatementStatus } from '../../Dashboard/UserActionItems/userActionItemStatuses'
import { markUserActionItemCompleteIfExists } from '../../Dashboard/UserActionItems/service'
import { useAnalyticsTrack } from '../../Amplitude'
import { useNavigate } from 'react-router-dom'

export const SkipConnectBankAccountModal = ({
  closeModal,
}: {
  closeModal: () => void
}) => {
  const track = useAnalyticsTrack()
  const navigate = useNavigate()

  const handleSkipClick = useCallback(async () => {
    track('completed onboarding item connect financial accounts', {
      status: 'skipped',
    })
    await markUserActionItemCompleteIfExists(
      OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT,
      track,
      UploadPrevMonthStatementStatus.skipped
    )
    navigate('/onboarding-v2/complete')
  }, [track, navigate])

  const handleCloseModal = () => {
    track('cancelled skip connect business account')
    closeModal()
  }

  return (
    <Modal closeIcon onClose={handleCloseModal} open size="small">
      <Modal.Header>Are you sure you want to skip?</Modal.Header>
      <Modal.Content>
        <Card
          style={{
            backgroundColor: Colors.lightRed,
          }}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Image
                  src="https://heard-images.s3.amazonaws.com/assets/warning-logo.png"
                  style={{
                    width: 80,
                    height: 80,
                  }}
                />
              </Grid.Column>
              <Grid.Column width={10}>
                <Text>
                  <b>
                    You need to connect a business checking account to use
                    Heard.
                  </b>
                </Text>
                <br />
                <Text>
                  If you don&apos;t have a compatible account, you can skip for
                  now and return later to connect it.
                </Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleCloseModal} variant="actionLink">
          Go Back
        </Button>
        <Button onClick={handleSkipClick} variant="warning">
          Skip
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
