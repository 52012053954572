import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { Moment } from 'moment'

// BL
import { DocumentTabPane } from '.'
import { bankStatementsPane as copy } from '../copyConstants'
import {
  closeUploadModal,
  openUploadModal,
  useFeatureDispatch,
  useFeatureState,
} from '../state'
import { TabCategoryKeys as ModalTypes } from '../constants'

// UI
import { DocumentSearchBarOnChangeData } from '../sharedComponents/DocumentsSearchBar'
import TabPaneToolbar from '../sharedComponents/TabPaneToolbar'
import UploadDocumentButton from '../sharedComponents/Buttons/UploadDocumentButton'
import UserDocumentsTable from '../sharedComponents/Table/UserDocumentsTable'
import { BankStatementRow } from '../sharedComponents/Table/UserDocumentsTableRow'
import FileUploadModal from '../../../components/FileUpload/FileUploadModal'
import { markUserActionItemCompleteIfExists } from '../../Dashboard/UserActionItems/service'
import { UserActionItemActionItemIdentifiers } from '../../Dashboard/UserActionItems/userActionItems.slice'
import { UploadDocumentType } from '../../../constants/businessConstants'
import { useAnalyticsTrack } from '../../Amplitude'

const BankStatements: DocumentTabPane = ({ documents, tableOptions }) => {
  const [filteredDocuments, setFilteredDocuments] = useState(documents)
  const { activeUploadModal } = useFeatureState()
  const dispatch = useFeatureDispatch()
  const location = useLocation()
  const { tabName, action } = useParams<{
    tabName: string | undefined
    action: string | undefined
  }>()
  const [monthQuery, setMonthQuery] = useState<number>()
  const [yearQuery, setYearQuery] = useState<number>()
  const [searchParams] = useSearchParams()
  const track = useAnalyticsTrack()

  const onSearch = useCallback((data: DocumentSearchBarOnChangeData) => {
    setFilteredDocuments(data.filtered)
  }, [])

  const openModal = useCallback(() => {
    track('clicked document upload', {
      document_category: ModalTypes.BANK_STATEMENTS,
      document_subcategory: '',
    })
    dispatch?.(openUploadModal(ModalTypes.BANK_STATEMENTS))
  }, [dispatch, track])

  useEffect(() => {
    setFilteredDocuments(documents)

    if (tabName) {
      const month = Number(searchParams.get('month'))
      const year = Number(searchParams.get('year'))
      if (action === 'upload' && !isNaN(month) && !isNaN(year)) {
        setMonthQuery(month)
        setYearQuery(year)
        openModal()
      }
    }
  }, [documents, action, tabName, location.search, openModal, searchParams])

  const setUploadedFile = useCallback(
    async ({ statementMonth }: { statementMonth?: Moment }) => {
      if (statementMonth) {
        await markUserActionItemCompleteIfExists(
          UserActionItemActionItemIdentifiers.uploadBankStatements({
            month: (statementMonth.month() + 1).toString(),
            year: statementMonth.year().toString(),
          }),
          (event, properties) => track(event, properties)
        )
      }
    },
    [track]
  )

  return (
    <>
      <TabPaneToolbar
        documents={documents}
        onSearch={onSearch}
        rightElement={<UploadDocumentButton onClick={openModal} />}
      />
      <UserDocumentsTable
        columns={copy.static.tableColumns}
        documents={filteredDocuments}
        RowComponent={BankStatementRow}
        tableOptions={tableOptions}
      />
      <FileUploadModal
        documentType={UploadDocumentType.STATEMENT}
        open={activeUploadModal === ModalTypes.BANK_STATEMENTS}
        close={() => dispatch?.(closeUploadModal())}
        userFacing
        monthYear={
          monthQuery && yearQuery ? `${yearQuery}-${monthQuery}` : undefined
        }
        setUploadedFile={setUploadedFile}
      />
    </>
  )
}

BankStatements.paneDescription = copy.static.paneDescription
BankStatements.paneTitle = copy.static.paneTitle

export default BankStatements
