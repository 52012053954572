import {
  light,
  regular,
  solid,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { Fragment, ReactNode, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, List, Loader } from 'semantic-ui-react'

import {
  Button,
  Card,
  Icon,
  Link,
  Text,
  Modal,
  GridRowColumn,
  Accordion,
} from '../../../../../components/BaseComponents'
import {
  useFetchResponse,
  useReselector,
} from '../../../../../utils/sharedHooks'
import {
  selectActiveQuarterlyTaxEstimateDetails,
  selectHasUserStartedQteWizardThisQuarter,
  selectIsPastTaxEstimateCalculationDate,
} from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import {
  DATE_FORMATS,
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../../utils/dateHelpers'
import { BaseIconProps } from '../../../../../components/BaseComponents/Icon'
import { Colors } from '../../../../../styles/theme'
import { getUnpaidTaxEstimatesByYear } from '../../userTaxEstimates.selector'
import PreviousTaxPayments from '../../../TaxesProfile/FormFlow/PreviousTaxPayments'
import { splitTaxYearQuarter } from '../../helpers'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import { updateFinancialProfile } from '../../../../../actions/financialProfileActions'
import { getFinancialProfile } from '../../../../../selectors/user.selectors'
import { dateWithoutTimestamp } from '../../service'
import {
  FETCH_NEEDED_BANK_STATEMENTS_FOR_CURRENT_TAX_QUARTER,
  getNeededBankStatementsForCurrentTaxQuarter,
} from '../../../../UserDocuments/userDocuments.slice'
import { selectIsFetchingForKeys } from '../../../../../reducers/fetch'
import { useAnalyticsTrack } from '../../../../Amplitude'

const getChecklistItemIconProps = (pastDue: boolean) => {
  return {
    color: pastDue ? 'red' : 'oak',
    style: { minHeight: 50, minWidth: 50, marginRight: 24 },
  } as BaseIconProps
}

const addressCardIcon = (pastDue: boolean) => (
  <Icon {...getChecklistItemIconProps(pastDue)} icon={light('address-card')} />
)
const quarterIcon = (pastDue: boolean) => (
  <Icon
    {...getChecklistItemIconProps(pastDue)}
    icon={light('circle-quarter-stroke')}
  />
)
const bookIcon = (pastDue: boolean) => (
  <Icon
    {...getChecklistItemIconProps(pastDue)}
    icon={light('book-open-cover')}
  />
)
const uploadIcon = (pastDue: boolean) => (
  <Icon {...getChecklistItemIconProps(pastDue)} icon={light('cloud-upload')} />
)

const ChecklistItemCard = ({
  isDone,
  icon,
  header,
  isStarted,
  children,
  onClick,
}: {
  isDone: boolean
  icon: ReactNode
  header: ReactNode
  isStarted?: boolean
  children?: ReactNode
  onClick?: () => void
}) => {
  return (
    <Card type="subsection" backgroundColor={isDone ? 'stone40' : 'natural'}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>{icon}</div>
        <div style={{ width: '100%' }}>
          <Grid>
            <Grid.Row>
              <Grid.Column verticalAlign={'middle'} width={11}>
                <Text as="h3" style={{ marginBottom: 8 }}>
                  {header}
                </Text>
                {children}
              </Grid.Column>
              <Grid.Column width={5} verticalAlign="middle">
                <Button
                  fullWidth
                  variant={isDone ? 'secondary' : 'primary'}
                  disabled={isDone}
                  onClick={onClick}
                >
                  {isDone ? (
                    <>
                      <Icon icon={light('check')} style={{ marginRight: 8 }} />
                      Done
                    </>
                  ) : isStarted ? (
                    'Continue'
                  ) : (
                    'Start'
                  )}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    </Card>
  )
}

const DeadlineText = ({
  isDone,
  onEditClick,
}: {
  isDone: boolean
  onEditClick: () => void
}) => {
  const isPastCalculationDate = useReselector(
    selectIsPastTaxEstimateCalculationDate
  )

  if (isPastCalculationDate) {
    return null
  }

  if (!isDone) {
    return <Text color="forest">You’ll need your most recent paystub.</Text>
  }

  return (
    <Text color="forest">
      <Button onClick={onEditClick} variant="link">
        Edit
      </Button>{' '}
      income and withholdings information
    </Text>
  )
}

export const UpdateTaxInfoCTA = ({ isDone }: { isDone: boolean }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const fp = useReselector(getFinancialProfile)

  const startedQteWizardThisQuarter = useReselector(
    selectHasUserStartedQteWizardThisQuarter
  )

  const onEditClick = () => {
    dispatch(
      updateFinancialProfile(fp?.id, {
        qteWizardLastStartedAt: dateWithoutTimestamp().format(
          DATE_FORMATS.TIMESTAMP
        ),
      })
    )
    navigate('/taxes/profile')
  }
  const isPastCalculationDate = useReselector(
    selectIsPastTaxEstimateCalculationDate
  )

  return (
    <ChecklistItemCard
      isDone={isDone}
      isStarted={startedQteWizardThisQuarter}
      icon={addressCardIcon(isPastCalculationDate)}
      header="Update your income and withholdings"
      onClick={onEditClick}
    >
      <DeadlineText onEditClick={onEditClick} isDone={isDone} />
    </ChecklistItemCard>
  )
}

export const UpdateQTEPaymentsCTA = ({ isDone }: { isDone: boolean }) => {
  const [showPreviousPaymentsModal, setShowPreviousPaymentsModal] =
    useState(false)
  const activeQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )
  const isPastCalculationDate = useReselector(
    selectIsPastTaxEstimateCalculationDate
  )
  const unpaidTaxEstimatesByYear = useReselector(
    getUnpaidTaxEstimatesByYear,
    activeQuarterDetails?.taxYear
  ).reduce((s, estimate) => s.add(estimate.taxQuarter), new Set<string>())

  const formattedEstimates = useMemo(() => {
    if (!activeQuarterDetails) {
      return null
    }
    const listItems = [...unpaidTaxEstimatesByYear].map((taxQuarter) => {
      const { quarter, year } = splitTaxYearQuarter(taxQuarter)

      return (
        <List.Item key={taxQuarter}>
          <Text color="forest">
            <b>
              Q{quarter} {year}
            </b>
          </Text>
        </List.Item>
      )
    })
    return <List bulleted items={listItems} />
  }, [activeQuarterDetails, unpaidTaxEstimatesByYear])

  const onEditClick = () => {
    setShowPreviousPaymentsModal(true)
  }

  return (
    <ChecklistItemCard
      isDone={isDone}
      icon={quarterIcon(isPastCalculationDate)}
      header="Enter your quarterly tax payments"
      onClick={onEditClick}
    >
      {!isDone && (
        <>
          <Text color="forest">Enter payments for the following quarters:</Text>
          <Text style={{ marginLeft: 8, marginTop: 8 }} color="forest">
            {formattedEstimates}
          </Text>
        </>
      )}
      <DeadlineText onEditClick={onEditClick} isDone={isDone} />
      <Modal
        open={showPreviousPaymentsModal}
        size={'large'}
        style={{ paddingTop: 20 }}
        dimmer="inverted"
        closeOnDimmerClick
        onClose={() => setShowPreviousPaymentsModal(false)}
      >
        <Modal.Content>
          <PreviousTaxPayments />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowPreviousPaymentsModal(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </ChecklistItemCard>
  )
}

export const UploadNeededBankStatementsCTA = () => {
  const track = useAnalyticsTrack()

  // need to get data in the component itself for useFetchResponse to work
  const neededStatements = useFetchResponse(
    getNeededBankStatementsForCurrentTaxQuarter
  )
  const totalNeededStatements =
    neededStatements?.reduce((accum, statement) => {
      return accum + statement.statements.length
    }, 0) ?? 0
  const isDone = Boolean(neededStatements && totalNeededStatements === 0)
  const navigate = useNavigate()

  const isPastCalculationDate = useReselector(
    selectIsPastTaxEstimateCalculationDate
  )

  const fetching = useReselector(selectIsFetchingForKeys, [
    FETCH_NEEDED_BANK_STATEMENTS_FOR_CURRENT_TAX_QUARTER,
  ])

  const formattedStatements = useMemo(
    () =>
      neededStatements?.map(
        ({ financial_institution_name, name, mask, statements }) => {
          const accountText = `${financial_institution_name} ${name}: ${mask}`

          return (
            <Fragment key={accountText}>
              <Text as="h3">{accountText}</Text>
              <List
                bulleted
                items={statements.map((statement) => (
                  <List.Item key={`${accountText} ${statement}`}>
                    <Text>
                      {isoToUTCDateTime(statement).toFormat(
                        DATE_FORMATS_LUXON.MONTH_YEAR_LONG
                      )}
                    </Text>
                  </List.Item>
                ))}
              />
            </Fragment>
          )
        }
      ),
    [neededStatements]
  )

  const content = useMemo(() => {
    if (fetching) {
      return (
        <GridRowColumn centerContent>
          <Loader active inline />
        </GridRowColumn>
      )
    }

    return (
      <Grid>
        {isDone ? (
          <GridRowColumn>
            <Text>
              Great job! You&apos;re all caught up and do not have any missing
              statements.{' '}
              <Link to={'/practice/documents?tab=bank'} as="secondaryLink">
                View Your Documents
              </Link>
            </Text>
          </GridRowColumn>
        ) : (
          <>
            <GridRowColumn>
              <Text>
                Help your bookkeeper complete your books by uploading all your{' '}
                <b>business</b> bank statements.
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text>
                <b>
                  {totalNeededStatements} statement
                  {totalNeededStatements > 1 ? 's' : ''}
                </b>{' '}
                {totalNeededStatements > 1 ? 'are' : 'is'} missing
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Accordion
                title="View list of missing statements"
                content={formattedStatements}
                variant="text"
              />
            </GridRowColumn>
          </>
        )}
      </Grid>
    )
  }, [fetching, totalNeededStatements, formattedStatements, isDone])

  return (
    <ChecklistItemCard
      isDone={isDone}
      icon={uploadIcon(isPastCalculationDate)}
      header="Upload your statements"
      onClick={() => {
        track('QTE Upload Bank Statements CTA Clicked')
        navigate('/practice/documents?tab=bank')
      }}
    >
      {content}
    </ChecklistItemCard>
  )
}

export const ReviewTxnCTA = ({
  isDone,
  count,
}: {
  isDone: boolean
  count: number
}) => {
  const navigate = useNavigate()
  const isPastCalculationDate = useReselector(
    selectIsPastTaxEstimateCalculationDate
  )
  return (
    <ChecklistItemCard
      isDone={isDone}
      icon={bookIcon(isPastCalculationDate)}
      header="Review your transactions"
      onClick={() => navigate('/transactions/review')}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column verticalAlign={'middle'} width={11}>
            <Text color="forest">
              {isDone ? (
                <>
                  You&apos;re all caught up.{' '}
                  <Link to="/transactions/all" as="secondaryLink">
                    View transactions
                  </Link>
                </>
              ) : (
                <>
                  Get the most accurate estimate by making sure your
                  transactions are up-to-date.
                  <br />
                  <b>
                    {count} transaction{count !== 1 && 's'}
                  </b>{' '}
                  need{count === 1 && 's'} a note/categorization.
                </>
              )}
            </Text>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ChecklistItemCard>
  )
}

export const BooksStatus = ({
  booksReconciled,
}: {
  booksReconciled: boolean
}) => {
  return (
    <Card type="subsection" backgroundColor="stone40" padding="8px 32px">
      <Text color="forest">
        <Icon
          color={booksReconciled ? 'green' : 'yellow'}
          style={{ minHeight: 16, minWidth: 16, marginRight: 8 }}
          icon={
            booksReconciled ? regular('book-heart') : regular('book-open-cover')
          }
        />
        Your bookkeeper has indicated your books{' '}
        {booksReconciled ? (
          <>
            have been{' '}
            <span style={{ color: Colors['green'] }}>
              <b>reviewed.</b>
            </span>
          </>
        ) : (
          <>
            are still{' '}
            <span style={{ color: Colors['yellow'] }}>
              <b>in review.</b>
            </span>
          </>
        )}
      </Text>
    </Card>
  )
}

export const BooksIncompleteInfo = () => (
  <Card type="subsection" padding="16px">
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon
        style={{ minHeight: 20, minWidth: 20, marginRight: 16 }}
        icon={solid('info-circle')}
      />
      <div>
        <Text as="h3">
          I&apos;ve clarified my transactions. Why are my books still in review?
        </Text>
        <List bulleted>
          <List.Item>
            <Text>
              If your bookkeeper needs more information, they&apos;ll reach out
              via Conversations
            </Text>
          </List.Item>
          <List.Item>
            <Text>
              If you&apos;ve completed all of the steps, your bookkeeper may
              need more time to categorize your transactions
            </Text>
          </List.Item>
        </List>
      </div>
    </div>
  </Card>
)
