import { useCallback, useEffect } from 'react'
import { Grid, Image } from 'semantic-ui-react'

import {
  Text,
  Icon,
  GridRowColumn,
  Alert,
  Card,
  IconButton,
} from '../../../../components/BaseComponents'
import {
  getGroupedActiveTaxEstimatesWithinActiveQuarter,
  getGroupedTaxEstimatesForQuarter,
  selectAreAnyEstimatesLastQuarterNotPaidOrPaidAfterIRSDeadline,
} from '../userTaxEstimates.selector'
import UserTaxEstimateSummary from './UserTaxEstimateRow'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectActiveQTEDetailsAsDates,
  selectQuarterDetailsForQuarterBeforeActiveQuarter,
} from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import {
  selectTaxCalculationByTaxQuarter,
  selectCalculationHomeState,
  selectUserTaxCalculationWithinActiveQuarter,
} from '../../../../selectors/userTaxCalculation.selectors'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import { UserTaxEstimate } from '../userTaxEstimates.slice'
import {
  UserTaxCalculation,
  UserTaxCalculationStatuses,
  updateUserTaxCalculation,
} from '../../../Admin/UserTaxCalculations/userTaxCalculation.slice'
import { Colors } from '../../../../styles/theme'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import HighEstimatesAlert from '../HighEstimatesAlert'
import { useAnalyticsTrack } from '../../../Amplitude'

/*
  Provides a summary for the active quarter – "Q3 2022 Quarterly Taxes"
  - Displays the payment due date
  - Displays a summary for the entire group
  - Lists each individual estimate's summary
*/
const EstimatesForQuarter = ({
  federalTaxEstimate,
  homeStateTaxEstimate,
  stateTaxEstimates,
}: {
  federalTaxEstimate: UserTaxEstimate | null
  homeStateTaxEstimate: UserTaxEstimate | null
  stateTaxEstimates: UserTaxEstimate[] | null
}) => (
  <>
    {/* Federal Estimate */}
    <GridRowColumn>
      <UserTaxEstimateSummary
        overrideBgColor="stone40"
        estimate={federalTaxEstimate}
      />
    </GridRowColumn>

    {/* Home State Estimate */}
    <GridRowColumn>
      <UserTaxEstimateSummary
        overrideBgColor="stone40"
        estimate={homeStateTaxEstimate}
      />
    </GridRowColumn>

    {/* Additional State Estimates */}
    {stateTaxEstimates?.map((e) => (
      <GridRowColumn key={`state-estimate-${e.id}`}>
        <UserTaxEstimateSummary
          overrideBgColor="stone40"
          key={`state-estimate-${e.id}`}
          estimate={e}
        />
      </GridRowColumn>
    ))}
  </>
)

const UnseenEstimateBanner = ({
  calculation,
}: {
  calculation?: UserTaxCalculation | null
}) => {
  const dispatch = useAppDispatch()
  const onClose = useCallback(async () => {
    if (calculation) {
      await dispatch(
        updateUserTaxCalculation(calculation.id, {
          unseenEstimate: false,
        })
      )
    }
  }, [calculation, dispatch])

  if (
    !calculation ||
    calculation.status !== UserTaxCalculationStatuses.savedAndNotified ||
    calculation.unseenEstimate === false ||
    calculation.unseenEstimate === null
  ) {
    return null
  }

  return (
    <GridRowColumn>
      <Card
        type="subsection"
        backgroundColor="stone40"
        style={{ border: `1px solid ${Colors.lightGray}` }}
      >
        <IconButton
          icon={regular('close')}
          style={{ position: 'absolute', top: 12, right: 12 }}
          color="darkGray"
          size="xl"
          onClick={onClose}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 44,
          }}
        >
          <Image
            style={{ height: 90 }}
            src="https://heard-images.s3.amazonaws.com/assets/sparkle-paper-plane.svg"
          />
          <Text as="h2">Your new federal tax estimate has arrived!</Text>
        </div>
      </Card>
    </GridRowColumn>
  )
}

export const ActiveQuarterTaxSummary = ({
  quarterText: activeQuarterText,
}: {
  quarterText: string
}) => {
  const activeQuarterDetails = useReselector(selectActiveQTEDetailsAsDates)
  const calculation = useReselector(selectUserTaxCalculationWithinActiveQuarter)
  const homeState = useReselector(selectCalculationHomeState, calculation?.id)
  const { federalTaxEstimate, homeStateTaxEstimate, stateTaxEstimates } =
    useReselector(getGroupedActiveTaxEstimatesWithinActiveQuarter, homeState)

  const track = useAnalyticsTrack()

  useEffect(() => {
    const trackAttributes: {
      [key: string]: string | number | null
    } = {
      quarter: activeQuarterDetails?.taxQuarter ?? null,
      year: activeQuarterDetails?.taxYear ?? null,
      federalEstimateInCents: federalTaxEstimate?.estimateInCents ?? null,
    }

    const allStateEstimates = [
      ...(stateTaxEstimates ?? []),
      homeStateTaxEstimate,
    ]
    for (const stateEstimate of allStateEstimates ?? []) {
      if (stateEstimate) {
        trackAttributes[`stateEstimateInCents_${stateEstimate.filingState}`] =
          stateEstimate.estimateInCents
      }
    }

    track?.('viewed active quarter estimates', trackAttributes)
  }, [
    federalTaxEstimate?.estimateInCents,
    activeQuarterDetails,
    stateTaxEstimates,
    homeStateTaxEstimate,
    track,
  ])

  if (!activeQuarterDetails) {
    return null
  }

  return (
    <Grid>
      <UnseenEstimateBanner calculation={calculation} />
      <GridRowColumn verticalAlign="middle">
        <Text as="h2">{activeQuarterText} Estimates</Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Text>
          <Icon
            color="darkGray"
            style={{ paddingRight: 8 }}
            icon={regular('calendar')}
          />
          IRS payment due{' '}
          {activeQuarterDetails.forDisplay.irsPaymentDue.toFormat(
            DATE_FORMATS_LUXON.DISPLAY_LONG
          )}
        </Text>
      </GridRowColumn>
      <HighEstimatesAlert
        title="This estimate seems high"
        taxCalc={calculation}
      />
      <EstimatesForQuarter
        federalTaxEstimate={federalTaxEstimate}
        homeStateTaxEstimate={homeStateTaxEstimate}
        stateTaxEstimates={stateTaxEstimates}
      />
    </Grid>
  )
}

export const LastQuarterTaxSummary = () => {
  const lastQuarterDetails = useReselector(
    selectQuarterDetailsForQuarterBeforeActiveQuarter
  )
  const activeQuarterDetails = useReselector(selectActiveQTEDetailsAsDates)
  const lastTaxQuarter = `${lastQuarterDetails?.taxYear}-${lastQuarterDetails?.taxQuarter}`
  const calculation = useReselector(
    selectTaxCalculationByTaxQuarter,
    lastTaxQuarter
  )
  const homeState = useReselector(selectCalculationHomeState, calculation?.id)
  const { federalTaxEstimate, homeStateTaxEstimate, stateTaxEstimates } =
    useReselector(getGroupedTaxEstimatesForQuarter, {
      taxQuarter: lastTaxQuarter,
      homeState,
    })

  const lastQuarterIsQ4 = lastQuarterDetails?.taxQuarter === '4'

  const estimateIsNotPaidOrLate = useReselector(
    selectAreAnyEstimatesLastQuarterNotPaidOrPaidAfterIRSDeadline
  )

  if (!activeQuarterDetails) {
    return null
  }

  return (
    <Grid>
      <GridRowColumn>
        <Alert customIcon={<Icon size="1x" icon={solid('calendar-check')} />}>
          The Quarterly Checklist for Q{activeQuarterDetails?.taxQuarter}{' '}
          {activeQuarterDetails.taxYear} estimates will open on{' '}
          {activeQuarterDetails.forDisplay.newUserCutOff.toFormat(
            DATE_FORMATS_LUXON.DISPLAY_LONG
          )}
        </Alert>
      </GridRowColumn>
      <GridRowColumn>
        <Text as="h2">Last Quarter&apos;s Estimates</Text>
      </GridRowColumn>
      {estimateIsNotPaidOrLate && (
        <GridRowColumn short>
          <Text as="bodyLg">
            {lastQuarterIsQ4
              ? `If you weren’t able to pay your Q4 estimate in time, we’ll account for this when preparing your annual tax return. Please note: this may increase your tax bill for ${lastQuarterDetails?.taxYear}. If you didn’t make a Q4 payment, you may incur an underpayment penalty fee.`
              : ` If you weren’t able to submit your checklist and pay your Q${lastQuarterDetails?.taxQuarter} estimate in time, we’ll account for this when preparing your Q${activeQuarterDetails?.taxQuarter} estimate. Please note: you may see a larger Q${activeQuarterDetails?.taxQuarter} estimate as a result.`}
          </Text>
        </GridRowColumn>
      )}
      <EstimatesForQuarter
        federalTaxEstimate={federalTaxEstimate}
        homeStateTaxEstimate={homeStateTaxEstimate}
        stateTaxEstimates={stateTaxEstimates}
      />
    </Grid>
  )
}
