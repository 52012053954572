import React from 'react'
import { Container, Header } from 'semantic-ui-react'

import CalendlyLinksCard from '../../Checkins/Admin/CalendlyLinksCard'

const AdminProfile = () => (
  <Container id="adminPanel">
    <Header as="h2">Admin Profile</Header>
    <CalendlyLinksCard />
  </Container>
)

export default AdminProfile
