import { useEffect, useState } from 'react'
import { Grid, Container, Divider, Image } from 'semantic-ui-react'

import SignupHeader from '../SignupHeader'
import { Text, Card, Button } from '../../../components/BaseComponents'
import {
  JOURNEY_SELECTIONS,
  SIGNUP_PATHS,
  canOptIntoPreviousYearTaxes,
} from '../helpers'
import { updateFinancialProfile } from '../../../actions/financialProfileActions'
import { getFinancialProfile } from '../../../selectors/user.selectors'
import { useSignupPageUpdate } from '../../../services/onboardingService'
import { useAnalyticsView } from '../../Amplitude'
import {
  useNavigateWithPersistParams,
  useReselector,
  useScrollRef,
} from '../../../utils/sharedHooks'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'

const JourneySelection = ({
  value,
  imageUrl,
  title,
  subtitle,
  selection,
  setSelection,
  recs,
}: {
  value: string
  imageUrl: string
  title: string
  subtitle: string
  selection: string | undefined
  setSelection: (val: string) => void
  recs: string[]
}) => {
  const selected = selection === value

  useSignupPageUpdate(SIGNUP_PATHS.practiceJourney)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <Grid.Column>
      <Card backgroundColor="stone" style={{ minHeight: 620, display: 'flex' }}>
        <Grid>
          <Grid.Row>
            <Grid.Column style={{ display: 'flex', justifyContent: 'center' }}>
              <Image src={imageUrl} style={{ height: 102 }} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Text as="h2" textAlign="center">
                {title}
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Text as="h3" textAlign="center">
                {subtitle}
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Text as="bodyLg">You have:</Text>
              <ul style={{ marginTop: 0 }}>
                {recs.map((rec) => (
                  <li key={rec}>
                    <Text as="bodyLg">{rec}</Text>
                  </li>
                ))}
              </ul>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {!isMobile && <div style={{ flex: 1 }} />}
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Button
                variant={selected ? 'primary' : 'secondary'}
                onClick={() => setSelection(value)}
                fullWidth
              >
                {selected ? 'Selected' : 'Select'}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Grid.Column>
  )
}

const PracticeJourney = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigateWithPersistParams()
  const [loading, setLoading] = useState(false)
  const pageView = useAnalyticsView()
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const { scrollRef, scrollToRef } = useScrollRef({ autoScroll: true })

  useEffect(() => {
    pageView('sign up practice journey')
  }, [pageView])

  useEffect(() => {
    scrollToRef()
  }, [scrollToRef])

  const financialProfile = useReselector(getFinancialProfile)

  const [selection, setSelection] = useState(
    financialProfile?.practiceType || ''
  )

  const handleSubmit = async () => {
    setLoading(true)
    const res = await updateFinancialProfile(financialProfile?.id, {
      practiceType: selection,
      practiceSize: selection === 'group' ? '2-10' : '1',
    })(dispatch)
    setLoading(false)
    if (res) {
      if (canOptIntoPreviousYearTaxes()) {
        navigate(SIGNUP_PATHS.previousYearTax)
      } else {
        navigate(SIGNUP_PATHS.choosePlan)
      }
    }
  }

  return (
    <Container style={{ paddingTop: 64 }}>
      <Grid stackable>
        <span ref={scrollRef} />
        <SignupHeader currentStep={2} />
        <Grid.Row>
          <Grid.Column>
            <Text as="h1">Where are you in your private practice journey?</Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="short">
          <Grid.Column>
            <Text as="bodyLg">
              Choose the option that is closest to your current practice.
            </Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="equal">
          <JourneySelection
            {...JOURNEY_SELECTIONS['gettingStarted']}
            setSelection={setSelection}
            selection={selection}
          />
          <JourneySelection
            {...JOURNEY_SELECTIONS['soloPractice']}
            setSelection={setSelection}
            selection={selection}
          />
          <JourneySelection
            {...JOURNEY_SELECTIONS['groupPractice']}
            setSelection={setSelection}
            selection={selection}
          />
        </Grid.Row>

        <Divider />
        {!isMobile && (
          <Grid.Row>
            <Grid.Column width={2}>
              <Button
                variant="secondary"
                fullWidth
                onClick={() => navigate(SIGNUP_PATHS.aboutYourPractice)}
              >
                Back
              </Button>
            </Grid.Column>
            <Grid.Column width={12} />
            <Grid.Column width={2}>
              <Button
                fullWidth
                onClick={handleSubmit}
                disabled={!selection || loading}
                id="btn-signup-practice-type"
                loading={loading}
              >
                Continue
              </Button>
            </Grid.Column>
          </Grid.Row>
        )}
        {isMobile && (
          <Grid.Row>
            <Grid.Column>
              <Button
                fullWidth
                onClick={handleSubmit}
                disabled={!selection || loading}
                id="btn-signup-practice-type"
                loading={loading}
              >
                Continue
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button
                variant="secondary"
                fullWidth
                onClick={() => navigate(SIGNUP_PATHS.aboutYourPractice)}
              >
                Back
              </Button>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Container>
  )
}

export default PracticeJourney
