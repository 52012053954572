import { Grid } from 'semantic-ui-react'
import { GridRowColumn } from '../../../components/BaseComponents'
import { TransactionOrSplit } from '../../../reducers/admin/allTransactions.slice'
import { NotesInput } from './NotesInput'

interface NotesPanelProps {
  transaction: TransactionOrSplit
}

export const NotesPanel = ({ transaction }: NotesPanelProps) => {
  return (
    <Grid>
      <GridRowColumn>
        <NotesInput transaction={transaction} />
      </GridRowColumn>
    </Grid>
  )
}
