import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'
import { Moment } from 'moment'

import { UserDocumentCategory } from '../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { fetchIfNeededWrapper, fetchWrapper } from '../../reducers/fetch'
import { TaxUserDocument } from '../Taxes/AnnualTaxes/taxUserDocuments.slice'
import { FinancialAccount } from '../../reducers/finances/financialAccountsReducer'
import { StatementData } from '../../components/FileUpload/StatementUploadSection'

export interface StatementFinancialAccounts {
  financialAccountId: number
  statementUserDocumentId: number
  financialAccount?: FinancialAccount
  createdAt?: string
  updatedAt?: string
  deletedAt?: string | null
}

export interface Statement {
  id: number
  userDocumentId: number
  statementMonth: string | null
  plaidStatementId: string | null
  statementSource: string | null
  statementFinancialAccounts?: StatementFinancialAccounts[]
  createdAt?: string
  updatedA?: string
  deletedAt?: string | null
}

export interface UserDocument {
  id: number
  financialProfileId: number
  transactionId: number | null
  bookkeepingReportId: number | null
  awsFileName: string
  documentType: string | null
  fileDescription: string | null
  statementMonth: string | null
  statementFinancialAccountId: number | null
  updatedAt: string
  createdAt: string
  uploadedAt: string | null
  userDocumentCategoryId: number | null
  // value is null for placeholder doc
  signedUrl: string | null
  customerDescription: string | null
  category?: UserDocumentCategory
  needsReview: boolean | null
  textractDocumentType: null
  textractUserDocumentCategoryId: null
  FinancialProfileId: number
  taxUserDocument?: TaxUserDocument
  statement?: Statement | Partial<Statement>
}

export interface MissingStatement {
  logo: string
  financial_institution_name: string | null
  name: string
  mask: string | null
  statements: string[]
}

export interface UserDocumentState {
  [key: string]: UserDocument
}

const userDocumentSlice = createSlice({
  name: 'userDocuments',
  initialState: {} as UserDocumentState,
  reducers: {
    receiveUserDocuments: (
      state,
      action: PayloadAction<{
        [key: string]: UserDocument
      }>
    ) => ({ ...state, ...action.payload }),
    receiveSingleUserDocument: (
      state,
      action: PayloadAction<{
        userDocument: UserDocument
        taxUserDocument?: TaxUserDocument
      }>
    ) => {
      state[action.payload.userDocument.id] = action.payload.userDocument
    },
    destroyUserDocument: (
      state,
      action: PayloadAction<{
        deletedId: number
        deletedTaxDocumentId: number | null
        deletedTaxUserDocumentId: number | null
      }>
    ) => {
      delete state[action.payload.deletedId]
    },
  },
})

export default userDocumentSlice.reducer

// Actions
export const {
  receiveUserDocuments,
  receiveSingleUserDocument,
  destroyUserDocument,
} = userDocumentSlice.actions

interface UploadDocProps {
  transactionId: number | null
  bookkeepingReportId: number | null
  awsFileName: string
  documentType?: string | null
  fileDescription: string | null
  statementMonth: Moment | null
  statementFinancialAccountId: number | null
  userDocumentCategoryId: number | null
  customerDescription: string | null
  annualTaxFilingFormId: number | null
  annualTaxFormTypeId: number | null
  year?: string
  statement?: StatementData
}

interface UpdateDocumentRequest {
  transactionId: number | null
}

export const createSingleUserDocument = (data: UploadDocProps) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error creating the user document.',
    fetchFunction: (dispatch) =>
      axios
        .post<{
          userDocument: UserDocument
          taxUserDocument?: TaxUserDocument
        }>('/finances/api/v1/user_documents', {
          ...data,
          statementMonth: data.statementMonth?.format('YYYY-MM'),
        })
        .then((json) => {
          dispatch(
            receiveSingleUserDocument({
              userDocument: json.data.userDocument,
              taxUserDocument: json.data.taxUserDocument,
            })
          )

          return json.data
        }),
  })

export const UPDATE_SINGLE_USER_DOCUMENT_KEY = 'UPDATE_SINGLE_USER_DOCUMENT_KEY'
export const updateSingleUserDocument = (
  id: number,
  data: UpdateDocumentRequest
) =>
  fetchWrapper({
    fetchKey: UPDATE_SINGLE_USER_DOCUMENT_KEY,
    defaultErrorMessage: 'There was an error updating the user document.',
    fetchFunction: async (dispatch) => {
      const json = await axios.put<UserDocument>(
        `/finances/api/v1/user_documents/${id}`,
        data
      )
      dispatch(receiveSingleUserDocument({ userDocument: json.data }))
      return json.data
    },
  })

export const FETCH_USER_DOCUMENTS_KEY = 'FETCH_USER_DOCUMENTS_KEY'
export const fetchUserDocuments = (
  shouldNotDownload = false,
  alwaysFetch = false
) =>
  fetchIfNeededWrapper({
    alwaysFetch,
    fetchKey: FETCH_USER_DOCUMENTS_KEY,
    defaultErrorMessage: 'There was an error fetching all user documents.',
    fetchFunction: (dispatch) =>
      axios
        .get<UserDocument[]>('/finances/api/v1/user_documents', {
          params: {
            shouldNotDownload,
          },
        })
        .then((json) => {
          dispatch(receiveUserDocuments(keyBy(json.data, 'id')))
          return json.data
        }),
  })

export const DESTROY_SINGLE_USER_DOCUMENT_KEY =
  'DESTROY_SINGLE_USER_DOCUMENT_KEY'
export const destroySingleUserDocument = (documentId: number) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error destroying the user document.',
    fetchKey: DESTROY_SINGLE_USER_DOCUMENT_KEY,
    fetchFunction: (dispatch) =>
      axios
        .delete<{
          deletedId: number
          deletedTaxDocumentId: number | null
          deletedTaxUserDocumentId: number | null
        }>(`/finances/api/v1/user_documents/${documentId}`)
        .then((json) => {
          dispatch(destroyUserDocument(json.data))
          return json.data
        }),
  })

export const userUpdateStatement = (
  documentId: number,
  data: Partial<UserDocument> & {
    statementFinancialAccounts?: {
      accountsToCreate: number[]
      accountsToDelete: number[]
    }
  }
) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.put<{
        document: UserDocument
        financialProfileId: number
      }>(`/finances/api/v1/user_documents/statement/${documentId}`, data)

      dispatch(receiveSingleUserDocument({ userDocument: json.data.document }))

      return json.data
    },
  })

export const FETCH_NEEDED_BANK_STATEMENTS_FOR_CURRENT_TAX_QUARTER =
  'FETCH_NEEDED_BANK_STATEMENTS_FOR_CURRENT_TAX_QUARTER'
export const getNeededBankStatementsForCurrentTaxQuarter = () =>
  fetchWrapper({
    defaultErrorMessage:
      'There was an error fetching the needed bank statements for the current tax quarter.',
    fetchKey: FETCH_NEEDED_BANK_STATEMENTS_FOR_CURRENT_TAX_QUARTER,
    fetchFunction: () =>
      axios
        .get<
          MissingStatement[]
        >('/finances/api/v1/user_documents/statement/current_tax_quarter')
        .then((json) => {
          return json.data
        }),
  })
