import { useEffect } from 'react'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Alert,
  GridRowColumn,
  Icon,
  Link,
} from '../../components/BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import { selectActiveQTEDetailsAsDates } from '../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { useAppDispatch } from '../../utils/typeHelpers'
import { DATE_FORMATS_LUXON } from '../../utils/dateHelpers'
import { FEATURE_FLAG_KEYS } from '../OpenFeature'

const QTEConversationAlert = () => {
  const dispatch = useAppDispatch()

  const shouldShowQteConversationAlert = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.showQteConversationAlert,
    false
  )

  const activeDetails = useReselector(selectActiveQTEDetailsAsDates)
  const { now, withOffset } = activeDetails || {}

  useEffect(() => {
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
  }, [dispatch])

  // Display message if user is in conversation alert group and it falls within a few days of the QTE being due
  if (
    !shouldShowQteConversationAlert ||
    !now ||
    !withOffset ||
    now > withOffset.irsPaymentDue ||
    now < withOffset.aFewDaysBeforePaymentDue
  ) {
    return null
  }

  return (
    <GridRowColumn>
      <Alert
        title="For questions about your quarterly tax estimates:"
        customIcon={<Icon icon={regular('comments-question')} color="orange" />}
        type="warn"
      >
        We will do our best to answer you quickly, but as mentioned in previous
        emails and in the Quarterly Tax Center, we can’t guarantee that you’ll
        receive a response by the IRS payment deadline. We encourage you to make
        a payment before{' '}
        {withOffset?.irsPaymentDue.toFormat(DATE_FORMATS_LUXON.MONTH_DAY)}, even
        if we can’t answer you in time. You can find guidance on how much to pay
        for your estimates{' '}
        <Link
          href="https://support.joinheard.com/hc/en-us/articles/4416196188439-What-do-you-mean-by-estimate-Can-I-pay-more-or-less-than-the-estimate-you-gave-me-"
          newPage
        >
          here
        </Link>
        .
      </Alert>
    </GridRowColumn>
  )
}

export default QTEConversationAlert
