import { useEffect, useMemo } from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import currency from 'currency.js'

import {
  Button,
  Card,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../utils/deviceWidthHelpers'
import { getProductByInterval } from '../helpers'
import { StripeProduct } from '../../../../actions/settings/billingActions'
import { StripeInterval } from '../../../../reducers/subscription.slice'
import PlanDetailsSection from './components/PlanDetailsSection'
import { FREE_TRIAL_DAYS } from '../../helpers'
import {
  centsToCurrency,
  formatCurrency,
} from '../../../../utils/currencyHelpers'
import HistoricalBookkeepingSection from './components/HistoricalBookkeepingSection'
import { useHistoricalBookkeepingPrice } from '../hooks'

const StripeProductCard = ({
  stripeProducts,
  selectedProduct,
  onSelect,
  freeTrialEnabled,
  basicPlanEnabled,
  isLateJoiner,
}: {
  stripeProducts: StripeProduct[]
  selectedProduct?: StripeProduct
  onSelect: (val: StripeProduct) => void
  freeTrialEnabled: boolean
  basicPlanEnabled: boolean
  isLateJoiner: boolean | null | undefined
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const annualPlan = getProductByInterval(stripeProducts, StripeInterval.year)
  const monthlyPlan = getProductByInterval(stripeProducts, StripeInterval.month)
  const isAnnual = selectedProduct === annualPlan

  const CARD_WITH_DROP_SHADOW_STYLE = {
    boxShadow: '0px 4px 12px 0px #00000026',
    ...(isMobile ? { paddingLeft: 16, paddingRight: 16 } : {}),
  }

  const historicalBookkeepingPrice = useHistoricalBookkeepingPrice()

  useEffect(() => {
    if (annualPlan) onSelect(annualPlan)
  }, [onSelect, annualPlan])

  const productPrice = centsToCurrency(
    selectedProduct?.default_price.unit_amount || 0
  )

  const totalDueToday = useMemo(() => {
    if (freeTrialEnabled) {
      return currency(0)
    }

    if (historicalBookkeepingPrice) {
      return productPrice.add(currency(historicalBookkeepingPrice.amount))
    }

    return productPrice
  }, [freeTrialEnabled, historicalBookkeepingPrice, productPrice])

  const subText = useMemo(() => {
    if (freeTrialEnabled) {
      return `${FREE_TRIAL_DAYS} days free then ${formatCurrency(productPrice, { hideDecimalsIfZero: true })} billed ${
        isAnnual ? 'annually' : 'monthly'
      }`
    } else if (historicalBookkeepingPrice) {
      return `Then ${formatCurrency(productPrice, { hideDecimalsIfZero: true })} billed ${
        isAnnual ? 'annually' : 'monthly'
      }`
    }

    return `Billed ${isAnnual ? 'annually' : 'monthly'}`
  }, [freeTrialEnabled, historicalBookkeepingPrice, isAnnual, productPrice])

  return (
    <Grid>
      <GridRowColumn>
        <Button
          rounded
          variant={isAnnual ? 'primary' : 'secondary'}
          style={{ display: 'inline' }}
          size={isMobile ? 'small' : 'medium'}
          onClick={() => {
            if (annualPlan) onSelect(annualPlan)
          }}
          id="annual-plan-toggle-button"
        >
          Annual Plan{' '}
          <span
            className={isAnnual ? 'selected' : ''}
            id="annual-percent-savings-tag"
          >
            SAVE 15%
          </span>
        </Button>
        <Button
          rounded
          variant={!isAnnual ? 'primary' : 'secondary'}
          style={{ display: 'inline', marginLeft: 8 }}
          size={isMobile ? 'small' : 'medium'}
          onClick={() => {
            if (monthlyPlan) onSelect(monthlyPlan)
          }}
        >
          {isLateJoiner ? 'Annual Plan — Paid Monthly' : 'Monthly Plan'}
        </Button>
      </GridRowColumn>
      <GridRowColumn>
        <Card style={CARD_WITH_DROP_SHADOW_STYLE} fullWidth>
          <Card.Content>
            <PlanDetailsSection
              stripeProducts={stripeProducts}
              selectedProduct={selectedProduct}
              freeTrialEnabled={freeTrialEnabled}
              basicPlanEnabled={basicPlanEnabled}
              isLateJoiner={isLateJoiner}
            />
            <HistoricalBookkeepingSection />
            <Divider />
            <Grid>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={8}>
                  <Text as={isMobile ? 'h3' : 'h2'}>Total Due Today</Text>
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                  <Text
                    as={isMobile ? 'h1' : 'display2'}
                    style={{ display: 'inline' }}
                  >
                    {formatCurrency(totalDueToday, {
                      hideDecimalsIfZero: true,
                    })}
                  </Text>
                  {!historicalBookkeepingPrice && (
                    <Text
                      as={isMobile ? 'h2' : 'h1'}
                      style={{ display: 'inline' }}
                    >
                      /{isAnnual ? 'yr' : 'mo'}
                    </Text>
                  )}
                </Grid.Column>
              </Grid.Row>
              <GridRowColumn textAlign="right" short>
                <Text color="green" as="bodyLg">
                  {subText}
                </Text>
              </GridRowColumn>
            </Grid>
          </Card.Content>
        </Card>
      </GridRowColumn>
    </Grid>
  )
}

export default StripeProductCard
