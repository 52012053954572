import { useEffect, useState } from 'react'

import { updateUser } from '../../actions/userActions'
import { Button } from '../../components/BaseComponents'
import { AnimatedModal, OnboardingModalIdents } from './config'
import { getCurrentUser } from '../../selectors/user.selectors'
import { useReselector } from '../../utils/sharedHooks'
import { call, clipboard, waveGirl } from '../../assets/lottie/onboarding.json'
import { useAnalyticsView } from '../Amplitude'
import { useAppDispatch } from '../../utils/typeHelpers'

const WelcomeModal = () => {
  const currentUser = useReselector(getCurrentUser)
  const [open, setOpen] = useState(true)
  const dispatch = useAppDispatch()
  const pageView = useAnalyticsView()

  useEffect(() => {
    if (currentUser) {
      dispatch(
        updateUser(currentUser.id, {
          lastOnboardingScreen: OnboardingModalIdents.WELCOME,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleContinue = () => {
    setOpen(false)
    pageView('onboarding items')
  }

  return (
    <AnimatedModal
      dimmer={'blurring'}
      content={[
        {
          animationTitle: 'Welcome Animation',
          lottieAnimation: waveGirl,
          lottieLoop: true,
          title: 'Welcome to Heard!',
          description:
            "We're glad you're here! Congrats on taking the first step to simplifying your financial back-office.",
        },
        {
          animationTitle: 'Clipboard',
          lottieAnimation: clipboard,
          title: 'Set up Your Account',
          description:
            'First, we need to collect a few things from you to get started with your bookkeeping as soon as possible.',
        },
        {
          animationTitle: 'Telephone',
          lottieAnimation: call,
          title: "Let's Get Acquainted",
          description:
            "Then, you'll have the option to join a group webinar with the Heard Onboarding Team to set you up for success.",
        },
      ]}
      modalActions={
        <Button onClick={handleContinue} className={'get-started'}>
          Get started
        </Button>
      }
      open={open}
    />
  )
}

export default WelcomeModal
