import {
  Button,
  Icon,
  Link,
  Modal,
  Text,
} from '../../components/BaseComponents'
import { Image } from 'semantic-ui-react'
import { useCallback } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { OnboardingTaskIdents } from './config'
import { ConnectBusinessBankAccountStatus } from '../Dashboard/UserActionItems/userActionItemStatuses'
import { updateUserActionItem } from '../Dashboard/UserActionItems/userActionItems.slice'
import { useReselector } from '../../utils/sharedHooks'
import { getUserActionItemByActionItemIdentifier } from '../Dashboard/UserActionItems/userActionItems.selectors'
import { useAnalyticsTrack } from '../Amplitude'
import { useAppDispatch } from '../../utils/typeHelpers'

interface Props {
  open: boolean
  onClose: () => void
}
export const NewBankAccountModal = ({ open, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const actionItem = useReselector(
    getUserActionItemByActionItemIdentifier,
    OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT
  )
  const track = useAnalyticsTrack()

  const completeActionItem = useCallback(
    async (status: ConnectBusinessBankAccountStatus) => {
      if (actionItem) {
        await updateUserActionItem(actionItem.id, {
          id: actionItem.id,
          status,
          completedAt: new Date().toISOString(),
        })(dispatch)
        track('completed todo', {
          completed_source: 'auto-completed',
          todo_description: actionItem.actionItem.description,
        })
      }
    },
    [actionItem, dispatch, track]
  )

  const onApplyButtonClicked = useCallback(async () => {
    window
      .open('https://www.bluevine.com/partner/heard-checking', '_blank')
      ?.focus()
    await completeActionItem(ConnectBusinessBankAccountStatus.inProgress)
    track('completed onboarding item connect financial accounts', {
      status: 'in progress',
    })
    onClose()
  }, [onClose, completeActionItem, track])

  return (
    <Modal closeIcon onClose={() => onClose()} size="tiny" open={open}>
      <Modal.Header>Opening a business bank account</Modal.Header>
      <Modal.Content>
        <Text style={{ textAlign: 'justify', marginBottom: '15px' }}>
          To make it easier for our members, we’ve partnered with{' '}
          <Link
            href={
              'https://www.bluevine.com/newsroom/heard-partners-with-bluevine-to-offer-business-checking-to-therapists'
            }
            newPage
          >
            Bluevine
          </Link>{' '}
          to provide business checking to mental health professionals. You can
          also check out{' '}
          <Link
            href={
              'https://www.joinheard.com/articles/do-i-need-a-business-checking-and-savings-account-for-my-private-practice'
            }
            newPage
          >
            this
          </Link>{' '}
          article for advice on how to select a business bank account.
        </Text>
        <Image
          src={'https://heard-images.s3.amazonaws.com/assets/ob_bluevine.svg'}
        />
        <Text as={'bodySm'} style={{ marginTop: '15px' }}>
          Clicking Apply will take you to an external site.
        </Text>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className={'button__full-width-mobile'}
          onClick={async () => {
            await completeActionItem(
              ConnectBusinessBankAccountStatus.notConnected
            )
            track('completed onboarding item connect financial accounts', {
              status: 'not connected',
            })
            onClose()
          }}
          variant="actionLink"
          style={{ marginRight: '20px' }}
        >
          No thanks
        </Button>
        <Button
          className={'button__full-width-mobile'}
          onClick={onApplyButtonClicked}
        >
          Apply{' '}
          <Icon
            size={'sm'}
            icon={regular('square-arrow-up-right')}
            style={{ marginLeft: '8px' }}
          />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
