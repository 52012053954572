import React, { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { orderBy } from 'lodash'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { chartOfAccounts } from '../../ChartOfAccounts/chartOfAccount.selectors'
import {
  FETCH_CHART_OF_ACCOUNTS_KEY,
  fetchChartOfAccountsIfNeeded,
} from '../../ChartOfAccounts/chartOfAccount.slice'
import { getAllTransactionCategories } from '../../Reports/reports.selectors'
import {
  FETCH_TRANSACTION_CATEGORIES_KEY,
  fetchTransactionCategoriesIfNeeded,
} from '../../Reports/reports.slice'

import {
  Card,
  Dropdown,
  GridRowColumn,
  Link,
  Text,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { getIsFetching } from '../../../reducers/fetch'
import ChartOfAccountTable from './ChartOfAccountTable'

const TransactionCategoriesPanel = () => {
  const dispatch = useAppDispatch()
  const isFetchingTransactionCategories = useReselector(
    getIsFetching,
    FETCH_TRANSACTION_CATEGORIES_KEY
  )
  const isFetchingChartOfAccounts = useReselector(
    getIsFetching,
    FETCH_CHART_OF_ACCOUNTS_KEY
  )

  const [chartOfAccountId, setChartOfAccountId] = useState<number>()

  useEffect(() => {
    dispatch(fetchTransactionCategoriesIfNeeded())
    dispatch(fetchChartOfAccountsIfNeeded())
  }, [dispatch])

  const categoriesObject = useReselector(getAllTransactionCategories)
  const chartOfAccountsObject = useReselector(chartOfAccounts)

  let coas = Object.values(chartOfAccountsObject)

  // Sort by non-archived first, then by calendar year
  coas = orderBy(coas, ['archivedAt', 'calendarYear'], ['desc', 'desc'])

  const mostRecent = coas[0]
  useEffect(() => {
    setChartOfAccountId(mostRecent?.id)
  }, [mostRecent?.id])

  const LINK_TO_ACCOUNTING_POLICIES =
    'https://www.notion.so/heard/2146f50f5823434daa87041aecbcb4b1?v=416dff674d8b4da2a3076afef96a2624'

  return (
    <Card>
      <Grid>
        <GridRowColumn>
          <Text as="h2">Chart of Accounts</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text as="bodyMd">
            The Chart of Accounts (CoA) holds all the financial accounts that
            are in Heard&apos;s general ledger. To view accounting policies,{' '}
            <Link href={LINK_TO_ACCOUNTING_POLICIES}>click here.</Link>
          </Text>
        </GridRowColumn>
        <Grid.Row />
        {!isFetchingTransactionCategories && !isFetchingChartOfAccounts && (
          <GridRowColumn width={3}>
            <Dropdown
              options={coas.map((coa) => ({
                text: coa.name,
                value: coa.id,
              }))}
              value={chartOfAccountId}
              onChange={setChartOfAccountId}
              fullWidth
            />
          </GridRowColumn>
        )}
        {chartOfAccountId && (
          <GridRowColumn>
            <ChartOfAccountTable
              categories={categoriesObject}
              coa={coas.find((coa) => coa.id === chartOfAccountId)}
            />
          </GridRowColumn>
        )}
      </Grid>
    </Card>
  )
}

export default TransactionCategoriesPanel
