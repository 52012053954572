import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Card, Header, Grid, Button } from 'semantic-ui-react'

import { fetchTransactionCounts } from '../../../actions/adminActions'
import TransactionImportModal from './TransactionImportModal'
import { User } from '../../../reducers/auth/userReducer'
import { useAppDispatch } from '../../../utils/typeHelpers'

const UserTransactionsCard = ({ user }: { user: User }) => {
  const dispatch = useAppDispatch()
  const [counts, setCounts] = useState<{
    pendingCount: number
    uncategorizedCount: number
    requestedClarificationCount: number
  }>()
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const counts = await fetchTransactionCounts({ userId: user.id })(dispatch)
      setCounts(counts)
    }
    fetchData()
  }, [dispatch, user])

  return (
    <Card fluid className="userTransactionsCard">
      <div className="flexbox">
        <Card.Header>
          <Header as="h4">Transactions</Header>
        </Card.Header>
        <Button className="link" onClick={() => setModalOpen(true)}>
          Manual Upload
        </Button>
        {modalOpen && (
          <TransactionImportModal
            user={user}
            open={modalOpen}
            close={() => setModalOpen(false)}
          />
        )}
      </div>

      <Card.Content>
        <Grid stackable doubling className="noPadding">
          <Grid.Column textAlign="center" width={4}>
            <div>
              <Header as="h3">{counts?.uncategorizedCount}</Header>
              <p>Uncategorized</p>
            </div>
          </Grid.Column>
          <Grid.Column textAlign="center" width={4}>
            <div>
              <Header as="h3">{counts?.requestedClarificationCount}</Header>
              <p>Needs Clarification</p>
            </div>
          </Grid.Column>
          <Grid.Column textAlign="center" width={4}>
            <div>
              <Header as="h3">{counts?.pendingCount}</Header>
              <p>Pending</p>
            </div>
          </Grid.Column>
        </Grid>
      </Card.Content>
      <Card.Content textAlign="right">
        <Link to={`/admin/finances/transactions/${user.id}`}>
          <Button className="link">View All User Transactions</Button>{' '}
          &gt;&gt;&gt;{' '}
        </Link>
      </Card.Content>
    </Card>
  )
}

export default UserTransactionsCard
