import { useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import * as yup from 'yup'
import { FormikProvider, useFormik } from 'formik'

import {
  Button,
  FormikDraftInput,
  FormikDropdown,
  FormikInput,
  getFieldName,
  GridRowColumn,
  makeReqStringSchema,
  Modal,
  Text,
} from '../../components/BaseComponents'
import { BorderedIcon } from '../../components/BaseComponents/Icon'
import { POST_CREATE_TICKET_KEY, postCreateNewTicket } from './actions'
import { InputError } from '../../components/BaseComponents/Input'
import { useReselector } from '../../utils/sharedHooks'
import {
  AUTO_CAT_HEADER,
  selectDefaultTicketFields,
  selectTicketTopics,
} from './conversation.slice'
import { getIsFetching } from '../../reducers/fetch'
import {
  fetchSubscriptions,
  selectIsBasicPlan,
} from '../../reducers/subscription.slice'
import { fetchUserTransactions } from '../Transactions/transactions.slice'
import { useAnalyticsTrack } from '../Amplitude'
import { markUserActionItemCompleteIfExists } from '../Dashboard/UserActionItems/service'
import { UserActionItemActionItemIdentifiers } from '../Dashboard/UserActionItems/userActionItems.slice'
import { useAppDispatch } from '../../utils/typeHelpers'

const validationSchema = yup.object({
  topicIden: makeReqStringSchema({ field: 'topic' }),
  subject: makeReqStringSchema({ field: 'subject' }),
  body: makeReqStringSchema({ field: 'comment' }),
  htmlBody: makeReqStringSchema({ field: 'comment' }),
})

export const NewConversationModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const ticketTopics = useReselector(selectTicketTopics)
  const loading = useReselector(getIsFetching, POST_CREATE_TICKET_KEY)
  const [searchParams] = useSearchParams()

  const transactionId = searchParams.get('transactionId')
  const limitedBankAccess = searchParams.get('limitedBankAccess') === 'true'

  const defaultTicketFields = useReselector(selectDefaultTicketFields, {
    recatTransactionId: searchParams.get('recatTransactionId'),
    transactionId,
    limitedBankAccess,
  })

  useEffect(() => {
    dispatch(fetchSubscriptions())
    dispatch(fetchUserTransactions())
  }, [dispatch])

  const isUserOnBasicPlan = useReselector(selectIsBasicPlan)

  const ticketTopicOptions = useMemo(() => {
    const topics = ticketTopics.map(({ name, identifier }) => ({
      text: name,
      value: identifier,
    }))

    if (isUserOnBasicPlan) {
      const index = topics.findIndex((item) => item.value === 'annual-taxes')
      topics.splice(index, 1)
    }

    return topics
  }, [ticketTopics, isUserOnBasicPlan])

  const track = useAnalyticsTrack()

  const formik = useFormik({
    initialValues: {
      topicIden: defaultTicketFields.topicIden,
      subject: defaultTicketFields.subject,
      body: defaultTicketFields.body,
      htmlBody: defaultTicketFields.htmlBody,
    },
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async ({ body, htmlBody, subject, topicIden }) => {
      if (subject.includes(AUTO_CAT_HEADER)) {
        track('submitted autocat request (to bk)')
      }
      if (limitedBankAccess) {
        markUserActionItemCompleteIfExists(
          UserActionItemActionItemIdentifiers.limitedBankAccess,
          (event, properties) => track(event, properties)
        )
      }
      const newTicket = await postCreateNewTicket({
        internalSubmitter: subject.includes(AUTO_CAT_HEADER) ? true : undefined,
        comment: { body, htmlBody },
        topic: topicIden,
        subject,
        tags: defaultTicketFields.tags,
      })(dispatch)
      if (newTicket) {
        navigate(`/conversations/${newTicket.id}`)
      }
    },
  })

  const bodyPlaceholder = transactionId
    ? 'Please describe your issue with this transaction. If you would like it to be auto-categorized, let us know which category to use.'
    : "What's on your mind?"

  return (
    <FormikProvider value={formik}>
      <Modal open={open} onClose={onClose} closeIcon size="small">
        <Modal.Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <BorderedIcon
              icon={regular('comment')}
              wrapperColor="moss"
              color="forest"
              height={40}
            />
            <Text as="h2" style={{ marginLeft: 5 }}>
              New Conversation
            </Text>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <GridRowColumn>
              <Text as="bodyLg">
                Please choose a topic and provide as much detail as possible.
              </Text>
            </GridRowColumn>
            <Grid.Row>
              <Grid.Column>
                <FormikDropdown
                  label="Topic"
                  name={getFieldName<typeof formik.values>('topicIden')}
                  options={ticketTopicOptions}
                  fullWidth
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12} mobile={16}>
                <FormikInput
                  label="Subject"
                  name={getFieldName<typeof formik.values>('subject')}
                  placeholder="Add a subject line to specify your needs"
                  fullWidth
                />
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn>
              <FormikDraftInput
                bodyName={getFieldName<typeof formik.values>('body')}
                htmlBodyName={getFieldName<typeof formik.values>('htmlBody')}
                placeholder={bodyPlaceholder}
              />
              {/*body is used for this check since html body has empty tags by default*/}
              <InputError error={formik.touched.body && formik.errors.body} />
            </GridRowColumn>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            variant="actionLink"
            style={{ marginRight: 30 }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            disabled={!formik.isValid}
            loading={loading}
          >
            Send
          </Button>
        </Modal.Actions>
      </Modal>
    </FormikProvider>
  )
}
