import axios from 'axios'
import { normalize } from 'normalizr'

import { fetchWrapper } from '../../reducers/fetch'
import { ticketSchema } from '../../schema'
import {
  setTicketComments,
  setTickets,
  setTicketTopics,
  Ticket,
  TicketComment,
  TicketTopic,
  updateTicket,
  updateYourTurnCount,
} from './conversation.slice'
import { RoleName } from '../../reducers/admin/allRolesReducer'
import { addConversationUsers } from '../../reducers/taxTeam.slice'

export interface ConversationUser {
  id: number
  firstName: string
  lastName: string
  profileUrl?: string
  roles: Array<{
    id: number
    name: RoleName
  }>
}

export const FETCH_ONE_CONVERSATION_KEY = 'FETCH_ONE_CONVERSATION_KEY'
export const fetchSingleConversation = ({
  ticketId,
  annualTaxFormId,
  zendeskFormType,
}: {
  ticketId?: string
  annualTaxFormId?: number
  zendeskFormType?: string
}) =>
  fetchWrapper({
    fetchKey: FETCH_ONE_CONVERSATION_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{
        ticket: Ticket
        users: ConversationUser[]
      }>(`/finances/api/v1/conversations/${ticketId}`, {
        params: {
          annualTaxFormId,
          zendeskFormType,
        },
      })

      const { ticket, users } = json.data

      const normalizedData = normalize<
        unknown,
        {
          ticket?: { [key: string]: Ticket }
          ticketComment?: { [key: number]: TicketComment }
        }
      >(ticket, ticketSchema)

      dispatch(setTickets(normalizedData.entities.ticket || {}))
      dispatch(setTicketComments(normalizedData.entities.ticketComment || {}))
      dispatch(addConversationUsers(users))

      return json.data
    },
  })

export const FETCH_CONVERSATIONS_PAGE_KEY = 'FETCH_CONVERSATIONS_PAGE_KEY'
export const fetchConversationPage = ({
  nextPage,
  annualTaxFormId,
  zendeskFormType,
}: {
  nextPage?: string | null
  annualTaxFormId?: number
  zendeskFormType?: string
} = {}) =>
  fetchWrapper({
    fetchKey: FETCH_CONVERSATIONS_PAGE_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{
        tickets: Ticket[]
        users: ConversationUser[]
        nextPage: string | null
      }>('/finances/api/v1/conversations/page', {
        params: {
          nextPage,
          annualTaxFormId,
          zendeskFormType,
        },
      })

      const { tickets, users } = json.data

      const normalizedData = normalize<
        unknown,
        {
          ticket?: { [key: string]: Ticket }
          ticketComment?: { [key: number]: TicketComment }
        }
      >(tickets, [ticketSchema])

      dispatch(setTickets(normalizedData.entities.ticket || {}))
      dispatch(setTicketComments(normalizedData.entities.ticketComment || {}))
      dispatch(addConversationUsers(users))

      return json.data
    },
  })

export const FETCH_CONVERSATIONS_KEY = 'FETCH_CONVERSATIONS_KEY'
export const fetchConversations = ({
  annualTaxFormId,
  zendeskFormType,
}: { annualTaxFormId?: number; zendeskFormType?: string } = {}) =>
  fetchWrapper({
    fetchKey: FETCH_CONVERSATIONS_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{
        tickets: Ticket[]
        users: ConversationUser[]
      }>('/finances/api/v1/conversations', {
        params: {
          annualTaxFormId,
          zendeskFormType,
        },
      })

      const { tickets, users } = json.data

      const normalizedData = normalize<
        unknown,
        {
          ticket?: { [key: string]: Ticket }
          ticketComment?: { [key: number]: TicketComment }
        }
      >(tickets, [ticketSchema])

      dispatch(setTickets(normalizedData.entities.ticket || {}))
      dispatch(setTicketComments(normalizedData.entities.ticketComment || {}))
      dispatch(addConversationUsers(users))

      return json.data
    },
  })

export const POST_CREATE_TICKET_KEY = 'POST_CREATE_TICKET_KEY'
export const postCreateNewTicket = ({
  internalSubmitter,
  comment,
  subject,
  topic,
  tags,
}: {
  internalSubmitter?: boolean
  comment: {
    body: string
    htmlBody: string
  }
  subject: string
  topic: string
  tags?: string[]
}) =>
  fetchWrapper({
    fetchKey: POST_CREATE_TICKET_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<Ticket>('/finances/api/v1/conversations', {
        internalSubmitter,
        comment,
        subject,
        topic,
        tags,
      })

      const data = normalize<
        unknown,
        {
          ticket?: { [key: string]: Ticket }
          ticketComment?: { [key: number]: TicketComment }
        }
      >(json.data, ticketSchema)

      dispatch(setTickets(data.entities.ticket || {}))
      dispatch(setTicketComments(data.entities.ticketComment || {}))

      return json.data
    },
  })

export const PUT_COMMENT_KEY = 'PUT_COMMENT_KEY'
export const putAddComment = (
  id: number | string,
  ticketBody: {
    body: string
    htmlBody: string
  }
) =>
  fetchWrapper({
    fetchKey: PUT_COMMENT_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.put<Ticket>(
        `/finances/api/v1/conversations/${id}/comment`,
        ticketBody
      )

      const data = normalize<
        unknown,
        {
          ticket?: { [key: string]: Ticket }
          ticketComment?: { [key: number]: TicketComment }
        }
      >(json.data, ticketSchema)

      dispatch(setTickets(data.entities.ticket || {}))
      dispatch(setTicketComments(data.entities.ticketComment || {}))

      return json.data
    },
  })

export const putCloseTicket = (id: string | number) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.put<{ archivedAt: string }>(
        `/finances/api/v1/conversations/${id}/close`
      )

      dispatch(updateTicket({ ...json.data, id: Number(id) }))

      return json.data
    },
  })

export const fetchUnreadConversationCount = () =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{ count: number }>(
        '/finances/api/v1/conversations/badge_count'
      )

      dispatch(updateYourTurnCount(json.data.count))
      return json.data
    },
    handleError: (dispatch) => dispatch(updateYourTurnCount(0)),
  })

export const FETCH_CONVERSATIONS_TOPIC_KEY = 'FETCH_CONVERSATIONS_TOPIC_KEY'
export const fetchTicketTopics = () =>
  fetchWrapper({
    fetchKey: FETCH_CONVERSATIONS_TOPIC_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{ [key: string]: TicketTopic }>(
        '/finances/api/v1/conversations/topics'
      )
      dispatch(setTicketTopics(json.data))
      return json.data
    },
  })

export const updateTicketCustomField = (
  id: number,
  fieldId: number,
  value: string
) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.put<Ticket>(
        `/finances/api/v1/conversations/${id}/customField`,
        {
          id: fieldId,
          value,
        }
      )
      const data = normalize<
        unknown,
        {
          ticket?: { [key: string]: Ticket }
          ticketComment?: { [key: number]: TicketComment }
        }
      >(json.data, ticketSchema)

      dispatch(setTickets(data.entities.ticket || {}))
      dispatch(setTicketComments(data.entities.ticketComment || {}))

      return json.data
    },
  })

export const POST_CREATE_WELCOME_TICKET_KEY = 'POST_CREATE_WELCOME_TICKET_KEY'
export const postCreateWelcomeTicket = () =>
  fetchWrapper({
    fetchKey: POST_CREATE_TICKET_KEY,
    fetchFunction: async () => {
      const json = await axios.post<Ticket>(
        '/finances/api/v1/conversations/welcomeTicket'
      )
      return json.data
    },
  })
