import { useCallback, useEffect, useRef, useState } from 'react'
import { Grid, Image, List } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { uniq } from 'lodash'

import {
  Alert,
  Button,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { UpdateYourBooksStepProps } from './UpdateYourBooks'
import TransactionCard from './TransactionCard'
import TransactionHeader from './TransactionHeader'
import AddTransactionCard from './AddTransactionCard'
import { TransactionCategoryIdentifier } from '../../../../../reducers/admin/allTransactions.slice'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../utils/sharedHooks'
import { selectUserEoyReviewProgressForSubstepIdentifier } from '../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.selector'
import { SubStepIdentifiers } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { updateUserEoyReviewProgress } from '../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { useAppDispatch } from '../../../../../utils/typeHelpers'

const filterCategories = [
  TransactionCategoryIdentifier.client_discounts_refunds,
  TransactionCategoryIdentifier.consulting_income,
  TransactionCategoryIdentifier.other_income,
  TransactionCategoryIdentifier.retail_income,
  TransactionCategoryIdentifier.speaking_income,
  TransactionCategoryIdentifier.supervision_income,
  TransactionCategoryIdentifier.teaching_income,
  TransactionCategoryIdentifier.uncategorized_income,
]

const AddBusinessIncomeStep = ({
  goForward,
  goBack,
}: UpdateYourBooksStepProps) => {
  const dispatch = useAppDispatch()

  const progress = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.addBusinessIncomeTransactions
  )

  const initHasBusinessIncomeToAdd =
    progress?.responses?.[0]?.hasBusinessIncomeToAdd

  const initBusinessTransactionIds = progress?.responses?.[0]?.transactionIds

  const [showAddTransactionModal, setShowAddTransactionModal] = useState(false)
  const [addedTransactionIds, setAddedTransactionIds] = useState(
    initBusinessTransactionIds || []
  )
  const [editingTransactionId, setEditingTransactionId] = useState<number>()
  const scrollRef = useRef<HTMLSpanElement>(null)
  const scrollToRef = useCallback(
    () => scrollRef.current?.scrollIntoView({ behavior: 'smooth' }),
    []
  )
  const [hasBusinessIncomeToAdd, setHasBusinessIncomeToAdd] = useState(
    typeof initHasBusinessIncomeToAdd === 'boolean'
      ? initHasBusinessIncomeToAdd
      : undefined
  )

  useEffect(() => {
    if (showAddTransactionModal) {
      scrollToRef()
    }
  }, [scrollToRef, showAddTransactionModal])

  useEffect(() => {
    if (!hasBusinessIncomeToAdd) {
      setShowAddTransactionModal(false)
    }
  }, [hasBusinessIncomeToAdd])

  useEffect(() => {
    if (!showAddTransactionModal) {
      setEditingTransactionId(undefined)
    }
  }, [showAddTransactionModal])

  const addTransactionToList = (transactionId: number) => {
    setAddedTransactionIds(uniq([...addedTransactionIds, transactionId]))
  }

  const editTransaction = (transactionId: number) => {
    setShowAddTransactionModal(true)
    setEditingTransactionId(transactionId)
  }

  const deleteTransaction = (transactionId: number) => {
    setAddedTransactionIds(
      addedTransactionIds.filter((tId) => tId !== transactionId)
    )
  }

  const saveAndContinue = useAsyncCallback(async () => {
    let success = true
    if (progress?.id) {
      success = Boolean(
        await updateUserEoyReviewProgress(progress.id, {
          responses: [
            { hasBusinessIncomeToAdd, transactionIds: addedTransactionIds },
          ],
        })(dispatch)
      )
    }
    if (success) {
      await goForward({
        completedSteps: [SubStepIdentifiers.addBusinessIncomeTransactions],
      })
    }
  })

  return (
    <Grid>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/payroll.svg"
          style={{ width: 120, height: 120 }}
        />
      </GridRowColumn>
      <GridRowColumn width={10} spacer={3}>
        <Text as="h1" textAlign="center">
          Do you have <b>business income</b> from the following categories in
          your personal bank accounts?
        </Text>
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column width={5} />
        <Grid.Column width={3}>
          <List bulleted>
            <List.Item>Client Discounts/Refunds</List.Item>
            <List.Item>Consulting Income</List.Item>
            <List.Item>Retail Income</List.Item>
            <List.Item>Other Income</List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={3}>
          <List bulleted>
            <List.Item>Speaking Income</List.Item>
            <List.Item>Supervision Income</List.Item>
            <List.Item>Teaching Income</List.Item>
            <List.Item>Uncategorized Income</List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} />
        <Grid.Column width={2}>
          <Button
            onClick={() => setHasBusinessIncomeToAdd(true)}
            variant="selector"
            fullWidth
            active={hasBusinessIncomeToAdd === true}
          >
            Yes
          </Button>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            onClick={() => setHasBusinessIncomeToAdd(false)}
            variant="selector"
            fullWidth
            active={hasBusinessIncomeToAdd === false}
          >
            No
          </Button>
        </Grid.Column>
      </Grid.Row>
      {hasBusinessIncomeToAdd && (
        <>
          <GridRowColumn>
            <Text textAlign="center">
              Please add <b>business-related</b> transactions that aren’t
              already in Heard.
            </Text>
          </GridRowColumn>
          <GridRowColumn spacer={6} width={4}>
            <Button
              variant="secondary"
              fullWidth
              onClick={() => setShowAddTransactionModal(true)}
            >
              <Icon icon={regular('plus')} style={{ marginRight: 10 }} />
              Add Transaction
            </Button>
          </GridRowColumn>
          <Grid.Row />
          {showAddTransactionModal && (
            <>
              <GridRowColumn spacer={2} width={12}>
                <span ref={scrollRef} />
                <AddTransactionCard
                  onClose={() => setShowAddTransactionModal(false)}
                  onSave={addTransactionToList}
                  transactionId={editingTransactionId}
                  categoryType="Income"
                  categoryFilter={filterCategories}
                />
              </GridRowColumn>
              <Grid.Row />
            </>
          )}

          {addedTransactionIds.length > 0 && <TransactionHeader />}
          <GridRowColumn short>
            {addedTransactionIds.map((id) => (
              <TransactionCard
                key={id}
                transactionId={id}
                variant="edit"
                editAction={editTransaction}
                deleteAction={deleteTransaction}
              />
            ))}
          </GridRowColumn>
          <GridRowColumn width={10} spacer={3}>
            <Alert>
              The bookkeeping and tax teams will review your business-related
              transactions to make sure we file your taxes correctly.
            </Alert>
          </GridRowColumn>
        </>
      )}
      <FormFlowFooter
        onBack={goBack}
        onForward={saveAndContinue.callback}
        loading={saveAndContinue.loading}
        continueDisabled={
          // If selection hasn't been made
          hasBusinessIncomeToAdd === undefined ||
          // Or there are transactions to add but none have been added
          (hasBusinessIncomeToAdd && !addedTransactionIds.length) ||
          // Or continue processing
          saveAndContinue.loading
        }
      />
    </Grid>
  )
}

export default AddBusinessIncomeStep
