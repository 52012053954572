import React from 'react'

// BL
import { FinancialAccountReconciliationWithTransactions } from '../../../../reducers/admin/allFinancialAccountReconciliationsReducer'
import { ReactClickSignature } from '../shared'
import { toUTCDateString } from '../service'

// UI
import { Menu, Button, Label } from 'semantic-ui-react'
import { FinancialAccountSumResponse } from '../../../../actions/admin/adminFinancialAccountActions'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'

interface Props {
  account: FinancialAccountSumResponse | null
  onBackClicked: ReactClickSignature
  reconciliation: FinancialAccountReconciliationWithTransactions
}

const ReconciliationToolbar: React.FC<Props> = ({
  account,
  reconciliation,
  onBackClicked,
}) => {
  const startDateFormatted = toUTCDateString({
    value: reconciliation.startingBalanceDate,
    format: DATE_FORMATS.DISPLAY_LONG,
  })
  const endDateFormatted = toUTCDateString({
    value: reconciliation.endingBalanceDate,
    format: DATE_FORMATS.DISPLAY_LONG,
  })

  return (
    <Menu borderless className="mute reconciliation-toolbar">
      <Menu.Item>
        <Button className="mute" onClick={onBackClicked} icon="arrow left" />
        <h4>
          {account?.plaidInstitutionName} {account?.name}: {account?.mask}
        </h4>
      </Menu.Item>
      <Menu.Item position="right">
        Period of <strong>{startDateFormatted}</strong> to{' '}
        <strong>{endDateFormatted}</strong>
        <Label color={reconciliation.status === 'incomplete' ? 'red' : 'green'}>
          {reconciliation.status}
        </Label>
      </Menu.Item>
    </Menu>
  )
}

export default ReconciliationToolbar
