import React from 'react'

// BL
import { UserDocument } from '../userDocuments.slice'

// UI
import { Grid } from 'semantic-ui-react'
import DocumentsSearchBar, {
  DocumentSearchBarOnChangeData,
} from '../sharedComponents/DocumentsSearchBar'

interface DocumentsToolbarProps {
  documents: UserDocument[]
  onSearch: (_data: DocumentSearchBarOnChangeData) => void
  rightElement?: React.ReactNode
}

const TabPaneToolbar: React.FC<DocumentsToolbarProps> = ({
  documents,
  onSearch,
  rightElement,
}) => (
  <Grid className="tab-category-pane-toolbar">
    <Grid.Row>
      <Grid.Column width={5}>
        <DocumentsSearchBar documents={documents} onChange={onSearch} />
      </Grid.Column>
      <Grid.Column width={6} />
      <Grid.Column width={5}>{rightElement}</Grid.Column>
    </Grid.Row>
  </Grid>
)

export default TabPaneToolbar
