import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  FETCH_CONVERSATIONS_KEY,
  FETCH_ONE_CONVERSATION_KEY,
  fetchConversations,
  fetchSingleConversation,
} from '../actions'
import ConversationHeader from './ConversationHeader'
import ConversationMessages from './ConversationMessages'
import ConversationInput from './ConversationInput'
import {
  useLoadedFlagValue,
  useReselector,
  useScrollRef,
  useTimeoutRef,
  useWindowSize,
} from '../../../utils/sharedHooks'
import { Loader } from '../../../components/BaseComponents'
import { selectTicketById } from '../conversation.slice'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import ArchiveConversationModal from '../ArchiveConversationModal'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'
import { useAppDispatch } from '../../../utils/typeHelpers'

// Rough estimation of height of header.  Doesn't need to be exact
const HEADER_HEIGHT_MOBILE_TABLET = 50

const ConversationThreadPanel = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()

  const ticket = useReselector(selectTicketById, id)
  const windowSize = useWindowSize()
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const isTablet = useIsDeviceWidth(DeviceWidth.tablet)
  const { scrollToRef, scrollRef } = useScrollRef({ scrollInnerOnly: true })
  const scrollTimeoutRef = useTimeoutRef()
  const loadPaginated = useLoadedFlagValue(
    FEATURE_FLAG_KEYS.usePaginatedConversations,
    false
  )

  useEffect(() => {
    if (loadPaginated !== null) {
      if (loadPaginated) {
        dispatch(fetchSingleConversation({ ticketId: id }))
      } else {
        dispatch(fetchConversations())
      }
    }
  }, [dispatch, loadPaginated, id])

  useEffect(() => {
    // Delay set here so api call/redux has time to update
    scrollTimeoutRef.current = setTimeout(scrollToRef, 200)
  }, [scrollTimeoutRef, scrollToRef, ticket?.id])
  const [closeConvoModalOpen, setCloseConvoModalOpen] = useState(false)

  const maxHeight =
    (windowSize.height || 0) -
    (isMobile || isTablet ? HEADER_HEIGHT_MOBILE_TABLET : 0)

  return (
    <div style={{ overflow: 'hidden' }}>
      {!ticket && (
        <Loader
          fetchKey={
            loadPaginated ? FETCH_ONE_CONVERSATION_KEY : FETCH_CONVERSATIONS_KEY
          }
        />
      )}
      <div
        style={{
          display: 'grid',
          height: '100vh',
          maxHeight,
          gridTemplateColumns: 'auto',
          gridTemplateRows: 'auto 1fr auto',
        }}
      >
        <div style={{ gridColumn: 1, gridRow: 1 }}>
          <ConversationHeader
            id={id}
            setCloseConvoModalOpen={setCloseConvoModalOpen}
          />
        </div>
        <div
          style={{
            gridColumn: 1,
            gridRow: 2,
            overflow: 'hidden scroll',
            padding: isMobile ? '0 15px 0 24px' : '0 15px 0 0',
          }}
        >
          <ConversationMessages id={id} fullWidth={isMobile} />
          <span ref={scrollRef} />
        </div>
        <div style={{ gridColumn: 1, gridRow: 3 }}>
          <ConversationInput
            id={id}
            scrollToBottom={scrollToRef}
            setCloseConvoModalOpen={setCloseConvoModalOpen}
          />
        </div>
      </div>
      <ArchiveConversationModal
        open={closeConvoModalOpen}
        close={() => setCloseConvoModalOpen(false)}
        convoId={id}
      />
    </div>
  )
}

export default ConversationThreadPanel
