import { Grid, Image } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Card,
  GridRowColumn,
  Icon,
  Link,
  Text,
} from '../../../../../components/BaseComponents'
import { useReselector } from '../../../../../utils/sharedHooks'
import { getGroupedActiveTaxEstimatesWithinActiveQuarter } from '../../userTaxEstimates.selector'
import { STATE_QUARTERLY_TAX_PAYMENTS } from '../../../taxConstants'
import { shortNames } from '../../../../../constants/statesShortNamesConstants'

const StateSafeHarborCard = () => {
  const { stateTaxEstimates } = useReselector(
    getGroupedActiveTaxEstimatesWithinActiveQuarter
  )

  return (
    <Card type="subsection">
      <Grid>
        <Grid.Row>
          <Grid.Column
            width={2}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/question_coin.svg"
              style={{ width: 80, height: 80 }}
            />
          </Grid.Column>
          <Grid.Column width={14}>
            <Grid>
              <GridRowColumn>
                <Text as="h2">What about state taxes?</Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text color="darkGray">
                  We’re unable to provide an estimate since it&apos;s so late in
                  the quarter. However, we’ll provide you with your state
                  estimate next quarter. In the meantime, visit your
                  state&apos;s website for information on how to calculate your
                  estimated taxes.
                </Text>
              </GridRowColumn>
              {stateTaxEstimates?.map((estimate) => {
                const state = estimate.filingState

                const helpArticleLink =
                  state && STATE_QUARTERLY_TAX_PAYMENTS[state]?.helpArticle

                const longName = state && shortNames[state]

                if (!helpArticleLink || !longName) {
                  return null
                }

                return (
                  <GridRowColumn key={estimate.id}>
                    <Link href={helpArticleLink} newPage>
                      <Icon
                        icon={regular('square-arrow-up-right')}
                        style={{ marginRight: 8 }}
                      />
                      {longName} taxes
                    </Link>
                  </GridRowColumn>
                )
              })}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

export default StateSafeHarborCard
