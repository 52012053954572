import React, { useEffect, useState } from 'react'
import moment from 'moment'
import CurrencyInput from 'react-currency-input-field'
import { Button, Form, InputOnChangeData } from 'semantic-ui-react'

//BL
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { centsToDollars, dollarsToCents } from '../../../utils/currencyHelpers'

//UI
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { DatePicker } from '../../BaseComponents'

export interface EditTransactionFormFields {
  amountInCents: number
  date: string
  description: string
  id: number
}

interface Props {
  loading: boolean
  onSubmit(_: EditTransactionFormFields): void
  transaction: Transaction
}

const EditTransactionForm: React.FC<Props> = ({
  loading,
  onSubmit,
  transaction,
}) => {
  const [description, setDescription] = useState(transaction.description)
  const [date, setDate] = useState(transaction.date)
  const [amountInDollars, setAmountInDollars] = useState<number>(
    centsToDollars(transaction.amountInCents)
  )

  useEffect(() => {
    const formattedDate = moment(transaction.date).format(DATE_FORMATS.INPUT)
    setDate(formattedDate)

    const dollars = centsToDollars(transaction.amountInCents)
    setAmountInDollars(dollars)
  }, [transaction.amountInCents, transaction.date])

  const subtitleStyle = {
    fontWeight: 'normal' as const,
    backgroundColor: 'yellow',
    padding: '2px',
    margin: '4px',
  }

  const onDescriptionChange = (_event: unknown, { value }: InputOnChangeData) =>
    setDescription(value)

  const onSaveClicked = () => {
    const amountInCents = dollarsToCents(amountInDollars)
    onSubmit({
      amountInCents,
      date,
      description,
      id: transaction.id,
    })
  }

  return (
    <Form loading={loading}>
      <Form.Group widths="equal">
        {/* Description */}
        <Form.Input
          fluid
          label="Description"
          value={description}
          onChange={onDescriptionChange}
          placeholder="e.g. Starbucks"
        />

        {/* Date */}
        <DatePicker value={date} onChange={setDate} />
      </Form.Group>

      {/* Amount */}
      <div className="field">
        <label>
          Amount
          <span style={subtitleStyle}>
            Negative amounts indicate a debit, e.g. —$xxx.xx
          </span>
        </label>
        <CurrencyInput
          prefix="$"
          onValueChange={(
            _: unknown,
            __: unknown,
            values?: { float: number | null }
          ) => {
            setAmountInDollars(values?.float || 0)
          }}
          defaultValue={amountInDollars}
        />
      </div>

      <Button onClick={onSaveClicked} primary>
        Save
      </Button>
    </Form>
  )
}

export default EditTransactionForm
