import { createSelector } from 'reselect'
import {
  SkyflowAnyToken,
  SkyflowBankToken,
  SkyflowSsnToken,
} from '../reducers/skyflowSlice'
import { ReduxState } from '../utils/typeHelpers'

export const isSsnToken = (token: SkyflowAnyToken): token is SkyflowSsnToken =>
  'social_security_number' in token

export const isBankToken = (
  token: SkyflowAnyToken
): token is SkyflowBankToken => 'bank_account_number' in token

export const getSkyflowTokens = (state: ReduxState) => state.skyflowTokens

export const getSkyflowSsnTokens = createSelector(
  [getSkyflowTokens],
  (skyflowTokens) => Object.values(skyflowTokens).filter(isSsnToken)
)

export const getSkyflowSsnTokenBySkyflowId = createSelector(
  getSkyflowSsnTokens,
  (_: unknown, id: string) => id,
  (skyflowTokens, id) => skyflowTokens.find((t) => t.skyflow_id === id)
)

export const getSkyflowBankToken = createSelector(
  [getSkyflowTokens],
  (skyflowTokens) => Object.values(skyflowTokens).find(isBankToken)
)

export const getSkyflowUserSsnToken = createSelector(
  [getSkyflowSsnTokens],
  (skyflowTokens) =>
    skyflowTokens.find(
      (t) => t.relationship?.includes('user') && t.social_security_number
    )
)

export const getSkyflowSpouseSsnToken = createSelector(
  [getSkyflowSsnTokens],
  (skyflowTokens) =>
    skyflowTokens.find(
      (t) => t.relationship?.includes('spouse') && t.social_security_number
    )
)

export const getSkyflowDependentSsnTokenList = createSelector(
  [getSkyflowSsnTokens],
  (skyflowTokens) =>
    skyflowTokens.filter(
      (t) => t.relationship?.includes('dependent') && t.social_security_number
    )
)

export const getSkyflowDependentSsnToken = createSelector(
  [
    getSkyflowDependentSsnTokenList,
    (_: unknown, dependent: { firstName: string; lastName: string }) =>
      dependent,
  ],
  (skyflowTokens, dependent) =>
    skyflowTokens.find(
      (t) =>
        t.first_name.trim().toLowerCase() ===
          dependent.firstName.trim().toLowerCase() &&
        t.last_name.trim().toLowerCase() ===
          dependent.lastName.trim().toLowerCase()
    )
)

export const selectDependentSsnTokenBySkyflowId = createSelector(
  getSkyflowDependentSsnTokenList,
  (_: unknown, skyflowId?: string | null) => skyflowId,
  (skyflowTokens, skyflowId) =>
    skyflowTokens.find((t) => t.skyflow_id === skyflowId)
)
