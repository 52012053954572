import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { isArray, mergeWith, sortBy } from 'lodash'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../components/BaseComponents'
import { Colors } from '../../../../styles/theme'
import {
  useReselector,
  useScrollRef,
  useWindowSize,
} from '../../../../utils/sharedHooks'
import { selectImportantDatesForYear } from '../annualTaxFilings.selector'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import { selectQtesForImportantDates } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'

const DateEntry = styled(
  ({
    text,
    date,
    eventInfo,
    className,
  }: {
    isStart?: boolean
    text: string
    date?: DateTime | null
    eventInfo?: ReactNode
    className?: string
  }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
      <div
        style={{
          backgroundColor: isOpen ? Colors.stone : undefined,
        }}
        onClick={() => setIsOpen(!isOpen)}
        role="presentation"
        className={className}
      >
        <Text>{text}</Text>
        {isOpen && (
          <>
            {date && (
              <Text as="eyebrow" color="darkGray" style={{ margin: '20px 0' }}>
                Due {date.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}
              </Text>
            )}
            {eventInfo}
          </>
        )}
      </div>
    )
  }
)(({ isStart, eventInfo }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderLeft: `2px solid ${isStart ? Colors.green : Colors.orange}`,
  padding: '15px 10px',
  cursor: eventInfo ? 'pointer' : 'default',
  backgroundColor: Colors.white,

  '&:hover': {
    backgroundColor: eventInfo ? Colors.stone : undefined,
  },
}))

const TodayCard = () => {
  const { scrollRef, scrollToRef } = useScrollRef({
    scrollInnerOnly: true,
    autoScroll: true,
  })

  useEffect(() => {
    scrollToRef()
  }, [scrollToRef])

  const icon = useMemo(() => {
    switch (DateTime.now().get('month')) {
      case 1:
        return regular('snowman')
      case 2:
        return regular('heart')
      case 3:
        return regular('clover')
      case 4:
        return regular('umbrella')
      case 5:
        return regular('flower-tulip')
      case 6:
        return regular('sunglasses')
      case 7:
        return regular('soft-serve')
      case 8:
        return regular('umbrella-beach')
      case 9:
        return regular('leaf-maple')
      case 10:
        return regular('jack-o-lantern')
      case 11:
        return regular('acorn')
      default:
      case 12:
        return regular('snowflake')
    }
  }, [])

  return (
    <Text as="h3" color="green" style={{ margin: '10px 0' }}>
      <Icon icon={icon} style={{ marginRight: 10 }} />
      Today
      <span ref={scrollRef} />
    </Text>
  )
}

interface DateMapping {
  [month: string]: { [day: number]: ReactNode[] }
}

const mergeDateMapping = (
  existingMapping: DateMapping,
  date: DateTime | null,
  component: ReactNode
) => {
  if (!date) {
    return existingMapping
  }
  return mergeWith(
    existingMapping,
    {
      [date.toFormat('y-LL')]: {
        [date.toISO() || '']: [component],
      },
    },
    (objValue, srcValue) =>
      isArray(objValue) ? objValue.concat(srcValue) : undefined
  )
}

const AnnualTaxImportantDateCard = ({ year }: { year: string }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { height } = useWindowSize()
  const importantDates = useReselector(selectImportantDatesForYear, year)

  const qtes = useReselector(selectQtesForImportantDates, year)

  useEffect(() => {
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
  }, [dispatch])

  const sortedDates = useMemo(() => {
    let dateMapping: DateMapping = {}

    // Add today
    dateMapping = mergeDateMapping(dateMapping, DateTime.now(), <TodayCard />)

    // Should show the new year for upcoming year
    const nextYear = Number(year) + 1

    const newYears = DateTime.fromObject({ year: nextYear, month: 1, day: 1 })

    dateMapping = mergeDateMapping(
      dateMapping,
      newYears,
      <Text as="h3" key="new year" color="green" style={{ margin: '10px 0' }}>
        <Icon icon={regular('party-horn')} style={{ marginRight: 10 }} />
        Happy New Year!
      </Text>
    )

    for (const date of importantDates) {
      dateMapping = mergeDateMapping(
        dateMapping,
        date.startDate,
        <DateEntry
          text={`${date.name} opens`}
          isStart
          key={date.name}
          date={date.startDate}
          eventInfo={date.description}
        />
      )

      dateMapping = mergeDateMapping(
        dateMapping,
        date.endDate,
        <DateEntry text={`${date.name} due`} key={date.name} />
      )
    }

    for (const qte of qtes) {
      dateMapping = mergeDateMapping(
        dateMapping,
        DateTime.fromISO(qte.irsPaymentDueAt),
        <DateEntry
          text={`${nextYear} Q${qte.taxQuarter} Estimates Quarterly Tax Estimate due`}
          key={qte.taxQuarter}
        />
      )
    }

    return dateMapping
  }, [importantDates, qtes, year])

  // todo Hide this card for the 2022 year.  Can be removed once current year changes to 2023
  if (process.env.VITE_ANNUAL_TAXES_2023_ENABLED !== 'true') {
    return null
  }

  return (
    <Card type="subsection" backgroundColor="stone40">
      <Grid>
        <Grid.Row>
          <Grid.Column width={10}>
            <Text as="h2">Important Dates</Text>
          </Grid.Column>
          <Grid.Column width={6}>
            <Button
              variant="link"
              onClick={() => navigate(`/taxes/annual/timeline/${year}`)}
            >
              Timeline
              <Icon
                icon={regular('arrow-up-right-from-square')}
                style={{ marginLeft: 10 }}
              />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid
        style={{
          minHeight: 500,
          height: height ? height - 300 : 500,
          overflowY: 'scroll',
        }}
      >
        {sortBy(Object.entries(sortedDates), ([monthName]) => monthName).map(
          ([monthName, days]) => (
            <Fragment key={monthName}>
              <GridRowColumn>
                <Text>
                  {DateTime.fromFormat(monthName, 'y-LL').toFormat(
                    DATE_FORMATS_LUXON.MONTH_YEAR
                  )}
                </Text>
              </GridRowColumn>
              {sortBy(Object.entries(days), ([day]) => day).map(
                ([day, events]) => (
                  <Grid.Row key={`${monthName}-${day}`}>
                    <Grid.Column width={2}>
                      <Text as="eyebrow" color="darkGray" textAlign="center">
                        {DateTime.fromISO(day, { setZone: true }).toFormat(
                          'ccc'
                        )}
                      </Text>
                      <Text as="bodyLg" color="darkGray" textAlign="center">
                        {DateTime.fromISO(day, { setZone: true }).toFormat('d')}
                      </Text>
                    </Grid.Column>
                    <Grid.Column width={14}>
                      <Grid style={{ marginTop: 0 }}>
                        {events.map((event, index) => (
                          <GridRowColumn key={index} short>
                            {event}
                          </GridRowColumn>
                        ))}
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                )
              )}
            </Fragment>
          )
        )}
      </Grid>
    </Card>
  )
}

export default AnnualTaxImportantDateCard
