import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { Header, Grid, Icon, Button } from 'semantic-ui-react'
import moment from 'moment-timezone'
import { CSVLink } from 'react-csv'
import currency from 'currency.js'

import {
  fetchAdminExpensesBreakdown,
  fetchAdminProfitsBreakdown,
  fetchAdminProfitAndLoss,
  AdminExpenses,
  AdminProfits,
} from '../../../actions/reportActions'
import { fetchAllUsersIfNeeded } from '../../../actions/admin/adminAllUsersActions'
import { fetchTransactionCategoriesIfNeeded } from '../../../features/Reports/reports.slice'
import { getUserById } from '../../../selectors/user.selectors'
import './ProfitAndLossTable.scss'
import { getTransactionCategorySelector } from '../../../features/Reports/reports.selectors'
import { Dispatch, ReduxState, wrapDispatch } from '../../../utils/typeHelpers'
import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'

const LineItem = ({
  item,
}: {
  item: { transactionCategoryId: number; sum: string }
}) => {
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const category = useReselector(
    getTransactionCategorySelector,
    item.transactionCategoryId
  )

  return (
    <Grid.Row className={expanded ? 'lineItemRow active' : 'lineItemRow'}>
      <Grid.Column width={16} className="lineItem" onClick={toggleExpanded}>
        <p style={{ width: '100%' }}>{category?.name || 'Uncategorized'}</p>
        <hr
          style={{
            width: '70%',
            marginTop: '1em',
            marginInlineEnd: '10px',
            border: '0',
          }}
        />
        <p>
          <CurrencyFormatLabel value={item?.sum} />
        </p>
      </Grid.Column>
    </Grid.Row>
  )
}

interface ParentProps {
  userId: string
  startDate?: string
  endDate?: string
}
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = ParentProps & StateProps & DispatchProps

interface State {
  profitsObj: AdminProfits | null
  expensesObj: AdminExpenses | null
  selectedPnl: string
  csvData: string[][]
  csvFetching: boolean
}

class ProfitAndLossTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      profitsObj: null,
      expensesObj: null,
      selectedPnl: '2021',
      csvData: [],
      csvFetching: true,
    }
  }
  async componentDidMount() {
    await this.props.fetchTransactionCategoriesIfNeeded()
    await this.props.fetchAllUsersIfNeeded()

    this.fetchData()
  }

  fetchData = async () => {
    const { startDate, endDate, userId } = this.props

    const params = {
      userId,
      startDate: startDate ? moment(startDate, 'YYYY-MM-DD') : undefined,
      endDate: endDate ? moment(endDate, 'YYYY-MM-DD') : undefined,
    }
    const profits = await this.props.fetchAdminProfitsBreakdown(params)
    const expenses = await this.props.fetchAdminExpensesBreakdown(params)
    await this.formatCSVData()
    this.setState({ profitsObj: profits, expensesObj: expenses })
  }

  formatCSVData = async () => {
    const { startDate, endDate, userId, user, fetchAdminProfitAndLoss } =
      this.props

    this.setState({ csvFetching: true })
    const profitAndLoss = await fetchAdminProfitAndLoss({
      userId,
      startDate: startDate ? moment(startDate, 'YYYY-MM-DD') : undefined,
      endDate: endDate ? moment(endDate, 'YYYY-MM-DD') : undefined,
    })
    const csv: string[][] = []

    csv.push([
      'Profit and Loss Statement for:',
      user?.financialProfile?.businessName || '',
      `${user?.firstName} ${user?.lastName}`,
    ])
    csv.push(['', '', ''])

    csv.push(['Income', '', ''])
    csv.push(['Category', 'Account Type', 'Amount in Dollars'])
    profitAndLoss.profits.forEach((profit) => {
      csv.push([profit.name, profit.account_type, profit.sum])
    })
    csv.push(['>> Income Total', '...', profitAndLoss.profitSum.toString()])
    csv.push(['', '', ''])

    csv.push(['Expenses', '', ''])

    csv.push(['Category', 'Account Type', 'Amount in Dollars'])
    profitAndLoss.expenses.forEach((expense) => {
      csv.push([expense.name, expense.account_type, expense.sum])
    })
    csv.push(['', '', ''])
    csv.push(['>> Expenses Total', '...', profitAndLoss.expensesSum.toString()])
    const netProfit = currency(profitAndLoss.profitSum).add(
      profitAndLoss.expensesSum
    ).value
    csv.push(['', '', ''])
    csv.push(['Net Profit', '...', netProfit.toString()])

    this.setState({ csvData: csv, csvFetching: false })
  }

  renderProfits() {
    return this.state.profitsObj?.profits.map((profit) => (
      <LineItem item={profit} key={profit.transactionCategoryId} />
    ))
  }

  renderExpenses() {
    return this.state.expensesObj?.expenses.map((expense) => (
      <LineItem item={expense} key={expense.transactionCategoryId} />
    ))
  }

  render() {
    const { csvData, selectedPnl, csvFetching, profitsObj, expensesObj } =
      this.state
    const { startDate, endDate, user } = this.props

    const netIncome = currency(profitsObj?.sum || 0).add(
      expensesObj?.sum || 0
    ).value
    return (
      <div id="profitAndLoss" className="backdrop">
        <Grid doubling className="statementTitle">
          <Grid.Row>
            <Grid.Column width={10}>
              <Header as="h2">Profit and Loss Statement</Header>
            </Grid.Column>

            <Grid.Column width={4}>
              <Button float="right" loading={csvFetching} fluid>
                <Icon name="download" />
                {!this.state.csvFetching && (
                  <CSVLink
                    data={csvData}
                    filename={`${selectedPnl}-profit-and-loss-${user?.firstName}-${user?.lastName}.csv`}
                    className="hidden"
                    target="_blank"
                  >
                    Download Profit and Loss
                  </CSVLink>
                )}
              </Button>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign="center">
            <Grid.Column>
              <Header as="h4">
                {user?.financialProfile?.businessName ||
                  `${user?.firstName} ${user?.lastName}`}
              </Header>
              <Header as="h3" className="compact">
                Profit and Loss Statement
              </Header>
              <Header as="h6" className="compact">
                for the period{' '}
                {moment.utc(startDate).format(DATE_FORMATS.DISPLAY_SHORT)} —{' '}
                {moment.utc(endDate).format(DATE_FORMATS.DISPLAY_SHORT)}
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid padded doubling className="padded">
          <Header as="h4" className="green">
            Income
          </Header>
          {this.renderProfits()}

          <Grid.Row className="lineItemRow total">
            <Grid.Column className="lineItem">
              <Header as="h6">Total Income</Header>
              <hr
                style={{
                  width: '70%',
                  marginTop: '1em',
                  marginInlineEnd: '10px',
                }}
              />
              <Header as="h6">
                <CurrencyFormatLabel value={profitsObj?.sum} />
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid doubling>
          <Header as="h4" className="green">
            Expenses
          </Header>
          {this.renderExpenses()}

          <Grid.Row className="lineItemRow total">
            <Grid.Column className="lineItem">
              <b>Total Expenses</b>
              <hr
                style={{
                  width: '70%',
                  marginTop: '1em',
                  marginInlineEnd: '10px',
                }}
              />
              <Header as="h6">
                <CurrencyFormatLabel value={expensesObj?.sum} />
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid doubling>
          <Grid.Row className="lineItemRow total">
            <Grid.Column className="lineItem">
              <Header as="h4" className="green">
                Net Profit
              </Header>
              <hr
                style={{
                  width: '70%',
                  marginTop: '1em',
                  marginInlineEnd: '10px',
                  borderColor: '#F8F9FB',
                }}
              />
              <Header as="h6">
                <CurrencyFormatLabel value={netIncome} />
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state: ReduxState, props: ParentProps) => ({
  user: getUserById(state, props.userId),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTransactionCategoriesIfNeeded: wrapDispatch(
    dispatch,
    fetchTransactionCategoriesIfNeeded
  ),
  fetchAdminExpensesBreakdown: wrapDispatch(
    dispatch,
    fetchAdminExpensesBreakdown
  ),
  fetchAdminProfitsBreakdown: wrapDispatch(
    dispatch,
    fetchAdminProfitsBreakdown
  ),
  fetchAllUsersIfNeeded: wrapDispatch(dispatch, fetchAllUsersIfNeeded),
  fetchAdminProfitAndLoss: wrapDispatch(dispatch, fetchAdminProfitAndLoss),
})

export default connect<StateProps, DispatchProps, ParentProps, ReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ProfitAndLossTable)
