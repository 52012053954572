import React, { useState } from 'react'
import { Button, Icon, Loader } from 'semantic-ui-react'

import { adminUpdateUserTransaction } from '../../../actions/admin/transactionActions'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'

export const useToggleTransactionClarification = ({
  transaction,
  onUpdated = () => undefined,
}: {
  transaction: Transaction
  onUpdated?: () => void
}) => {
  const dispatch = useAppDispatch()
  const [isUpdating, setUpdating] = useState(false)

  const toggleClarification = async () => {
    const requestedClarificationAt = transaction.requestedClarificationAt
      ? null
      : Date.now()
    setUpdating(true)
    const data = {
      requestedClarificationAt,
    }

    await adminUpdateUserTransaction(transaction.id, data)(dispatch)
    setUpdating(false)
    onUpdated()
  }

  return {
    isUpdating,
    toggleClarification,
  }
}

const NeedsClarificationButton = ({
  transaction,
}: {
  transaction: Transaction
}) => {
  const { isUpdating, toggleClarification } = useToggleTransactionClarification(
    {
      transaction,
    }
  )

  if (isUpdating) {
    return <Loader active inline />
  } else if (transaction.requestedClarificationAt) {
    return (
      <span>
        <Icon name="question circle" color="red" />
        <br />
        <Button
          className="link"
          style={{ color: '#B3B3B3 !important' }}
          onClick={() => toggleClarification()}
        >
          Remove Request
        </Button>
      </span>
    )
  } else {
    return (
      <Icon
        name="question circle"
        color="grey"
        className="needsClarificationButton"
        onClick={() => toggleClarification()}
      />
    )
  }
}

export default NeedsClarificationButton
