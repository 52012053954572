import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Icon,
  Label,
  Modal,
} from 'semantic-ui-react'
import moment, { Moment } from 'moment'

import { upsertAccountReconciliation } from '../../../../actions/admin/accountReconciliationActions'
import { fetchFinancialAccountTransactionSum } from '../../../../actions/admin/adminFinancialAccountActions'
import {
  bulkUpdateUserTransactions,
  fetchReconciliationTransactions,
} from '../../../../actions/adminActions'
import { FinancialAccountReconciliationWithTransactions } from '../../../../reducers/admin/allFinancialAccountReconciliationsReducer'
import { FinancialAccountWithAdminInfo } from '../../../../reducers/admin/financialAccountsReducer'
import {
  formatCurrency,
  centsToDollars,
  dollarsToCents,
} from '../../../../utils/currencyHelpers'
import { DatePicker, Input } from '../../../BaseComponents'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { ENABLE_ADMIN, useAnalyticsTrack } from '../../../../features/Amplitude'

export interface ReconciliationModalProps {
  account: FinancialAccountWithAdminInfo
  close: () => void
  open: boolean
  formType: string
  onSumMatch: () => void
  userId: number
}

const FORM_FIELD_PADDING = '1em 2em 0em 2em'
const YESTERDAY = moment.utc().subtract(1, 'day').startOf('day')

const ReconciliationModal = ({
  account,
  close,
  open,
  formType,
  onSumMatch,
  userId,
}: ReconciliationModalProps) => {
  const mostRecentReconciliation =
    account.reconciliations.length > 0 ? account.reconciliations[0] : null

  const [startDate, setStartDate] = useState<Moment | null>(null)
  const [calculatedStartDate, setCalculatedStartDate] = useState<Moment | null>(
    null
  )
  const [startAmount, setStartAmount] = useState('0')
  const [startingBalance, setStartingBalance] = useState('0')
  const [endDate, setEndDate] = useState<Moment | null>(null)
  const [calculatedEndDate, setCalculatedEndDate] = useState<Moment | null>(
    null
  )
  const [endAmount, setEndAmount] = useState('0')
  const [loading, setLoading] = useState(false)
  const [reconciled, setReconciled] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const track = useAnalyticsTrack(ENABLE_ADMIN)

  const { plaidInstitutionName: institution, mask, name: accountName } = account
  const previouslyReconciled = formType !== 'never_reconciled'

  const incomplete = formType === 'incomplete'

  const title = reconciled
    ? 'Reconciliation Complete'
    : incomplete
      ? 'Resume a Reconciliation'
      : 'Begin a Reconciliation'
  const instruction = reconciled
    ? 'This period has been reconciled!'
    : previouslyReconciled
      ? 'Enter the ending details as they appear on your bank statement'
      : 'Enter both starting and ending details as they appear on your bank statement'
  const startDetails = previouslyReconciled
    ? `— Auto populated from the ${
        incomplete ? 'current (incomplete)' : 'previous'
      } reconciliation`
    : ''
  const endDetails = incomplete
    ? '— Auto populated from the current (incomplete) reconciliation'
    : ''

  const resetForm = useCallback(() => {
    setStartDate(null)
    setStartAmount('0')
    setEndDate(null)
    setEndAmount('0')
    setLoading(false)
    setReconciled(false)
  }, [])

  useEffect(() => {
    if (!open) {
      resetForm()
      return
    }

    if (!mostRecentReconciliation) {
      setStartDate(YESTERDAY)
      setCalculatedStartDate(YESTERDAY)
      setEndDate(YESTERDAY)
      setCalculatedEndDate(YESTERDAY)
      return
    }

    const previouslyReconciled = formType !== 'never_reconciled'

    let startDate: Moment | null = null
    let startAmount = 0
    let endDate: Moment | null = null
    let endAmount = 0

    if (previouslyReconciled && formType === 'needs_updating') {
      // This reco is complete, but new transactions exist. Add 1 day to the reco's END date
      startDate = moment
        .utc(mostRecentReconciliation.endingBalanceDate)
        .add(1, 'day')
      startAmount = centsToDollars(
        mostRecentReconciliation.endingBalanceInCents
      )
      endDate = YESTERDAY
    }

    if (previouslyReconciled && formType === 'incomplete') {
      // This reco was not finished. Use the existing START date for this reco

      startDate = moment.utc(mostRecentReconciliation.startingBalanceDate)
      startAmount = centsToDollars(
        mostRecentReconciliation.startingBalanceInCents
      )
      endDate = moment.utc(mostRecentReconciliation.endingBalanceDate)
      endAmount = centsToDollars(mostRecentReconciliation.endingBalanceInCents)
    }

    setStartDate(startDate)
    setCalculatedStartDate(startDate)
    setStartAmount(startAmount.toString())
    setStartingBalance(startAmount.toString())
    setEndDate(endDate)
    setCalculatedEndDate(endDate)
    setEndAmount(endAmount.toString())
  }, [open, account.type, formType, mostRecentReconciliation, resetForm])

  const handleError = (err: unknown) => {
    alert(err)
    setLoading(false)
  }

  const compareHeardAndStatementBalances = useCallback(
    async (reconciliation: FinancialAccountReconciliationWithTransactions) => {
      const defaultResult = {
        doMatch: false,
        delta: null,
        transactionSumIds: null,
      }
      try {
        let accountSum

        if (startDate && endDate) {
          accountSum = await dispatch(
            fetchFinancialAccountTransactionSum(account.id, startDate, endDate)
          )
        }

        if (!accountSum) {
          handleError(new Error('Could not sum transactions. Please try again'))
          return defaultResult
        }

        const { transactionsSumInCents: heardDelta, transactionSumIds } =
          accountSum

        if (!heardDelta) {
          return defaultResult
        }

        const statementDelta =
          reconciliation.endingBalanceInCents -
          reconciliation.startingBalanceInCents

        const delta = centsToDollars(Number(heardDelta) - statementDelta)
        return {
          delta,
          doMatch: delta === 0,
          transactionSumIds,
        }
      } catch (err) {
        handleError(err)
        return defaultResult
      }
    },
    [account.id, dispatch, endDate, startDate]
  )

  /*
    1. Validate inputs
    2. Confirm with user
    3. Ensure transactions exist for this account and period. Return if none exist
    4. Upsert the reconciliation
    5. Compare heardBalance with statementBalance
    If !equal
    6. Return & redirect user to the reconciliation table
    Else
    7. Update reconciliation status to 'complete'
    8. For the specific set of transaction Ids, update their reconciliationId
    9. Callbacks and update state
  */
  const onSubmit = useCallback(async () => {
    // 1
    if (
      typeof startAmount === 'undefined' ||
      !startDate ||
      typeof endAmount === 'undefined' ||
      !endDate
    ) {
      alert('Please make sure all fields are filled in')
      return
    }

    // 2
    const message = `\nQuick check before getting underway — Do these dates and figures look correct?
    
    ${institution} ${accountName} ${mask}:

      Started on ${moment(startDate).format(
        DATE_FORMATS.INPUT
      )} with ${formatCurrency(startAmount)}
      Ended on ${moment(endDate).format(
        DATE_FORMATS.INPUT
      )} with ${formatCurrency(endAmount)}
    `
    const result = confirm(message)

    if (result) {
      setLoading(true)

      try {
        // 3
        const transactionsQuery = await fetchReconciliationTransactions(
          userId,
          account.id,
          startDate,
          endDate
        )(dispatch)

        if (!transactionsQuery || transactionsQuery.count === 0) {
          throw new Error(
            `We currently do not have any transactions between ${startDate} and ${endDate}. Please force pull this account and retry the reconciliation.`
          )
        }

        // 4
        let reconciliationId

        if (formType === 'incomplete' && mostRecentReconciliation) {
          reconciliationId = mostRecentReconciliation.id
        }

        const reconciliation = await upsertAccountReconciliation(
          reconciliationId,
          {
            accountId: account.id,
            endingBalanceDate: endDate,
            endingBalanceInCents: dollarsToCents(Number(endAmount)),
            startingBalanceDate: startDate,
            startingBalanceInCents: dollarsToCents(Number(startAmount)),
            status: 'incomplete',
            type: 'manual',
          }
        )(dispatch)

        // 5
        const compare = await compareHeardAndStatementBalances(reconciliation)
        const { doMatch, transactionSumIds, delta } = compare
        const reconAnalyticsAttributes = {
          reconciliationId: reconciliation.id,
          accountId: account.id,
          startDate: startDate.format(DATE_FORMATS.INPUT),
          endDate: endDate.format(DATE_FORMATS.INPUT),
          startDateChanged: !startDate.isSame(calculatedStartDate),
          endDateChanged: !endDate.isSame(calculatedEndDate),
          startBalanceChanged:
            dollarsToCents(Number(startAmount)) !==
            reconciliation.startingBalanceInCents,
        }

        if (!doMatch) {
          track('Reconciliation Break', { ...reconAnalyticsAttributes, delta })
          // 6
          navigate(`${location.pathname}/reconciliation/${reconciliation.id}`)
        } else {
          // 7
          await upsertAccountReconciliation(reconciliation.id, {
            accountId: account.id,
            endingBalanceDate: endDate,
            endingBalanceInCents: dollarsToCents(Number(endAmount)),
            startingBalanceDate: startDate,
            startingBalanceInCents: dollarsToCents(Number(startAmount)),
            status: 'complete',
            type: 'manual',
          })(dispatch)

          const transactionIds = transactionSumIds || []

          // 8
          await bulkUpdateUserTransactions({
            transactionIds,
            reconciliationId: reconciliation.id,
          })(dispatch)

          // 9
          setReconciled(true)
          onSumMatch()
          setLoading(false)
          track('Reconciliation Complete', reconAnalyticsAttributes)
        }
      } catch (err) {
        handleError(err)
      }
    }
  }, [
    compareHeardAndStatementBalances,
    account.id,
    accountName,
    dispatch,
    endAmount,
    endDate,
    calculatedEndDate,
    formType,
    institution,
    mask,
    mostRecentReconciliation,
    onSumMatch,
    startAmount,
    startDate,
    calculatedStartDate,
    location.pathname,
    navigate,
    userId,
    track,
  ])

  const reconciliationComplete = useMemo(
    () => (
      <Container style={{ textAlign: 'center' }}>
        <h2 style={{ padding: '2em' }}>{instruction}</h2>
        <Icon color="green" size="massive" name="check circle" />
      </Container>
    ),
    [instruction]
  )

  return (
    <Modal open={open}>
      <Modal.Header as="h3">{title}</Modal.Header>
      {!reconciled && (
        <Modal.Content>
          <Header>{instruction}</Header>

          <h4>
            {institution}
            <br />
            {accountName}: {mask}
          </h4>
          <Divider />

          <Form>
            <Label color="green" ribbon>
              Starting
              <Label.Detail>{startDetails}</Label.Detail>
            </Label>

            <Form.Field style={{ padding: FORM_FIELD_PADDING }}>
              <DatePicker
                label="Date"
                disabled={loading}
                maxDate={endDate?.toDate() || YESTERDAY.toDate()}
                placeholder="Starting Balance Date"
                value={startDate?.format(DATE_FORMATS.INPUT) || ''}
                onChange={(value) => {
                  const result = confirm(
                    `Confirm the updated starting date looks correct: \n${value}`
                  )

                  if (result) {
                    setStartDate(moment.utc(value, DATE_FORMATS.INPUT))
                  }
                }}
              />
            </Form.Field>
            <Form.Field style={{ padding: FORM_FIELD_PADDING }}>
              <Input
                label="Amount (USD $)"
                componentType="currency"
                value={startingBalance}
                onChange={setStartingBalance}
                onBlur={() => {
                  const result = confirm(
                    `Are you sure you want to update the starting balance to ${formatCurrency(
                      startingBalance
                    )}?`
                  )
                  if (result) {
                    setStartAmount(startingBalance)
                  } else {
                    setStartingBalance(startAmount)
                  }
                }}
                disabled={loading}
                placeholder="Starting Balance Amount"
              />
            </Form.Field>

            <Label color="red" ribbon>
              Ending
              <Label.Detail style={{ color: 'white' }}>
                {endDetails}
              </Label.Detail>
            </Label>
            <Form.Field style={{ padding: FORM_FIELD_PADDING }}>
              <DatePicker
                label="Date"
                disabled={loading}
                minDate={startDate?.toDate()}
                maxDate={YESTERDAY.toDate()}
                placeholder="Ending Balance Date"
                value={endDate?.format(DATE_FORMATS.INPUT) || ''}
                onChange={(value) =>
                  setEndDate(moment.utc(value, DATE_FORMATS.INPUT))
                }
              />
            </Form.Field>
            <Form.Field style={{ padding: FORM_FIELD_PADDING }}>
              <Input
                label="Amount (USD $)"
                componentType="currency"
                value={endAmount}
                onChange={setEndAmount}
                disabled={loading}
                placeholder="Ending Balance Amount"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
      )}
      {reconciled && reconciliationComplete}
      <Modal.Actions>
        <Button basic disabled={loading} onClick={close}>
          {`${reconciled ? 'Close' : 'Cancel'}`}
        </Button>
        {!reconciled && (
          <Button
            primary
            loading={loading}
            disabled={loading}
            onClick={onSubmit}
          >
            {incomplete ? 'Resume' : 'Begin'}
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  )
}

export default ReconciliationModal
