import { TaskCTAButton } from './config'
import { useState } from 'react'
import { ConnectBankAccountModal } from './ConnectBankAccountModal'
import { NewBankAccountModal } from './NewBankAccountModal'
import { ConnectBusinessBankAccountStatus } from '../Dashboard/UserActionItems/userActionItemStatuses'

const ConnectBusinessBankAccountButton = ({
  completedAt,
  onClick,
  status,
}: {
  completedAt?: string
  onClick?: () => void
  status?: string
}) => {
  const [openConnectAccountModal, setOpenConnectAccountModal] = useState(false)
  const [openNewAccountModal, setOpenNewAccountModal] = useState(false)
  return (
    <>
      <TaskCTAButton
        {...(completedAt &&
        status &&
        status !== ConnectBusinessBankAccountStatus.connected
          ? { isEditable: true }
          : { disabled: Boolean(completedAt) })}
        completedAt={completedAt}
        completedText="Done"
        onClick={() => {
          setOpenConnectAccountModal(true)
          if (onClick) onClick()
        }}
      />
      <ConnectBankAccountModal
        isOpen={openConnectAccountModal}
        onClose={() => setOpenConnectAccountModal(false)}
        onNewBankAccount={() => setOpenNewAccountModal(true)}
      />
      <NewBankAccountModal
        open={openNewAccountModal}
        onClose={() => {
          setOpenNewAccountModal(false)
        }}
      />
    </>
  )
}

export default ConnectBusinessBankAccountButton
