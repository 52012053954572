import { Button, Modal, Radio } from '../../components/BaseComponents'
import { useState } from 'react'
import { useHeardPlaid } from '../../components/shared/plaid/helpers'
import { isUndefined } from 'lodash'
interface Props {
  isOpen: boolean
  onClose: () => void
  onNewBankAccount: () => void
}
export const ConnectBankAccountModal = ({
  isOpen,
  onClose,
  onNewBankAccount,
}: Props) => {
  const { open } = useHeardPlaid()

  const [hasBankAccount, setHasBankAccount] = useState<boolean>()

  return (
    <Modal
      closeIcon
      onClose={() => {
        onClose()
        // Reset value to its default state after closing the modal so when it's opened again it'll have the default option selected
        setHasBankAccount(undefined)
      }}
      size="tiny"
      open={isOpen}
    >
      <Modal.Header>Do you have a business bank account?</Modal.Header>
      <Modal.Content>
        <Radio
          label="Yes"
          style={{ display: 'block', marginBottom: '20px' }}
          checked={!isUndefined(hasBankAccount) && hasBankAccount}
          onChange={() => setHasBankAccount(true)}
        />
        <Radio
          label="Not yet – I need help opening a new account"
          style={{ display: 'block' }}
          checked={!isUndefined(hasBankAccount) && !hasBankAccount}
          onChange={() => setHasBankAccount(false)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            onClose()
            setHasBankAccount(undefined)
          }}
          variant="actionLink"
          style={{ marginRight: '20px' }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (hasBankAccount) {
              onClose()
              open()
            } else {
              onClose()
              onNewBankAccount()
              // Reset value to its default state after closing the modal so when it's opened again it'll have the default option selected
              setHasBankAccount(undefined)
            }
          }}
          disabled={isUndefined(hasBankAccount)}
        >
          Continue
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
