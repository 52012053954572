import { Grid, Icon, Image } from 'semantic-ui-react'
import { Alert, Button, Text } from '../../../components/BaseComponents'
import Card from '../../../components/BaseComponents/Card'
import {
  FinancialAdvisoryProfile,
  FinancialAdvisoryProfilesStatus,
  putFinancialAdvisoryProfile,
} from '../financialAdvisory.slice'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { track } from '@amplitude/analytics-browser'

const PlanCompletedPanel = ({
  faProfile,
}: {
  faProfile: FinancialAdvisoryProfile
}) => {
  const dispatch = useDispatch()
  const [error, setError] = useState('')

  // This officially enables the user to start onboarding to Uprise
  const viewUprisePlan = async () => {
    setError('')
    track('viewed uprise plan', {
      fa_profile_id: faProfile.id,
      user_id: faProfile.userId,
    })
    // Set profile to active
    await putFinancialAdvisoryProfile(faProfile.id, {
      status: FinancialAdvisoryProfilesStatus.active,
    })(dispatch)
  }
  return (
    <>
      {error && (
        <Alert type="error">
          It looks like we encountered an error while trying to onboard you!
          Please try again.
        </Alert>
      )}
      <Card type="section" backgroundColor="natural">
        <Grid stackable doubling centered>
          <Grid.Row>
            <Grid.Column textAlign="center" verticalAlign="middle" width={5}>
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/celebration.svg"
                alt="heard financial advisory"
                width="300"
              />
            </Grid.Column>
            <Grid.Column width={11}>
              <Text as="h2">Your Financial Plan is ready!</Text>
              <br />
              <Text as="bodyMd">
                We’re so excited to share your personalized financial plan,
                crafted with your financial goals in mind, alongside your
                financial advisor.
              </Text>
              <br />
              <Text as="h3">With this financial plan, you can:</Text>
              <br />
              <Text as="bodyMd">
                <Icon name="check" /> Review recommendations provided by your
                advisor
              </Text>
              <Text as="bodyMd">
                <Icon name="check" /> Message asynchronously with your advisor
              </Text>
              <Text as="bodyMd">
                <Icon name="check" /> Schedule 1:1 video calls for an additional
                fee
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5} />
            <Grid.Column width={11}>
              <Button variant="primary" fullWidth onClick={viewUprisePlan}>
                View your Financial Plan
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </>
  )
}

export default PlanCompletedPanel
