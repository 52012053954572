import { Grid } from 'semantic-ui-react'
import { GridRowColumn, Text } from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { selectUserStatements } from '../../UserDocuments/userDocuments.selector'
import { EditableStatementTable } from '../../Onboarding/Setup/EditableStatementTable'
import { useEffect } from 'react'
import { useAnalyticsView } from '../../Amplitude'

export const ReviewStatements = () => {
  const statements = useReselector(selectUserStatements)
  const pageView = useAnalyticsView()
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    pageView('review monthly bank statements')
  }, [pageView])

  return (
    <Grid>
      <GridRowColumn>
        <Text as="h2"> Review bank statements </Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Text> Double-check that you have uploaded bank statements for: </Text>
        <ul>
          <li>
            <Text> Each month of {currentYear} </Text>
          </li>
          <li>
            <Text> From all connected business accounts </Text>
          </li>
        </ul>
      </GridRowColumn>
      <GridRowColumn>
        {Boolean(statements.length > 0) && (
          <EditableStatementTable documents={statements} editable={false} />
        )}
      </GridRowColumn>
    </Grid>
  )
}
