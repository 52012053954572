import { useCallback, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Grid, Loader } from 'semantic-ui-react'

import {
  useReselector,
  useScrollRef,
  useWindowSize,
} from '../../../utils/sharedHooks'
import {
  filterUserTransactions,
  getAllTransactionFilters,
} from '../transactions.selectors'
import { markUserActionItemCompleteIfExists } from '../../Dashboard/UserActionItems/service'
import { UserActionItemActionItemIdentifiers } from '../../Dashboard/UserActionItems/userActionItems.slice'
import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import {
  TRANSACTIONS_SORT_BY,
  updateTransactionFilters,
} from '../../../reducers/finances/transactionFiltersReducer'
import {
  restoreManualTransaction,
  updateUserTransaction,
} from '../transactions.slice'
import {
  Alert,
  Button,
  GridRowColumn,
  Table,
  Text,
} from '../../../components/BaseComponents'
import UserTransactionsFilter from '../UserTransactionsFilter'
import { getCurrentUser } from '../../../selectors/user.selectors'
import TransactionPagination from '../../../components/Finances/TransactionsPanel/TransactionPagination'
import TransactionsTableRow from './TransactionTableRow'
import {
  DeviceWidth,
  DeviceWidthCombo,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import MobileTableRow from './MobileTableRow'
import { TRANSACTIONS_TABLE_DESCRIPTION } from '../copyConstants'
import CategorizationTooltip from '../TransactionDetails/CategorizationTooltip'
import { useAnalyticsTrack } from '../../Amplitude'
import { MessageBanner } from './MessageBanner'
import {
  UndoTransactionContext,
  UNDO_TRANSACTION_TYPES,
} from '../UndoTransactionContext'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface TransactionTableProps {
  transactionsType?: 'expenses' | 'income'
  tableType: keyof typeof TRANSACTIONS_TABLE_DESCRIPTION
  fetching: boolean
  /* Indicates if we should default to showing editable fields */
  review?: boolean
  /*Indicates if we should allow ability to filter transactions */
  filterable: boolean
}

const TransactionsTable = ({
  transactionsType,
  tableType,
  fetching,
  review,
  filterable,
}: TransactionTableProps) => {
  const { scrollRef, scrollToRef } = useScrollRef()
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()
  const [submitting, setSubmitting] = useState(false)
  const [error, showError] = useState(false)
  const [undoRevertError, setUndoRevertError] = useState(false)
  const [undoMessage, setUndoMessage] = useState('')
  const [searchParams] = useSearchParams()
  const currentUser = useReselector(getCurrentUser)
  const { transactions, count } = useReselector(
    filterUserTransactions,
    tableType
  )
  const { sortVal, sortAsc } = useReselector(getAllTransactionFilters)
  const markComplete = searchParams.get('markComplete')
  const isMobileOrTablet = useIsDeviceWidth(DeviceWidthCombo.mobileOrTablet)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const isTablet = useIsDeviceWidth(DeviceWidth.tablet)
  const { height } = useWindowSize()

  const {
    setUndoTransactionId,
    setUndoTransactionType,
    undoTransactionId,
    undoTransactionType,
  } = useContext(UndoTransactionContext)

  useEffect(() => {
    if (markComplete) {
      markUserActionItemCompleteIfExists(
        UserActionItemActionItemIdentifiers.reviewRecentTransactions,
        (event, properties) => track(event, properties)
      )
    }
    dispatch(fetchFinancialAccountsIfNeeded())
  }, [dispatch, markComplete, track])

  const getSorted = (name: string) => {
    return name === sortVal ? (sortAsc ? 'ascending' : 'descending') : undefined
  }

  const sortColumn = useCallback(
    (name: string) => {
      if (sortVal === name) {
        dispatch(updateTransactionFilters({ sortAsc: !sortAsc }))
      } else {
        dispatch(updateTransactionFilters({ sortVal: name, sortAsc: false }))
      }
    },
    [dispatch, sortAsc, sortVal]
  )

  const submitReview = async () => {
    setSubmitting(true)

    try {
      await Promise.all(
        transactions.map((transaction) =>
          updateUserTransaction(transaction.id, {
            requestedClarificationAt: null,
            notifiedRequestedClarificationAt: null,
          })(dispatch)
        )
      )
      markUserActionItemCompleteIfExists(
        UserActionItemActionItemIdentifiers.transactionsNeedReview,
        (event, properties) => track(event, properties)
      )
      setSubmitting(false)
    } catch (_) {
      showError(true)
    }
  }

  const handleUndo = async () => {
    try {
      const isTypeDelete = undoTransactionType === UNDO_TRANSACTION_TYPES.DELETE

      let txn
      if (isTypeDelete) {
        txn = await restoreManualTransaction(undoTransactionId)(dispatch)
      } else {
        txn = await updateUserTransaction(undoTransactionId.toString(), {
          manuallyDedupedAt: null,
        })(dispatch)
      }

      if (!txn) {
        setUndoRevertError(true)
        return
      }

      setUndoTransactionId(NaN)
      setUndoTransactionType('')
      setUndoRevertError(false)
      setUndoMessage('')
    } catch (err) {
      setUndoRevertError(true)
      console.error('Error undoing transaction', err)
    }
  }

  useEffect(() => {
    if (undoTransactionType !== '') {
      const errorMessage = 'Could not undo due to an error. Please try again.'
      const isTypeDelete = undoTransactionType === UNDO_TRANSACTION_TYPES.DELETE
      const message = isTypeDelete
        ? 'Transaction Deleted.'
        : 'Duplicate transaction has been removed.'

      setUndoMessage(undoRevertError ? errorMessage : message)
    }
  }, [undoRevertError, undoTransactionType])

  return (
    <div
      id="transactionsTableContainer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...(!isMobileOrTablet && {
          minHeight: 500,
          height: height ? height - 300 : 500,
        }),
      }}
    >
      <span ref={scrollRef} />
      <Grid doubling>
        {error && (
          <>
            <GridRowColumn>
              <Alert type="error">
                It looks like we were unable to save your review! Please refresh
                and try again.
              </Alert>
            </GridRowColumn>
            <Grid.Row />
          </>
        )}
        {tableType !== 'all' && !isMobile && (
          <GridRowColumn>
            <Text>{TRANSACTIONS_TABLE_DESCRIPTION[tableType]}</Text>
          </GridRowColumn>
        )}
        {filterable && (
          <GridRowColumn short>
            <UserTransactionsFilter transactionsType={transactionsType} />
          </GridRowColumn>
        )}

        {count === 0 && (
          <GridRowColumn centered textAlign="center">
            <Text as="h3">No transactions yet!</Text>
          </GridRowColumn>
        )}
      </Grid>

      {Boolean(undoTransactionId) && (
        <MessageBanner
          close={() => {
            setUndoTransactionId(NaN)
          }}
          error={undoRevertError}
          message={undoMessage}
          undo={handleUndo}
        />
      )}

      {count !== 0 && (
        <>
          <Grid
            style={{
              overflow: 'initial',
            }}
          >
            {isMobileOrTablet && (
              <>
                {isTablet && (
                  <GridRowColumn short>
                    {transactions.map((transaction) => (
                      <MobileTableRow
                        key={transaction.id}
                        transaction={transaction}
                        review={review}
                      />
                    ))}
                  </GridRowColumn>
                )}
                {isMobile && (
                  <>
                    {transactions.map((transaction) => (
                      <MobileTableRow
                        key={transaction.id}
                        transaction={transaction}
                        review={review}
                      />
                    ))}
                  </>
                )}
                {tableType === 'review' && (
                  <GridRowColumn
                    width={isMobile ? 16 : 9}
                    spacer={isMobile ? undefined : 7}
                  >
                    <Button
                      onClick={submitReview}
                      disabled={submitting}
                      fullWidth
                    >
                      Complete review for {Object.keys(transactions).length}{' '}
                      transactions
                    </Button>
                  </GridRowColumn>
                )}
              </>
            )}
            {!isMobileOrTablet && (
              <>
                <GridRowColumn>
                  <Table
                    className="userTransactionsTable backgroundWhite"
                    sortable
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                          sorted={getSorted(TRANSACTIONS_SORT_BY.DATE)}
                          onClick={() => sortColumn(TRANSACTIONS_SORT_BY.DATE)}
                        >
                          Date
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{ minWidth: '274px' }}
                          sorted={getSorted(TRANSACTIONS_SORT_BY.DESCRIPTION)}
                          onClick={() =>
                            sortColumn(TRANSACTIONS_SORT_BY.DESCRIPTION)
                          }
                        >
                          Description
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={getSorted(TRANSACTIONS_SORT_BY.AMOUNT)}
                          onClick={() =>
                            sortColumn(TRANSACTIONS_SORT_BY.AMOUNT)
                          }
                        >
                          Amount
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={getSorted(TRANSACTIONS_SORT_BY.TYPE)}
                          onClick={() => sortColumn(TRANSACTIONS_SORT_BY.TYPE)}
                        >
                          Type
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={getSorted(TRANSACTIONS_SORT_BY.CATEGORY)}
                          onClick={() =>
                            sortColumn(TRANSACTIONS_SORT_BY.CATEGORY)
                          }
                        >
                          Category{' '}
                          {!isMobile && <CategorizationTooltip openOnHover />}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{ minWidth: '140px' }}
                          sorted={getSorted(TRANSACTIONS_SORT_BY.ACCOUNT)}
                          onClick={() =>
                            sortColumn(TRANSACTIONS_SORT_BY.ACCOUNT)
                          }
                        >
                          Account
                        </Table.HeaderCell>
                        <Table.HeaderCell /> {/* Display Icon */}
                        <Table.HeaderCell /> {/* Details menu */}
                      </Table.Row>
                    </Table.Header>

                    {!fetching && currentUser && (
                      <Table.Body>
                        {transactions.map((transaction) => (
                          <TransactionsTableRow
                            transaction={transaction}
                            key={transaction.id}
                            review={review}
                          />
                        ))}
                      </Table.Body>
                    )}
                    {fetching && (
                      <Table.Body>
                        <Table.Row>
                          <Loader active inline />
                        </Table.Row>
                      </Table.Body>
                    )}
                  </Table>
                </GridRowColumn>
                {tableType === 'review' && (
                  <GridRowColumn spacer={9}>
                    <Button
                      onClick={submitReview}
                      disabled={submitting}
                      fullWidth
                    >
                      Complete review for {Object.keys(transactions).length}{' '}
                      transactions
                    </Button>
                  </GridRowColumn>
                )}
              </>
            )}
          </Grid>

          {(isMobileOrTablet || tableType !== 'review') && (
            <Grid>
              <GridRowColumn
                centered
                columnStyle={{ display: 'flex', justifyContent: 'center' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: 12,
                  }}
                >
                  <TransactionPagination
                    transactionsCount={count}
                    scrollToRef={scrollToRef}
                  />
                </div>
              </GridRowColumn>
            </Grid>
          )}
        </>
      )}
    </div>
  )
}

export default TransactionsTable
