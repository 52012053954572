import { CSSProperties, useCallback, useEffect, useState } from 'react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import { PlaidItem } from '../../../reducers/finances/plaidItemReducer'
import { Fonts, Colors } from '../../../styles/theme'
import { Alert, Icon, Link, Loader } from '../../BaseComponents'
import PlaidLink from '../../shared/plaid/PlaidLink'
import Text from '../../BaseComponents/Text'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { fetchPlaidStatementInstitutionsIfNeeded } from '../../../actions/plaidStatementInstitutionActions'
import { selectInstitutionSupportsPlaidStatements } from '../../../selectors/financeSelectors'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useAnalyticsTrack } from '../../../features/Amplitude'

const SparklesIcon = ({ style }: { style: CSSProperties }) => (
  <Icon
    icon={solid('sparkles')}
    style={{
      width: 20,
      ...style,
    }}
  />
)

const Title = ({ style = {} }: { style?: CSSProperties }) => (
  <Text as="h3" style={style}>
    NEW! Automatic statement uploads
  </Text>
)

const Body = () => (
  <Text style={{ ...Fonts.bodySm }}>
    Re-login to this financial institution in Plaid to activate.{' '}
    <Link
      newPage
      href="https://support.joinheard.com/hc/en-us/articles/24343313630103/"
      as="secondaryLink"
    >
      Learn more &#8594;
    </Link>
  </Text>
)

const PlaidButton = ({
  account,
  disconnected,
  style = {},
}: {
  account: FinancialAccount
  disconnected: boolean
  style?: CSSProperties
}) => (
  <PlaidLink
    account={account}
    disconnected={disconnected}
    connectButtonText="Re-login in Plaid"
    reconnectButtonText="Re-login in Plaid"
    buttonSize="medium"
    style={style}
  />
)
export const PlaidStatementRelogin = ({
  institution,
  accounts,
  disconnected,
}: {
  institution: PlaidItem
  accounts: FinancialAccount[]
  disconnected: boolean
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const dispatch = useAppDispatch()
  const [fetching, setFetching] = useState(true)
  const institutionSupported = useReselector(
    selectInstitutionSupportsPlaidStatements,
    institution.institutionId
  )
  const track = useAnalyticsTrack()

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchPlaidStatementInstitutionsIfNeeded())
      setFetching(false)
    }

    fetch()
  }, [dispatch])

  const onClose = useCallback(() => {
    track('close plaid statement reauth', {
      institutionId: institution.institutionId,
      institutionName: institution.institutionName,
    })
  }, [track, institution.institutionId, institution.institutionName])

  // Do not show if institution is not supported or already has already opted in for this institution
  if (
    !institutionSupported ||
    institution.accounts.some(
      (a) =>
        a.statementPermissions === 'plaid_statement' || a.bankAccessEnabledAt
    )
  ) {
    return null
  }

  if (fetching) {
    return <Loader />
  }

  return (
    <Alert
      fullWidth
      closeable
      useCustomContent
      unsetMobileMargin={-14}
      permanentDismissalKey={`plaidStatementAccountReloginDismissed.${institution.institutionId}`}
      onClose={onClose}
      style={{ backgroundColor: Colors.stone }}
    >
      {isMobile ? (
        <div style={{ width: '96%' }}>
          <div>
            <SparklesIcon
              style={{ display: 'inline-block', verticalAlign: 'baseline' }}
            />
            <Title
              style={{
                display: 'inline-block',
                maxWidth: '85%',
                paddingLeft: 6,
              }}
            />
          </div>
          <div style={{ padding: 8 }}>
            <Body />
          </div>
          <PlaidButton
            account={accounts[0]}
            disconnected={disconnected}
            style={{ width: '100%' }}
          />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            width: '96%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ verticalAlign: 'center' }}>
            <SparklesIcon style={{ paddingRight: 8 }} />
          </div>
          <div style={{ flexGrow: 2 }}>
            <Title />
            <Body />
          </div>
          <PlaidButton
            account={accounts[0]}
            disconnected={disconnected}
            style={{ maxWidth: 160 }}
          />
        </div>
      )}
    </Alert>
  )
}
