import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import { Table, Icon } from 'semantic-ui-react'
import moment from 'moment'

import './TransactionTable.scss'
import { sortReports } from '../../selectors/reportSelectors'
import { BookkeepingReport } from '../../reducers/finances/bookkeepingReportsReducer'
import { DATE_FORMATS } from '../../utils/dateHelpers'

const BookkeepingReportTableRow = ({
  report,
}: {
  report: BookkeepingReport
}) => (
  <Table.Row
    negative={Boolean(report.dueDate) && moment(report.dueDate).isBefore()}
    positive={Boolean(report.completedAt)}
  >
    <Table.Cell>{report.id}</Table.Cell>
    <Table.Cell>
      <b>
        {report.reports?.financialProfile?.firstName}{' '}
        {report.reports?.financialProfile?.lastName}
      </b>
    </Table.Cell>
    <Table.Cell>
      <Moment format={DATE_FORMATS.MONTH_YEAR}>{report.date}</Moment>
    </Table.Cell>
    <Table.Cell>
      {report.dueDate && (
        <Moment format={DATE_FORMATS.DISPLAY_SHORT}>{report.dueDate}</Moment>
      )}
      {!report.dueDate && 'None'}
    </Table.Cell>
    <Table.Cell textAlign="center">
      <Icon
        name={
          report.statuses[0]?.status === 'categorized'
            ? 'check circle'
            : 'circle outline'
        }
        size="large"
      />
    </Table.Cell>
    <Table.Cell textAlign="center">
      <Icon
        name={report.documentsCount === '0' ? 'circle outline' : 'check circle'}
        size="large"
      />
    </Table.Cell>
    <Table.Cell textAlign="center">
      <Icon
        name={report.reconciledAt ? 'check circle' : 'circle outline'}
        size="large"
      />
    </Table.Cell>
    <Table.Cell textAlign="center">
      <Icon
        name={report.completedAt ? 'check circle' : 'circle outline'}
        size="large"
      />
    </Table.Cell>
    <Table.Cell>
      <Link
        to={`/admin/finances/records/${report.reports?.userId}/monthly-books/${report.id}`}
      >
        View Books
      </Link>
    </Table.Cell>
  </Table.Row>
)

const BookkeepingReportTable = ({
  reports,
}: {
  reports: { [p: string]: BookkeepingReport }
}) => {
  const [sortColumn, setSortColumn] = useState<string | null>(null)
  const [sortDirection, setSortDirection] = useState<
    'descending' | 'ascending'
  >()
  const [sortedReports, setSortedReports] = useState(
    Object.values(reports || {})
  )
  const resortReports = useCallback(
    (sortColumn: string) => {
      const newSortDirection =
        sortDirection === 'ascending' ? 'descending' : 'ascending'
      setSortDirection(newSortDirection)
      setSortColumn(sortColumn)
      setSortedReports(sortReports(sortedReports, sortColumn, newSortDirection))
    },
    [sortDirection, sortedReports]
  )

  return (
    <Table sortable striped compact="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={sortColumn === 'id' ? sortDirection : undefined}
            onClick={() => resortReports('id')}
            width={1}
          >
            ID
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              sortColumn === 'reports.financialProfile.firstName'
                ? sortDirection
                : undefined
            }
            onClick={() => resortReports('reports.financialProfile.firstName')}
            width={2}
          >
            Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn === 'date' ? sortDirection : undefined}
            onClick={() => resortReports('date')}
            width={1}
          >
            Bookkeeping Month
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn === 'dueDate' ? sortDirection : undefined}
            onClick={() => resortReports('dueDate')}
            width={2}
          >
            Due Date
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn === 'status' ? sortDirection : undefined}
            onClick={() => resortReports('status')}
            width={2}
          >
            Bookkeeping Complete
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn === 'documentsCount' ? sortDirection : undefined}
            onClick={() => resortReports('documentsCount')}
            width={2}
          >
            Documents
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn === 'reconciledAt' ? sortDirection : undefined}
            onClick={() => resortReports('reconciledAt')}
            width={2}
          >
            Reconciled
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn === 'completedAt' ? sortDirection : undefined}
            onClick={() => resortReports('completedAt')}
            width={2}
          >
            Done
          </Table.HeaderCell>
          <Table.HeaderCell width={2} className="noSort">
            Actions
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.values(sortedReports).map((report) => (
          <BookkeepingReportTableRow report={report} key={report.id} />
        ))}
      </Table.Body>
    </Table>
  )
}
export default BookkeepingReportTable
