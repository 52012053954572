import { SkyflowAnyToken } from '../reducers/skyflowSlice'

export const formatSsnForSkyflow = (ssn: string) => {
  if (ssn && ssn.length === 9) {
    return `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(5)}`
  }
  return ''
}

export const mapTokenToSkyflowRequest = (token: Partial<SkyflowAnyToken>) => {
  const fields = { ...token }
  if (fields.skyflow_id) {
    delete fields.skyflow_id
  }
  if ('social_security_number' in fields && fields.social_security_number) {
    const pattern = /\d{3}-\d{2}-\d{4}/gm
    const { social_security_number: ssn } = fields
    fields.social_security_number = pattern.test(ssn)
      ? ssn
      : formatSsnForSkyflow(ssn)
  }
  return fields
}
