import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FILING_STATUSES } from '../../../taxConstants'
import { dollarsToCurrency } from '../../../../../utils/currencyHelpers'
import { Text } from '../../../../../components/BaseComponents'

export enum CALCULATE_SAFE_HARBOR_STEP {
  calculateSafeHarborIntro = 'calculateSafeHarborIntro',
  quarterlyTaxRecommendations = 'quarterlyTaxRecommendations',
  totalTaxesAndIncome = 'totalTaxesAndIncome',
  finish = 'finish',
}

const mfsHighIncomeLimitInDollars = 75000
const nonMfsHighIncomeLimitInDollars = 150000

export const calculateSafeHarbor = ({
  adjustedGrossIncomeInDollars,
  totalFederalTaxesInDollars,
  filingStatus,
}: {
  adjustedGrossIncomeInDollars: number | null
  totalFederalTaxesInDollars: number | null
  filingStatus?: FILING_STATUSES | null
}) => {
  if (!adjustedGrossIncomeInDollars || !totalFederalTaxesInDollars) {
    return null
  }

  //realistically a user should always have a filing status because it's required during setup, but treat them as not MFS
  //married filing separately has a lower high income limit from the other filing statuses
  if (
    !filingStatus ||
    filingStatus !== FILING_STATUSES.married_filing_separately
  ) {
    if (adjustedGrossIncomeInDollars > nonMfsHighIncomeLimitInDollars) {
      return dollarsToCurrency(totalFederalTaxesInDollars)
        .multiply(1.1)
        .divide(4)
        .dollars()
    } else {
      return dollarsToCurrency(totalFederalTaxesInDollars).divide(4).dollars()
    }
  } else {
    if (adjustedGrossIncomeInDollars > mfsHighIncomeLimitInDollars) {
      return dollarsToCurrency(totalFederalTaxesInDollars)
        .multiply(1.1)
        .divide(4)
        .dollars()
    } else {
      return dollarsToCurrency(totalFederalTaxesInDollars).divide(4).dollars()
    }
  }
}

export const SafeHarborTooltip = () => (
  <>
    <Text as="h3">Safe Harbor Estimate</Text>
    <br />
    <Text>
      The term “safe harbor” means you are protected from penalties by law as
      long as certain conditions are met.
    </Text>
    <br />
    <Text>
      It is calculated by taking your prior year tax liability and dividing by
      4. For example, if you paid $1,000 last year for federal taxes,
      you&apos;ll divide it by 4 and pay $250 for this quarter&apos;s tax
      payment. You&apos;ll do the same calculation for your state quarterly tax
      payment (if it applies). To reduce the risk of an underpayment penalty,
      make sure to pay at least 90% of what you owe for the current year or 100%
      of what you owe for the prior year.
    </Text>
  </>
)

export const useCalculateSafeHarborSteps = (
  currentStep: CALCULATE_SAFE_HARBOR_STEP
) => {
  const [_, setSearchParams] = useSearchParams()

  const goToStep = useCallback(
    (newStep: 1 | -1 | CALCULATE_SAFE_HARBOR_STEP) => {
      if (typeof newStep === 'string') {
        setSearchParams({ step: newStep })
        return
      }

      // Use updated financial profile to calculate next step.  Default to current fp state
      const steps = [
        CALCULATE_SAFE_HARBOR_STEP.calculateSafeHarborIntro,
        CALCULATE_SAFE_HARBOR_STEP.quarterlyTaxRecommendations,
        CALCULATE_SAFE_HARBOR_STEP.totalTaxesAndIncome,
        CALCULATE_SAFE_HARBOR_STEP.finish,
      ]

      const currentStepIndex = steps.indexOf(currentStep)

      // Go forward or backward in stack
      setSearchParams({ step: steps[currentStepIndex + newStep] })
    },
    [currentStep, setSearchParams]
  )

  const goToNextStep = useCallback(
    (step?: CALCULATE_SAFE_HARBOR_STEP) => goToStep(step || 1),
    [goToStep]
  )

  const goToPreviousStep = useCallback(() => goToStep(-1), [goToStep])

  return { goToNextStep, goToPreviousStep }
}
