import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'

import {
  Alert,
  Card,
  GridRowColumn,
  Text,
} from '../../../../../components/BaseComponents'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../utils/sharedHooks'
import { selectUserTransactionsByCategoryIdentifierForYear } from '../../../../Transactions/transactions.selectors'
import { UpdateYourBooksStepProps } from './UpdateYourBooks'
import TransactionHeader from './TransactionHeader'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import TransactionCard from './TransactionCard'
import { selectAnnualTaxFilingFormById } from '../../annualTaxFilingForms.selector'
import { TransactionCategoryIdentifier } from '../../../../../reducers/admin/allTransactions.slice'
import { SubStepIdentifiers } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'

const OtherExpensesStep = ({ goForward, goBack }: UpdateYourBooksStepProps) => {
  const { formId } = useParams()
  const filingForm = useReselector(selectAnnualTaxFilingFormById, formId)

  const otherExpensesTransactionsInState = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.other_expenses,
    filingForm?.year
  )

  const [otherExpensesTransactionIds, setOtherExpensesTransactionIds] =
    useState<number[]>([])

  useEffect(() => {
    if (
      otherExpensesTransactionsInState.length &&
      !otherExpensesTransactionIds.length
    ) {
      setOtherExpensesTransactionIds(
        otherExpensesTransactionsInState.map((t) => t.id)
      )
    }
  }, [otherExpensesTransactionIds.length, otherExpensesTransactionsInState])

  const saveAndContinue = useAsyncCallback(() =>
    goForward({ completedSteps: [SubStepIdentifiers.categorizeOtherExpenses] })
  )

  return (
    <Grid>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/payroll.svg"
          style={{ width: 120, height: 120 }}
        />
      </GridRowColumn>
      <GridRowColumn width={10} spacer={3}>
        <Text as="h1" textAlign="center">
          We saw that you have some transactions categorized as “Other Expenses”
        </Text>
      </GridRowColumn>
      <GridRowColumn width={10} spacer={3}>
        <Text>
          Do these look right? If not, select a category or add a note
          describing what the expense or purchase was for.
        </Text>
      </GridRowColumn>
      <Grid.Row />
      {otherExpensesTransactionIds?.length > 0 && <TransactionHeader />}
      <GridRowColumn short>
        {otherExpensesTransactionIds.map((tId) => (
          <TransactionCard key={tId} transactionId={tId} />
        ))}
      </GridRowColumn>
      {otherExpensesTransactionIds?.length === 0 && (
        <GridRowColumn width={10} spacer={3}>
          <Card backgroundColor="lightGreen">
            <Text>
              All transactions have been recategorized. You may continue to the
              next step.
            </Text>
          </Card>
        </GridRowColumn>
      )}

      <GridRowColumn width={10} spacer={3}>
        <Alert title="Why do I need to categorize these?">
          Transactions that are categorized as “Other Expenses” are at a high
          audit risk. This step helps us provide the tax team with as much
          context as possible.
        </Alert>
      </GridRowColumn>
      <FormFlowFooter
        onBack={goBack}
        onForward={saveAndContinue.callback}
        loading={saveAndContinue.loading}
        continueDisabled={saveAndContinue.loading}
      />
    </Grid>
  )
}

export default OtherExpensesStep
