import { FC, useEffect, useMemo, useState } from 'react'
import { useFormik, FormikProvider } from 'formik'
import { Button, Divider, Form, Grid, Modal } from 'semantic-ui-react'
import { boolean, object, ObjectSchema, string } from 'yup'
import {
  updateUserFinancialProfile,
  adminUpdateUserWithId,
} from '../../../actions/admin/adminAllUsersActions'
import {
  BUSINESS_ENTITY_OPTIONS,
  NUMBER_OF_PROVIDER_OPTIONS,
  PRACTICE_TYPE_OPTIONS,
  TRUE_FALSE_OPTIONS,
  YEAR_PRACTICE_STARTED_OPTIONS,
} from '../../../constants/onboardingConstants'
import { FinancialProfile, User } from '../../../reducers/auth/userReducer'

import {
  FormikDropdown,
  FormikInput,
  FormikLocationSearchInput,
  getFieldName,
} from '../../BaseComponents'
import { useAppDispatch } from '../../../utils/typeHelpers'

type FinancialProfilePayload = Pick<
  FinancialProfile,
  | 'businessName'
  | 'practiceType'
  | 'businessAddress'
  | 'practiceSize'
  | 'yearPracticeStarted'
  | 'businessEntity'
  | 'einNumber'
  | 'accountsSeparate'
  | 'willingAccountsSeparate'
>

const validationSchema: ObjectSchema<FinancialProfilePayload> = object({
  businessName: string(),
  practiceType: string(),
  businessAddress: string(),
  practiceSize: string(),
  yearPracticeStarted: string(),
  businessEntity: string(),
  einNumber: string().matches(/\d{2}-?\d{7}/g, 'EIN should be 9 numbers long'),
  accountsSeparate: boolean().nullable(),
  willingAccountsSeparate: boolean().nullable(),
})

type UserPayload = Pick<User, 'firstName' | 'lastName'>
const userValidationSchema: ObjectSchema<UserPayload> = object({
  firstName: string().required(),
  lastName: string().required(),
})

interface PracticeEditModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  user: User
}

const PracticeEditModal: FC<PracticeEditModalProps> = ({
  open,
  setOpen,
  user,
}) => {
  const dispatch = useAppDispatch()

  const [isFormSubmitting, setIsFormSubmitting] = useState(false)

  const { firstName, lastName, financialProfile } = user

  const initialValues: FinancialProfilePayload = useMemo(
    () => ({
      ...(financialProfile ?? {}),
    }),
    [financialProfile]
  )

  const initialUserValues: UserPayload = useMemo(
    () => ({
      ...(user ?? {}),
    }),
    [user]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (financialProfile?.id) {
        setIsFormSubmitting(true)

        // When the practice type is solo, we want to submit '1' as the practice size, otherwise submit
        // the chosen/existing value.
        await updateUserFinancialProfile(financialProfile.id, {
          ...values,
          practiceSize: isPracticeTypeSolo ? '1' : values.practiceSize,
        })(dispatch)

        setIsFormSubmitting(false)
      }

      setOpen(false)
    },
  })

  const userFormik = useFormik({
    initialValues: initialUserValues,
    validationSchema: userValidationSchema,
    onSubmit: async (values) => {
      if (user?.id) {
        setIsFormSubmitting(true)
        // When the practice type is solo, we want to submit '1' as the practice size, otherwise submit
        // the chosen/existing value.
        await adminUpdateUserWithId(user.id, {
          ...values,
        })(dispatch)
        setIsFormSubmitting(false)
      }

      setOpen(false)
    },
  })
  // Pulling this value out prevents the below `useEffect` from getting into an infinite re-render
  // look when depending on `formik` directly
  const { setFieldValue } = formik

  const isPracticeTypeSolo = useMemo(
    () => formik.values.practiceType === 'solo',
    [formik.values.practiceType]
  )

  useEffect(() => {
    if (isPracticeTypeSolo) {
      setFieldValue(getFieldName<FinancialProfilePayload>('practiceSize'), '1')
    }
  }, [setFieldValue, isPracticeTypeSolo])

  return (
    <Modal onClose={() => setOpen(false)} open={open}>
      <Modal.Header>
        Edit {`${firstName} ${lastName}`}&apos;s Practice Information
      </Modal.Header>
      <Modal.Content>
        <FormikProvider value={userFormik}>
          <Form className="userForm" onSubmit={userFormik.handleSubmit}>
            <Grid doubling stackable centered>
              <Grid.Row />
              <Grid.Row>
                <Grid.Column width={8}>
                  <FormikInput
                    label="First Name"
                    name={getFieldName<UserPayload>('firstName')}
                    type="text"
                    fullWidth
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <FormikInput
                    label="Last Name"
                    name={getFieldName<UserPayload>('lastName')}
                    type="text"
                    fullWidth
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={13} />
                <Grid.Column width={3}>
                  <Button
                    primary
                    fluid
                    disabled={isFormSubmitting}
                    loading={isFormSubmitting}
                    type="submit"
                    id="btn-update-User"
                  >
                    Update User
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormikProvider>
        <Divider />
        <FormikProvider value={formik}>
          <Form className="financialProfileForm" onSubmit={formik.handleSubmit}>
            <Grid doubling stackable centered>
              <Grid.Row />
              <Grid.Row>
                <Grid.Column width={8}>
                  <FormikInput
                    label="Practice Name"
                    name={getFieldName<FinancialProfilePayload>('businessName')}
                    type="text"
                    fullWidth
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <FormikDropdown
                    label="Practice Type"
                    placeholder="Select an Option"
                    name={getFieldName<FinancialProfilePayload>('practiceType')}
                    options={PRACTICE_TYPE_OPTIONS}
                    fullWidth
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <FormikLocationSearchInput
                        label="Business Address"
                        placeholder="Enter the address of the business's office or PO box"
                        name={getFieldName<FinancialProfilePayload>(
                          'businessAddress'
                        )}
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <FormikDropdown
                        disabled={isPracticeTypeSolo}
                        label="# of Providers"
                        placeholder="Select Range"
                        name={getFieldName<FinancialProfilePayload>(
                          'practiceSize'
                        )}
                        options={
                          isPracticeTypeSolo
                            ? [{ text: '1', value: '1' }]
                            : NUMBER_OF_PROVIDER_OPTIONS
                        }
                        fullWidth
                      />
                    </Form.Field>
                    <Form.Field>
                      <FormikDropdown
                        label="Year Practice Started"
                        placeholder="Select an Option"
                        name={getFieldName<FinancialProfilePayload>(
                          'yearPracticeStarted'
                        )}
                        options={YEAR_PRACTICE_STARTED_OPTIONS}
                        fullWidth
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <FormikDropdown
                        label="Legal Entity Type"
                        placeholder="Select an Option"
                        name={getFieldName<FinancialProfilePayload>(
                          'businessEntity'
                        )}
                        options={BUSINESS_ENTITY_OPTIONS}
                        fullWidth
                      />
                    </Form.Field>
                    <Form.Field>
                      <FormikInput
                        label="Business EIN Number"
                        name={getFieldName<FinancialProfilePayload>(
                          'einNumber'
                        )}
                        fullWidth
                        format="##-#######"
                        componentType="pattern"
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <FormikDropdown
                        label="Back Accounts Separate"
                        placeholder="Select Option"
                        name={getFieldName<FinancialProfilePayload>(
                          'accountsSeparate'
                        )}
                        options={TRUE_FALSE_OPTIONS}
                        fullWidth
                      />
                    </Form.Field>
                    <Form.Field>
                      <FormikDropdown
                        label="Willing to Separate Accounts"
                        placeholder="Select Option"
                        name={getFieldName<FinancialProfilePayload>(
                          'willingAccountsSeparate'
                        )}
                        options={TRUE_FALSE_OPTIONS}
                        fullWidth
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={3}>
                  <Button secondary fluid onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                </Grid.Column>

                <Grid.Column width={10} />

                <Grid.Column width={3}>
                  <Button
                    primary
                    fluid
                    disabled={isFormSubmitting}
                    loading={isFormSubmitting}
                    type="submit"
                    id="btn-update-profile"
                  >
                    Update Practice
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormikProvider>
      </Modal.Content>
    </Modal>
  )
}

export default PracticeEditModal
