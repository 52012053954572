import { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import { toggleSidebar } from '../../reducers/sidebar.slice'
import {
  getScheduleOnboardingActionItems,
  getScheduleOnboardingNumCompleted,
  getSetupYourAccountActionItems,
  getSetupYourAccountNumCompleted,
} from '../Dashboard/UserActionItems/userActionItems.selectors'
import {
  COMPLETE_ONBOARDING_KEY,
  FETCH_USER_ACTION_ITEMS_KEY,
  fetchUserActionItemsIfNeeded,
  postCompleteOnboarding,
} from '../Dashboard/UserActionItems/userActionItems.slice'
import WelcomeModal from './WelcomeModal'
import { GridRowColumn } from '../../components/BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import { isNil, sortBy } from 'lodash'
import OnboardingAccordion from './OnboardingAccordion'
import { getCurrentUser } from '../../selectors/user.selectors'
import OnboardingTaskCard from './OnboardingTaskCard'
import { logSentryMessage } from '../../utils/sentryHelpers'
import { getIsFetching, getIsFetchingOrNotStarted } from '../../reducers/fetch'
import ScheduleOnboardingCard from './ScheduleOnboardingCard'
import AccordionCoverForm from './AccordionCoverForm'
import { OnboardingTaskIdents } from './config'
import './style.scss'
import PageHeader from '../../components/shared/PageHeader'
import { useNavigate } from 'react-router-dom'
import { useAnalyticsTrack } from '../Amplitude'
import { useAppDispatch } from '../../utils/typeHelpers'

/**
 * User sees 2 accordions - 1) Set up your account, 2) Schedule onboarding call
 * and completes tasks within each. The complete progression looks like:
 *  1. User completes all tasks within "Setup your account" (Accordion 1)
 *  2. Hooray component appears within Accordion 1
 *  3. User clicks "Let's do this"
 *  4. Accordion 2 "Schedule onboarding call" opens, Accordion 1 closes (still enabled), Hooray graphic no longer rendered
 *  5. User schedules call successfully
 *  6. "All set" graphic appears within Accordion 2
 *  7. User clicks "View Your Heard Dashboard"
 */

const LOCAL_STORAGE_KEYS = {
  clickedHoorayButton: (userId?: number) => `clicked-hooray-button-${userId}`,
  scheduledOnboardingCall: (userId?: number) =>
    `scheduled-onboarding-call-${userId}`,
}

const OnboardingDashboard = () => {
  const dispatch = useAppDispatch()
  const currentUser = useReselector(getCurrentUser)
  const navigate = useNavigate()
  const track = useAnalyticsTrack()

  // Accordion 1 related
  let setupAccountActionItems = useReselector(getSetupYourAccountActionItems)

  setupAccountActionItems = sortBy(
    setupAccountActionItems,
    (userActionItem) => {
      switch (userActionItem.actionItem.identifier) {
        case OnboardingTaskIdents.SIGN_BAA_AGREEMENT:
          return 0
        case OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT:
          return 1
        case OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS:
          return 2
        default:
          return 3
      }
    }
  )

  const setupAccountNumCompleted = useReselector(
    getSetupYourAccountNumCompleted
  )

  // Accordion 2 related
  const scheduleOnboardingActionItems = useReselector(
    getScheduleOnboardingActionItems
  )
  const scheduleOnboardingNumCompleted = useReselector(
    getScheduleOnboardingNumCompleted
  )

  /** States **/
  // True when all accordion #1 items are complete
  const setupAccountCompleted = useMemo(
    () =>
      setupAccountActionItems.length > 0
        ? setupAccountNumCompleted === setupAccountActionItems.length
        : false,
    [setupAccountActionItems.length, setupAccountNumCompleted]
  )

  // True when localStorage value exists for this user
  const [clickedHoorayButton, setClickedHoorayButton] = useState(
    window.localStorage.getItem(
      LOCAL_STORAGE_KEYS.clickedHoorayButton(currentUser?.id)
    ) === 'true'
  )

  // True when localStorage value exists OR user.onboarding_meeting_at is set
  const [scheduledOnboardingCall, setScheduledOnboardingCall] = useState(
    window.localStorage.getItem(
      LOCAL_STORAGE_KEYS.scheduledOnboardingCall(currentUser?.id)
    ) === 'true' || !isNil(currentUser?.onboardingMeetingAt)
  )

  const [creatingAmplitudeEvent, setCreatingAmplitudeEvent] = useState(false)

  useEffect(() => {
    dispatch(toggleSidebar(false))
    dispatch(fetchUserActionItemsIfNeeded())
  }, [dispatch])

  const isFetching = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_USER_ACTION_ITEMS_KEY
  )

  useEffect(() => {
    if (isFetching) {
      return
    }
    if (!setupAccountActionItems.length) {
      logSentryMessage(
        'User does not have any accordion 1 action items generated. Please resolve immediately as this blocks them from accessing their dashboard.'
      )
    }
    if (!scheduleOnboardingActionItems.length) {
      logSentryMessage(
        'User does not have any accordion 2 action items generated. Please resolve immediately as this blocks them from accessing their dashboard.'
      )
    }
  }, [
    isFetching,
    scheduleOnboardingActionItems.length,
    setupAccountActionItems.length,
  ])

  const onHoorayClicked = useCallback(() => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.clickedHoorayButton(currentUser?.id),
      'true'
    )
    setClickedHoorayButton(true)
  }, [currentUser?.id])

  const creatingPostOnboardingItems = useReselector(
    getIsFetching,
    COMPLETE_ONBOARDING_KEY
  )

  const onOnboardingItemsCompleted = useCallback(async () => {
    // Create the post-onboarding tasks to appear on the dashboard
    // Note - the BE sets user.onboardingItemsCompletedAt within the postCompleteOnboarding call
    await dispatch(postCompleteOnboarding())

    setCreatingAmplitudeEvent(true)
    track('completed onboarding items')

    // Adding delay so Amplitude event can be registered before page reload
    setTimeout(() => {
      navigate('/dashboard')
      window.location.reload()
      setCreatingAmplitudeEvent(false)
    }, 3000)
  }, [dispatch, navigate, track])

  return (
    <div>
      <WelcomeModal />
      <PageHeader header="Onboarding to Heard" />
      <Grid>
        <OnboardingAccordion
          content={
            !clickedHoorayButton && setupAccountCompleted ? (
              <AccordionCoverForm
                onClick={onHoorayClicked}
                title="Hooray!"
                buttonText="Let's do this"
                subtitle="That was the hard part. Now let's meet face-to-face."
              />
            ) : (
              setupAccountActionItems.map(({ id }, index) => (
                <GridRowColumn
                  style={
                    index !== setupAccountActionItems.length - 1
                      ? { marginBottom: 16 }
                      : undefined
                  }
                  key={`OnboardingActionItem_${id}`}
                >
                  <OnboardingTaskCard id={id} />
                </GridRowColumn>
              ))
            )
          }
          imageSrc="https://heard-images.s3.amazonaws.com/assets/ob_clipboard.svg"
          initialOpen={!clickedHoorayButton}
          key={'OB_Accordion_1'}
          rowStyle={
            !clickedHoorayButton && setupAccountCompleted
              ? { height: 550 }
              : undefined
          }
          subtitle={`${setupAccountNumCompleted}/${setupAccountActionItems.length} complete`}
          title={'1. Set up your account'}
        />

        <OnboardingAccordion
          content={
            scheduledOnboardingCall ? (
              <AccordionCoverForm
                buttonText="View Your Heard Dashboard"
                loading={creatingPostOnboardingItems || creatingAmplitudeEvent}
                onClick={onOnboardingItemsCompleted}
                subtitle="Thanks for completing these tasks. Now let's tour your Heard dashboard and familiarize yourself with the different features."
                title="All Set!"
              />
            ) : (
              <ScheduleOnboardingCard
                onScheduleComplete={() => {
                  setScheduledOnboardingCall(true)
                  localStorage.setItem(
                    LOCAL_STORAGE_KEYS.scheduledOnboardingCall(currentUser?.id),
                    'true'
                  )
                }}
              />
            )
          }
          disabled={!clickedHoorayButton}
          initialOpen={clickedHoorayButton}
          imageSrc="https://heard-images.s3.amazonaws.com/assets/ob_telephone.svg"
          key={'OB_Accordion_2'}
          rowStyle={scheduledOnboardingCall ? { height: 550 } : undefined}
          subtitle={`${scheduleOnboardingNumCompleted}/${scheduleOnboardingActionItems.length} complete`}
          title={'2. Schedule onboarding call'}
        />
      </Grid>
    </div>
  )
}

export default OnboardingDashboard
