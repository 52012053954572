import { useCallback, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'
import { EditorState } from 'draft-js'
import moment from 'moment'

import {
  Alert,
  Button,
  GridRowColumn,
} from '../../../components/BaseComponents'
import { PUT_COMMENT_KEY, putAddComment } from '../actions'
import {
  useReselector,
  useTimeoutRef,
  useWindowSize,
} from '../../../utils/sharedHooks'
import {
  selectAllowCloseConversation,
  selectTicketById,
} from '../conversation.slice'
import StyledDraftEditor from './StyledDraftEditor'
import { convertDraftToCommentBody, getResetEditorState } from '../helpers'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { getFetchError, getIsFetching } from '../../../reducers/fetch'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'

const ConversationInput = ({
  id,
  scrollToBottom,
  setCloseConvoModalOpen,
}: {
  id?: string
  scrollToBottom: () => void
  setCloseConvoModalOpen: (open: boolean) => void
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const ticket = useReselector(selectTicketById, id)
  const currentUser = useReselector(getCurrentUser)
  const currentUserName = `${currentUser?.firstName} ${currentUser?.lastName}`
  const scrollTimeoutRef = useTimeoutRef()
  const loading = useReselector(getIsFetching, PUT_COMMENT_KEY)
  const error = useReselector(getFetchError, PUT_COMMENT_KEY)
  const windowSize = useWindowSize()

  const isReopenedTicket = searchParams.get('reopened') === 'true'

  const sendMessage = useCallback(async () => {
    if (!id) {
      return
    }

    const res = await putAddComment(
      id,
      convertDraftToCommentBody(editorState)
    )(dispatch)

    // Replying to closed tickets will return a new ticket.  Navigate to the new ticket
    if (res && res.id.toString() !== id) {
      navigate(`/conversations/${res.id}?reopened=true`)
      // Only the id param of this page is changing so it isn't re-rendered and the editor isn't refreshed
      // There should NOT be a return here
    }

    if (res?.id) {
      setEditorState((prevState) => getResetEditorState(prevState))
    }

    // Delay set here so api call/redux has time to update
    scrollTimeoutRef.current = setTimeout(scrollToBottom, 200)
  }, [dispatch, editorState, id, navigate, scrollTimeoutRef, scrollToBottom])

  const closeConversationButtonVisible = useReselector(
    selectAllowCloseConversation,
    ticket?.id
  )
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const inputPadding = { padding: '0px 24px' }

  return (
    <Grid style={{ margin: '0 30px' }}>
      <Grid.Row className="short" />
      {ticket?.archivedAt && (
        <GridRowColumn>
          <Alert>
            This conversation was archived by {currentUserName} at{' '}
            {moment(ticket.archivedAt).format(
              `${DATE_FORMATS.TIME} ${DATE_FORMATS.DISPLAY_SHORT}`
            )}
            .
          </Alert>
        </GridRowColumn>
      )}
      {ticket?.merged && (
        <GridRowColumn>
          <Alert>This conversation was merged into a new conversation</Alert>
        </GridRowColumn>
      )}
      {isReopenedTicket && (
        <GridRowColumn>
          <Alert>
            Because you replied to an archived conversation, we have opened a
            new conversation for you
          </Alert>
        </GridRowColumn>
      )}
      <GridRowColumn short style={isMobile ? inputPadding : {}}>
        <Divider style={{ margin: '0 -60px' }} />
      </GridRowColumn>
      <Grid.Row style={isMobile ? inputPadding : {}}>
        <Grid.Column>
          <StyledDraftEditor
            toolbarClassName={`toolbar ${isMobile && 'mobile'}`}
            editorState={editorState}
            onEditorStateChange={setEditorState}
            editorStyle={{
              // The editor auto-grows but we don't want it to take up the whole window so
              // let's constrain the height of the editor to 40% of the window height
              maxHeight: windowSize.height * 0.4,
              overflowY: 'auto',
            }}
          />
        </Grid.Column>
      </Grid.Row>
      {isMobile && (
        <GridRowColumn short>
          <Divider style={{ margin: '10px -60px' }} />
        </GridRowColumn>
      )}
      {error?.message && (
        <GridRowColumn short>
          <Alert type="error">{error.message}</Alert>
        </GridRowColumn>
      )}
      <Grid.Row style={isMobile ? inputPadding : {}}>
        <Grid.Column only="computer tablet" width={4}>
          {closeConversationButtonVisible && (
            <Button
              variant="secondary"
              fullWidth
              onClick={() => setCloseConvoModalOpen(true)}
            >
              Archive
            </Button>
          )}
        </Grid.Column>
        <Grid.Column only="computer tablet" width={8} />
        <Grid.Column floated="left" computer={4} tablet={4} mobile={16}>
          <Button
            fullWidth
            disabled={!editorState.getCurrentContent().hasText()}
            onClick={sendMessage}
            loading={loading}
          >
            Send
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default ConversationInput
