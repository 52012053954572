import React from 'react'
import { Header, Card, Icon } from 'semantic-ui-react'
import { BookkeepingReport } from '../../../reducers/finances/bookkeepingReportsReducer'

const BookkeepingStatusCard = ({ report }: { report: BookkeepingReport }) => (
  <Card fluid>
    <Card.Content>
      <Header as="h5">Bookkeeping Status</Header>
      <br />
      <div className="flexbox">
        <div className="centered">
          <Header as="h6">Books</Header>
          <Icon name="check circle" size="large" />
        </div>
        <div className="centered">
          <Header as="h6">Statements</Header>
          <Icon
            name={
              report.documentsCount === '0' ? 'circle outline' : 'check circle'
            }
            size="large"
          />
        </div>
        <div className="centered">
          <Header as="h6">Reconciled</Header>
          <Icon
            name={report.reconciledAt ? 'check circle' : 'circle outline'}
            size="large"
          />
        </div>
        <div className="centered">
          <Header as="h6">Done</Header>
          <Icon
            name={report.completedAt ? 'check circle' : 'circle outline'}
            size="large"
          />
        </div>
      </div>
    </Card.Content>
  </Card>
)

export default BookkeepingStatusCard
