import { ReactNode } from 'react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'

import { Button, Icon, Modal, Text } from '../../components/BaseComponents'
import { ButtonProps } from '../../components/BaseComponents/Button'
import { createIsSomeEnum } from '../../utils/typeHelpers'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { isArray } from 'lodash'
import { CSSProperties } from 'styled-components'
import Carousel, { Slide } from '../../components/Onboarding/Carousel'
import { UserActionItem } from '../Dashboard/UserActionItems/userActionItems.slice'
import Lottie from 'lottie-react'
import { Colors } from '../../styles/theme'

import './style.scss'

// these values are pulled from the DB under the `action_items.identifier` column
export enum OnboardingTaskIdents {
  // HelloSign package modal
  SIGN_BAA_AGREEMENT = 'sign-baa-agreement',
  SCHEDULE_ONBOARDING_MEETING = 'schedule-onboarding-meeting',
  // File upload for prev month statements
  UPLOAD_PREV_MONTH_STATEMENTS = 'upload-prev-month-statements',
  // Nav to accounts/new
  CONNECT_A_BUSINESS_BANK_ACCOUNT = 'connect-a-business-bank-account',
}

export enum OnboardingV2TaskIdents {
  // HelloSign package modal
  SIGN_BAA_AGREEMENT = OnboardingTaskIdents.SIGN_BAA_AGREEMENT,
  // File upload for prev month statements
  UPLOAD_PREV_MONTH_STATEMENTS = OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS,
  // Nav to accounts/new
  CONNECT_A_BUSINESS_BANK_ACCOUNT = OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT,
}

export enum GuidedOnboardingTaskIdents {
  SIGN_BAA_AGREEMENT = OnboardingTaskIdents.SIGN_BAA_AGREEMENT,
  // File upload for prev month statements
  UPLOAD_PREV_MONTH_STATEMENTS = OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS,
  // Nav to accounts/new
  CONNECT_A_BUSINESS_BANK_ACCOUNT = OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT,
  SCHEDULE_ONBOARDING_MEETING = OnboardingTaskIdents.SCHEDULE_ONBOARDING_MEETING,
  FINANCIAL_ACCOUNT_PRESCREEN = 'financial-account-prescreen',
  FINANCIAL_ACCOUNT_QUESTIONS = 'financial-account-questions',
}

export enum PostOnboardingTaskIdents {
  // Nav to profile edit
  FILL_OUT_BUSINESS_PROFILE = 'fill-out-business-profile',
  // PayrollAccessModal
  SET_UP_PAYROLL_ACCESS = 'set-up-payroll-access',
  // File upload for previous year personal tax return
  UPLOAD_TAX_RETURN_1040 = 'upload-tax-return-1040',
  // File upload for previous year business tax return
  UPLOAD_TAX_RETURN_1120S = 'upload-tax-return-1120s',
  // OnboardingEstimatedTaxPaymentsModal
  ENTER_QTE_PAYMENTS = 'enter-qte-payments',
  // Navigate to allocation creation
  SET_UP_HOW_YOUR_EARNINGS_ARE_ALLOCATED = 'set-up-how-your-earnings-are-allocated',
  // Open pendo tour
  GET_TO_KNOW_HEARD = 'get-to-know-heard',
  // nav to /taxes/profile
  UPDATE_TAX_PROFILE = 'update-tax-profile',
  // File upload for business_incorporation
  UPLOAD_BUSINESS_INCORPORATION_DOCUMENTS = 'upload-business-incorporation-documents',
  // File upload for cp261_notice
  UPLOAD_CP261_NOTICE = 'upload-cp261-notice',
  // File upload for form_2553
  UPLOAD_FORM_2553 = 'upload-form-2553',
  // Nav to separate transactions
  SEPARATE_BUSINESS_AND_PERSONAL_TRANSACTIONS = 'separate-business-and-personal-transactions',
}

// Determines which action items appear in the first accordion
export const setupYourAccountItemsFilter = (userActionItem: UserActionItem) =>
  [
    OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT,
    OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS,
    OnboardingTaskIdents.SIGN_BAA_AGREEMENT,
  ].includes(userActionItem.actionItem.identifier as OnboardingTaskIdents)

// Determines which action items appear in the second accordion
export const scheduleOnboardingItemsFilter = (userActionItem: UserActionItem) =>
  [OnboardingTaskIdents.SCHEDULE_ONBOARDING_MEETING].includes(
    userActionItem.actionItem.identifier as OnboardingTaskIdents
  )

export enum OnboardingModalIdents {
  WELCOME = 'onboarding',
  HOORAY_MODAL = 'hooray-modal',
  FINISHED_MODAL = 'finished-modal',
  SETUP_COMPLETE = '/setup/complete',
}

export const isOnboardingTaskIdent = createIsSomeEnum(OnboardingTaskIdents)
export const isOnboardingModalIdent = createIsSomeEnum(OnboardingModalIdents)

export const prevYearMonth = moment()
  .subtract(1, 'month')
  .format(DATE_FORMATS.MONTH_YEAR)

export const templateDescription = (
  description: string,
  templateValue?: string
) => (templateValue ? description.replace(/%s/g, templateValue) : description)

export const TaskCTAButton = ({
  completedAt,
  completedText,
  disabled,
  onClick,
  variant,
  text,
  isEditable,
  loading,
}: {
  completedAt?: string
  completedText: string
  disabled?: boolean
  onClick: () => void
  variant?: ButtonProps['variant']
  text?: string
  isEditable?: boolean
  loading?: boolean
}) => (
  <Button
    disabled={disabled}
    className={completedAt && 'completed'}
    loading={loading}
    style={
      completedAt
        ? {
            backgroundColor: 'transparent',
            color: Colors.black,
            border: `1px solid ${Colors.black}`,
          }
        : {}
    }
    fullWidth
    onClick={onClick}
    variant={variant ?? 'primary'}
  >
    {completedAt ? (
      <>
        <Icon
          icon={isEditable ? light('pencil') : light('check')}
          style={{ marginRight: 8 }}
        />
        {isEditable ? 'Edit' : completedText}
      </>
    ) : (
      (text ?? 'Start')
    )}
  </Button>
)

export const ModalAnimation = ({
  url,
  title,
  style,
}: {
  url: string
  title: string
  style?: CSSProperties
}) => (
  <iframe
    style={{
      border: 'none',
      ...style,
    }}
    width="200"
    height="200"
    src={url}
    title={title}
    sandbox="allow-scripts"
  />
)

export const ModalLottieAnimation = ({
  animationData,
  loop,
}: {
  animationData: object
  loop: boolean
}) => (
  <Lottie
    style={{
      width: '200px',
      height: '200px',
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
    animationData={animationData}
    loop={loop}
  />
)

export const AnimatedModal = ({
  closeIcon,
  modalActions,
  onClose,
  open,
  content,
  dimmer,
}: {
  closeIcon?: boolean
  content: Slide | Slide[]
  modalActions: ReactNode
  onClose?: () => void
  open: boolean
  dimmer?: 'blurring' | 'inverted'
}) => (
  <Modal
    dimmer={dimmer}
    closeIcon={closeIcon}
    open={open}
    onClose={onClose}
    size={'small'}
  >
    <Modal.Content
      style={
        !isArray(content)
          ? {
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }
          : {}
      }
    >
      {isArray(content) ? (
        <Carousel slides={content} />
      ) : (
        <>
          {content.animationUrl && (
            <ModalAnimation
              url={content.animationUrl}
              title={content.animationTitle}
            />
          )}
          {content.lottieAnimation && (
            <ModalLottieAnimation
              animationData={content.lottieAnimation}
              loop={Boolean(content.lottieLoop)}
            />
          )}
          <Text as="display">{content.title}</Text>
          <br />
          <Text as="bodyLg">{content.description}</Text>
        </>
      )}
    </Modal.Content>
    <Modal.Actions
      style={
        isArray(content)
          ? { border: 'none', paddingBottom: '38px', paddingTop: '0px' }
          : {}
      }
    >
      {modalActions}
    </Modal.Actions>
  </Modal>
)

export const useIsOnboardingNav = () => {
  const [searchParams] = useSearchParams()
  return searchParams.get('onboarding') === 'true'
}
