import { useState, useMemo, useCallback, useEffect } from 'react'
import { TextArea, Text, Link } from '../../../components/BaseComponents'
import { TransactionOrSplit } from '../../../reducers/admin/allTransactions.slice'
import { updateUserTransaction } from '../transactions.slice'
import { debounce } from 'lodash'
import { useAnalyticsTrack } from '../../Amplitude'
import { useAppDispatch } from '../../../utils/typeHelpers'

export const NotesInput = ({
  transaction,
}: {
  transaction: TransactionOrSplit
}) => {
  const { notes, confirmedAt } = transaction

  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()

  const [transactionNote, setTransactionNote] = useState<string | undefined>(
    notes || ''
  )

  const debouncedUpdateNotes = useMemo(
    () =>
      debounce((newNotes: string | undefined) => {
        if ((transaction.notes || '') === (newNotes || '')) return
        track('updated notes', {
          note_text: newNotes ?? '',
          transaction_status: confirmedAt ? 'post-confirm' : 'pre-confirm',
          transaction_id: transaction.id,
        })
        updateUserTransaction(transaction.id, {
          notes: newNotes,
          notesLastUpdatedAt: new Date().toISOString(),
        })(dispatch)
      }, 2000),
    [confirmedAt, transaction.notes, transaction.id, dispatch, track]
  )

  const handleChange = useCallback(
    (newValue: string | undefined) => {
      setTransactionNote(newValue)
      debouncedUpdateNotes(newValue)
    },
    [debouncedUpdateNotes]
  )

  useEffect(() => {
    // Cleanup function to update notes when the component unmounts
    return () => {
      debouncedUpdateNotes.flush() // Execute any pending debounced updates immediately
    }
  }, [debouncedUpdateNotes])

  const noteDisclaimer = confirmedAt
    ? 'The accounting team has already reviewed this transaction. If you have questions, please reach out via'
    : 'If you have questions or an auto-categorization request, please reach out via'

  return (
    <>
      <TextArea
        label="Notes"
        rows={2}
        placeholder="Add details about this transaction"
        value={transactionNote || ''}
        onChange={handleChange}
        style={{ fontSize: 14 }}
      />
      <Text as="bodySm" color="darkGray">
        {noteDisclaimer}{' '}
        <Link
          to={`/conversations?newConversation=true&transactionId=${transaction.id}`}
          trackMessage="clicked submit transaction question"
          trackAttributes={{
            transaction_status: confirmedAt ? 'post-confirm' : 'pre-confirm',
          }}
          size="small"
        >
          Conversations
        </Link>
        .
      </Text>
    </>
  )
}
