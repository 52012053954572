import React, { useEffect } from 'react'
import { Button, Grid, Header, Modal } from 'semantic-ui-react'
import TaxProfileForm from './TaxProfile/TaxProfileForm'
import { dateWithoutTimestamp } from '../../../features/Taxes/QuarterlyTaxEstimates/service'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { fetchAdminUsersDocuments } from '../../../actions/admin/userDocumentsActions'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import { fetchHistoriesForModel } from '../../../features/Admin/Histories/histories.slice'
import { getFinancialProfileHistoriesSortedSelector } from '../../../features/Admin/Histories/histories.selector'
import TaxProfileHistoryPanel from './TaxProfileHistoryPanel'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'

const TaxProfileModal = ({
  open,
  close,
  user,
}: {
  open: boolean
  close: () => void
  user: UserWithAdminInfo
}) => {
  const dispatch = useAppDispatch()

  const sortedFinancialProfileHistories = useReselector(
    getFinancialProfileHistoriesSortedSelector,
    user.id
  )

  useEffect(() => {
    async function fetchRemote() {
      await fetchAdminUsersDocuments(user.id)(dispatch)
      await fetchHistoriesForModel({
        modelName: 'FinancialProfile',
        newVersionJsonWhere: JSON.stringify({
          userId: user.id,
        }),
      })(dispatch)
    }

    fetchRemote()
  }, [user, dispatch])

  const profile = user.financialProfile

  if (!profile) {
    return null
  }

  return (
    <Modal
      open={open}
      size={'large'}
      onClose={close}
      closeOnEscape={false}
      id="taxProfile"
    >
      <Modal.Header
        style={{ background: '#f7f7f7', padding: '1.5em', fontSize: '85%' }}
      >
        <Header style={{ display: 'inline-block' }} as="h2">
          {user.firstName} {user.lastName}&apos;s Tax Information
        </Header>
        <p style={{ float: 'right' }}>
          Reviewed By {user.firstName}:{' '}
          {profile.taxProfileLastReviewed &&
            dateWithoutTimestamp(profile.taxProfileLastReviewed).format(
              DATE_FORMATS.DISPLAY_SHORT
            )}
          {!profile.taxProfileLastReviewed && 'never'}
          <span style={{ paddingLeft: 10 }}>
            Last Updated:{' '}
            {dateWithoutTimestamp(profile.updatedAt).format(
              DATE_FORMATS.DISPLAY_SHORT
            )}
          </span>
          <span style={{ paddingLeft: 10 }}>
            <Button circular icon="close" onClick={close} />
          </span>
        </p>
      </Modal.Header>
      <Modal.Content style={{ paddingTop: 0 }}>
        <Grid>
          <Grid.Row style={{ padding: '0', marginTop: '0' }}>
            <Grid.Column width={5} style={{ background: '#f7f7f7' }}>
              <TaxProfileHistoryPanel
                style={{ paddingTop: '1.7em' }}
                financialProfileHistories={sortedFinancialProfileHistories}
              />
            </Grid.Column>
            <Grid.Column width={11}>
              <TaxProfileForm
                onCancelClick={close}
                onSaveSuccess={close}
                userId={user.id}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

export default TaxProfileModal
