import { random } from 'lodash'
import { DateTime, Info } from 'luxon'
import { Colors } from '../../../styles/theme'
import {
  addCents,
  formatCurrencyFromCents,
} from '../../../utils/currencyHelpers'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { patternFormatter } from 'react-number-format'
import { DropdownOption } from '../../BaseComponents'

export enum INSIGHTS_CHANGE_TYPE {
  INCREASE = 'increase',
  DECREASE = 'decrease',
  SAME = 'same',
  EMPTY = 'empty',
  LAST_MONTH_EMPTY = 'last_month_empty',
}

export enum ARTICLE_TYPE {
  ARTICLE = 'article',
  WEBINAR = 'webinar',
}

export enum INSIGHTS_TYPE {
  EXPENSES = 'expenses',
  PROFIT = 'profit',
  INCOME = 'income',
}

export enum CATEGORY_FILTERS {
  ALL = 'all',
  TOP = 'top',
  DEDUCTIBLE = 'deductible',
}

export interface ChartAPIResponse {
  month_number: number
  month_abbreviation: string
  year: number
  transaction_category_id: number
  transaction_category_identifier: string
  transaction_category_name: string
  transaction_category_sum_in_cents: string
  is_tax_deductible: boolean
  total_expenses?: number
  total_income?: number
}

export interface SummaryAPIResponse {
  target_month_name: string
  target_month_total_in_cents: number
  ytd_monthly_average_in_cents: number
  ytd_total_in_cents: number
  previous_month_total_in_cents: number
  previous_month_name: string
  percent_change_from_previous_month_decimal: number
  percent_of_target_months_counterpart_decimal: number
}

export interface ChartCategories {
  [category_identifier: string]: {
    id: number
    total_amount: number
    category_name: string
  }
}

export interface UncategorizedTransactionResponse {
  total_transactions: number
  total_amount_in_cents: number
  needs_review: boolean
}

export const getInsightsDateRange = (endDate: DateTime): [string, string] => {
  const startDate = endDate.minus({ months: 11 }).startOf('month')

  return [
    startDate.toFormat(DATE_FORMATS_LUXON.TIMESTAMP),
    endDate.toFormat(DATE_FORMATS_LUXON.TIMESTAMP),
  ]
}

export const getInsightsMonthYear = (endDate: DateTime) => ({
  month: endDate.month,
  year: endDate.year,
})

interface MonthData {
  [month: string]: {
    month: string
    month_number: number
    year: number
  }
}

export const generateMonthMap = (dateRange: [string, string]) => {
  const [startDateString, endDateString] = dateRange

  const startDate = DateTime.fromFormat(
    startDateString,
    DATE_FORMATS_LUXON.TIMESTAMP
  )
  const endDate = DateTime.fromFormat(
    endDateString,
    DATE_FORMATS_LUXON.TIMESTAMP
  )

  const monthMap: MonthData = {}

  for (
    let currentMonth = startDate;
    currentMonth <= endDate;
    currentMonth = currentMonth.plus({ months: 1 })
  ) {
    const month = currentMonth.toFormat('LLL')
    monthMap[month] = {
      month,
      month_number: currentMonth.month,
      year: currentMonth.year,
    }
  }

  return monthMap
}

export const findTopCategories = (categories: ChartCategories, top: number) => {
  const categoryIdentifiers = Object.keys(categories)
  categoryIdentifiers.sort(
    (a, b) => categories[b].total_amount - categories[a].total_amount
  )
  const topCategories = categoryIdentifiers.slice(0, top)
  return topCategories
}

export const getCategoryData = (data: ChartAPIResponse[]) => {
  const chartCategories: ChartCategories = {}
  for (const item of data) {
    const {
      transaction_category_id,
      transaction_category_identifier,
      transaction_category_name,
      transaction_category_sum_in_cents,
    } = item
    if (!chartCategories[transaction_category_identifier]) {
      chartCategories[transaction_category_identifier] = {
        id: transaction_category_id,
        total_amount: parseInt(transaction_category_sum_in_cents),
        category_name: transaction_category_name,
      }
    } else {
      chartCategories[transaction_category_identifier].total_amount = addCents(
        chartCategories[transaction_category_identifier].total_amount,
        parseInt(transaction_category_sum_in_cents)
      )
    }
  }
  for (const category of Object.keys(chartCategories)) {
    chartCategories[category].total_amount = Math.abs(
      chartCategories[category].total_amount
    )
  }
  const topCategories = findTopCategories(chartCategories, 3)
  return {
    chartCategories,
    topCategories,
  }
}

export const processDataForChart = (
  data: ChartAPIResponse[],
  dateRange: [string, string],
  type: INSIGHTS_TYPE
) => {
  const months = generateMonthMap(dateRange)
  const chartKeys: {
    [month: string]: {
      [category_identifier: string]: number | string | undefined
      month: string
      month_number: number
      year: number
      total_expenses?: number
      total_income?: number
    }
  } = { ...months }
  for (const item of data) {
    const {
      month_abbreviation,
      transaction_category_identifier,
      transaction_category_sum_in_cents,
      total_expenses,
      total_income,
    } = item
    chartKeys[month_abbreviation] = {
      ...chartKeys[month_abbreviation],
      [transaction_category_identifier]: parseInt(
        transaction_category_sum_in_cents
      ),
    }
    if (type === INSIGHTS_TYPE.EXPENSES) {
      chartKeys[month_abbreviation][transaction_category_identifier] =
        -(chartKeys[month_abbreviation][
          transaction_category_identifier
        ] as number)
    }
    if (type === INSIGHTS_TYPE.PROFIT) {
      chartKeys[month_abbreviation].total_expenses = total_expenses
      chartKeys[month_abbreviation].total_income = total_income
    }
  }
  const chartData = Object.values(chartKeys)
  chartData.sort((a, b) => {
    if (a.year === b.year) {
      return a.month_number - b.month_number
    } else {
      return a.year - b.year
    }
  })
  return chartData
}

export const filterChartAPIResponse = (
  data: ChartAPIResponse[],
  filter: string | null,
  topCategories: string[] = []
) => {
  if (filter === 'all' || !filter) return data
  if (filter === 'top')
    return data.filter((item) =>
      topCategories.includes(item.transaction_category_identifier)
    )
  if (filter === 'deductible')
    return data.filter((item) => item.is_tax_deductible)
  return data.filter((item) => item.transaction_category_identifier === filter)
}

export const filterChartCategories = (
  categories: ChartCategories,
  filter: string | null,
  topCategories: string[] = []
) => {
  const filteredCategories: ChartCategories = {}
  if (filter === 'all' || !filter) return categories
  if (filter === 'top') {
    topCategories.forEach((categoryIdentifier) => {
      if (categories[categoryIdentifier]) {
        filteredCategories[categoryIdentifier] = categories[categoryIdentifier]
      }
    })
  } else if (categories[filter]) {
    filteredCategories[filter] = categories[filter]
  }
  return filteredCategories
}

const chartColors = [
  Colors.magenta,
  Colors.teal,
  Colors.orange,
  Colors.yellow,
  Colors.blue,
  Colors.red,
  Colors.forest,
]

export const getBarColor = (id: number) => {
  return chartColors[id % chartColors.length]
}

export const EXAMPLE_CATEGORIES = [
  'Payroll: Contractors',
  'Rent & Lease',
  'Communication',
  'Software Services',
  'Accounting Fees',
  'Travel',
]

// function to create example response from API
// will be deleted once API endpoints are completed and integrated
export const getExampleExpenseChartData = () => {
  const exampleData: ChartAPIResponse[] = []
  const months = Info.months('short')
  delete months[2]
  delete months[5]
  delete months[9]

  months.forEach((month, i) => {
    EXAMPLE_CATEGORIES.forEach((category, idx) => {
      exampleData.push({
        month_number: i + 1,
        month_abbreviation: month,
        year: month === 'Jan' ? 2024 : 2023,
        transaction_category_id: idx,
        transaction_category_identifier: category,
        transaction_category_name: category,
        transaction_category_sum_in_cents: random(10000, 300000).toString(),
        is_tax_deductible: true,
      })
    })
  })
  return exampleData
}

export const formatPercentage = (percentage?: number) => {
  if (!percentage) return '0%'
  let formattedPercentage = Math.abs(percentage * 100)
  if (formattedPercentage >= 1) {
    formattedPercentage = Math.round(formattedPercentage)
  } else if (formattedPercentage < 1) {
    // round to 1 decimal place for percentages under 1
    formattedPercentage = Math.round(formattedPercentage * 10) / 10
  }
  return `${formattedPercentage}%`
}

export const EXPENSE_BREAKDOWN_PAGE_SIZE = 8

export const isTopExpense = (
  topExpenseCategoryIds: number[],
  categoryId: number
) => {
  return topExpenseCategoryIds.indexOf(categoryId) >= 0
}

export const applyCategoryFilter = (
  expensesBreakdown: InsightsRowAPIResponse[],
  categoryFilter: CATEGORY_FILTERS | string,
  topExpenseCategoryIds: number[]
) => {
  return expensesBreakdown.filter((expenseBreakdown) => {
    if (categoryFilter === CATEGORY_FILTERS.ALL) {
      return true
    } else if (categoryFilter === CATEGORY_FILTERS.TOP) {
      return isTopExpense(
        topExpenseCategoryIds,
        expenseBreakdown.transaction_category_id
      )
    } else if (categoryFilter === CATEGORY_FILTERS.DEDUCTIBLE) {
      return expenseBreakdown.is_tax_deductible
    } else {
      return expenseBreakdown.transaction_category_name === categoryFilter
    }
  })
}

export interface InsightsRowAPIResponse {
  start_date: string
  end_date: string
  transaction_category_id: number
  transaction_category_name: string
  transactions_sum_in_cents: number
  transactions_count: number
  is_tax_deductible: boolean
  percent_of_total_cents_decimal: number
}

export interface InsightsTransactionsAPIResponse {
  id: number
  date: string
  transaction_category_id: number
  transaction_category_name: string
  amount_in_cents: number
  description: string
}

export const computeEndDateForInsightsExpensesBreakdownTable = (
  startDate: DateTime,
  endDate: DateTime,
  yearFilter: number,
  monthFilter: string
) => {
  const year = yearFilter

  if (monthFilter === 'ytd' && year === endDate.year) {
    return endDate
  } else if (monthFilter === 'ytd') {
    return startDate.endOf('year')
  } else {
    return startDate.endOf('month')
  }
}

export const MONTHS_ARR = [
  { text: 'Year to Date', value: 'ytd' },
  { text: 'January', value: '0' },
  { text: 'February', value: '1' },
  { text: 'March', value: '2' },
  { text: 'April', value: '3' },
  { text: 'May', value: '4' },
  { text: 'June', value: '5' },
  { text: 'July', value: '6' },
  { text: 'August', value: '7' },
  { text: 'September', value: '8' },
  { text: 'October', value: '9' },
  { text: 'November', value: '10' },
  { text: 'December', value: '11' },
]

export const formatSummary = (
  summaryData?: SummaryAPIResponse,
  insightsType?: INSIGHTS_TYPE
) => {
  if (!summaryData) return null
  const {
    target_month_name,
    previous_month_name,
    target_month_total_in_cents,
    ytd_monthly_average_in_cents,
    ytd_total_in_cents,
    percent_change_from_previous_month_decimal,
    percent_of_target_months_counterpart_decimal,
    previous_month_total_in_cents,
  } = summaryData
  return {
    month_name: target_month_name.trim(),
    previous_month_name: previous_month_name.trim(),
    month_total: formatCurrencyFromCents(
      insightsType === INSIGHTS_TYPE.EXPENSES
        ? -target_month_total_in_cents
        : target_month_total_in_cents
    ),
    previous_month_total: formatCurrencyFromCents(
      insightsType === INSIGHTS_TYPE.EXPENSES
        ? -previous_month_total_in_cents
        : previous_month_total_in_cents
    ),
    monthly_average: ytd_monthly_average_in_cents
      ? formatCurrencyFromCents(
          insightsType === INSIGHTS_TYPE.EXPENSES
            ? -ytd_monthly_average_in_cents
            : ytd_monthly_average_in_cents
        )
      : undefined,
    year_total: formatCurrencyFromCents(
      insightsType === INSIGHTS_TYPE.EXPENSES
        ? -ytd_total_in_cents
        : ytd_total_in_cents
    ),
    percent_change: percent_change_from_previous_month_decimal,
    percent_counterpart_change: percent_of_target_months_counterpart_decimal,
  }
}

export const formatEin = (ein?: string | null) => {
  if (!ein) {
    return ''
  }

  // We save the ein with a dash sometimes - if it comes back in the format don't add formatting
  return ein.includes('-')
    ? ein
    : patternFormatter(ein, { format: '##-#######' })
}

export const getIncomeOrExpenseLabel = (insightsType: INSIGHTS_TYPE) => {
  return insightsType === INSIGHTS_TYPE.INCOME ? 'Income' : 'Expenses'
}

export const insightsCopyConstants: {
  [type: string]: {
    [field: string]: string
  }
} = {
  [INSIGHTS_TYPE.EXPENSES]: {
    label: 'Expenses',
    currentPanelTitle: 'Current Expenses',
    emptyCurrentSummary:
      'How much your monthly expenses spending increases or decreases will show up here.',
    trackPageTitle: 'Track Expenses',
    trackPageDescription:
      'Track your expense spending and monitor performance for your practice',
    emptyChart: 'Expense categories will show up here.',
    emptyChartMonth: 'No Expenses for this month',
    trackButton: 'clicked expenses page button',
  },
  [INSIGHTS_TYPE.INCOME]: {
    label: 'Income',
    currentPanelTitle: 'Current Income',
    emptyCurrentSummary:
      'How much your monthly income increases or decreases will show up here.',
    trackPageTitle: 'Track Income',
    trackPageDescription:
      'Track your income and monitor performance for your practice',
    emptyChart: 'Income categories will show up here.',
    emptyChartMonth: 'No Income for this month',
    trackButton: 'clicked income page button',
  },
  [INSIGHTS_TYPE.PROFIT]: {
    label: 'Profit',
    currentPanelTitle: 'Current Profit',
    emptyCurrentSummary:
      'How much your monthly profit increases or decreases will show up here.',
    trackPageTitle: 'Track Profit',
    trackPageDescription:
      'Track your profit and monitor performance for your practice.',
    emptyChart: 'Your profit will show up here.',
    emptyChartMonth: 'No Profit for this month',
    trackButton: 'clicked profit page button',
  },
}

export const insightsTrackDefaultFilters: {
  [type: string]: DropdownOption[]
} = {
  [INSIGHTS_TYPE.EXPENSES]: [
    { text: 'All Expenses', value: 'all' },
    { text: 'Top Expenses', value: 'top' },
  ],
  [INSIGHTS_TYPE.INCOME]: [{ text: 'All Categories', value: 'all' }],
  [INSIGHTS_TYPE.PROFIT]: [],
}

export enum FinancialInsightsButtonType {
  CLOSE_BANNER = 'close-banner',
  YEAR_FILTER = 'year-filter',
  MONTH_FILTER = 'month-filter',
  EXPENSES_FILTER = 'expenses-filter',
  CATEGORY_TRANSACTIONS = 'category-transactions',
  TABLE_PAGE = 'table-page',
  LEARN_MORE_CARD = 'learn-more',
  INCOME_FILTER = 'income-filter',
}
