/**
 * This Panel shows when a User has done nothing related to Advisory
 *
 */

import { Grid, Image, Icon } from 'semantic-ui-react'
import {
  GridRowColumn,
  Pill,
  Button,
  Text,
  Link,
  Card,
} from '../../../components/BaseComponents'
import CalendlyPopupModal from '../../../components/shared/CalendlyPopupModal'
import { useState } from 'react'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { createIntroCallScheduledAfterEffects } from '../service'
import { useReselector } from '../../../utils/sharedHooks'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { useAnalyticsTrack } from '../../Amplitude'
import { AdvisorHelpSummaryCard } from './PreIntroCallPanel'
import { getAdvisoryCallPricing } from '../financialAdvisory.selectors'

export const UpriseIntroCard = () => {
  return (
    <Card type="subsection" backgroundColor="stone40">
      <Grid stackable doubling centered>
        <Grid.Row>
          <Grid.Column textAlign="center" width={12}>
            <Text as="h2">Who will I be working with?</Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={12} textAlign="center">
            <Text>
              Heard offers financial advisory in partnership with Uprise, a team
              of Certified Financial Planners that build and create customized
              financial plans based on your goals.
            </Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            width={12}
            textAlign="center"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/uprise_team.svg"
              width="75"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            width={12}
            textAlign="center"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/uprise.svg"
              width="150"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}
const INTRO_CALL_CALENDLY_URL =
  'https://calendly.com/d/cmjd-h62-zbx/heard-intro-advisory-call'

const IntroCallCTAPanel = () => {
  const [calendlyOpen, setCalendlyOpen] = useState(false)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const currentUser = useReselector(getCurrentUser)
  const track = useAnalyticsTrack()
  const callPricing = useReselector(getAdvisoryCallPricing)

  // Used to manage after effects of scheduling the intro call
  const introCallScheduledAfterEffects = async (
    eventUri: string | undefined
  ) => {
    track('scheduled intro advisory call', {
      user_id: currentUser?.id || null,
      event_uri: eventUri || null,
    })
    await createIntroCallScheduledAfterEffects({ currentUser })
    // Reload to properly view changes
    location.reload()
  }

  return (
    <>
      <Grid
        className="statementTitle"
        style={{ margin: '0 5px' }}
        stackable
        doubling
      >
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={6} textAlign="center">
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/financial_advice.svg"
              alt="heard financial advisory"
            />
          </Grid.Column>
          <Grid.Column width={10}>
            <Grid style={{ margin: '0 5px' }}>
              <GridRowColumn>
                <Pill>Annual Plan Benefit Only</Pill>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="h1">
                  Small business financial planning for therapists like you
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  Heard has partnered with{' '}
                  <Link newPage href="https://uprise.us/" as="secondaryLink">
                    Uprise
                  </Link>
                  , a premium financial advisory platform, to connect our
                  therapists with financial experts geared towards mental health
                  small business owners like you.
                </Text>
              </GridRowColumn>

              <GridRowColumn>
                <Text>
                  <b>Included in your Heard annual membership</b>
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  <Icon name="check" size="small" />
                  20–min introductory consultation with a Certified Financial
                  Planner
                </Text>
                <br />
                <Text>
                  <Icon name="check" size="small" />
                  Personalized comprehensive financial plan
                </Text>
                <br />
                <Text>
                  <Icon name="check" size="small" />
                  Unlimited chat with your advisor to keep your plan updated
                </Text>
              </GridRowColumn>

              <GridRowColumn>
                <Text>
                  <b>For additional support, add-on</b>
                  <br />
                  <Text>
                    <Icon name="plus" size="small" />
                    45 minute follow-up video calls at ${callPricing}
                  </Text>
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Button
                  fullWidth={isMobile}
                  onClick={() => {
                    setCalendlyOpen(true)
                    track('clicked schedule intro call button', {
                      financial_advisory_step:
                        'start schedule introductary call',
                    })
                  }}
                >
                  Schedule Intro Call
                </Button>
              </GridRowColumn>
              {calendlyOpen && (
                <CalendlyPopupModal
                  url={INTRO_CALL_CALENDLY_URL}
                  open={calendlyOpen}
                  onClose={() => setCalendlyOpen(false)}
                  onScheduled={(eventUri: string | undefined) => {
                    introCallScheduledAfterEffects(eventUri)
                    track('scheduled introductory financial advisory', {
                      financial_advisory_step:
                        'completed schedule introductory call',
                    })
                  }}
                />
              )}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <AdvisorHelpSummaryCard />
      <br />
      <UpriseIntroCard />
      <br />
      <br />
      <br />
    </>
  )
}
export default IntroCallCTAPanel
