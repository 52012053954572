import { CSSProperties, FC, ReactNode, SyntheticEvent } from 'react'
import { Grid, Icon, StrictPopupProps } from 'semantic-ui-react'
import { Link, Text } from '../../../../components/BaseComponents'
import Popup from '../../../../components/BaseComponents/Popup'
import { insertBetween } from '../../../../utils/arrayHelpers'

interface Props extends Omit<StrictPopupProps, 'children'> {
  popup: {
    title?: string
    body: string | FC
    link?: string
    linkText?: string
  }
  labelComponent?: string | ReactNode
  style?: CSSProperties
}

// if the body of the tooltip includes a `<br/>`, parse that and insert an empty line where it is
const parseTooltipBody = (body: string) => {
  if (body.includes('<br/>')) {
    return insertBetween<ReactNode>(
      body.split('<br/>'),
      <>
        <br />
        <br />
      </>
    )
  }

  return body
}

const Tooltip: FC<Props> = ({ popup, labelComponent, ...rest }) => (
  <Popup
    hoverable
    {...rest}
    content={
      <Grid
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation()
        }}
      >
        {popup.title && (
          <Grid.Row style={{ paddingBottom: '0' }}>
            <Grid.Column>
              <Text style={{ paddingTop: '10px' }} as="h3">
                {popup.title}
              </Text>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column>
            {typeof popup.body === 'string' ? (
              <Text>{parseTooltipBody(popup.body)}</Text>
            ) : (
              <popup.body />
            )}
          </Grid.Column>
        </Grid.Row>
        {popup.link && (
          <Grid.Row style={{ padding: '0 0 20px 0' }}>
            <Grid.Column>
              <Link href={popup.link} newPage>
                {/* Wrapping in <span> so icon vertically aligns */}
                <span>
                  {popup.linkText || 'Learn more'}{' '}
                  <Icon name="long arrow alternate right" />
                </span>
              </Link>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    }
    trigger={
      labelComponent && (
        <span style={{ cursor: 'pointer' }}>{labelComponent}</span>
      )
    }
  />
)

export default Tooltip
