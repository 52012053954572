import { isoToUTCDateTime } from '../../../utils/dateHelpers'
/**
 *
 * This is a file used for 2024 Late joiner helpers.
 *
 * Since these dates are decided last minute, pulling into one file for easier management
 *
 */

/**
 *
 * Form 1040 has 3 tranches of late joiners
 *
 * Tranche 1: Joined Jan 1 - Feb 16 '24
 * Tranche 2: Joined Feb 16 - Apr 1
 * Tranche 3: Joined Apr 1 - Sep 1
 */
export const FORM_1040_LATE_JOINER_ON_TIME_CUTOFF = isoToUTCDateTime(
  '2024-02-16T08:00:00.000Z'
)
export const FORM_1040_LATE_JOINER_FINAL_CUTOFF = isoToUTCDateTime(
  '2024-04-01T07:00:00.000Z'
)
export const FORM_1040_LATE_JOINER_EXTENDED_CUTOFF = isoToUTCDateTime(
  '2024-09-02T07:00:00.000Z'
)

/**
 *
 * Form 1120S has 2 tranches of late joiners
 *
 * Tranche 1: Joined pre-Mar 1
 * Tranche 2: Joined Mar 1 - Jul 1
 */

export const FORM_1120_S_LATE_JOINER_ON_TIME_CUTOFF = isoToUTCDateTime(
  '2024-03-01T08:00:00.000Z'
)
export const FORM_1120_S_LATE_JOINER_FINAL_CUTOFF = isoToUTCDateTime(
  '2024-07-02T07:00:00.000Z'
)
