import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { fetchPaymentMethodUpdateSession } from '../../actions/settings/billingActions'
import { Button, Card, Link, Text } from '../../components/BaseComponents'
import { MEMBERSHIP_STATUS } from '../../reducers/auth/userReducer'
import { selectPrimaryMembership } from '../../selectors/user.selectors'
import { useReselector } from '../../utils/sharedHooks'
import { useAppDispatch } from '../../utils/typeHelpers'

const StatusHeader: Partial<Record<MEMBERSHIP_STATUS, string>> = {
  paid: 'Restored',
  pending: 'Pending',
  unpaid: 'Suspended',
}

const AccountSuspended = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const membership = useReselector(selectPrimaryMembership)
  const { membershipStatus, status } = membership ?? {} // remove membershipStatus with CORE-253
  const currentMembershipStatus = membershipStatus ?? status
  const [loading, setLoading] = useState(false)

  const header = StatusHeader[currentMembershipStatus ?? 'unpaid']

  const redirectToStripeSession = async () => {
    setLoading(true)
    const res = await fetchPaymentMethodUpdateSession('/accounts/suspended')(
      dispatch
    )

    if (res) {
      window.location.href = res.url
    } else {
      setLoading(false)
    }
  }

  return currentMembershipStatus &&
    !(currentMembershipStatus in StatusHeader) ? (
    <Navigate replace to="/dashboard" />
  ) : (
    <Card
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: '81px 230px',
      }}
    >
      <Text as="h1" textAlign="center">
        Account {header}
      </Text>
      <br />
      {currentMembershipStatus === 'unpaid' && (
        <>
          <Text textAlign="center">
            Your last payment for Heard has failed.
          </Text>
          <br />
          <Text textAlign="center">
            You&apos;re unable to use Heard until you update with a valid form
            of payment. Don&apos;t worry, all your data is safe and secure.
          </Text>
        </>
      )}
      {currentMembershipStatus === 'pending' && (
        <Text textAlign="center">
          Thank you for updating your payment method! Your account is currently
          pending and should be updated within 5 minutes. Please refresh the
          page to check if your account has been restored.
        </Text>
      )}
      {currentMembershipStatus === 'paid' && (
        <Text textAlign="center">
          Your account has been restored! Please click the button below to go
          back to your dashboard or any of the sidebar items to get back to
          where you were!
        </Text>
      )}
      <br />
      {['unpaid', 'paid'].includes(currentMembershipStatus ?? '') && (
        <Button
          disabled={loading}
          loading={loading}
          onClick={
            currentMembershipStatus === 'unpaid'
              ? redirectToStripeSession
              : () => navigate('/dashboard')
          }
        >
          {currentMembershipStatus === 'unpaid'
            ? 'Pay and Restore Account'
            : 'Return to Dashboard'}
        </Button>
      )}
      {/* We have a selector for this in `user.selectors.ts`, but since we're already using a
            selector to get the user's `membershipStatus`, we can reduce using another selector
            call for this. */}
      {['pending', 'unpaid'].includes(currentMembershipStatus ?? '') && (
        <>
          <br />
          <Text>
            Is this a mistake? Contact us via email at{' '}
            <Link as="secondaryLink" href="mailto:contact@joinheard.com">
              contact@joinheard.com
            </Link>
            .
          </Text>
        </>
      )}
    </Card>
  )
}

export default AccountSuspended
