import { useState } from 'react'
import { Grid, Container } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import SignupHeader from '../SignupHeader'
import {
  Button,
  FormikInput,
  getFieldName,
  makeNumberSchema,
  Text,
  Card,
  Link,
  makeReqEmailSchema,
} from '../../../components/BaseComponents'
import { postWaitlistUser } from '../waitlistActions'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { useSignupPageUpdate } from '../../../services/onboardingService'
import { SIGNUP_PATHS } from '../helpers'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useReselector } from '../../../utils/sharedHooks'

const validationSchema = yup.object({
  email: makeReqEmailSchema({ field: 'email' }),
  numberOfEmployees: makeNumberSchema({
    field: 'number of employees',
  }),
})

const SignupWaitlist = () => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const user = useReselector(getCurrentUser)

  useSignupPageUpdate(SIGNUP_PATHS.waitlist)

  const formik = useFormik({
    initialValues: {
      email: '',
      numberOfEmployees: '',
    },
    validationSchema,
    onSubmit: async ({ email, numberOfEmployees }) => {
      const res = await postWaitlistUser({
        email,
        numberOfEmployees: Number(numberOfEmployees),
      })(dispatch)

      if (res) {
        setSubmitted(true)
      }
    },
  })

  return (
    <Container style={{ paddingTop: 64 }}>
      <Grid>
        <SignupHeader currentStep={1} />
        {!submitted && (
          <>
            <Grid.Row>
              <Grid.Column>
                <Text as="h1">
                  {user?.firstName || ''}, congratulations on building a
                  flourishing private practice
                </Text>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="short">
              <Grid.Column>
                <Text as="bodyLg">
                  Currently, we only serve private practices with up to 10
                  employees. Join our waitlist for updates on when we can better
                  help private practices like yours.
                </Text>
              </Grid.Column>
            </Grid.Row>
            <FormikProvider value={formik}>
              <Grid.Row>
                <Grid.Column width={7}>
                  <FormikInput
                    label="Email address to receive updates"
                    placeholder="jane.smith@privatepractice.com"
                    required
                    name={getFieldName<typeof formik.values>('email')}
                    fullWidth
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={7}>
                  <FormikInput
                    label="Total number of employees"
                    description="Include yourself as an employee"
                    placeholder="Enter number of employees"
                    required
                    name={getFieldName<typeof formik.values>(
                      'numberOfEmployees'
                    )}
                    fullWidth
                  />
                </Grid.Column>
              </Grid.Row>
            </FormikProvider>
            <Grid.Row />
            <Grid.Row>
              <Grid.Column width={3}>
                <Button
                  fullWidth
                  onClick={formik.submitForm}
                  id="btn-signup-join-waitlist"
                >
                  Join Waitlist
                </Button>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        {submitted && (
          <>
            <Grid.Row>
              <Grid.Column width={1} />
              <Grid.Column width={14}>
                <Card backgroundColor="stone">
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Text as="display" textAlign="center">
                          Thank you!
                        </Text>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Text as="h3" textAlign="center">
                          You’ve been put on the waitlist.
                        </Text>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Text as="bodyLg" textAlign="center">
                          We&apos;ll reach out to you if things change.
                        </Text>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={6} />
              <Grid.Column width={4}>
                <Link href="https://www.joinheard.com">
                  <Button variant="secondary" fullWidth>
                    Return to Site
                  </Button>
                </Link>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
    </Container>
  )
}

export default SignupWaitlist
