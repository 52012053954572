import { Grid, Image } from 'semantic-ui-react'
import {
  Button,
  GridRowColumn,
  Modal,
  Text,
} from '../../components/BaseComponents'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'

const OnboardingSkipModal = ({
  loading,
  open,
  onClose,
  onSkipped,
  subtitle,
}: {
  loading?: boolean
  open: boolean
  onClose: () => void
  onSkipped: () => void
  subtitle: string
}) => {
  const getBackButton = () => (
    <Button onClick={onClose} fullWidth variant="actionLink">
      Go back
    </Button>
  )

  const getSkipButton = () => (
    <Button loading={loading} disabled={loading} onClick={onSkipped} fullWidth>
      Skip for now
    </Button>
  )

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Content>
        <Grid textAlign="center">
          <GridRowColumn>
            <Image
              style={{ display: 'inline', minHeight: 200 }}
              src="https://heard-images.s3.amazonaws.com/assets/ob_headscratcher.svg"
            />
          </GridRowColumn>
          <GridRowColumn>
            <Text as="display">Are you sure?</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>{subtitle}</Text>
          </GridRowColumn>
          <Grid.Row verticalAlign="middle">
            {isMobile ? (
              <>
                <Grid.Column width={16}>{getSkipButton()}</Grid.Column>
                <Grid.Column style={{ padding: 16 }} width={16}>
                  {getBackButton()}
                </Grid.Column>
              </>
            ) : (
              <>
                <Grid.Column width={2} />
                <Grid.Column width={6}>{getBackButton()}</Grid.Column>
                <Grid.Column width={6}>{getSkipButton()}</Grid.Column>
              </>
            )}
            <Grid.Column width={2} />
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

export default OnboardingSkipModal
