import { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import ReactDatePicker, {
  DatePickerProps,
  ReactDatePickerCustomHeaderProps,
} from 'react-datepicker'
import styled from 'styled-components'
import { omit } from 'lodash'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { shift } from '@floating-ui/dom'

import 'react-datepicker/dist/react-datepicker.css'

import { Colors, Fonts, FontWeight } from '../../styles/theme'
import Input, { InputProps } from './Input'

import { DATE_FORMATS } from '../../utils/dateHelpers'
import {
  QUARTER_PNL_OPTIONS,
  YEARLY_PNL_OPTIONS,
} from '../../constants/businessConstants'
import {
  DeviceWidth,
  DeviceWidthCombo,
  useIsDeviceWidth,
} from '../../utils/deviceWidthHelpers'
import Modal from './Modal'
import Icon, { IconButton } from './Icon'
import Text from './Text'
import Button from './Button'
import Dropdown from './Dropdown'

type RangeProps =
  | {
      selectsRange?: false
      onChange: (value: string) => void
    }
  | {
      selectsRange: true
      onChange: (value: [string, string]) => void
    }

interface BaseProps
  extends Omit<
      DatePickerProps,
      | 'value'
      | 'onChange'
      | 'dateFormat'
      | 'onFocus'
      | 'startDate'
      | 'endDate'
      | 'selectsRange'
      | 'autoComplete'
      | 'onBlur'
      | 'onSelect'
      | 'excludeScrollbar'
    >,
    Omit<
      InputProps,
      'onChange' | 'onKeyDown' | 'tabIndex' | 'onSelect' | 'customInput'
    > {
  dateFormat?: DATE_FORMATS
  startDate?: string
  endDate?: string
}

// This helper should stay local to DatePicker because of reliance on moment
const parseDateStringToDate = (dateString: string | undefined) =>
  // This is the current reason we are reliant on moment for date picker - input parsing is much stricter in Luxon
  dateString && moment(dateString).isValid()
    ? moment(dateString).toDate()
    : undefined

// This helper should stay local to DatePicker because of reliance on moment
// Set it to the beginning of the day on selection
const convertSelectedDateToString = (date: Date, format: DATE_FORMATS) =>
  moment(date).startOf('day').format(format)

// Takes the partial date input and starts formatting it before entry completion.  ie for format MM/DD/YYYY 06/23 will set the date but not year
const formatPartialDateStringToMoment = (
  value: string,
  format: DATE_FORMATS
) => {
  // This helper will only work for formats that are 1:1 with length of format (ie MM/DD is 06/23).
  // It won't work for formats that aren't (ie M/D which can be 3/4 or 11/11)
  if ([DATE_FORMATS.INPUT, DATE_FORMATS.DISPLAY_SIMPLE].includes(format)) {
    const partialDateMoment = moment.utc(
      value,
      format.substring(0, value?.length)
    )

    if (partialDateMoment.isValid()) {
      return partialDateMoment
    }
  }

  const parsedMomentFromString = moment.utc(value)

  return parsedMomentFromString.isValid() ? parsedMomentFromString : null
}

type Props = BaseProps & RangeProps

const DatePickerInput = ({
  onChange,
  selectsRange,
  dateFormat,
  ...rest
}: Props & { onClick?: () => void; dateFormat: DATE_FORMATS }) => (
  <Input
    {...omit(
      rest,
      'customInput',
      'onSelect',
      'startDate',
      'endDate',
      'onCalendarOpen',
      'onCalendarClose',
      'maxDate'
    )}
    className="dateInput"
    leftIcon="calendar outline"
    componentType="date"
    onChange={(value: string) => {
      if (!selectsRange) {
        onChange(value)
      }
    }}
    onBlur={(e) => {
      rest.onBlur?.(e)
      const parsedMomentFromString = moment.utc(e.target.value)

      if (parsedMomentFromString.isValid() && !selectsRange) {
        onChange(parsedMomentFromString.format(dateFormat))
      }
    }}
  />
)

const CustomDateHeader = ({
  showYear,
  toggleShowYear,
  date,
  decreaseYear,
  decreaseMonth,
  increaseYear,
  increaseMonth,
  showYearPicker,
}: ReactDatePickerCustomHeaderProps & {
  showYear: boolean
  showYearPicker?: boolean
  toggleShowYear: () => void
}) => (
  <div style={{ display: 'flex', marginBottom: 20 }}>
    <div
      onClick={() => !showYearPicker && toggleShowYear()}
      style={{ display: 'flex', flex: 1, marginLeft: 25, cursor: 'pointer' }}
      role="presentation"
    >
      <Text as="h2">
        {showYear ? 'Select Year' : moment(date).format('MMMM YYYY')}
      </Text>
      {!showYearPicker && (
        <Icon
          icon={showYear ? solid('caret-up') : solid('caret-down')}
          style={{ marginLeft: 10, color: Colors.darkGray }}
        />
      )}
    </div>

    <IconButton
      icon={regular('angle-left')}
      onClick={showYear ? decreaseYear : decreaseMonth}
      style={{ marginRight: 30 }}
    />
    <IconButton
      icon={regular('angle-right')}
      onClick={showYear ? increaseYear : increaseMonth}
      style={{ marginRight: 35 }}
    />
  </div>
)

const DatePicker = (props: Props) => {
  const {
    dateFormat = DATE_FORMATS.INPUT,
    onChange,
    value,
    className,
    startDate,
    endDate,
    selectsRange,
    showYearPicker,
  } = props
  const pickerRef = useRef<ReactDatePicker>(null)
  const fallbackRange = useMemo(
    () => [parseDateStringToDate(startDate), parseDateStringToDate(endDate)],
    [startDate, endDate]
  )
  const isMobileOrTablet = useIsDeviceWidth(DeviceWidthCombo.mobileOrTablet)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const [openModal, setOpenModal] = useState(false)
  const [showYear, setShowYear] = useState(Boolean(showYearPicker))

  // Tells the popover to stay within the content boundaries and give it a little extra padding
  const popoverModifiers = useMemo(
    () => [
      shift({
        boundary: document.querySelector('.content-wrapper') || undefined,
        padding: 10,
      }),
    ],
    []
  )

  useEffect(() => {
    if (isMobile) {
      pickerRef.current?.setOpen(openModal)
    }
  }, [isMobile, openModal])

  const [[start, end], setRange] = useState(fallbackRange)

  // This is not a perfect representation of the value sent back to parent - it's for the date picker selection so
  // doesn't have to be perfect
  const selectedDate = useMemo(() => {
    if (!value) {
      return null
    }

    const parsedMoment = formatPartialDateStringToMoment(value, dateFormat)

    return parsedMoment?.isValid()
      ? new Date(parsedMoment.year(), parsedMoment.month(), parsedMoment.date())
      : null
  }, [dateFormat, value])

  // This is not a perfect representation of the value sent back to parent - it's for the date picker selection so
  // doesn't have to be perfect
  const dateValue = useMemo(() => {
    const defaultDate = new Date().toISOString()
    if (!value) {
      return defaultDate
    }

    const parsedMoment = formatPartialDateStringToMoment(value, dateFormat)

    return parsedMoment?.isValid()
      ? new Date(
          parsedMoment.year(),
          parsedMoment.month(),
          parsedMoment.date()
        ).toISOString()
      : defaultDate
  }, [dateFormat, value])

  const selectedYearlyOption = useMemo(() => {
    const index = YEARLY_PNL_OPTIONS.findIndex(
      (option) => option.startDate === startDate && option.endDate === endDate
    )
    return index === -1 ? undefined : index
  }, [endDate, startDate])

  const selectedQuarterOption = useMemo(() => {
    const index = QUARTER_PNL_OPTIONS.findIndex(
      (option) => option.startDate === startDate && option.endDate === endDate
    )
    return index === -1 ? undefined : index
  }, [endDate, startDate])

  const handleClearClick = () => {
    if (selectsRange) {
      onChange(['', ''])
      setRange([undefined, undefined])
    } else {
      onChange('')
    }
  }

  const handleQuickOption = ({
    startDate: start,
    endDate: end,
  }: {
    startDate: string
    endDate: string
  }) => {
    setRange([parseDateStringToDate(start), parseDateStringToDate(end)])
    if (selectsRange) {
      onChange([start, end])
    }
    pickerRef.current?.setOpen(false)
  }

  if (isMobile && selectsRange) {
    return (
      <div>
        <DatePickerInput
          {...props}
          dateFormat={dateFormat}
          onClearClick={handleClearClick}
          onClick={() => setOpenModal(true)}
        />
        <Modal
          className={`${className} mobile-date-picker`}
          size="fullscreen"
          open={openModal}
        >
          <Modal.Content>
            <ReactDatePicker
              {...props}
              selectsRange
              selectsMultiple={undefined}
              dateFormat={dateFormat}
              disabledKeyboardNavigation
              endDate={end}
              focusSelectedMonth
              monthsShown={1}
              onChange={(dateOrRange) => {
                if (Array.isArray(dateOrRange)) {
                  setRange([
                    dateOrRange[0] || undefined,
                    dateOrRange[1] || undefined,
                  ])
                }
              }}
              ref={pickerRef}
              open={openModal}
              selected={selectedDate}
              shouldCloseOnSelect={!selectsRange || Boolean(showYearPicker)}
              startDate={start}
              value={dateValue}
              useWeekdaysShort
              popperModifiers={popoverModifiers}
              isClearable
            >
              {selectsRange && (
                <>
                  <div className="left-panel">
                    {isMobile && (
                      <div>
                        <Icon
                          icon={regular('close')}
                          onClick={() => {
                            setOpenModal(false)
                          }}
                        />
                      </div>
                    )}
                    <div>
                      <Text
                        as="eyebrow"
                        style={{ marginBottom: 8 }}
                        color="forest"
                      >
                        By Year
                      </Text>
                      <Dropdown
                        placeholder="Select"
                        options={YEARLY_PNL_OPTIONS.map((option, index) => ({
                          text: option.value,
                          value: index,
                        }))}
                        onChange={(index) =>
                          handleQuickOption(YEARLY_PNL_OPTIONS[index])
                        }
                        value={selectedYearlyOption}
                        fullWidth
                      />
                    </div>
                    <div>
                      <Text
                        as="eyebrow"
                        style={{
                          marginBottom: 8,
                          marginTop: 0,
                        }}
                        color="forest"
                      >
                        By Quarter
                      </Text>
                      <Dropdown
                        placeholder="Select"
                        options={QUARTER_PNL_OPTIONS.map((option, index) => ({
                          text: option.value,
                          value: index,
                        }))}
                        onChange={(index) =>
                          handleQuickOption(QUARTER_PNL_OPTIONS[index])
                        }
                        value={selectedQuarterOption}
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="datepicker-ctas">
                    <Button
                      className="cancel"
                      onClick={() => {
                        setRange(fallbackRange)
                        setOpenModal(false)
                      }}
                      size="large"
                      variant="actionLink"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        onChange([
                          start
                            ? convertSelectedDateToString(start, dateFormat)
                            : '',
                          end
                            ? convertSelectedDateToString(end, dateFormat)
                            : '',
                        ])
                        setOpenModal(false)
                      }}
                      size="large"
                    >
                      Apply
                    </Button>
                  </div>
                </>
              )}
            </ReactDatePicker>
          </Modal.Content>
        </Modal>
      </div>
    )
  } else {
    const rangeProps = selectsRange
      ? {
          selectsRange: true as const,
          onChange: (dateOrRange: [Date | null, Date | null]) => {
            if (Array.isArray(dateOrRange)) {
              setRange([
                dateOrRange[0] || undefined,
                dateOrRange[1] || undefined,
              ])
            }
          },
          selectsMultiple: undefined,
        }
      : {
          selectsRange: undefined,
          onChange: (dateOrRange: Date | null) => {
            if (!Array.isArray(dateOrRange) && dateOrRange) {
              onChange(convertSelectedDateToString(dateOrRange, dateFormat))
            }
          },
          selectsMultiple: undefined,
        }

    return (
      <div
        className={`${className} ${
          isMobileOrTablet ? 'tablet-date-picker' : ''
        }`}
      >
        <DatePickerInput
          {...props}
          dateFormat={dateFormat}
          onClearClick={handleClearClick}
          onClick={() => pickerRef.current?.setOpen(true)}
        />
        <ReactDatePicker
          {...props}
          {...rangeProps}
          dateFormat={dateFormat}
          disabledKeyboardNavigation
          endDate={end}
          focusSelectedMonth
          monthsShown={selectsRange && !isMobileOrTablet ? 2 : 1}
          ref={pickerRef}
          selected={selectedDate}
          shouldCloseOnSelect={
            (!selectsRange && !showYear) || Boolean(showYearPicker)
          }
          startDate={start}
          value={dateValue}
          useWeekdaysShort
          renderCustomHeader={
            !selectsRange
              ? ({ ...props }) => (
                  <CustomDateHeader
                    {...props}
                    showYear={showYear}
                    showYearPicker={showYearPicker}
                    toggleShowYear={() => {
                      setShowYear((prev) => !prev)
                      // If you change the year pages the month pages also change even if a year is not selected and
                      // there's also no way to set the open date.  This is a hack to set the month back to whatever is selected
                      // It does end up flashing but it's better than the other behavior
                      pickerRef.current?.setOpen(false, true)
                      // There is a delay here because if the modal is set to closed -> open without this the modal doesn't actually close
                      setTimeout(
                        () => pickerRef.current?.setOpen(true, true),
                        0
                      )
                    }}
                  />
                )
              : undefined
          }
          onSelect={() => setShowYear(Boolean(showYearPicker))}
          showYearPicker={showYear}
          popperModifiers={popoverModifiers}
          isClearable
        >
          {selectsRange && (
            <>
              <div className="left-panel">
                <div>
                  <Text as="eyebrow" style={{ marginBottom: 8 }} color="forest">
                    By Year
                  </Text>
                  <Dropdown
                    placeholder={isMobileOrTablet ? 'Select' : 'Select year'}
                    options={YEARLY_PNL_OPTIONS.map((option, index) => ({
                      text: option.value,
                      value: index,
                    }))}
                    onChange={(index) =>
                      handleQuickOption(YEARLY_PNL_OPTIONS[index])
                    }
                    value={selectedYearlyOption}
                    fullWidth
                  />
                </div>
                <div>
                  <Text
                    as="eyebrow"
                    style={{
                      marginBottom: 8,
                      marginTop: isMobileOrTablet ? 0 : 40,
                    }}
                    color="forest"
                  >
                    By Quarter
                  </Text>
                  <Dropdown
                    placeholder={isMobileOrTablet ? 'Select' : 'Select quarter'}
                    options={QUARTER_PNL_OPTIONS.map((option, index) => ({
                      text: option.value,
                      value: index,
                    }))}
                    onChange={(index) =>
                      handleQuickOption(QUARTER_PNL_OPTIONS[index])
                    }
                    value={selectedQuarterOption}
                    fullWidth
                  />
                </div>
              </div>
              <div className="datepicker-ctas">
                <Button
                  className="cancel"
                  onClick={() => {
                    setRange(fallbackRange)
                    pickerRef.current?.setOpen(false)
                  }}
                  size="large"
                  variant="actionLink"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    onChange([
                      start
                        ? convertSelectedDateToString(start, dateFormat)
                        : '',
                      end ? convertSelectedDateToString(end, dateFormat) : '',
                    ])
                    pickerRef.current?.setOpen(false)
                  }}
                  size="large"
                >
                  Apply
                </Button>
              </div>
            </>
          )}
        </ReactDatePicker>
      </div>
    )
  }
}

export default styled((props: Props) => <DatePicker {...props} />)(
  ({ selectsRange }) => ({
    '&&&.mobile-date-picker': {
      position: 'absolute',
      width: '100% !important',
      overflow: 'hidden',

      '.react-datepicker-popper': {
        width: '100vw',
        marginTop: 0,
        transform: 'none !important',
        paddingTop: 0,
      },

      '.react-datepicker__month-container': {
        paddingTop: 230,
        marginLeft: 'auto',
        marginRight: 'auto',
      },

      '.react-datepicker__tab-loop': {
        '.react-datepicker': {
          borderRadius: 0,
          height: '100vh',
          boxShadow: 'none',

          '.react-datepicker__navigation--previous': {
            top: 258,
            left: 52,
          },

          '.react-datepicker__navigation--next': {
            top: 258,
            right: 57,
          },

          '.left-panel': {
            borderRadius: 0,
            flexDirection: 'column',

            '& > div:first-of-type': {
              display: 'flex',
              justifyContent: 'flex-end',
            },
          },

          '.datepicker-ctas': {
            width: '87%',
            flexDirection: 'column',
            top: 600,

            'button:first-of-type': {
              order: 2,
              padding: '24px 0',
            },

            '.cancel': {
              marginRight: 0,
            },
          },
        },
      },
    },

    '&&&.tablet-date-picker': {
      '.react-datepicker__month-container': {
        paddingTop: selectsRange ? 140 : 0,
      },

      '.react-datepicker__tab-loop': {
        '.react-datepicker': {
          '.react-datepicker__navigation--previous': {
            top: selectsRange ? 167 : undefined,
            left: 22,
          },

          '.react-datepicker__navigation--next': {
            top: selectsRange ? 167 : undefined,
            right: 32,
          },
        },
      },
    },

    '&&&.tablet-date-picker, &&&.mobile-date-picker': {
      '.react-datepicker__tab-loop': {
        '.react-datepicker': {
          ...(selectsRange && {
            paddingLeft: 0,
          }),

          '.left-panel': {
            height: 'auto',
            width: '100%',
            display: 'flex',
            gap: 8,
          },

          '.left-panel>div': {
            width: '100%',
          },
        },
      },
    },

    '&&&:not(.mobile-date-picker)': {
      position: 'relative',
    },

    '&&&': {
      '.react-datepicker-wrapper': {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        height: '100%',
        width: '100%',
        zIndex: -2,

        '.react-datepicker__input-container': {
          height: '100%',

          input: {
            height: '100%',
            width: '100%',
          },
        },
      },

      // native react-datepicker classes
      '.react-datepicker__children-container': {
        // Items set via absolute positioning but want to make this not take up space
        width: 0,
        padding: 0,
        margin: 0,
      },

      '.react-datepicker__day-names': {
        marginTop: 20,
      },

      // We don't want to show the datepicker triangle
      '.react-datepicker__triangle': {
        display: 'none',
      },

      '.react-datepicker-popper': {
        zIndex: 3,
      },

      '.react-datepicker__tab-loop': {
        div: {
          ...Fonts.bodyMd,
          color: Colors.black,
          fontWeight: FontWeight.MEDIUM,
        },

        '.react-datepicker': {
          border: 'none',
          borderRadius: 8,
          display: 'flex',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',

          ...(selectsRange && {
            paddingLeft: 240,
            paddingBottom: 120,
          }),

          '.react-datepicker__header': {
            backgroundColor: Colors.white,
            border: 'none',
            marginTop: 20,
          },

          '.react-datepicker__current-month': {
            ...Fonts.h2,
            marginBottom: 24,
          },

          '.react-datepicker__month': {
            margin: '0 20px 10px',
          },

          '.react-datepicker__day': {
            width: 45,
            height: 34,
            margin: '2px 0',
            paddingTop: 5,

            '&:empty': {
              height: 0,
              padding: 0,
            },
          },

          '.react-datepicker__day--outside-month': {
            color: Colors.mediumGray,
          },

          '.react-datepicker__day-name': {
            ...Fonts.eyebrow,
            color: Colors.mediumGray,
            width: 35,
            margin: '0 5px',
          },

          '.react-datepicker__day--keyboard-selected': {
            backgroundColor: Colors.white,
          },

          '.react-datepicker__navigation--previous': {
            top: 25,
            left: selectsRange ? 265 : 22,
          },

          '.react-datepicker__navigation--next': {
            top: 25,
            right: 32,
          },

          '.react-datepicker__navigation-icon--previous::before,.react-datepicker__navigation-icon--next::before':
            {
              borderColor: Colors.black,
              height: 8,
              width: 8,
              borderTopWidth: 1,
              borderRightWidth: 1,
            },

          '.react-datepicker__day--today': {
            boxShadow: `0px 0px 0px 1px ${Colors.green} inset`,
            borderRadius: 8,
            color: Colors.green,
          },

          '.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range':
            {
              backgroundColor: Colors.moss,
              borderRadius: 0,
            },

          '.react-datepicker__day--disabled': {
            backgroundColor: Colors.gray,
            color: Colors.black,
          },

          // This needs to be below "today" so it takes precedence
          '.react-datepicker__day--selected, .react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end, .react-datepicker__day--range-start, .react-datepicker__day--range-end':
            {
              backgroundColor: Colors.green,
              color: Colors.white,
            },

          '.react-datepicker__day--selecting-range-start, .react-datepicker__day--range-start':
            {
              borderRadius: '8px 0 0 8px',
            },

          '.react-datepicker__day--range-end': {
            borderRadius: '0 8px 8px 0',
          },

          '.react-datepicker__day--selected, .react-datepicker__day--range-end.react-datepicker__day--range-start':
            {
              borderRadius: 8,
            },

          '.left-panel': {
            backgroundColor: Colors.stone,
            borderBottomLeftRadius: 8,
            borderTopLeftRadius: 8,
            height: '100%',
            left: 0,
            padding: 24,
            position: 'absolute',
            width: 240,
          },

          '.datepicker-ctas': {
            display: 'flex',
            position: 'absolute',
            right: 24,
            bottom: 24,

            '.cancel': {
              marginRight: 40,
            },
          },
        },
      },

      // For year picker
      '.react-datepicker__year--container': {
        width: 355,
      },

      '.react-datepicker__year-wrapper': {
        maxWidth: 'initial',
      },

      '.react-datepicker__year-text': {
        border: `solid 1px ${Colors.darkGray}`,
        borderRadius: 8,
        padding: '10px 11px',
        width: 100,
        marginLeft: 10,
        marginBottom: 6,

        '&.react-datepicker__year-text--selected': {
          borderColor: Colors.transparent,
          backgroundColor: Colors.green,
          color: Colors.white,
        },

        '&.react-datepicker__year-text--disabled': {
          backgroundColor: Colors.gray,
          color: Colors.black,
        },
      },
    },

    '.dateInput .icon.input i.close.icon.icon-clear': {
      top: '0 !important',
    },
  })
)

export type { Props as DatePickerProps }
