import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Image, List } from 'semantic-ui-react'

import {
  Alert,
  Card,
  GridRowColumn,
  Text,
} from '../../../../../components/BaseComponents'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../utils/sharedHooks'
import { selectUserTransactionsByCategoryIdentifierForYear } from '../../../../Transactions/transactions.selectors'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { UpdateYourBooksStepProps } from './UpdateYourBooks'
import TransactionHeader from './TransactionHeader'
import TransactionCard from './TransactionCard'
import { TransactionCategoryIdentifier } from '../../../../../reducers/admin/allTransactions.slice'
import { selectAnnualTaxFilingFormById } from '../../annualTaxFilingForms.selector'
import { SubStepIdentifiers } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'

const OtherIncomeStep = ({ goForward, goBack }: UpdateYourBooksStepProps) => {
  const { formId } = useParams()
  const filingForm = useReselector(selectAnnualTaxFilingFormById, formId)

  const otherIncomeTransactionsInState = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.other_income,
    filingForm?.year
  )
  const [otherIncomeTransactionsIds, setOtherIncomeTransactionsIds] = useState<
    number[]
  >([])

  // Finds ids that are no longer in state (because they were updated)
  const updatedIds = useMemo(
    () =>
      otherIncomeTransactionsIds.filter(
        (id) => !otherIncomeTransactionsInState.map((t) => t.id).includes(id)
      ),
    [otherIncomeTransactionsIds, otherIncomeTransactionsInState]
  )

  useEffect(() => {
    // Still want to display accounts after category updates,
    // so don't update the otherIncomeTransactions list that was initially loaded
    if (
      otherIncomeTransactionsInState.length &&
      !otherIncomeTransactionsIds.length
    ) {
      setOtherIncomeTransactionsIds(
        otherIncomeTransactionsInState.map((t) => t.id)
      )
    }
  }, [otherIncomeTransactionsIds.length, otherIncomeTransactionsInState])

  const hasUpdatedCategory = (id: number) =>
    Boolean(updatedIds.find((uId) => uId === id))

  const saveAndContinue = useAsyncCallback(() =>
    goForward({ completedSteps: [SubStepIdentifiers.categorizeOtherIncome] })
  )

  return (
    <Grid>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/payroll.svg"
          style={{ width: 120, height: 120 }}
        />
      </GridRowColumn>
      <GridRowColumn width={10} spacer={3}>
        <Text as="h1" textAlign="center">
          We saw that you have some transactions categorized as “Other Income”
        </Text>
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column width={5} />
        <Grid.Column width={3}>
          <List bulleted>
            <List.Item>Consulting Income</List.Item>
            <List.Item>Retail Income</List.Item>
            <List.Item>Speaking Income</List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={3}>
          <List bulleted>
            <List.Item>Supervision Income</List.Item>
            <List.Item>Therapy Income</List.Item>
            <List.Item>Teaching Income</List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn width={10} spacer={3}>
        <Text>
          If a transaction doesn’t fit in any of the above categories, add a
          note describing where the income came from.
        </Text>
      </GridRowColumn>
      <Grid.Row />
      <TransactionHeader />
      <GridRowColumn short>
        {otherIncomeTransactionsIds.map((tId) => (
          <TransactionCard
            key={tId}
            transactionId={tId}
            backgroundColor={hasUpdatedCategory(tId) ? 'lightGreen' : undefined}
          />
        ))}
      </GridRowColumn>
      {otherIncomeTransactionsIds?.length === 0 && (
        <GridRowColumn width={10} spacer={3}>
          <Card backgroundColor="lightGreen">
            <Text>
              All transactions have been recategorized. You may continue to the
              next step.
            </Text>
          </Card>
        </GridRowColumn>
      )}
      <GridRowColumn width={10} spacer={3}>
        <Alert title="Why are we asking this?">
          This step helps us provide the tax team with as much context as
          possible.
        </Alert>
      </GridRowColumn>
      <FormFlowFooter
        onBack={goBack}
        onForward={saveAndContinue.callback}
        loading={saveAndContinue.loading}
        continueDisabled={saveAndContinue.loading}
      />
    </Grid>
  )
}

export default OtherIncomeStep
