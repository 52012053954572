import { createSelector } from 'reselect'

import { ReduxState } from '../../../utils/typeHelpers'
import {
  ANNUAL_TAX_FILING_FORM_TYPES,
  FORM_1040_TYPE,
  FORM_1120_S_TYPE,
} from './Questionnaires/constants'
import {
  AnnualTaxFilingForm,
  FormFilingStatus,
  TaxfyleJobStatus,
} from './annualTaxFilingForms.slice'
import { selectCurrentAnnualTaxYear } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'

export const getAnnualTaxFilingForms = (state: ReduxState) =>
  state.annualTaxFilingForms

export const selectAnnualTaxFilingFormById = createSelector(
  getAnnualTaxFilingForms,
  (_: unknown, id: number | undefined | string) => id,
  (forms, id) => (id && forms[id] ? forms[id] : null)
)

//This is a list of user IDs that should see the TQ, even though they have signed up (became paying) after the cutoff date
// E.g. users who created their account during 2022 but ended up paying for Heard in 2023
// Below values are from the following query:
// SELECT
// 	users.id
// FROM
// 	users
// 	JOIN memberships ON memberships.user_id = users.id
// WHERE
// 	users.created_at < '2022-12-31 23:59:59+00' and start_date > '2022-12-31 23:59:59+00'
//  and memberships.membership_type = 'finances' and memberships.membership_status in ('paid');
//
//
// const USER_IDS_ALLOWED_FOR_TQ_POST_CUTOFF: number[] = [
//   736, 744, 862, 643, 979, 789, 3705, 3696, 3721, 3751, 739, 3115, 678, 3588,
//   982, 975, 898, 902, 3319,
// ]

// {return false}
// // If a prior year and there was no tax details this user was disqualified
// year !== currentTaxYear
//   ? isNil(annualTaxDetails)
//   : // If current year check if they fall in the cutoff or are exception
//     !isNil(user) &&
//     moment(user.createdAt).isAfter(
//       moment.utc(annualTaxDetails?.newUserCutOffAt).endOf('day')
//     ) &&
//     // This allows us to hardcode users who we are honoring, despite signing up after cut off date
//     !USER_IDS_ALLOWED_FOR_TQ_POST_CUTOFF.includes(user.id)
// )

const reorderForms = (forms: AnnualTaxFilingForm[]) => {
  /* Scorp first */
  const form1120s = forms.filter(
    (form) => form.formType?.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s
  )
  const otherForms = forms.filter(
    (form) => form.formType?.name !== ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s
  )

  return form1120s.concat(otherForms)
}

export const selectFilingFormsForYear = createSelector(
  getAnnualTaxFilingForms,
  (_: unknown, year: string | undefined) => year,
  (annualTaxFilingForms, yearToSearch) =>
    Object.values(annualTaxFilingForms).filter(
      ({ year }) => year === yearToSearch
    )
)

export const selectIsTwoFormFiler = createSelector(
  selectFilingFormsForYear,
  (forms) => forms.length > 1
)

export const getExtendedTaxFormsForYear = createSelector(
  getAnnualTaxFilingForms,
  (_: unknown, year: string) => year,
  (annualTaxFilingForms, yearToSearch) =>
    Object.values(annualTaxFilingForms).filter(
      ({ year, isExtended }) => year === yearToSearch && isExtended
    )
)

export const selectHasTwoExtendedForms = createSelector(
  getExtendedTaxFormsForYear,
  (forms) => forms.length > 1
)

export const getAnnualTaxFormsForFilingSelector = createSelector(
  [
    getAnnualTaxFilingForms,
    (_: unknown, annualTaxFilingId: number | undefined) => annualTaxFilingId,
  ],
  (annualTaxFilingForms, annualTaxFilingId) => {
    if (!annualTaxFilingForms) {
      return []
    }
    let forms = Object.keys(annualTaxFilingForms)
      .filter(
        (key) =>
          annualTaxFilingForms[key].annualTaxFilingId === annualTaxFilingId
      )
      .map((key) => annualTaxFilingForms[key])
    forms = reorderForms(forms)
    return forms || []
  }
)

export const selectFormIdByFormTypeAndYear = createSelector(
  getAnnualTaxFilingForms,
  (_: unknown, props: { formType: string | undefined; year: string }) => props,
  (taxFilingForms, { formType, year }) => {
    const { id, formTypeId } =
      Object.values(taxFilingForms).find(
        (form) => form.formType.name === formType && form.year === year
      ) ?? {}

    return {
      filingFormId: id,
      taxFilingFormTypeId: formTypeId,
    }
  }
)

export const selectIncompleteForms = createSelector(
  getAnnualTaxFormsForFilingSelector,
  (taxForms) => {
    const year = Number(taxForms[0]?.year)

    // For previous years use filing status
    if (year < 2023) {
      return taxForms.filter(
        ({ formFilingStatus }) =>
          formFilingStatus !== FormFilingStatus.complete &&
          formFilingStatus !== FormFilingStatus.complete_no_payment
      )
    } else {
      return taxForms.filter(
        ({ taxfyleJob }) => taxfyleJob?.jobStatus !== TaxfyleJobStatus.completed
      )
    }
  }
)

export const selectAllFilingFormsComplete = createSelector(
  getAnnualTaxFormsForFilingSelector,
  selectIncompleteForms,
  (taxForms, incompleteForms) =>
    taxForms.length > 0 && incompleteForms.length === 0
)

const findFormByType = (
  taxFilingForms: AnnualTaxFilingForm[],
  formName: string
) => taxFilingForms.find(({ formType }) => formType.name === formName)

export const select1040FormForYear = createSelector(
  selectFilingFormsForYear,
  (forms) => findFormByType(forms, FORM_1040_TYPE)
)

export const select1120sFormForYear = createSelector(
  selectFilingFormsForYear,
  (forms) => findFormByType(forms, FORM_1120_S_TYPE)
)

export const selectUserNeeds1040And1120sForms = createSelector(
  select1040FormForYear,
  select1120sFormForYear,
  (form1040, form1120s) => Boolean(form1040 && form1120s)
)

export const selectIsFiling1120s = createSelector(
  selectCurrentAnnualTaxYear,
  getAnnualTaxFilingForms,
  (year, forms) => {
    return Object.values(forms).some(
      (form) => form.year === year && form.formType.name === FORM_1120_S_TYPE
    )
  }
)

export const selectIsFiling1120sForYear = createSelector(
  selectFilingFormsForYear,
  (forms) => {
    return forms.some((form) => form.formType.name === FORM_1120_S_TYPE)
  }
)
