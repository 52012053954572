import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Container,
  Grid,
  Header,
  Modal,
  Table,
  Form,
} from 'semantic-ui-react'
import {
  createCalendlyLink,
  fetchCalendlyLinks,
  updateCalendlyLink,
} from '../../../actions/admin/userCallEventsActions'
import { createYearDropdownValues, QUARTER_DROPDOWN_OPTIONS } from '../helpers'
import { CalendlyLink } from '../../../reducers/calendlyLink.slice'
import { getUserId } from '../../../reducers/auth/user.selectors'
import {
  getCalendlyLinkById,
  getCalendlyLinkIds,
} from '../../../selectors/userCallEventSelectors'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useReselector } from '../../../utils/sharedHooks'

const CalendlyLinkCreateUpdateModal = ({
  close,
  calendlyLink,
}: {
  close: () => void
  calendlyLink?: CalendlyLink
}) => {
  const dispatch = useAppDispatch()
  const userId = useReselector(getUserId)

  const [quarter, setQuarter] = useState(calendlyLink?.quarter)
  const [quarterError, setQuarterError] = useState<string>()
  const [year, setYear] = useState(calendlyLink?.year)
  const [yearError, setYearError] = useState<string>()
  const [link, setLink] = useState(calendlyLink?.calendlyLink)
  const [linkError, setLinkError] = useState<string>()
  const [callName, setCallName] = useState(calendlyLink?.userFacingEventName)
  const [callNameError, setCallNameError] = useState<string>()
  const [description, setDescription] = useState(
    calendlyLink?.userFacingDescription
  )
  const [descriptionError, setDescriptionError] = useState<string>()

  const createEvent = useCallback(() => {
    if (!quarter || !year || !link || !callName || !description || !userId) {
      return
    }

    // If an id is sent then this is an update
    if (calendlyLink?.id) {
      dispatch(
        updateCalendlyLink(calendlyLink.id, {
          adminUserId: userId,
          quarter,
          year,
          calendlyLink: link,
          userFacingEventName: callName,
          userFacingDescription: description,
        })
      )
    } else {
      dispatch(
        createCalendlyLink({
          adminUserId: userId,
          quarter,
          year,
          calendlyLink: link,
          userFacingEventName: callName,
          userFacingDescription: description,
        })
      )
    }

    close()
  }, [
    calendlyLink?.id,
    callName,
    close,
    description,
    dispatch,
    link,
    quarter,
    userId,
    year,
  ])

  const yearDropdownValues = useMemo(() => createYearDropdownValues(), [])

  return (
    <Modal open>
      <Modal.Content>
        <Form>
          <Header as={'h3'}>Create a Calendly Link</Header>
          <Form.Dropdown
            selection
            options={yearDropdownValues}
            defaultValue={year}
            label={'Year:'}
            required
            onChange={(_, props) => {
              if (!props.value || typeof props.value !== 'string') {
                setYearError('Required')
              } else {
                setYear(props.value)
                setYearError(undefined)
              }
            }}
            error={yearError}
          />
          <Form.Dropdown
            selection
            options={QUARTER_DROPDOWN_OPTIONS}
            defaultValue={quarter}
            label={'Quarter:'}
            required
            onChange={(_, props) => {
              if (
                !props.value ||
                typeof props.value !== 'string' ||
                !['1', '2', '3', '4'].includes(props.value)
              ) {
                setQuarterError('Required')
              } else {
                setQuarter(props.value as '1' | '2' | '3' | '4')
                setQuarterError(undefined)
              }
            }}
            error={quarterError}
          />
          <Form.Input
            placeholder="i.e. https://calendly.com/12312421"
            defaultValue={link}
            label={'Calendly Link:'}
            required
            onChange={(_, props) => {
              if (!props.value) {
                setLinkError('Required')
              } else if (!props.value.includes('https://calendly.com/')) {
                setLinkError('Not a valid Calendly link')
              } else {
                setLink(props.value)
                setLinkError(undefined)
              }
            }}
            error={linkError}
          />
          <Form.Input
            placeholder="i.e. Q1 Checkin"
            defaultValue={callName}
            label={'Call Name (User Facing):'}
            required
            onChange={(_, props) => {
              if (!props.value) {
                setCallNameError('Required')
              } else {
                setCallName(props.value)
                setCallNameError(undefined)
              }
            }}
            error={callNameError}
          />
          <Form.Input
            placeholder="one to two sentences description"
            defaultValue={description}
            label={'Call Description(User Facing):'}
            required
            onChange={(_, props) => {
              if (!props.value) {
                setDescriptionError('Required')
              } else {
                setDescription(props.value)
                setDescriptionError(undefined)
              }
            }}
            error={descriptionError}
          />
          <Form.Group>
            <Form.Button basic onClick={close}>
              Cancel
            </Form.Button>
            <Form.Button basic onClick={createEvent}>
              {calendlyLink?.id ? 'Update' : 'Create'}
            </Form.Button>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  )
}

const CalendlyRow = ({ id }: { id: number }) => {
  const calendlyLink = useReselector(getCalendlyLinkById, id)

  const [open, setIsOpen] = useState(false)

  if (!calendlyLink) {
    return null
  }

  const { quarter, year, calendlyLink: url } = calendlyLink

  return (
    <Table.Row>
      <Table.Cell>{year}</Table.Cell>
      <Table.Cell>Q{quarter}</Table.Cell>
      <Table.Cell>
        <a href={url}>{url}</a>
      </Table.Cell>
      <Table.Cell>
        <Button className={'link'} onClick={() => setIsOpen(true)}>
          update
        </Button>
      </Table.Cell>
      {open && (
        <CalendlyLinkCreateUpdateModal
          close={() => setIsOpen(false)}
          calendlyLink={calendlyLink}
        />
      )}
    </Table.Row>
  )
}

const CalendlyLinksCard = () => {
  const dispatch = useAppDispatch()
  const linkIds = useReselector(getCalendlyLinkIds)
  const [open, setIsOpen] = useState(false)

  useEffect(() => {
    dispatch(fetchCalendlyLinks())
  }, [dispatch])

  return (
    <Container className={'backdrop'}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={12} verticalAlign={'bottom'}>
            <Header as={'h4'}>Quarterly Checkins Calendly Links</Header>
          </Grid.Column>
          <Grid.Column width={4}>
            <Button basic onClick={() => setIsOpen(true)}>
              Create a Calendly Link
            </Button>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Year</Table.HeaderCell>
                <Table.HeaderCell>Quarter</Table.HeaderCell>
                <Table.HeaderCell>Your Calendly Link</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {linkIds.map((id) => (
                <CalendlyRow id={id} key={id} />
              ))}
            </Table.Body>
          </Table>
        </Grid.Row>
      </Grid>
      {open && <CalendlyLinkCreateUpdateModal close={() => setIsOpen(false)} />}
    </Container>
  )
}

export default CalendlyLinksCard
