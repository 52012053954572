import { useEffect, useState } from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import {
  Alert,
  GridRowColumn,
  Link,
  Text,
  DatePicker,
} from '../../BaseComponents'
import { ArticleCard } from '../shared/ArticleCard'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import {
  ARTICLE_TYPE,
  FinancialInsightsButtonType,
  INSIGHTS_TYPE,
} from '../shared/utils'
import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import {
  useReselector,
  useToggle,
  useTrackFinancialInsightsButtonClick,
} from '../../../utils/sharedHooks'
import PageHeader from '../../shared/PageHeader'
import { useAppDispatch } from '../../../utils/typeHelpers'
import InsightsBreakdownTable from '../shared/InsightsBreakdownTable'
import { CurrentPanel } from '../shared/CurrentPanel'
import { UncategorizedTransactionBanner } from '../shared/UncategorizedTransactionBanner'
import { isEmpty } from 'lodash'
import { selectActivePlaidFinancialAccounts } from '../../../selectors/financeSelectors'
import { TrackInsightsSection } from '../shared/TrackInsightsSection'
import { FEATURE_FLAG_KEYS } from '../../../features/OpenFeature'
import { BannerCard } from '../../BaseComponents/BannerCard'

const InsightsIncomePanel = () => {
  const dispatch = useAppDispatch()
  const [displayBanner, toggleBanner] = useToggle(true)
  const [inputDate, setInputDate] = useState('')

  const trackInsights = useTrackFinancialInsightsButtonClick()

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const shouldDisplayDatePicker = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.insightsDatePicker,
    false
  )

  useEffect(() => {
    dispatch(fetchFinancialAccountsIfNeeded())
  }, [dispatch])

  const linkedPlaidAccounts = useReselector(selectActivePlaidFinancialAccounts)
  const hasLinkedPlaidAccounts = !isEmpty(linkedPlaidAccounts)

  const closeBanner = () => {
    toggleBanner()
    trackInsights({
      button: FinancialInsightsButtonType.CLOSE_BANNER,
      insightsType: INSIGHTS_TYPE.INCOME,
    })
  }

  return (
    <>
      <PageHeader header="Income" />
      {shouldDisplayDatePicker && (
        <div style={{ position: 'absolute', top: 20, right: 20 }}>
          <DatePicker value={inputDate} onChange={setInputDate} clearable />
        </div>
      )}
      <Grid padded={isMobile}>
        <GridRowColumn short />
        {displayBanner && (
          <GridRowColumn>
            <BannerCard
              headerText="What is Income?"
              imageUrl="https://heard-images.s3.amazonaws.com/assets/pavilion.svg"
              onClose={closeBanner}
              bodyContent="Income is the money you receive in exchange for your services.
                  If you have other streams of income deposited into the linked
                  accounts, you can see it all here in one place."
            />
          </GridRowColumn>
        )}
        {!hasLinkedPlaidAccounts && (
          <GridRowColumn>
            <Alert>
              <Text>
                You do not have a connected bank account.{' '}
                <Link to={'/accounts#connected-institutions'}>
                  Link Business Account
                </Link>
              </Text>
            </Alert>
          </GridRowColumn>
        )}
        <UncategorizedTransactionBanner inputDate={inputDate} />
        <CurrentPanel
          insightsType={INSIGHTS_TYPE.INCOME}
          inputDate={inputDate}
        />
        <TrackInsightsSection
          type={INSIGHTS_TYPE.INCOME}
          inputDate={inputDate}
        />
        <GridRowColumn>
          <Text as="h2">Income Breakdown</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text color="darkGray">
            See transactions and monitor each stream of income
          </Text>
        </GridRowColumn>
        <InsightsBreakdownTable
          insightsType={INSIGHTS_TYPE.INCOME}
          inputDate={inputDate}
        />
        <GridRowColumn>
          <Text as="h2">Learn More</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text color="darkGray">
            Learn more about how to earn more income.
          </Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Divider style={{ padding: 0, margin: 0 }} />
        </GridRowColumn>
        <Grid.Row className="short" columns={isMobile ? 1 : 3}>
          <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
            <ArticleCard
              title="The Complete List of Income Streams for Therapists"
              type={ARTICLE_TYPE.ARTICLE}
              url="https://www.joinheard.com/articles/the-complete-list-of-income-streams-for-therapists"
              insightsType={INSIGHTS_TYPE.INCOME}
            />
          </Grid.Column>
          <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
            <ArticleCard
              title="How to Set Your Fees in Private Practice (Plus 3 Mistakes to Avoid)"
              type={ARTICLE_TYPE.ARTICLE}
              url="https://www.joinheard.com/articles/how-to-set-your-fees-in-private-practice-plus-3-mistakes-to-avoid"
              insightsType={INSIGHTS_TYPE.INCOME}
            />
          </Grid.Column>
          <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
            <ArticleCard
              title="Should Therapists Have a Separate Business for Other Income Streams?"
              type={ARTICLE_TYPE.ARTICLE}
              url="https://www.joinheard.com/articles/should-therapists-have-a-separate-business-for-other-income-streams"
              insightsType={INSIGHTS_TYPE.INCOME}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default InsightsIncomePanel
