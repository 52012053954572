import { Grid, Image } from 'semantic-ui-react'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { selectFederalEstimateByTaxQuarter } from '../../userTaxEstimates.selector'
import {
  Button,
  GridRowColumn,
  Link,
  Text,
} from '../../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../../components/BaseComponents/Grid'
import { useNavigate } from 'react-router-dom'
import useTaxPaymentInfoFlyout from '../TaxPaymentInfoFlyout'
import { useState } from 'react'
import UpdateQuarterlyTaxEstimateModal from '../UpdateQuarterlyTaxEstimateModal'

const FinishCalculateSafeHarbor = () => {
  const navigate = useNavigate()

  const activeQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )

  const federalTaxEstimate = useReselector(
    selectFederalEstimateByTaxQuarter,
    `${activeQuarterDetails?.taxYear}-${activeQuarterDetails?.taxQuarter}`
  )

  const [estimateModalOpen, setEstimateModalOpen] = useState(false)
  const openFlyout = useTaxPaymentInfoFlyout({
    estimateId: federalTaxEstimate?.id,
    openQTEModal: () => setEstimateModalOpen(true),
  })

  return (
    <Grid>
      <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
        <Text as="display2" textAlign="center">
          You&apos;re done!
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
        <Text textAlign="center">
          Now you can either proceed to payment (we&apos;ll walk you through how
          to do this), or if you&apos;re not ready yet, you can go back to the
          Quarterly Tax Center.
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Text textAlign="center">
          Made a mistake? Go back and{' '}
          <Link to="/taxes/quarterly/calculate-safe-harbor" as="secondaryLink">
            edit
          </Link>
        </Text>
      </GridRowColumn>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/calculator2.svg"
          style={{ height: 180, width: 180 }}
        />
      </GridRowColumn>
      {federalTaxEstimate && (
        <GridRowColumn
          centerContent
          {...makeGridConfig([4, 12, 16], true)}
          short
        >
          <Button fullWidth onClick={() => openFlyout()}>
            Pay Now
          </Button>
        </GridRowColumn>
      )}
      <GridRowColumn centerContent {...makeGridConfig([4, 12, 16], true)} short>
        <Button
          variant="secondary"
          onClick={() => navigate('/taxes/quarterly')}
          fullWidth
        >
          Return to Quarterly Taxes
        </Button>
      </GridRowColumn>
      {federalTaxEstimate && (
        <UpdateQuarterlyTaxEstimateModal
          open={estimateModalOpen}
          close={() => setEstimateModalOpen(false)}
          estimate={federalTaxEstimate}
        />
      )}
    </Grid>
  )
}

export default FinishCalculateSafeHarbor
