import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { FormikProvider, useFormik } from 'formik'
import { Divider, List } from 'semantic-ui-react'

import { UploadSuccessPayload } from './Module'
import LockedBooksMessage from '../LockedBooksMessage'
import { createSingleManualTransaction } from '../transactions.slice'
import { ManualTransactionModel } from '../../../reducers/admin/allTransactions.slice'
import { getUserBooksLockedForUserYear } from '../../../reducers/auth/user.selectors'
import { DATE_FORMATS, DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { UPLOAD_COPY } from '../copyConstants'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  Alert,
  Button,
  getFieldName,
  FormikDateInput,
  FormikInput,
  FormikTextArea,
  makeDateSchema,
  makeNumberSchema,
  makeReqStringSchema,
  Text,
} from '../../../components/BaseComponents'
import FileUploadButton from '../../../components/FileUpload/FileUploadButton'
import { UploadDocumentType } from '../../../constants/businessConstants'
import { UploadedFile } from '../../../components/FileUpload/FileUploadModal'
import { destroySingleUserDocument } from '../../UserDocuments/userDocuments.slice'
import { DisplayFile } from '../../Taxes/AnnualTaxes/TaxChecklist/UpdateYourBooks/TransactionReceiptsPanel'

export interface AddTransactionProps {
  onUploadComplete?: (_payload: {
    success: boolean
    data?: UploadSuccessPayload[]
  }) => void
  closeFlyout: () => void
  transactionType: string
}

export enum TransactionTypes {
  INCOME = 'Income',
  EXPENSE = 'Expense',
  DEFAULT = 'Transaction',
}

const AddTransaction = ({
  transactionType = TransactionTypes.DEFAULT,
  onUploadComplete,
  closeFlyout,
}: AddTransactionProps) => {
  const [uploadedFile, setUploadedFile] = useState<UploadedFile>()
  const [error, setError] = useState('')
  const currentDate = DateTime.local().toFormat(DATE_FORMATS_LUXON.INPUT)
  const booksLockedForUserYear = useReselector(getUserBooksLockedForUserYear)
  const dispatch = useAppDispatch()
  const priorYear = DateTime.local().minus({ years: 1 }).year
  const currentYear = DateTime.local().year
  const minDate = DateTime.fromISO(`${priorYear}-01-01`).toISODate()

  const deleteFile = async (fileId: number) => {
    await destroySingleUserDocument(fileId)(dispatch)
  }

  const formik = useFormik({
    initialValues: {
      date: currentDate,
      amountInDollars:
        transactionType === TransactionTypes.INCOME ? '0.00' : '-0.00',
      description: '',
      receipt: null as UploadedFile | null,
    },
    enableReinitialize: true,
    onSubmit: async ({ amountInDollars, date, description, receipt }) => {
      const manualTransaction: Omit<ManualTransactionModel, 'receipts'> & {
        receipt?: UploadedFile | null
      } = {
        amountInDollars,
        date,
        description: description.trim(),
        receipt,
      }

      try {
        const result =
          await createSingleManualTransaction(manualTransaction)(dispatch)

        if (result) {
          onUploadComplete?.({
            success: true,
            data: [{ ...manualTransaction, id: result?.id }],
          })
        } else {
          setError(UPLOAD_COPY.SINGLE_TRANSACTION_ERROR_MESSAGE)
          return
        }

        closeFlyout()
      } catch (error) {
        console.error(error)
      }
    },
  })

  const { isSubmitting, isValid, submitForm, setFieldValue } = formik

  useEffect(() => {
    setError('')
  }, [formik.values])

  return (
    <FormikProvider value={formik}>
      {booksLockedForUserYear && (
        <LockedBooksMessage year={booksLockedForUserYear} />
      )}

      <FormikDateInput
        className="padded"
        clearable
        required
        fullWidth
        label="Date"
        minDate={new Date(priorYear, 0, 1)}
        maxDate={new Date()}
        placeholder={DATE_FORMATS.INPUT}
        name={getFieldName<typeof formik.values>('date')}
        schema={makeDateSchema({
          field: 'this transaction',
          minDate: minDate || '',
          maxDate: DateTime.now().toISO(),
          rangeError: `You can only add transactions from ${priorYear}-${currentYear}.`,
        })}
      />

      <FormikInput
        required
        fullWidth
        className="padded"
        name={getFieldName<typeof formik.values>('amountInDollars')}
        label={`${transactionType} Amount`}
        componentType="currency"
        onChangeCallback={(value) => {
          if (transactionType === TransactionTypes.EXPENSE) {
            if (value === '' || value === '0.00') {
              formik.setFieldValue('amountInDollars', '-0.00')
            }
            if (Number(value) > 0) {
              formik.setFieldValue('amountInDollars', `-${value}`)
            }
          } else {
            if (value === '' || value === '-0.00') {
              formik.setFieldValue('amountInDollars', '0.00')
            }
            if (value && Number(value) < 0) {
              formik.setFieldValue('amountInDollars', Number(value) * -1)
            }
          }
        }}
        schema={makeNumberSchema({
          field: transactionType,
          allowedDecimals: 2,
          transactionNotZero: true,
          allowNegative: transactionType === TransactionTypes.EXPENSE,
          maxNumber:
            transactionType === TransactionTypes.EXPENSE ? -0.01 : undefined,
          minNumber:
            transactionType === TransactionTypes.INCOME ? 0.01 : undefined,
        })}
      />

      <FormikTextArea
        required
        label="Description"
        name={getFieldName<typeof formik.values>('description')}
        placeholder="e.g. USPS Withdrawal"
        schema={makeReqStringSchema({
          field: 'description',
        })}
      />

      {transactionType === TransactionTypes.EXPENSE && (
        <>
          <Text as="h3">Receipt</Text>
          {uploadedFile && (
            <List>
              <DisplayFile
                key={uploadedFile.id}
                receipt={uploadedFile}
                deleteFile={deleteFile}
              />
            </List>
          )}
          <FileUploadButton
            buttonText="Upload"
            userFacing
            documentType={UploadDocumentType.RECEIPT}
            setUploadedFile={(file) => {
              setUploadedFile(file)
              setFieldValue(getFieldName<typeof formik.values>('receipt'), file)
            }}
          />
        </>
      )}

      {error && (
        <Alert type="error" key={error}>
          {error}
        </Alert>
      )}

      <Divider />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          style={{ margin: '16px 16px 16px 0' }}
          variant="actionLink"
          onClick={() => closeFlyout()}
        >
          Cancel
        </Button>

        <Button
          style={{ margin: '16px 0' }}
          onClick={submitForm}
          disabled={!isValid || isSubmitting}
          aria-disabled={!isValid || isSubmitting}
          loading={isSubmitting}
        >
          Add {transactionType}
        </Button>
      </div>
    </FormikProvider>
  )
}

export default AddTransaction
