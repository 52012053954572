import { useMemo, useEffect, useState, useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SetupCard } from '../SetupCard'
import { IntroPage } from './IntroPage'
import { LBAIntroPage } from './LBAIntroPage'
import { LBAInterestConfirmationPage } from './LBAInterestConfirmationPage'
import { NoLBAIntroPage } from './NoLBAIntroPage'
import { PlaidIntroPage } from './PlaidIntroPage'
import {
  StatementStatus,
  fetchFinancialAccountsIfNeeded,
  getAccountUploadStatuses,
  ACCOUNT_UPLOAD_STATUSES_KEY,
} from '../../../actions/financialAccountActions'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { fetchUserDocuments } from '../../UserDocuments/userDocuments.slice'
import { selectUserStatements } from '../../UserDocuments/userDocuments.selector'
import { fetchPlaidItemsIfNeeded } from '../../../actions/plaidItemActions'
import { useReselector, useToggle } from '../../../utils/sharedHooks'
import { selectPlaidItems } from '../../../selectors/financeSelectors'
import { NoLBAInstitutionCard } from './NoLBAInstitutionCard'
import { ReviewStatements } from './ReviewStatements'
import { OnboardingTaskIdents } from '../../Onboarding/config'
import { UploadPrevMonthStatementStatus } from '../../Dashboard/UserActionItems/userActionItemStatuses'
import { markUserActionItemCompleteIfExists } from '../../Dashboard/UserActionItems/service'
import { useAnalyticsTrack, useAnalyticsView } from '../../Amplitude'
import { SkipUploadStatementsModal } from './SkipUploadStatementsModal'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { updateFinancialProfile } from '../../../actions/financialProfileActions'
import { isNil } from 'lodash'
import { getIsFetching } from '../../../reducers/fetch'

const SKIPPABLE_STEPS = ['unsupportedInstitutions', 'noLbaIntro']

export const UploadStatementsStage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()
  const pageView = useAnalyticsView()
  const [searchParams] = useSearchParams()

  const [showSkipButton, setShowSkipButton] = useState(false)
  const [showSkipModal, toggleSkipModal] = useToggle(false)
  const [continueDisabledMessage, setContinueDisabledMessage] = useState('')
  const [statuses, setStatuses] = useState<StatementStatus[]>()
  const currentUser = useReselector(getCurrentUser)
  const [isInterestedInLba, setIsInterestedInLba] = useState(
    currentUser?.financialProfile?.limitedBankAccessInterest
  )

  const statements = useReselector(selectUserStatements)
  const skipUploadStatements = searchParams.get('skip') === 'true'
  const uploadStatementsActionItemStatus = skipUploadStatements
    ? UploadPrevMonthStatementStatus.skipped
    : UploadPrevMonthStatementStatus.uploaded

  const handleSaveLbaInterest = useCallback(async () => {
    if (currentUser?.financialProfile) {
      await dispatch(
        updateFinancialProfile(currentUser.financialProfile.id, {
          limitedBankAccessInterest: isInterestedInLba ? true : false,
        })
      )
    }
  }, [currentUser, isInterestedInLba, dispatch])

  useEffect(() => {
    const fetchStatuses = async () => {
      const statusesResponse = await dispatch(getAccountUploadStatuses())
      if (statusesResponse) {
        setStatuses(statusesResponse)
      }
    }
    fetchStatuses()
    dispatch(fetchUserDocuments(true))
    dispatch(fetchFinancialAccountsIfNeeded())
    dispatch(fetchPlaidItemsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    pageView('upload monthly bank statements')
  }, [pageView])

  const plaidItems = useReselector(selectPlaidItems)

  const filteredPlaidItems = useMemo(() => {
    if (!statuses) return []
    const plaidItemArray = Object.values(plaidItems)
    const filteredItems = plaidItemArray.map((item) => {
      const accounts = item.accounts.filter((account) =>
        statuses.some(
          (status) => status.id === account.id && status.status === 'no_support'
        )
      )
      return {
        ...item,
        accounts,
      }
    })
    return filteredItems.filter((item) => item.accounts.length > 0)
  }, [plaidItems, statuses])

  const uploadStatus = useMemo(() => {
    if (!statuses) return 'none'
    const allStatements = statuses.every(
      (status) => status.status === 'plaid_statement'
    )
    if (allStatements) return 'plaid_statement'
    const allLBA = statuses.every(
      (status) => status.status === 'limited_bank_access'
    )
    if (allLBA) return 'all_lba'
    const someStatementsAndLBA = statuses.every(
      (status) =>
        status.status === 'limited_bank_access' ||
        status.status === 'plaid_statement'
    )
    if (someStatementsAndLBA) return 'some_statement_lba'
    const noLBA = statuses.every((status) => status.status === 'no_support')
    if (noLBA) return 'no_lba'
    const someLBA = statuses.some(
      (status) => status.status === 'limited_bank_access'
    )
    if (someLBA) return 'some_lba'
    return 'none'
  }, [statuses])

  const step = useMemo(() => {
    return searchParams.get('step')
  }, [searchParams])

  const handleLbaIntroContinue = async () => {
    await handleSaveLbaInterest()
    navigate('/onboarding-v2?stage=statements&step=lbaInterest')
  }

  const handleNoLbaInterestContinue = async () => {
    await handleSaveLbaInterest()
    navigate('/onboarding-v2?stage=statements&step=noLbaIntro')
  }

  const handleLbaInterestWithUnsupportedInstitutionsContinue = async () => {
    await handleSaveLbaInterest()
    navigate('/onboarding-v2?stage=statements&step=unsupportedInstitutions')
  }

  const handleUnsupportedInstitutionsContinue = async () => {
    await markUserActionItemCompleteIfExists(
      OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS,
      undefined,
      uploadStatementsActionItemStatus
    )
    navigate(
      `/onboarding-v2?stage=statements&step=review&skip=${skipUploadStatements}`
    )
  }

  const handleNoLbaIntroContinue = async () => {
    await markUserActionItemCompleteIfExists(
      OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS,
      undefined,
      uploadStatementsActionItemStatus
    )
    navigate('/onboarding-v2?stage=statements&step=review')
    track('completed upload monthly bank statements page')
  }

  const handleLbaInterestContinue = () => {
    if (filteredPlaidItems.length > 0) {
      navigate('/onboarding-v2?stage=statements&step=noLbaIntro')
    } else {
      track('completed onboarding item upload statements', {
        status: 'done',
      })
      navigate('/onboarding-v2/complete')
    }
  }

  const handlePlaidAccessContinue = () => {
    track('completed onboarding item upload statements', {
      status: 'done',
    })
    navigate('/onboarding-v2/complete')
  }

  const handleReviewContinue = async () => {
    await markUserActionItemCompleteIfExists(
      OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS,
      undefined,
      uploadStatementsActionItemStatus
    )
    track('completed onboarding item upload statements', {
      status: 'done',
    })
    navigate('/onboarding-v2/complete')
  }

  const handleStartContinue = () => {
    const interested = isNil(isInterestedInLba)
      ? ''
      : `&step=${isInterestedInLba}`
    let nextStep = 'noLbaIntro'

    if (uploadStatus === 'plaid_statement') {
      nextStep = 'plaidAccess'
    } else if (uploadStatus === 'no_lba') {
      nextStep = 'noLbaIntro'
    } else if (
      uploadStatus === 'all_lba' ||
      uploadStatus === 'some_lba' ||
      uploadStatus === 'some_statement_lba'
    ) {
      nextStep = `lbaIntro${interested}`
    }

    navigate(`/onboarding-v2?stage=statements&step=${nextStep}`)
  }

  const handleContinue = () => {
    const step = searchParams.get('step')

    if (step === 'start') {
      handleStartContinue()
      track('completed statements splash page')
    }

    if (step === 'lbaIntro') {
      if (!isInterestedInLba) {
        handleNoLbaInterestContinue()
      } else {
        handleLbaIntroContinue()
      }
      track('completed lba interest page')
    }

    if (step === 'noLbaIntro') {
      handleNoLbaIntroContinue()
    }

    if (step === 'lbaInterest') {
      if (filteredPlaidItems.length > 0) {
        handleLbaInterestWithUnsupportedInstitutionsContinue()
      } else {
        handleLbaInterestContinue()
      }
      track('completed lba interst confirmation page')
    }

    if (step === 'unsupportedInstitutions') {
      handleUnsupportedInstitutionsContinue()
    }

    if (step === 'noLbaInterest') {
      handleNoLbaInterestContinue()
    }

    if (step === 'plaidAccess') {
      handlePlaidAccessContinue()
      track('completed plaid statements page')
    }

    if (step === 'review') {
      handleReviewContinue()
    }
  }

  const component = useMemo(() => {
    switch (step) {
      case 'lbaIntro':
        return (
          <LBAIntroPage
            isInterestedInLba={isInterestedInLba}
            setIsInterestedInLba={setIsInterestedInLba}
          />
        )
      case 'lbaInterest':
        return (
          <LBAInterestConfirmationPage
            filteredPlaidItems={filteredPlaidItems}
          />
        )
      case 'noLbaIntro':
        return (
          <NoLBAIntroPage
            statements={statements}
            setShowSkipButton={setShowSkipButton}
          />
        )
      case 'plaidAccess':
        return <PlaidIntroPage />
      case 'noLBAInstitution':
        return <NoLBAInstitutionCard filteredPlaidItems={filteredPlaidItems} />
      case 'review':
        return <ReviewStatements />
      case 'unsupportedInstitutions':
        return <NoLBAInstitutionCard filteredPlaidItems={filteredPlaidItems} />
      default:
        return <IntroPage />
    }
  }, [
    step,
    isInterestedInLba,
    setIsInterestedInLba,
    filteredPlaidItems,
    statements,
  ])

  const handleBack = () => {
    if (step === 'start' || step === 'plaidAccess') {
      navigate('/onboarding-v2?stage=account&step=complete')
      return
    }

    let prevStep = 'step=start'

    if (
      (step === 'noLbaIntro' && uploadStatus === 'no_lba') ||
      step === 'lbaIntro'
    ) {
      prevStep = 'step=start'
    } else if (
      (step === 'noLbaIntro' && uploadStatus === 'all_lba') ||
      uploadStatus === 'some_lba'
    ) {
      prevStep = 'step=lbaIntro&interested=false'
    } else if (step === 'lbaInterest') {
      prevStep = 'step=lbaIntro&interested=true'
    } else if (step === 'unsupportedInstitutions') {
      prevStep = 'step=lbaInterest'
    } else if (step === 'review' && filteredPlaidItems.length > 0) {
      prevStep = `step=unsupportedInstitutions&skip=${skipUploadStatements}`
    } else if (step === 'review') {
      prevStep = 'step=noLbaIntro&skip=false'
    }

    navigate(`/onboarding-v2?stage=statements&${prevStep}`)
  }

  const loadingStatuses = useReselector(
    getIsFetching,
    ACCOUNT_UPLOAD_STATUSES_KEY
  )

  const continueDisabled = useMemo(() => {
    if (step === 'lbaIntro') {
      return isNil(isInterestedInLba)
    } else if (step === 'noLbaIntro') {
      setContinueDisabledMessage('At least one statement must be uploaded')
      return statements.length === 0
    }
    return loadingStatuses
  }, [step, loadingStatuses, isInterestedInLba, statements])

  const handleSkipClick = () => {
    toggleSkipModal()
  }

  const isSkipDisabled = useMemo(() => {
    return SKIPPABLE_STEPS.includes(step || '') && statements.length === 0
  }, [step, statements])

  const finalStep = useMemo(() => {
    //if user is interested in lba but still has plaid items to review then this is not the final step
    if (step === 'lbaInterest' && filteredPlaidItems.length > 0) {
      return false
    }
    return ['lbaInterest', 'review', 'plaidAccess'].includes(step ?? '')
  }, [step, filteredPlaidItems.length])

  const completeButtonContent = useMemo(() => {
    return finalStep ? 'Complete' : ''
  }, [finalStep])

  return (
    <SetupCard
      onBackClick={handleBack}
      currentStep={finalStep ? 2 : 1}
      continueDisabled={continueDisabled}
      onContinueClick={handleContinue}
      showSkipButton={showSkipButton}
      onSkipClick={isSkipDisabled ? handleSkipClick : undefined}
      completeButtonContent={completeButtonContent}
      continueDisabledMessage={continueDisabledMessage}
    >
      {component}
      {showSkipModal && (
        <SkipUploadStatementsModal closeModal={toggleSkipModal} />
      )}
    </SetupCard>
  )
}
