import { useEffect, useState } from 'react'
import { Grid, Image } from 'semantic-ui-react'

import {
  Alert,
  Card,
  GridRowColumn,
  Text,
} from '../../../../../components/BaseComponents'
import { Colors, Fonts } from '../../../../../styles/theme'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { selectUserTransactionsByCategoryIdentifierForYear } from '../../../../Transactions/transactions.selectors'
import Tooltip from '../../../QuarterlyTaxEstimates/components/Tooltip'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { UpdateYourBooksStepProps } from './UpdateYourBooks'
import TransactionHeader from './TransactionHeader'
import TransactionCard from './TransactionCard'
import { TransactionCategoryIdentifier } from '../../../../../reducers/admin/allTransactions.slice'
import { SubStepIdentifiers } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'

const OwnersDistributionStep = ({
  goForward,
  goBack,
}: UpdateYourBooksStepProps) => {
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const distributionTransactionsInState = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.owners_distribution,
    currentTaxYear
  )
  const [distributionTransactionIds, setDistributionTransactionIds] = useState<
    number[]
  >([])

  useEffect(() => {
    // Still want to display accounts after category updates,
    // so don't update the distributionTransactionsIds list that was initially loaded
    if (
      distributionTransactionsInState.length &&
      !distributionTransactionIds.length
    ) {
      setDistributionTransactionIds(
        distributionTransactionsInState.map((t) => t.id)
      )
    }
  }, [distributionTransactionIds.length, distributionTransactionsInState])

  const saveAndContinue = useAsyncCallback(() =>
    goForward({
      completedSteps: [
        SubStepIdentifiers.confirmOwnersDistributionTransactions,
      ],
    })
  )

  return (
    <Grid>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/payroll.svg"
          style={{ width: 120, height: 120 }}
        />
      </GridRowColumn>
      <GridRowColumn width={10} spacer={3}>
        <Text as="h1" textAlign="center">
          Do these Owner’s Distributions look correct?
        </Text>
      </GridRowColumn>
      <GridRowColumn width={10} spacer={3}>
        <Text>
          Your bookkeepers categorized these expenses as{' '}
          <b>Owner’s Distributions</b>, or business funds that you took out for
          personal use. If something doesn’t look right, please recategorize and
          add a note to explain why.
        </Text>
      </GridRowColumn>
      <GridRowColumn spacer={3} width={10}>
        <Tooltip
          popup={{
            title: 'Owner’s Distributions',
            body: 'Funds that you draw from your business checking account into your personal checking account, and are used solely for personal purposes. Owner’s Distributions are also used to categorize personal transactions in your books (i.e. QTE payments).\nAn example of an Owner’s Distribution is paying yourself.',
          }}
          labelComponent="What is an Owner’s Distribution?"
          style={{
            display: 'inline-block',
            borderBottom: `2px dashed ${Colors.green}`,
            ...Fonts.bodySm,
          }}
        />
      </GridRowColumn>
      <Grid.Row />
      {distributionTransactionIds?.length > 0 && <TransactionHeader />}
      <GridRowColumn short>
        {distributionTransactionIds.map((tId) => (
          <TransactionCard key={tId} transactionId={tId} />
        ))}
      </GridRowColumn>
      {distributionTransactionIds?.length === 0 && (
        <GridRowColumn width={10} spacer={3}>
          <Card backgroundColor="lightGreen">
            <Text>
              All transactions have been recategorized. You may continue to the
              next step.
            </Text>
          </Card>
        </GridRowColumn>
      )}
      <GridRowColumn width={10} spacer={3}>
        <Alert title="Why are we asking this?">
          Sometimes these transactions get miscategorized. For example, you
          might’ve reimbursed yourself for a business expense you paid for on a
          personal card. It’s categorized as an Owner’s Distribution, but it’s
          actually a business expense.
        </Alert>
      </GridRowColumn>
      <FormFlowFooter
        onBack={goBack}
        onForward={saveAndContinue.callback}
        loading={saveAndContinue.loading}
        continueDisabled={saveAndContinue.loading}
      />
    </Grid>
  )
}

export default OwnersDistributionStep
