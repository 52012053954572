import React, { useState, useEffect } from 'react'

//BL
import { ManualTransactionChildProps } from './ManualTransactionActions'
import { adminDeleteTransaction } from '../../../actions/adminActions'

//UI
import { Button, Confirm } from 'semantic-ui-react'
import { parseErrorFromCatch } from '../../../utils/errorHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'

const DeleteTransactionModule: React.FC<ManualTransactionChildProps> = ({
  disabled,
  onChildBeginLoading,
  onChildEndLoading,
  transaction,
}) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState<null | boolean>(null)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== null) {
      setOpen(!loading && open)
      if (loading) {
        onChildBeginLoading()
      } else {
        onChildEndLoading()
      }
    }
  }, [loading, onChildBeginLoading, onChildEndLoading, open])

  const openConfirm = () => setOpen(true)
  const closeConfirm = () => setOpen(false)

  const onConfirm = async () => {
    setLoading(true)
    await performSave()
  }

  const performSave = async () => {
    try {
      await adminDeleteTransaction(Number(transaction.id))(dispatch)
    } catch (err) {
      handleError(parseErrorFromCatch(err))
    }
  }

  function handleError(err: string) {
    alert(err)
  }

  return (
    <>
      <Button
        loading={loading === true}
        disabled={disabled}
        title="Delete manually created transaction"
        basic
        icon="delete"
        onClick={openConfirm}
      />
      <Confirm
        open={open}
        size="small"
        onConfirm={onConfirm}
        onCancel={closeConfirm}
        header={`Delete manually created transaction "${transaction.description}"?`}
        content={`Delete ${transaction.description}, id: ${transaction.id}?`}
      />
    </>
  )
}

export default DeleteTransactionModule
