import { useNavigate } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'

import {
  Button,
  Card,
  GridRowColumn,
  Popup,
  Text,
} from '../../../../../components/BaseComponents'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectActiveQTEDetailsAsDates } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { DATE_FORMATS_LUXON } from '../../../../../utils/dateHelpers'
import { SafeHarborTooltip } from './helpers'

export const WhatIsSafeHarborPopup = () => (
  <Popup
    trigger={
      <Button variant="tooltipLink">What is a safe harbor estimate?</Button>
    }
    content={<SafeHarborTooltip />}
    popupStyle={{ maxWidth: 400 }}
  />
)

const FederalSafeHarborCard = () => {
  const navigate = useNavigate()

  const activeQuarterDetails = useReselector(selectActiveQTEDetailsAsDates)

  if (!activeQuarterDetails) {
    return null
  }

  return (
    <Card type="section" backgroundColor="stone40">
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={4}>
            <Image
              style={{ height: 150, width: 150 }}
              src="https://heard-images.s3.amazonaws.com/assets/writing_check.svg"
            />
          </Grid.Column>
          <Grid.Column width={12}>
            <Grid>
              <GridRowColumn>
                <Text as="eyebrow" color="mediumGray">
                  important note
                </Text>
              </GridRowColumn>
              <GridRowColumn stretched>
                <Text as="h2">
                  Paying Q{activeQuarterDetails.taxQuarter} Federal Quarterly
                  Taxes
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="bodyLg" color="darkGray">
                  Since you signed up after{' '}
                  {activeQuarterDetails.withOffset.newUserCutOff.toFormat(
                    DATE_FORMATS_LUXON.DISPLAY_LONG
                  )}
                  , we won&apos;t be able to provide a calculated quarterly tax
                  estimate using your practice income this quarter. This
                  quarter, you can use our safe harbor estimate calculator to
                  determine an estimate that will help minimize federal
                  underpayment penalties.
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <WhatIsSafeHarborPopup />
              </GridRowColumn>
              <GridRowColumn>
                <Button
                  onClick={() => {
                    navigate('/taxes/quarterly/calculate-safe-harbor')
                  }}
                >
                  Calculate safe harbor
                </Button>
              </GridRowColumn>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

export default FederalSafeHarborCard
