import moment from 'moment-timezone'
import { flatten, range, times } from 'lodash'

export enum UploadDocumentType {
  STATEMENT = 'statement',
  TAX = 'tax',
  INCORPORATION = 'incorporation',
  RECEIPT = 'receipt',
  OTHER = 'other',
  BOOKKEEPING = 'bookkeeping',
}

import { DATE_FORMATS } from '../utils/dateHelpers'

const CURRENT_YEAR = moment().year()
export const DEFAULT_YEAR = CURRENT_YEAR.toString()
export const DEFAULT_START_DATE = moment().year(CURRENT_YEAR).startOf('year')

export const DEFAULT_END_DATE = moment().year(CURRENT_YEAR).endOf('year')

const YEARS_TO_SHOW = range(2020, CURRENT_YEAR + 1)

export enum TosVersion {
  tos_02_2022 = 'tos_02_2022',
  tos_01_2024 = 'tos_01_2024',
}

export const YEAR_OPTIONS = [
  ...YEARS_TO_SHOW.map((year) => ({
    text: year.toString(),
    value: year.toString(),
  })),
  {
    text: 'All Time',
    value: 'all',
  },
]

export const QUARTER_PNL_OPTIONS = flatten(
  YEARS_TO_SHOW.reverse().map((year) =>
    range(1, 5).map((quarter) => ({
      value: `${year} Q${quarter}`,
      startDate: moment()
        .year(year)
        .quarter(quarter)
        .startOf('quarter')
        .format(DATE_FORMATS.INPUT),
      endDate: moment()
        .year(year)
        .quarter(quarter)
        .endOf('quarter')
        .format(DATE_FORMATS.INPUT),
    }))
  )
)

export const YEARLY_PNL_OPTIONS = YEARS_TO_SHOW.map((year) => ({
  value: year,
  startDate: moment().year(year).startOf('year').format(DATE_FORMATS.INPUT),
  endDate: moment().year(year).endOf('year').format(DATE_FORMATS.INPUT),
}))

export const SCHEDULE_CALL_URL =
  'https://calendly.com/heardaccounting/heard-client-check-in'
export const HEARD_PROFILE_IMAGE_URL =
  'https://pbs.twimg.com/profile_images/1361356151288340483/CL1qp8es_400x400.png'

export const generateMonthlyStatementOptions = () =>
  times(
    Math.ceil(
      moment
        .duration(
          moment().endOf('month').diff(moment().year(2019).startOf('year'))
        )
        .asMonths()
    )
  ).map((i) => ({
    text: moment().subtract(i, 'months').format('YYYY-MM'),
    value: moment().subtract(i, 'months').format('YYYY-MM'),
  }))

export const USER_DOCUMENT_TYPES_OPTIONS = [
  UploadDocumentType.TAX,
  UploadDocumentType.STATEMENT,
  UploadDocumentType.OTHER,
  UploadDocumentType.INCORPORATION,
  UploadDocumentType.RECEIPT,
  UploadDocumentType.BOOKKEEPING,
].map((value) => ({ text: value[0].toUpperCase() + value.slice(1), value }))

export const BOOKKEEPING_REPORT_STATUSES = {
  Done: {
    name: 'done',
  },
  Reconciled: {
    name: 'reconciled',
  },
  InProgress: {
    name: 'in_progress',
  },
  Categorized: {
    name: 'categorized',
  },
  NotStarted: {
    name: 'not_started',
  },
  MissingInfo: {
    name: 'missing_info',
  },
  PlaidError: {
    name: 'plaid_error',
  },
}

export const INSTITUTIONS_MAP = {
  WELLS_FARGO: 'ins_127991',
  CHASE: 'ins_56',
}
