import React, { useState, useEffect } from 'react'
import { Button, Label } from 'semantic-ui-react'

import { adminUpdateUserTransaction } from '../../../actions/admin/transactionActions'
import RuleCreationModal from '../../../features/Admin/TransactionRules/index'
import { SplitTransactionButton } from './SplitTransaction/SplitTransactionModule'
import {
  PostTransactionPayload,
  Transaction,
} from '../../../reducers/admin/allTransactions.slice'
import { DateTime } from 'luxon'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { TransactionRuleCategoryType } from '../../../reducers/admin/transactionRulesReducer'

const TransactionTypeButton = ({
  transaction,
  onUpdate = (_: Transaction) => undefined,
}: {
  transaction: Transaction
  onUpdate?: (transaction: Transaction) => void
}) => {
  const dispatch = useAppDispatch()
  const [expensesTypeToggled, setExpensesTypeToggled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (!transaction.type && !transaction.splitAt) {
      setExpensesTypeToggled(true)
    } else {
      setExpensesTypeToggled(false)
    }
  }, [transaction])

  const expensesTypeChange = async (
    expenseType: TransactionRuleCategoryType
  ) => {
    setLoading(true)
    const data: PostTransactionPayload = {
      type: expenseType,
    }
    // We exclude Transaction if it's personal
    if (expenseType === 'personal') {
      data.excludedAt = Date.now()
    } else if (expenseType === 'business') {
      data.excludedAt = null
    }
    await adminUpdateUserTransaction(transaction.id, data)(dispatch)
    onUpdate({
      ...transaction,
      type: expenseType,
      excludedAt: data.excludedAt ? DateTime.now().toUTC().toISO() : null,
    })
    setExpensesTypeToggled(false)
    setLoading(false)
  }

  const onRevealClick = () => {
    if (transaction.splitAt) {
      alert('The expense type cannot be set. This transaction has been split.')
      return
    }
    if (transaction.splitFrom) {
      alert(
        'The expense type cannot be set. This transaction originates from a split transaction.'
      )
      return
    }
    setExpensesTypeToggled(true)
  }

  if (expensesTypeToggled) {
    return (
      <Button.Group basic size="mini">
        <Button
          loading={loading}
          onClick={() =>
            expensesTypeChange(TransactionRuleCategoryType.business)
          }
        >
          Business
        </Button>
        <Button
          loading={loading}
          onClick={() =>
            expensesTypeChange(TransactionRuleCategoryType.personal)
          }
        >
          Personal
        </Button>
        <SplitTransactionButton
          loading={loading}
          toggleGroup={() => setExpensesTypeToggled(false)}
          transaction={transaction}
          onUpdate={onUpdate}
        >
          Split
        </SplitTransactionButton>
      </Button.Group>
    )
  } else {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button basic className="link" onClick={onRevealClick}>
          {transaction.type}
        </Button>
        {transaction.confirmedAt &&
          !transaction.autocategorizedTransactionRuleId &&
          !transaction.splitAt && (
            <Button basic className="link" onClick={() => setModalOpen(true)}>
              Create Rule
            </Button>
          )}
        {transaction.autocategorizedTransactionRuleId && (
          <Label color="green">
            RULE: {transaction.autocategorizedTransactionRuleId}
          </Label>
        )}
        {modalOpen && transaction.type !== 'split' && (
          <RuleCreationModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            userId={transaction.userId}
            description={transaction.description}
            categoryType={transaction.type}
            categoryId={transaction.transactionCategoryId}
          />
        )}
      </div>
    )
  }
}

export default TransactionTypeButton
