import React, { useCallback, useEffect } from 'react'
import { Button, Card, Header, Label, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

import { getCallEventName, getUserCallLabelColorClass } from '../helpers'
import {
  getAdminCalendlyLinkByUserEventId,
  getAllUserCallEventById,
  getCurrentYearAllUserCallEventIds,
  getPastAllUserCallEventsIds,
} from '../../../selectors/userCallEventSelectors'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  fetchAdminUserCallEventsIfNeeded,
  updateAdminUserCallEvent,
} from '../../../actions/admin/userCallEventsActions'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'

const UserCallRow = ({ id, userId }: { id: number; userId: number }) => {
  const dispatch = useAppDispatch()

  const userCall = useReselector(getAllUserCallEventById, userId, id)

  const calendlyLink = useReselector(
    getAdminCalendlyLinkByUserEventId,
    userId,
    id
  )

  const toggleMissed = useCallback(() => {
    if (!userCall?.id) {
      return
    }

    if (userCall.eventState === 'missed') {
      dispatch(updateAdminUserCallEvent(userCall.id, { missedOn: null }))
    } else {
      dispatch(updateAdminUserCallEvent(userCall.id, { missedOn: Date.now() }))
    }
  }, [dispatch, userCall?.eventState, userCall?.id])

  if (!userCall) {
    return null
  }

  const { eventState, startTime, location } = userCall
  const startMoment = moment.utc(startTime).local()
  const abbreviation = moment.tz(moment.tz.guess()).format('z')

  return (
    <Table.Row>
      <Table.Cell>{getCallEventName(userCall, calendlyLink)}</Table.Cell>
      <Table.Cell>
        <Label className={getUserCallLabelColorClass(eventState)}>
          {eventState}
        </Label>
      </Table.Cell>
      <Table.Cell>
        {startTime && (
          <>
            {startMoment.format(DATE_FORMATS.DISPLAY_LONG)}
            <br />
            {startMoment.format(DATE_FORMATS.TIME)} {abbreviation}
          </>
        )}
      </Table.Cell>
      <Table.Cell>
        {location && <a href={location}>{location.replace('https://', '')}</a>}
      </Table.Cell>
      <Table.Cell>
        <Button className="link" onClick={toggleMissed}>
          {userCall.eventState === 'missed' ? 'Unmark missed' : 'Mark missed'}
        </Button>
      </Table.Cell>
    </Table.Row>
  )
}

const AdminUserCallsCard = ({
  userId,
  showPastEvents = false,
  showPastLink = true,
}: {
  userId: number
  showPastEvents?: boolean
  showPastLink?: boolean
}) => {
  const dispatch = useAppDispatch()
  const currentYearCallEventIds = useReselector(
    getCurrentYearAllUserCallEventIds,
    userId
  )

  const pastYearCallEventIds = useReselector(
    getPastAllUserCallEventsIds,
    userId
  )

  const ids = showPastEvents ? pastYearCallEventIds : currentYearCallEventIds

  useEffect(() => {
    dispatch(fetchAdminUserCallEventsIfNeeded(userId))
  }, [dispatch, userId])

  return (
    <Card fluid className="userCallsCard">
      <Card.Content>
        <div className="flexbox">
          <Header as="h4">User Calls</Header>
          {showPastLink && (
            <Button className="link">
              <Link to={`/admin/finances/records/${userId}/calls`}>
                View Past
              </Link>
            </Button>
          )}
        </div>

        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Call name</Table.HeaderCell>
              <Table.HeaderCell>State</Table.HeaderCell>
              <Table.HeaderCell>Date/Time</Table.HeaderCell>
              <Table.HeaderCell>Link</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {ids.map((id) => (
              <UserCallRow key={id} id={id} userId={userId} />
            ))}
          </Table.Body>
        </Table>
      </Card.Content>
    </Card>
  )
}

export default AdminUserCallsCard
