import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import moment, { Moment } from 'moment'
import { times } from 'lodash'

import {
  uploadDocument,
  UploadUserDocumentPayload,
  userUploadDocument,
  UploadError,
} from '../../actions/awsActions'
import { documentCategoryOptions } from '../../features/Admin/Documents/helpers'
import {
  getUserDocumentCategoriesById,
  selectUserDocumentCategory,
  selectUserDocumentCategoryByInternalName,
} from '../../features/Admin/UserDocumentCategories/userDocumentCategories.selectors'
import { User } from '../../reducers/auth/userReducer'
import {
  Button,
  Text,
  Alert,
  Dropdown,
  Modal,
  GridRowColumn,
  Card,
  IconButton,
  Icon,
  Checkbox,
  Link,
  DatePicker,
  Popup,
} from '../BaseComponents'
import { UserDocument } from '../../features/UserDocuments/userDocuments.slice'
import { TaxFilingUser } from '../../features/Taxes/AnnualTaxes/annualTaxFilings.slice'
import { getCurrentUser } from '../../selectors/user.selectors'
import { useReselector } from '../../utils/sharedHooks'
import FileUploadModalDescription from './FileUploadModalDescription'
import { selectAdminOrUserFinancialAccountOptions } from '../../selectors/financeSelectors'
import { fetchFinancialAccountsIfNeeded } from '../../actions/financialAccountActions'
import { fetchUserFinancialAccounts } from '../../actions/admin/adminFinancialAccountActions'
import { UploadDocumentType } from '../../constants/businessConstants'
import { parseErrorFromCatch } from '../../utils/errorHelpers'
import { getBookkeepingTitle } from './BookkeepingDescription'
import { DescriptionType } from '../../features/UserDocuments/constants'
import { useAnalyticsTrack } from '../../features/Amplitude'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { StatementUploadSection, StatementData } from './StatementUploadSection'
import { FEATURE_FLAG_KEYS } from '../../features/OpenFeature'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { DateTime } from 'luxon'
import { fetchUserDocumentCategoriesIfNeeded } from '../../features/Admin/UserDocumentCategories/userDocumentCategories.slice'
import { selectCurrentAnnualTaxYear } from '../../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useAppDispatch } from '../../utils/typeHelpers'

export interface UploadedFile {
  title: string
  url: string
  id: number
  statementMonth?: Moment
  taxDocumentType?: 'state' | 'federal'
}

const transformMonthYear = (monthYear?: string) => {
  return monthYear ? moment(monthYear, 'YYYY-MM') : undefined
}

const formatMonthYear = (monthYear?: string) => {
  return monthYear
    ? DateTime.fromFormat(monthYear, 'yyyy-M').toFormat('yyyy-MM')
    : undefined
}

interface Props {
  open: boolean
  close: () => void
  user?: User | TaxFilingUser
  userFacing: boolean
  documentType: UploadDocumentType
  categoryId?: number | null
  categoryInternalName?: string
  setUploadedFile?: (_: UploadedFile) => Promise<void> | void
  title?: string
  taxFilingFormId?: number
  description?: ReactNode
  monthYear?: string // YYYY-MM format
  // Sometimes a file should be uploaded with extra props
  extraPayload?: Partial<UploadUserDocumentPayload>

  // Handles checkbox at bottom of modal that can be used to skip uploading
  overrideCheckboxLabel?: ReactNode
  overrideCallback?: () => void

  // The header text displayed at the top of the modal
  modalHeaderText?: string
  hideModalHeaderText?: boolean

  descriptionType?: DescriptionType | null
  year?: string
  useFilteredAccountOptions?: boolean
}

const FileUploadModal = ({
  open,
  close,
  userFacing,
  documentType,
  setUploadedFile,
  taxFilingFormId,
  extraPayload,
  user: sentUser,
  title: sentTitle,
  description: sentDescription,
  categoryId: sentCategoryId,
  categoryInternalName: sentCategoryInternalName,
  monthYear: sentMonthYear,
  overrideCheckboxLabel,
  overrideCallback,
  modalHeaderText,
  hideModalHeaderText,
  descriptionType,
  year: sentYear,
  useFilteredAccountOptions = false,
}: Props) => {
  const dispatch = useAppDispatch()
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])
  const [error, setError] = useState('')
  const [uploading, setUploading] = useState(false)
  const [statementData, setStatementData] = useState<StatementData[]>([])
  const [month, setMonth] = useState(transformMonthYear(sentMonthYear)?.month())
  const [year, setYear] = useState(
    sentYear ?? transformMonthYear(sentMonthYear)?.year()?.toString()
  )
  const [statementFinancialAccountId, setStatementFinancialAccountId] =
    useState<number>()
  const [userDocumentCategoryId, setUserDocumentCategoryId] = useState(
    sentCategoryId ?? undefined
  )
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const nextTaxYear = (Number(currentTaxYear) + 1).toString()
  const [taxDocumentType, setTaxDocumentType] = useState<'federal' | 'state'>()
  const [uploadOverride, setUploadOverride] = useState(false)
  const documentCategories = useReselector(getUserDocumentCategoriesById)
  const filteredDocumentCategories = useMemo(
    () => documentCategoryOptions(documentCategories, documentType),
    [documentCategories, documentType]
  )
  const sentCategoryById = useReselector(
    selectUserDocumentCategory,
    sentCategoryId
  )
  const sentCategoryByName = useReselector(
    selectUserDocumentCategoryByInternalName,
    sentCategoryInternalName
  )

  const sentCategory = sentCategoryById || sentCategoryByName
  const currentUser = useReselector(getCurrentUser)
  const inputRef = useRef<HTMLInputElement>(null)
  const track = useAnalyticsTrack()

  const user = sentUser || currentUser
  const financialAccountOptions = useReselector(
    selectAdminOrUserFinancialAccountOptions,
    user?.id
  )

  const isAnnualCategory = useMemo(() => {
    return Object.values(documentCategories).find(
      (dc) => dc.id === userDocumentCategoryId
    )?.isAnnual
  }, [userDocumentCategoryId, documentCategories])

  const releaseStatementUpload = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.multiStatementUpload,
    false
  )

  // If the category id prop changes update the local state
  useEffect(() => {
    setUserDocumentCategoryId(sentCategory?.id ?? undefined)
  }, [sentCategory?.id])

  // If a sent month is sent update year and month local state
  useEffect(() => {
    if (!sentYear) {
      setYear(transformMonthYear(sentMonthYear)?.year()?.toString())
      setMonth(transformMonthYear(sentMonthYear)?.month())
    }
  }, [sentMonthYear, sentYear])

  // if Bank Statement modal fetch financial account information when needed
  useEffect(() => {
    if (documentType === UploadDocumentType.STATEMENT) {
      if (userFacing) {
        dispatch(fetchFinancialAccountsIfNeeded())
      } else if (user) {
        dispatch(fetchUserFinancialAccounts(user.id, true))
      }
    }
  }, [documentType, userFacing, user, dispatch])

  useEffect(() => {
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch])

  const uploadFiles = useCallback(async () => {
    if (!user?.id) {
      return
    }

    const uploadFile = async (file: File, index: number) => {
      let docRes: UserDocument | UploadError | null = null

      const statementMonth =
        year !== undefined && month !== undefined
          ? moment().year(Number(year)).month(month)
          : undefined

      const payload = {
        file,
        user: {
          id: user.id,
          uuid: user.uuid,
        },
        documentType,
        statementMonth,
        statementFinancialAccountId,
        userDocumentCategoryId,
        year:
          documentType === UploadDocumentType.TAX && year ? year : undefined,
        ...extraPayload,
      }

      if (releaseStatementUpload && statementData[index]) {
        const statement = {
          statementMonth: statementData[index].statementMonth,
          financialAccountIds: statementData[index].financialAccountIds,
        }
        payload.statement = statement
      }

      if (userFacing) {
        const res = await userUploadDocument(payload)(dispatch)
        if (res && 'userDocument' in res) {
          docRes = res.userDocument
        } else if (res && 'error' in res) {
          docRes = res
        }
      } else {
        docRes = await uploadDocument(payload)(dispatch)
      }

      if (docRes && 'error' in docRes) {
        throw new Error(docRes.error)
      } else if (setUploadedFile && docRes && 'id' in docRes) {
        await setUploadedFile({
          title: docRes.fileDescription || '',
          url: docRes.signedUrl || '',
          id: docRes.id,
          statementMonth,
          taxDocumentType,
        })
      }
    }
    setError('')
    setUploading(true)
    try {
      await Promise.all(
        filesToUpload.map((file, index) => uploadFile(file, index))
      )
      setFilesToUpload([])
      close()
    } catch (err) {
      setError(parseErrorFromCatch(err))
    } finally {
      setUploading(false)
    }
  }, [
    user?.id,
    user?.uuid,
    year,
    month,
    documentType,
    userDocumentCategoryId,
    extraPayload,
    userFacing,
    setUploadedFile,
    dispatch,
    filesToUpload,
    close,
    taxDocumentType,
    statementFinancialAccountId,
    releaseStatementUpload,
    statementData,
  ])

  const handleRemove = useCallback(
    (index: number) =>
      setFilesToUpload([
        ...filesToUpload.slice(0, index),
        ...filesToUpload.slice(index + 1),
      ]),
    [filesToUpload]
  )

  const title = useMemo(() => {
    if (sentTitle) {
      return sentTitle
    } else if (
      documentType === UploadDocumentType.BOOKKEEPING &&
      sentCategory
    ) {
      return getBookkeepingTitle(sentCategory, descriptionType)
    } else if (sentCategory?.title) {
      return sentCategory.title
    } else if (documentType === UploadDocumentType.TAX) {
      return 'Tax Documents'
    } else if (documentType === UploadDocumentType.INCORPORATION) {
      return 'Incorporation Documents'
    } else if (documentType === UploadDocumentType.OTHER) {
      return 'Other Documents'
    } else if (documentType === UploadDocumentType.RECEIPT) {
      return 'Receipts'
    } else if (documentType === UploadDocumentType.BOOKKEEPING) {
      return 'Bookkeeping Documents'
    }
    return 'Bank Statements'
  }, [sentCategory, documentType, sentTitle, descriptionType])

  const onUploadButtonClicked = useCallback(() => {
    const trackProperties: {
      document_category: string
      document_name: string
      document_subcategory: string
      report_month?: number
      report_year?: number
      tax_year?: number
    } = {
      document_category: documentType,
      document_subcategory: sentCategory?.id.toString() || '',
      document_name: sentCategory?.title || '',
    }

    if (year) {
      if (documentType === UploadDocumentType.STATEMENT) {
        trackProperties.report_year = Number(year)
      } else {
        trackProperties.tax_year = Number(year)
      }
    }
    if (month) {
      trackProperties.report_month = month
    }

    track('clicked document upload', trackProperties)

    if (uploadOverride) {
      overrideCallback?.()
      close()
    } else {
      uploadFiles()
    }
  }, [
    close,
    overrideCallback,
    uploadFiles,
    uploadOverride,
    track,
    sentCategory,
    documentType,
    month,
    year,
  ])

  const isUploadDisabled = useMemo(() => {
    // If upload override checkbox checked then enabled
    if (uploadOverride) {
      return false
    }

    // If no files are uploaded then disabled
    if (filesToUpload.length === 0) {
      return true
    }

    if (releaseStatementUpload) {
      if (
        documentType === UploadDocumentType.STATEMENT &&
        !statementData?.length
      ) {
        return true
      }
      return (
        documentType === UploadDocumentType.STATEMENT &&
        !statementData.every((item) => item.statementMonth)
      )
    }

    // If type is bank statement make sure year and month are selected
    return (
      documentType === UploadDocumentType.STATEMENT &&
      (year === undefined || month === undefined)
    )
  }, [
    documentType,
    filesToUpload.length,
    month,
    uploadOverride,
    year,
    releaseStatementUpload,
    statementData,
  ])

  const currentYear = moment().year().toString()
  const previousYear = (moment().year() - 1).toString()

  return (
    <Modal size="small" dimmer="inverted" open={open} onClose={close} closeIcon>
      <Modal.Header>
        {!hideModalHeaderText && (modalHeaderText || 'Upload documents')}
      </Modal.Header>
      <Modal.Content>
        <Card backgroundColor="stone">
          <Grid>
            {error && (
              <GridRowColumn>
                <Alert type="error" title={error} />
              </GridRowColumn>
            )}
            <GridRowColumn>
              <Text as="h2">{title}</Text>
            </GridRowColumn>
            <GridRowColumn>
              <FileUploadModalDescription
                descriptionType={descriptionType}
                sentDescription={sentDescription}
                documentType={documentType}
                sentCategoryId={sentCategory?.id}
                sentMonthYear={sentMonthYear}
                sentYear={sentYear?.toString()}
              />
            </GridRowColumn>

            <GridRowColumn computer={7} mobile={16}>
              <Button fullWidth onClick={() => inputRef.current?.click()}>
                <Icon icon={regular('upload')} style={{ marginRight: 10 }} />
                Select Documents
              </Button>
              <input
                type="file"
                multiple
                hidden
                onChange={(e) => {
                  if (e.target.files) {
                    const filesArray = Array.from(e.target.files)
                    setFilesToUpload(filesArray)
                    if (documentType === UploadDocumentType.STATEMENT) {
                      const defaultStatementData = filesArray.map(() => ({
                        statementMonth: formatMonthYear(sentMonthYear) || '',
                        financialAccountIds: [],
                      }))
                      setStatementData(defaultStatementData)
                    }
                    setUploadOverride(false)
                    e.target.value = ''
                  }
                }}
                ref={inputRef}
              />
            </GridRowColumn>
            {(documentType !== UploadDocumentType.STATEMENT ||
              !releaseStatementUpload) &&
              Boolean(filesToUpload.length) && (
                <>
                  <Divider />
                  <GridRowColumn>
                    <Text as="eyebrow">Files to Upload</Text>
                    <ul>
                      {filesToUpload.map((file, index) => (
                        <li key={file.name}>
                          <Text>
                            {file.name}
                            <IconButton
                              onClick={() => handleRemove(index)}
                              icon={regular('trash-can')}
                              style={{ marginLeft: 6 }}
                            />
                          </Text>
                        </li>
                      ))}
                    </ul>
                  </GridRowColumn>
                </>
              )}
            {releaseStatementUpload &&
              Boolean(filesToUpload.length) &&
              documentType === UploadDocumentType.STATEMENT && (
                <StatementUploadSection
                  filesToUpload={filesToUpload}
                  setFilesToUpload={setFilesToUpload}
                  statementData={statementData}
                  setStatementData={setStatementData}
                  user={user}
                  useFilteredAccountOptions={useFilteredAccountOptions}
                />
              )}
            {!releaseStatementUpload &&
              documentType === UploadDocumentType.STATEMENT &&
              !sentMonthYear && (
                <>
                  <Divider />
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Dropdown
                        label="Statement Year"
                        value={year}
                        options={[
                          { text: currentYear, value: currentYear },
                          { text: previousYear, value: previousYear },
                        ]}
                        fullWidth
                        onChange={setYear}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Dropdown
                        label="Statement Month"
                        value={month}
                        options={times(12).map((i) => ({
                          text: moment().set('months', i).format('MMMM'),
                          value: i,
                        }))}
                        fullWidth
                        onChange={setMonth}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <GridRowColumn>
                    <Dropdown
                      label="Financial Account"
                      value={statementFinancialAccountId}
                      options={financialAccountOptions}
                      fullWidth
                      onChange={setStatementFinancialAccountId}
                    />
                  </GridRowColumn>
                </>
              )}
            {[
              UploadDocumentType.TAX,
              UploadDocumentType.INCORPORATION,
              UploadDocumentType.BOOKKEEPING,
            ].includes(documentType) &&
              !sentCategory?.id && (
                <>
                  <Divider />
                  <GridRowColumn>
                    <Dropdown
                      search
                      fullWidth
                      label="Select Document Category If Relevant"
                      placeholder="Select Document Category"
                      value={userDocumentCategoryId}
                      options={filteredDocumentCategories}
                      onChange={setUserDocumentCategoryId}
                      required
                      clearable
                    />
                  </GridRowColumn>
                </>
              )}
            {(isAnnualCategory ||
              (documentType === UploadDocumentType.TAX &&
                !userDocumentCategoryId)) && (
              <GridRowColumn>
                <DatePicker
                  placeholder="Select year"
                  label="Tax Year"
                  value={year?.toString() ?? ''}
                  maxDate={new Date()}
                  onChange={setYear}
                  dateFormat={DATE_FORMATS.YEAR}
                  showYearPicker
                  clearable
                  afterLabel={
                    <Popup
                      content={
                        <Grid>
                          <GridRowColumn>
                            <Text>
                              A tax year is the 12-month calendar year covered
                              by a tax return. In the U.S., the tax year for
                              individuals runs from Jan. 1 to Dec. 31 and
                              includes taxes owed on earnings during that
                              period. For example, taxes withheld or owed for
                              earnings during the calendar year {currentTaxYear}{' '}
                              would be included on the tax return sent to the
                              IRS in {nextTaxYear}. Even though you are sending
                              the tax return in {nextTaxYear}, it is for the tax
                              year {currentTaxYear}.
                            </Text>
                          </GridRowColumn>
                        </Grid>
                      }
                    />
                  }
                />
              </GridRowColumn>
            )}
            {taxFilingFormId && (
              <GridRowColumn>
                <Dropdown
                  fluid
                  required
                  label="Select State or Federal"
                  placeholder="Select State or Federal"
                  options={[
                    { text: 'State', value: 'state' },
                    { text: 'Federal', value: 'federal' },
                  ]}
                  value={taxDocumentType}
                  onChange={setTaxDocumentType}
                />
              </GridRowColumn>
            )}
            {(sentCategory?.downloadFormUrl ||
              sentCategory?.viewExampleUrl) && (
              <Grid.Row>
                {sentCategory.downloadFormUrl && (
                  <Grid.Column width={5}>
                    <Link href={sentCategory.downloadFormUrl} newPage>
                      Download form
                      <Icon
                        icon={regular('square-up-right')}
                        style={{ marginLeft: 6 }}
                      />
                    </Link>
                  </Grid.Column>
                )}
                {sentCategory.viewExampleUrl && (
                  <Grid.Column width={5}>
                    <Link href={sentCategory.viewExampleUrl} newPage>
                      View Example
                      <Icon
                        icon={regular('square-up-right')}
                        style={{ marginLeft: 6 }}
                      />
                    </Link>
                  </Grid.Column>
                )}
              </Grid.Row>
            )}
          </Grid>
        </Card>
        {overrideCheckboxLabel && !filesToUpload.length && (
          <Checkbox
            label={overrideCheckboxLabel}
            onChange={(checked) => setUploadOverride(checked)}
            checked={uploadOverride}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          onClick={onUploadButtonClicked}
          loading={uploading}
          disabled={isUploadDisabled || uploading}
        >
          {uploadOverride ? 'Submit' : 'Upload'}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default FileUploadModal
