import { FormikProvider, useFormik } from 'formik'
import {
  Button,
  FormikRadioButton,
  GridRowColumn,
  Modal,
  getFieldName,
} from '../../components/BaseComponents'
import { Grid } from 'semantic-ui-react'

enum BankStatementReadiness {
  notReady = 'not_ready',
  ready = 'ready',
  unselected = 'unselected',
}

const UploadStatementsPromptModal = ({
  open,
  onClose,
  onNotReadyClicked,
  onReadyClicked,
  onSkipClicked,
}: {
  open: boolean
  onClose: () => void
  onNotReadyClicked: () => void
  onReadyClicked: () => void
  onSkipClicked: () => void
}) => {
  const formik = useFormik({
    initialValues: {
      statementReadiness: BankStatementReadiness.unselected,
    },
    onSubmit: ({ statementReadiness }) => {
      switch (statementReadiness) {
        case BankStatementReadiness.ready:
          onReadyClicked()
          break
        case BankStatementReadiness.notReady:
          onNotReadyClicked()
          break
        default:
      }
    },
  })

  return (
    <Modal closeIcon size="tiny" open={open} onClose={onClose}>
      <Modal.Header>Upload last month&apos;s statements</Modal.Header>
      <Modal.Content>
        <FormikProvider value={formik}>
          <Grid>
            <GridRowColumn>
              <FormikRadioButton
                name={getFieldName<typeof formik.values>('statementReadiness')}
                value={BankStatementReadiness.ready}
                label="I have my statements ready to upload"
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikRadioButton
                name={getFieldName<typeof formik.values>('statementReadiness')}
                value={BankStatementReadiness.notReady}
                label="I just opened my account and don't have a statement yet"
              />
            </GridRowColumn>
          </Grid>
        </FormikProvider>
      </Modal.Content>
      <Modal.Actions style={{ minHeight: 97 }}>
        <Button
          variant="link"
          style={{ marginRight: 20 }}
          onClick={onSkipClicked}
        >
          Skip for now
        </Button>
        <Button
          onClick={formik.submitForm}
          disabled={
            formik.values.statementReadiness ===
            BankStatementReadiness.unselected
          }
        >
          {formik.values.statementReadiness === BankStatementReadiness.notReady
            ? 'Done'
            : 'Next'}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default UploadStatementsPromptModal
