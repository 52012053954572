import { useEffect, useMemo } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { SidebarConfigProps } from '../../reducers/sidebar.slice'
import { MASS_MESSAGE_ADMIN, QA_PAGE_ENABLED } from '../../utils/adminHelpers'
import { Label } from '../BaseComponents'
import {
  getCurrentUser,
  getFreeTrialStatus,
  getUserIsAdmin,
  isCanceled,
} from '../../selectors/user.selectors'
import {
  selectPayrollCompany,
  selectPayrollProfile,
  selectShouldShowPayrollSideNavLink,
} from '../../features/Payroll/payroll.selectors'
import { selectYourTurnNumber } from '../../features/Conversations/conversation.slice'
import { fetchUnreadConversationCount } from '../../features/Conversations/actions'
import {
  fetchPayrollCompany,
  fetchPayrollProfileIfNeeded,
} from '../../features/Payroll/payrollActions'
import { filterNulls, useAppDispatch } from '../../utils/typeHelpers'
import { TrialStatus } from '../../reducers/auth/userReducer'
import { useReselector } from '../../utils/sharedHooks'
import {
  fetchSubscriptions,
  selectIsBasicPlan,
} from '../../reducers/subscription.slice'
import {
  FEATURE_FLAG_KEYS,
  useSetOpenFeatureTargetingKey,
} from '../../features/OpenFeature'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../features/Admin/AnnualTaxDetails/annualTaxDetails.slice'
import {
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
} from '../../features/Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import {
  FETCH_TAX_FILINGS_KEY,
  fetchAnnualTaxFilingsIfNeeded,
} from '../../features/Taxes/AnnualTaxes/annualTaxFilings.slice'
import { getIsFetchingOrNotStarted } from '../../reducers/fetch'
import { isNil } from 'lodash'
import { useBooleanFlagValue, useStringFlagValue } from '@openfeature/react-sdk'
import { selectFinancialAdvisoryProfile } from '../../features/FinancialAdvisory/financialAdvisory.selectors'
import { FinancialAdvisoryProfilesStatus } from '../../features/FinancialAdvisory/financialAdvisory.slice'

export const MESSAGES_ROUTE_KEY = 'messages'

export const useSidebarConfig = (): Record<
  string,
  SidebarConfigProps
> | null => {
  const dispatch = useAppDispatch()
  const isAdmin = useReselector(getUserIsAdmin)
  const user = useReselector(getCurrentUser)
  const payrollProfile = useReselector(selectPayrollProfile)
  const payrollCompany = useReselector(selectPayrollCompany)
  const count = useReselector(selectYourTurnNumber)
  const trialStatus = useReselector(getFreeTrialStatus)
  const shouldShowPayrollSideNavLink = useReselector(
    selectShouldShowPayrollSideNavLink
  )

  const fetchingAnnualTaxDetails = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ALL_ANNUAL_TAX_DETAILS_KEY
  )

  const fetchingAnnualTaxFilings = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_TAX_FILINGS_KEY
  )

  const fetchingAnnualTaxFilingForms = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY
  )

  const annualTaxFormsLoading =
    fetchingAnnualTaxDetails ||
    fetchingAnnualTaxFilings ||
    fetchingAnnualTaxFilingForms

  const releaseGuidedOnboarding = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.guidedOnboardingSetup,
    false
  )
  useSetOpenFeatureTargetingKey()

  useEffect(() => {
    if (user?.id && !isAdmin) {
      dispatch(fetchAllAnnualTaxDetailsIfNeeded())
      dispatch(fetchAnnualTaxFilingsIfNeeded())
      dispatch(fetchAnnualTaxFilingFormsIfNeeded())
      dispatch(fetchUnreadConversationCount())
      dispatch(fetchPayrollCompany())
      dispatch(fetchPayrollProfileIfNeeded())
      dispatch(fetchSubscriptions())
    }
  }, [user?.id, dispatch, isAdmin])

  const isBasicPlan = useReselector(selectIsBasicPlan)
  const isCanceledAccount = useReselector(isCanceled)

  const financialAdvisoryPilotEnabled =
    useStringFlagValue(FEATURE_FLAG_KEYS.financialAdvisoryPilot, 'no_pilot') ===
    'pilot_enabled'

  const financialAdvisoryProfile = useReselector(selectFinancialAdvisoryProfile)
  const payrollPages = useMemo(() => {
    if (isAdmin || !user?.payrollEnabledAt) {
      return []
    }

    if (
      !payrollProfile?.isActive ||
      payrollCompany?.company_status !== 'Approved'
    ) {
      if (payrollProfile?.needsMigration) {
        return [{ end: true, label: 'Set up', to: '/payroll/migrate' }]
      } else {
        return [
          {
            end: true,
            label: 'Enroll in Payroll',
            to: '/payroll/enroll/intro',
          },
        ]
      }
    }
    return [
      { end: true, label: 'Run Payroll', to: '/payroll/run_payroll' },
      {
        end: true,
        label: 'Employees & Contractors',
        to: '/payroll/employees',
      },
      { end: true, label: 'Payroll History', to: '/payroll/history' },
      {
        end: true,
        label: 'Payroll Documents',
        to: '/payroll/documents',
      },
      {
        end: true,
        label: 'Payroll Settings',
        to: '/payroll/settings',
      },
    ]
  }, [
    isAdmin,
    payrollCompany?.company_status,
    payrollProfile?.isActive,
    payrollProfile?.needsMigration,
    user?.payrollEnabledAt,
  ])

  const advisoryPages = useMemo(() => {
    if (isAdmin) {
      return []
    }
    if (
      financialAdvisoryProfile &&
      (financialAdvisoryProfile?.status ===
        FinancialAdvisoryProfilesStatus.active ||
        financialAdvisoryProfile?.status ===
          FinancialAdvisoryProfilesStatus.plan_completed)
    ) {
      return [
        { end: true, label: 'Overview', to: '/advisory/overview' },
        { end: true, label: 'Goals', to: '/advisory/goals' },
        { end: true, label: 'Message Advisor', to: '/advisory/messages' },
      ]
    } else {
      return [{ end: true, label: 'Enroll', to: '/advisory/overview' }]
    }
  }, [isAdmin, financialAdvisoryProfile])

  if (!isAdmin && annualTaxFormsLoading) {
    return null
  }

  if (isAdmin) {
    return {
      overview: {
        end: true,
        label: 'Overview',
        to: '/admin/finances',
      },
      users: {
        icon: regular('users'),
        label: 'Users',
        pages: filterNulls([
          {
            end: true,
            label: 'All Users',
            to: '/admin/finances/list-users',
          },
          {
            end: true,
            label: 'All Internal Users',
            to: '/admin/finances/all-internal-users',
          },
          {
            end: true,
            label: 'All Payroll Users',
            to: '/admin/payroll/users',
          },
          {
            end: true,
            label: 'Trigger SendGrid Emails',
            to: '/admin/finances/email-users',
          },
          MASS_MESSAGE_ADMIN(user)
            ? {
                end: true,
                label: 'Zendesk Bulk Messaging',
                to: '/admin/finances/mass-messaging',
              }
            : undefined,
          QA_PAGE_ENABLED()
            ? {
                end: true,
                label: 'QA Users',
                to: '/admin/finances/qa',
              }
            : undefined,
        ]),
      },
      taxes: {
        icon: regular('university'),
        label: 'Taxes',
        pages: [
          {
            end: true,
            label: 'Annual Tax Filings',
            to: '/admin/finances/annual-tax-filings',
          },
          {
            end: true,
            label: '1099-NEC Tax Filings',
            to: '/admin/finances/abound-tax-filings',
          },
          {
            end: true,
            label: 'QTE Calculations',
            to: '/admin/finances/qte-calculations',
          },
          {
            end: true,
            label: 'Federal Income Tax Details',
            to: '/admin/finances/federal-income-tax',
          },
          {
            end: true,
            label: 'State Income Tax Details',
            to: '/admin/finances/state-income-tax',
          },
          {
            end: true,
            label: 'Tax Checklist',
            to: '/admin/finances/tax-checklist',
          },
        ],
      },
      books: {
        icon: regular('book'),
        label: 'Books',
        pages: [
          {
            end: true,
            label: 'Bookkeeping CRM',
            to: '/admin/finances/books/crm',
          },
          {
            end: true,
            label: 'Incomplete Books',
            to: '/admin/finances/books/incomplete',
          },
          { end: true, label: 'All Books', to: '/admin/finances/books' },
        ],
      },
      businessRules: {
        icon: regular('suitcase'),
        label: 'Business Rules',
        pages: [
          {
            end: true,
            label: 'Autocategorization Rules',
            to: '/admin/finances/transaction-rules',
          },
          {
            end: true,
            label: 'Chart of Accounts',
            to: '/admin/finances/transaction-categories',
          },
          {
            end: true,
            label: 'Action Items',
            to: '/admin/finances/all-action-items',
          },
          {
            end: true,
            label: 'User Document Categories',
            to: '/admin/finances/user-document-categories',
          },
          {
            end: true,
            label: 'Quarterly Tax Estimate Details',
            to: '/admin/finances/quarterly-tax-estimate-details',
          },
          {
            end: true,
            label: 'Annual Tax Details',
            to: '/admin/finances/annual-tax-details',
          },
        ],
      },
      profile: {
        icon: regular('user'),
        label: 'Profile',
        pages: [
          { end: true, label: 'Your Admin Profile', to: '/admin/profile' },
        ],
      },
    }
  }

  const isFreeTrial = trialStatus === TrialStatus.active

  /*
    For trial users, we only show the payroll link if they have
    Gusto payroll (already setup on Gusto.com) and will be migrating it to Heard.
    If they already have it setup, "needsMigration" will be true.

    Pseudocode for showPayrollLink
      If payroll is enabled
        if user is **not** on a free trial
          show link
        else
          show link only if payrollProfile needs migration
      else
        don't show
  */
  const showPayrollLink =
    Boolean(user?.payrollEnabledAt) &&
    (!isFreeTrial || payrollProfile?.needsMigration)

  const showReferralsLink = process.env.VITE_REFERRAL_LINK_VISIBLE === 'true'

  /**
   * For Users who have a canceled plan — we do not show anything outside of Homepage
   */
  if (isCanceledAccount) {
    return {
      home: {
        end: true,
        exact: true,
        icon: regular('home'),
        label: 'Home',
        to: '/accounts/canceled',
        match: '/',
      },
    }
  }

  if (releaseGuidedOnboarding && isNil(user?.onboardingItemsCompletedAt)) {
    return {
      onboarding: {
        end: true,
        icon: regular('compass'),
        label: 'Get Started with Heard',
        to: '/onboarding-v2',
      },
    }
  }

  return {
    home: {
      end: true,
      exact: true,
      icon: regular('home'),
      label: 'Home',
      to: '/dashboard',
      match: '/',
    },
    [MESSAGES_ROUTE_KEY]: {
      end: true,
      icon: regular('envelope'),
      label: 'Conversations',
      ornament: (
        <Label color="green" circular>
          {count}
        </Label>
      ),
      to: '/conversations',
    },
    yourBusiness: {
      icon: regular('building'),
      label: 'Your Practice',
      pages: [
        {
          end: true,
          label: 'Profile',
          to: '/practice/profile',
        },
        { end: true, label: 'Documents', to: '/practice/documents' },
      ],
    },
    bookKeeping: {
      icon: regular('receipt'),
      label: 'Bookkeeping',
      pages: [
        { end: true, label: 'Transactions', to: '/transactions' },
        { label: 'Profit & Loss', to: '/reports/profit-loss/' },
        { end: true, label: 'Allocation Guides', to: '/allocation-guides' },
      ],
    },
    taxes: {
      icon: regular('university'),
      label: 'Taxes',
      pages: [
        { label: 'Quarterly', to: '/taxes/quarterly', end: true },
        {
          label: 'Annual',
          to: '/taxes/annual',
          icon: isBasicPlan ? regular('lock') : undefined,
        },
      ],
    },
    insights: {
      icon: regular('chart-mixed'),
      label: 'Insights',
      pages: [
        {
          label: 'Monthly Reports',
          match: '/reports/bookkeeping',
          to: '/reports/bookkeeping/overview',
        },
        { label: 'Annual Reports', to: '/reports/yearly' },
        {
          end: true,
          label: 'Income',
          to: '/insights/income',
        },
        {
          end: true,
          label: 'Expenses',
          to: '/insights/expenses',
        },
        {
          end: true,
          label: 'Profit',
          to: '/insights/profit',
        },
      ],
    },
    ...(shouldShowPayrollSideNavLink && {
      payroll: {
        icon: regular('envelope-open-dollar'),
        label: 'Payroll',
        to: '/payroll/welcome',
        end: true,
      },
    }),
    ...(showPayrollLink && {
      payroll: {
        icon: regular('envelope-open-dollar'),
        label: 'Payroll',
        pages: payrollPages,
        topRoute: '/payroll',
      },
    }),
    ...(financialAdvisoryPilotEnabled && {
      advisory: {
        icon: regular('envelope-open-dollar'),
        label: 'Financial Advisory',
        pages: advisoryPages,
        topRoute: '/advisory',
      },
    }),
    resources: {
      icon: regular('question-circle'),
      label: 'Resources',
      pages: [
        {
          href: 'https://support.joinheard.com/',
          label: 'Support Center',
          newPage: true,
        },
        {
          href: 'https://www.joinheard.com/resources',
          label: 'Resource Hub',
          newPage: true,
        },
      ],
    },
    ...(showReferralsLink && {
      referrals: {
        icon: regular('gift'),
        label: `Rewards - Get $${process.env.VITE_REFERRAL_FACTORY_REWARD_DOLLARS_SENDER}`,
        to: '/rewards',
      },
    }),
  }
}
