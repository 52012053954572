import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Button } from 'semantic-ui-react'
import moment from 'moment'
import { debounce } from 'lodash'

import { fetchTransactionCategoriesIfNeeded } from '../../features/Reports/reports.slice'
import {
  updateTransactionFilters,
  clearTransactionFilters,
  UpdateFilterPayload,
  TransactionFiltersState,
} from '../../reducers/finances/transactionFiltersReducer'
import { fetchTransactionCounts } from '../../actions/adminActions'
import { clearSelectedTransactions } from '../../reducers/admin/selectedTransactionsReducer'
import {
  Dispatch,
  filterNulls,
  ReduxState,
  wrapDispatch,
} from '../../utils/typeHelpers'
import { formatAccountName } from '../../features/Transactions/helpers'
import { getUserFinancialAccounts } from '../../selectors/financeSelectors'
import { DatePicker, Dropdown, Input } from '../BaseComponents'
import { getTransactionCategoryOptions } from '../../features/Reports/reports.selectors'
import { DATE_FORMATS } from '../../utils/dateHelpers'

type ParentProps = {
  userId?: number
  onFilterChange?: () => void
  supportV2?: boolean
}
type LocalState = {
  descriptionSearch: string | null
  notesSearch: string | null
}
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = ParentProps & StateProps & DispatchProps

class TransactionFilters extends Component<Props, LocalState> {
  constructor(props: Props) {
    super(props)
    // Need local state to debounce the search input for description and notes
    this.state = {
      descriptionSearch: props.transactionFilters.description,
      notesSearch: props.transactionFilters.notes,
    }
  }

  async componentDidMount() {
    await this.props.fetchTransactionCategoriesIfNeeded()
    const startDate = moment(this.props.transactionFilters.startDate)
    const endDate = moment(this.props.transactionFilters.endDate)
    await this.props.fetchTransactionCounts({
      userId: this.props.userId,
      startDate: startDate.isValid() ? startDate : undefined,
      endDate: endDate.isValid() ? endDate : undefined,
    })
  }

  onFilterChange() {
    if (this.props.onFilterChange) {
      this.props.onFilterChange()
    }
  }

  handleTransactionType = async (
    transactionsType: string,
    sortVal?: string
  ) => {
    this.onFilterChange()
    await this.props.updateTransactionFilters({
      transactionsType,
      sortVal,
    })
  }

  debounceTransactionFiltersUpdate = debounce(
    (filterState: Partial<TransactionFiltersState>) => {
      this.onFilterChange()
      this.props.updateTransactionFilters(filterState)
    },
    500
  )

  handleClearFilters = async () => {
    this.onFilterChange()
    this.setState({ descriptionSearch: null, notesSearch: null })
    await this.props.clearTransactionFilters()
  }

  handleDateRangeChange = async ([startDate, endDate]: [string, string]) => {
    this.onFilterChange()
    await this.props.updateTransactionFilters({
      startDate: startDate || null,
      endDate: endDate || moment().format(DATE_FORMATS.INPUT),
    })
  }

  // skipcq: JS-R1005
  render() {
    const {
      transactionFilters: {
        transactionsType,
        startDate,
        endDate,
        transactionCategoryId,
        financialAccountId,
      },
      clearSelectedTransactions,
      financialAccounts,
      updateTransactionFilters,
      transactionsCount,
      supportV2,
    } = this.props

    const bankAccountOptions =
      financialAccounts &&
      filterNulls(Object.values(financialAccounts)).map((account) => ({
        text: formatAccountName(account),
        value: account.id,
      }))

    return (
      <Grid className="transactionFilter" stackable doubling>
        <Grid.Row>
          <Grid.Column width={13}>
            <Button
              basic
              className={transactionsType === 'all' ? 'link active' : 'link'}
              onClick={() => this.handleTransactionType('all')}
            >
              All
            </Button>
            <Button
              basic
              className={
                transactionsType === 'pending' ? 'link active' : 'link'
              }
              onClick={() => this.handleTransactionType('pending')}
            >
              Pending{' '}
              {transactionsCount?.pendingCount
                ? transactionsCount?.pendingCount > 0
                  ? `(${transactionsCount?.pendingCount})`
                  : ''
                : ''}
            </Button>
            <Button
              basic
              className={
                transactionsType === 'uncategorized' ? 'link active' : 'link'
              }
              onClick={() => {
                this.handleTransactionType('uncategorized')
                // The uncategorized tab will only show items without categories and the filter will be disabled so clear it
                this.props.updateTransactionFilters({
                  transactionCategoryId: null,
                })
              }}
            >
              Uncategorized{' '}
              {transactionsCount?.uncategorizedCount
                ? transactionsCount?.uncategorizedCount > 0
                  ? `(${transactionsCount?.uncategorizedCount})`
                  : ''
                : ''}
            </Button>
            <Button
              basic
              className={transactionsType === 'notes' ? 'link active' : 'link'}
              onClick={() => {
                this.handleTransactionType('notes')
              }}
            >
              Notes
            </Button>
            <Button
              basic
              className={
                transactionsType === 'categorized' ? 'link active' : 'link'
              }
              onClick={() => this.handleTransactionType('categorized')}
            >
              Categorized
            </Button>
            <Button
              basic
              className={
                transactionsType === 'excluded' ? 'link active' : 'link'
              }
              onClick={() => this.handleTransactionType('excluded')}
            >
              Personal
            </Button>
            <span>
              <Button
                basic
                className={
                  transactionsType === 'requestedClarification'
                    ? 'link active'
                    : 'link'
                }
                onClick={() =>
                  this.handleTransactionType('requestedClarification')
                }
              >
                Needs Clarification{' '}
                {transactionsCount?.requestedClarificationCount
                  ? transactionsCount?.requestedClarificationCount > 0
                    ? `(${transactionsCount?.requestedClarificationCount})`
                    : ''
                  : ''}
              </Button>
            </span>
            <Button
              basic
              className={
                transactionsType === 'recentlyUpdated' ? 'link active' : 'link'
              }
              onClick={() =>
                this.handleTransactionType('recentlyUpdated', 'updatedAt')
              }
            >
              Recently Updated
            </Button>
          </Grid.Column>

          <Grid.Column width={3} float="right" textAlign="right">
            <Button className="link" onClick={this.handleClearFilters}>
              Clear all Filters
            </Button>
            <Button className="link" onClick={clearSelectedTransactions}>
              Clear all Selected
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: '0' }}>
          <Grid.Column width={supportV2 ? 3 : 6}>
            <DatePicker
              clearable
              value={`${startDate || ''} - ${endDate || ''}`}
              onChange={this.handleDateRangeChange}
              selectsRange
              startDate={startDate || undefined}
              endDate={endDate || undefined}
            />
          </Grid.Column>
          <Grid.Column width={supportV2 ? 3 : 4}>
            <Dropdown
              placeholder="Select Category"
              value={Number(transactionCategoryId) || ''}
              options={this.props.categoryOptions}
              onChange={(value) => {
                this.onFilterChange()
                updateTransactionFilters({
                  transactionCategoryId:
                    typeof value === 'number' ? value.toString() : null,
                })
              }}
              disabled={transactionsType === 'uncategorized'}
              selectOnBlur={false}
              search
              clearable
              fluid
            />
          </Grid.Column>
          <Grid.Column width={supportV2 ? 3 : 4}>
            {Boolean(bankAccountOptions?.length) && (
              <Dropdown
                placeholder="Select Bank Account"
                value={financialAccountId || ''}
                options={bankAccountOptions}
                onChange={(value) => {
                  this.onFilterChange()
                  updateTransactionFilters({
                    financialAccountId:
                      typeof value === 'number' ? value : null,
                  })
                }}
                selectOnBlur={false}
                clearable
                fluid
              />
            )}
          </Grid.Column>
          {supportV2 && (
            <>
              <Grid.Column width={3}>
                <Input
                  type="text"
                  placeholder="Description"
                  clearable
                  fullWidth
                  value={this.state.descriptionSearch || ''}
                  onChange={(value) => {
                    this.setState({ descriptionSearch: value })
                    this.debounceTransactionFiltersUpdate({
                      description: value,
                    })
                  }}
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <Input
                  type="text"
                  placeholder="Notes"
                  clearable
                  fullWidth
                  value={this.state.notesSearch || ''}
                  onChange={(value) => {
                    this.setState({ notesSearch: value })
                    this.debounceTransactionFiltersUpdate({ notes: value })
                  }}
                />
              </Grid.Column>
            </>
          )}
        </Grid.Row>
      </Grid>
    )
  }
}

const mapStateToProps = (state: ReduxState, props: ParentProps) => ({
  transactionFilters: state.transactionFilters,
  financialAccounts: props.userId
    ? getUserFinancialAccounts(state, props.userId)
    : [],
  categoryOptions: getTransactionCategoryOptions(state),
  transactionsCount: state.admin.allTransactions.transactionsCount,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateTransactionFilters: (payload: UpdateFilterPayload) =>
    dispatch(updateTransactionFilters(payload)),
  fetchTransactionCategoriesIfNeeded: () =>
    dispatch(fetchTransactionCategoriesIfNeeded()),
  clearTransactionFilters: () => dispatch(clearTransactionFilters()),
  clearSelectedTransactions: () => dispatch(clearSelectedTransactions()),
  fetchTransactionCounts: wrapDispatch(dispatch, fetchTransactionCounts),
})

export default connect<StateProps, DispatchProps, ParentProps, ReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(TransactionFilters)
