import { useCallback, useEffect, useState } from 'react'

import { markUserActionItemCompleteIfExists } from '../Dashboard/UserActionItems/service'
import { OnboardingTaskIdents, prevYearMonth, TaskCTAButton } from './config'
import FileUploadModal from '../../components/FileUpload/FileUploadModal'
import { UploadDocumentType } from '../../constants/businessConstants'
import { Text } from '../../components/BaseComponents'
import moment from 'moment'
import { useReselector } from '../../utils/sharedHooks'
import { selectFinancialAccounts } from '../../selectors/financeSelectors'
import { fetchFinancialAccountsIfNeeded } from '../../actions/financialAccountActions'
import UploadStatementsReadinessCheck from './UploadStatementsReadinessCheck'
import OnboardingSkipModal from './OnboardingSkipModal'
import {
  fetchUserActionItemsIfNeeded,
  updateUserActionItem,
} from '../Dashboard/UserActionItems/userActionItems.slice'
import { getUserActionItemByActionItemIdentifier } from '../Dashboard/UserActionItems/userActionItems.selectors'
import { UploadPrevMonthStatementStatus } from '../Dashboard/UserActionItems/userActionItemStatuses'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { useAnalyticsTrack } from '../Amplitude'
import { useAppDispatch } from '../../utils/typeHelpers'

enum ModalSteps {
  readinessCheck = 'readinessCheck',
  skipConfirmation = 'skipConfirmation',
  uploadFiles = 'uploadFiles',
}

const UploadPrevMonthStatementsButton = ({
  completedAt,
  onClick,
}: {
  completedAt?: string
  onClick?: () => void
}) => {
  const [modalStep, setModalStep] = useState<ModalSteps>()
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()

  useEffect(() => {
    dispatch(fetchFinancialAccountsIfNeeded(true))
    dispatch(fetchUserActionItemsIfNeeded())
  }, [dispatch])

  const actionItem = useReselector(
    getUserActionItemByActionItemIdentifier,
    OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS
  )

  const onUploadButtonClicked = useCallback(async () => {
    if (actionItem) {
      await dispatch(
        updateUserActionItem(actionItem.id, {
          id: actionItem.id,
          status: UploadPrevMonthStatementStatus.uploaded,
        })
      )
    }

    markUserActionItemCompleteIfExists(
      OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS,
      (event, properties) => track(event, properties)
    )

    track('completed onboarding item upload statements', {
      status: 'done',
    })
  }, [actionItem, dispatch, track])

  const onSkipConfirmed = useCallback(async () => {
    if (actionItem) {
      await dispatch(
        updateUserActionItem(actionItem.id, {
          id: actionItem.id,
          status: UploadPrevMonthStatementStatus.skipped,
        })
      )
    }
    markUserActionItemCompleteIfExists(
      OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS,
      (event, properties) => track(event, properties)
    )
    track('completed onboarding item upload statements', {
      status: 'skipped',
    })
    setModalStep(undefined)
  }, [actionItem, dispatch, track])

  const monthYearString = moment()
    .subtract(1, 'M')
    .format(DATE_FORMATS.MONTH_YEAR)

  const accountsObj = useReselector(selectFinancialAccounts)
  const accountsArray = accountsObj ? Object.values(accountsObj) : []

  return (
    <>
      <TaskCTAButton
        disabled={
          Boolean(completedAt) &&
          actionItem?.status !== UploadPrevMonthStatementStatus.skipped
        }
        isEditable={
          actionItem?.status === UploadPrevMonthStatementStatus.skipped
        }
        completedAt={completedAt}
        completedText="Done"
        onClick={() => {
          setModalStep(ModalSteps.readinessCheck)
          if (onClick) onClick()
        }}
      />
      <UploadStatementsReadinessCheck
        open={modalStep === ModalSteps.readinessCheck}
        onClose={() => setModalStep(undefined)}
        onSkipClicked={() => {
          setModalStep(ModalSteps.skipConfirmation)
        }}
        onReadyClicked={() => {
          setModalStep(ModalSteps.uploadFiles)
        }}
        onNotReadyClicked={async () => {
          if (actionItem) {
            await dispatch(
              updateUserActionItem(actionItem.id, {
                id: actionItem.id,
                status: UploadPrevMonthStatementStatus.noStatement,
              })
            )
          }
          setModalStep(undefined)
          await markUserActionItemCompleteIfExists(
            OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS,
            (event, properties) => track(event, properties)
          )

          track('completed onboarding item upload statements', {
            status: 'in progress',
          })
        }}
      />
      <OnboardingSkipModal
        open={modalStep === ModalSteps.skipConfirmation}
        onClose={() => setModalStep(ModalSteps.readinessCheck)}
        onSkipped={onSkipConfirmed}
        subtitle="If you skip uploading your statements, we won't be able to start bookkeeping for you. Don't worry - you can still tour Heard and upload your statements later."
      />
      <FileUploadModal
        open={modalStep === ModalSteps.uploadFiles}
        close={() => setModalStep(undefined)}
        userFacing
        documentType={UploadDocumentType.STATEMENT}
        setUploadedFile={onUploadButtonClicked}
        monthYear={prevYearMonth}
        hideModalHeaderText
        title="Upload last month's statements"
        description={
          <Text>
            We&apos;ll need your statements for {monthYearString} for the
            following accounts in order to get started with bookkeeping for your
            business.
            <br />
            <br />
            <ul>
              {accountsArray?.map((acct) => (
                <li key={`account-${acct?.id}-name`}>
                  {acct?.name} {acct?.mask}
                </li>
              ))}
            </ul>
          </Text>
        }
      />
    </>
  )
}

export default UploadPrevMonthStatementsButton
