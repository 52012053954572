import { Grid } from 'semantic-ui-react'

import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../../utils/deviceWidthHelpers'
import { formatCurrency } from '../../../../../utils/currencyHelpers'
import { useHistoricalBookkeepingPrice } from '../../hooks'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectProductDescription } from '../../../../../selectors/user.selectors'
import {
  Card,
  GridRowColumn,
  Popup,
  Text,
} from '../../../../../components/BaseComponents'
import DetailsAccordion from './DetailsAccordion'
import { DateTime } from 'luxon'
import currency from 'currency.js'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getAnnualTaxFilingForYearSelector } from '../../../../Taxes/AnnualTaxes/annualTaxFilings.selector'
import { getBookkeepingMonthsThisYearForPricing } from '../../../../../constants/pricingConstants'

const HistoricalBookkeepingSection = () => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const historicalBookkeepingPrice = useHistoricalBookkeepingPrice()
  const productDescription = useReselector(selectProductDescription, false, [
    'bookkeeping' as const,
  ])

  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const taxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentTaxYear
  )

  const includePriorYearTaxes = Boolean(taxFiling?.isLateJoiner)
  const monthsSoFar = getBookkeepingMonthsThisYearForPricing()

  if (
    !historicalBookkeepingPrice ||
    (!includePriorYearTaxes && monthsSoFar === 0)
  ) {
    return null
  }

  const pricePerMonth = currency(historicalBookkeepingPrice.costPerMonth)
  const costForThisYear = pricePerMonth.multiply(monthsSoFar)

  const currentYear = DateTime.now().year
  const lastYear = currentYear - 1

  return (
    <GridRowColumn>
      <Grid>
        <Grid.Row>
          <Grid.Column width={9}>
            <Text as={isMobile ? 'h2' : 'h1'}>Historical Bookkeeping</Text>
          </Grid.Column>
          <Grid.Column textAlign="right" width={7}>
            <Text as={isMobile ? 'h2' : 'h1'} style={{ display: 'inline' }}>
              {formatCurrency(historicalBookkeepingPrice.amount, {
                hideDecimalsIfZero: true,
              })}
            </Text>
            <Text as="bodySm" color="darkGray">
              One time fee
            </Text>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn short>
          <Card type="subsection" backgroundColor="natural">
            {monthsSoFar !== 0 && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text as="bodyLg" color="forest">
                  {monthsSoFar} months at{' '}
                  {formatCurrency(pricePerMonth, {
                    hideDecimalsIfZero: true,
                  })}
                  /mo of historical bookkeeping for {currentYear} taxes
                  <Popup
                    content={`In order to ensure that we have the most accurate picture of your finances, a Heard bookkeeper will review, categorize and validate all your ${currentYear} transactions for accuracy.`}
                  />
                </Text>
                <Text as="bodyLg" color="forest">
                  {formatCurrency(costForThisYear, {
                    hideDecimalsIfZero: true,
                  })}
                </Text>
              </div>
            )}
            {includePriorYearTaxes && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text as="bodyLg" color="forest">
                  12 months at{' '}
                  {formatCurrency(pricePerMonth, {
                    hideDecimalsIfZero: true,
                  })}
                  /mo of historical bookkeeping for {lastYear} taxes
                  <Popup
                    content={`The first step in preparing your ${lastYear} taxes is a review your books. A Heard bookkeeper will review, categorize and validate all your ${lastYear} transactions for accuracy. This ensures that Heard finds all of the deductions that you qualify for.`}
                  />
                </Text>
                <Text as="bodyLg" color="forest">
                  {formatCurrency(pricePerMonth.multiply(12), {
                    hideDecimalsIfZero: true,
                  })}
                </Text>
              </div>
            )}
          </Card>
        </GridRowColumn>
        <GridRowColumn className="short">
          <DetailsAccordion productDescription={productDescription} hideTitle />
        </GridRowColumn>
      </Grid>
    </GridRowColumn>
  )
}
export default HistoricalBookkeepingSection
