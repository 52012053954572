import { useCallback, useEffect, useState } from 'react'
import Moment from 'react-moment'
import { Form, Grid, Header } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import { centsToCurrency } from '../../../utils/currencyHelpers'
import {
  fetchSubscriptions,
  selectHeardProductSubscription,
  selectSubscription,
  SUBSCRIPTION_DEFAULT_KEY,
} from '../../../reducers/subscription.slice'
import {
  fetchBillingPortalSession,
  reactivateUserSubscription,
  submitCancelStripeSubscriptionAtPeriodEnd,
  submitCancellationRequest,
  STRIPE_CANCELLATION_REASON_KEYS,
} from '../../../actions/settings/billingActions'
import {
  Button,
  Card,
  Table,
  Text,
  GridRowColumn,
  Label,
  Modal,
  Alert,
  Radio,
} from '../../BaseComponents'
import { DATE_FORMATS, DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { ResponsiveHeader } from './ResponsiveHeader'
import {
  getCurrentUser,
  hasManualRequest,
  isCancelling,
  selectIsUserLateJoinerWithCommitment,
} from '../../../selectors/user.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import {
  DOWNLOAD_ACCOUNT_ZIP_KEY,
  downloadAccountInformation,
  updateMembership,
} from '../../../actions/userActions'
import {
  getAnnualTaxFilingForYearSelector,
  hasTaxfyleJobOrExtensionInProgress,
} from '../../../features/Taxes/AnnualTaxes/annualTaxFilings.selector'
import { selectCurrentAnnualTaxYear } from '../../../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  useAnalyticsTrack,
  useAnalyticsView,
} from '../../../features/Amplitude'
import { sendUserCancellationMessageZap } from '../../../actions/zapierActions'
import { selectIsPayrollActive } from '../../../features/Payroll/payroll.selectors'
import { fetchPayrollProfileIfNeeded } from '../../../features/Payroll/payrollActions'
import AccountDownloadModal from './AccountDownloadModal'
import { selectIsFetchingForKeys } from '../../../reducers/fetch'
import { useAppDispatch } from '../../../utils/typeHelpers'
import InvoiceList from './InvoiceList'

/**
 * This modal handles 3 states
 *
 * State 1: User can Cancel
 *
 * State 2: 2024 Late Joiner, with annual commitment — cannot self serve cancel
 *
 * State 3: User with tax filing job started — cannot self serve cancel
 *
 */

const ConfirmCancellationModal = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()
  const pageView = useAnalyticsView()
  const [currentCancellationScreenStep, setCurrentCancellationScreenStep] =
    useState(0)

  const currentUser = useReselector(getCurrentUser)
  const subscription = useReselector(selectHeardProductSubscription)
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [cancellationComment, setCancellationComment] = useState<
    string | undefined
  >()
  const [cancellationReason, setCancellationReason] = useState<
    string | undefined
  >('')
  const [error, setError] = useState('')
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const hasTaxfyleJob = useReselector(
    hasTaxfyleJobOrExtensionInProgress,
    currentTaxYear
  )
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentTaxYear
  )
  const lateJoinerWithCommitment = useReselector(
    selectIsUserLateJoinerWithCommitment,
    annualTaxFiling
  )
  const isActiveGEPUser = useReselector(selectIsPayrollActive)

  useEffect(() => {
    pageView('confirm cancellation modal')
  }, [pageView])

  useEffect(() => {
    if (
      currentCancellationScreenStep === 0 ||
      (currentCancellationScreenStep === 1 &&
        cancellationReason &&
        cancellationComment)
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [cancellationComment, cancellationReason, currentCancellationScreenStep])

  const cancelRequest = async () => {
    setDisabled(true)
    setLoading(true)
    const res = await submitCancellationRequest(currentUser?.id)(dispatch)
    track('submit manual cancellation request')
    if (res) {
      setDisabled(false)
      setLoading(false)

      await updateMembership(currentUser?.id, {
        manualCancellationRequestedAt: DateTime.now().toString(),
      })(dispatch)
      sendUserCancellationMessageZap(currentUser, 'manual_cancel')
      close()
      location.reload()
    } else {
      setError('We were unable to submit your cancellation request.')
      setDisabled(false)
      setLoading(false)
    }
  }

  const selfServeCancelSubscription = async () => {
    setLoading(true)
    setDisabled(true)
    track('submit stripe cancellation request')

    const res = await submitCancelStripeSubscriptionAtPeriodEnd({
      cancellationComment,
      cancellationReason,
    })(dispatch)
    if (res) {
      sendUserCancellationMessageZap(currentUser, 'submit_cancel')
      setDisabled(false)
      setLoading(false)
      location.reload()
    } else {
      setError('We were unable to submit your cancellation request.')
      setDisabled(false)
      setLoading(false)
    }
  }
  const renderHeader = () => {
    if (hasTaxfyleJob || lateJoinerWithCommitment || isActiveGEPUser) {
      return 'Please note: We cannot process your cancellation automatically'
    } else {
      return 'Cancel subscription'
    }
  }

  const renderModalBody = () => {
    const endDate = subscription?.nextBillingDate
      ? DateTime.fromSeconds(subscription?.nextBillingDate).toFormat(
          DATE_FORMATS_LUXON.DISPLAY_SHORT
        )
      : 'the end of your billing period'
    if (currentCancellationScreenStep === 1) {
      return (
        <Form>
          <Form.Field>
            <Form.Group grouped className="required">
              <label>
                Before you go, we’d appreciate it if you could take a moment to
                let us know why you’re cancelling:{' '}
              </label>
              <br />
              <Form.Field>
                <Radio
                  label="I need more features"
                  name={STRIPE_CANCELLATION_REASON_KEYS.missingFeatures}
                  checked={
                    cancellationReason ===
                    STRIPE_CANCELLATION_REASON_KEYS.missingFeatures
                  }
                  onChange={() =>
                    setCancellationReason(
                      STRIPE_CANCELLATION_REASON_KEYS.missingFeatures
                    )
                  }
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Ease of use was less than expected"
                  name={STRIPE_CANCELLATION_REASON_KEYS.tooComplex}
                  checked={
                    cancellationReason ===
                    STRIPE_CANCELLATION_REASON_KEYS.tooComplex
                  }
                  onChange={() =>
                    setCancellationReason(
                      STRIPE_CANCELLATION_REASON_KEYS.tooComplex
                    )
                  }
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Quality was less than expected"
                  name={STRIPE_CANCELLATION_REASON_KEYS.lowQuality}
                  checked={
                    cancellationReason ===
                    STRIPE_CANCELLATION_REASON_KEYS.lowQuality
                  }
                  onChange={() =>
                    setCancellationReason(
                      STRIPE_CANCELLATION_REASON_KEYS.lowQuality
                    )
                  }
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="It’s too expensive"
                  name={STRIPE_CANCELLATION_REASON_KEYS.tooExpensive}
                  checked={
                    cancellationReason ===
                    STRIPE_CANCELLATION_REASON_KEYS.tooExpensive
                  }
                  onChange={() =>
                    setCancellationReason(
                      STRIPE_CANCELLATION_REASON_KEYS.tooExpensive
                    )
                  }
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="I found an alternative"
                  name={STRIPE_CANCELLATION_REASON_KEYS.switchedService}
                  checked={
                    cancellationReason ===
                    STRIPE_CANCELLATION_REASON_KEYS.switchedService
                  }
                  onChange={() =>
                    setCancellationReason(
                      STRIPE_CANCELLATION_REASON_KEYS.switchedService
                    )
                  }
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Customer service was less than expected"
                  name={STRIPE_CANCELLATION_REASON_KEYS.customerService}
                  checked={
                    cancellationReason ===
                    STRIPE_CANCELLATION_REASON_KEYS.customerService
                  }
                  onChange={() =>
                    setCancellationReason(
                      STRIPE_CANCELLATION_REASON_KEYS.customerService
                    )
                  }
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="I no longer need it"
                  name={STRIPE_CANCELLATION_REASON_KEYS.unused}
                  checked={
                    cancellationReason ===
                    STRIPE_CANCELLATION_REASON_KEYS.unused
                  }
                  onChange={() =>
                    setCancellationReason(
                      STRIPE_CANCELLATION_REASON_KEYS.unused
                    )
                  }
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Other reasons"
                  name={STRIPE_CANCELLATION_REASON_KEYS.other}
                  checked={
                    cancellationReason === STRIPE_CANCELLATION_REASON_KEYS.other
                  }
                  onChange={() =>
                    setCancellationReason(STRIPE_CANCELLATION_REASON_KEYS.other)
                  }
                />
              </Form.Field>
            </Form.Group>
          </Form.Field>
          <br />
          <Form.Field required>
            <Form.TextArea
              label="Anything we could have improved on?"
              placeholder="Add additional comments"
              name="cancellationComment"
              value={cancellationComment}
              onChange={(e, target) =>
                setCancellationComment(target.value?.toString())
              }
              fluid
              required
            />
          </Form.Field>
        </Form>
      )
    } else if (hasTaxfyleJob) {
      return (
        <>
          <Text as="bodyLg">
            Since we have already started filing your {currentTaxYear} taxes, we
            cannot automatically cancel your subscription until your taxes are
            transmitted to the IRS. You have until {endDate} to get your taxes
            fully filed before your next renewal.
          </Text>
          <br />
          <Text as="bodyLg">
            If you want to continue, you can submit a request to cancel your
            subscription.
          </Text>
        </>
      )
    } else if (lateJoinerWithCommitment) {
      return (
        <>
          <Text as="bodyLg">
            Because you signed up this year and opted into {currentTaxYear}{' '}
            taxes, your contract includes an annual commitment to Heard.
          </Text>
          <br />
          <Card type="subsection" backgroundColor="lightYellow">
            <b>What does this mean?</b>
            <ul>
              <li>Refunds are not available.</li>
              <li>You may owe the remaining balance of your subscription.</li>
            </ul>
          </Card>
          <Text as="bodyLg">
            If you want to continue, you can submit a request to cancel your
            subscription.
          </Text>
        </>
      )
    } else {
      return (
        <>
          <Text as="bodyLg">
            You will have access to Heard until{' '}
            <b>
              {subscription?.nextBillingDate
                ? DateTime.fromSeconds(subscription?.nextBillingDate).toFormat(
                    DATE_FORMATS_LUXON.DISPLAY_SHORT
                  )
                : 'the end of your billing period'}
            </b>
            .
          </Text>
          <Card type="subsection" backgroundColor="lightRed">
            <Text as="bodyLg">
              <b>Are you sure you want to cancel?</b>
            </Text>
            <br />
            <Text as="bodyLg">
              After your access ends:
              <ul>
                <li>
                  We will no longer file {currentTaxYear} taxes, provide
                  quarterly tax estimates or categorize transactions for you.
                </li>
                <li>
                  You will no longer receive quarterly tax estimates or
                  bookkeeping services
                </li>
                <li>
                  You will no longer have access to your Profit & Loss and other
                  reports
                </li>
              </ul>
            </Text>
          </Card>
          <Text as="bodyLg">
            You will have the opportunity to download your account information
            after cancellation.
          </Text>
        </>
      )
    }
  }

  const renderActions = () => {
    if (hasTaxfyleJob || lateJoinerWithCommitment || isActiveGEPUser) {
      return (
        <Modal.Actions>
          <Button
            variant="secondary"
            onClick={() => {
              setCurrentCancellationScreenStep(0)
              close()
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={cancelRequest}
            disabled={disabled}
            loading={loading}
          >
            Submit Request
          </Button>
        </Modal.Actions>
      )
    } else {
      switch (currentCancellationScreenStep) {
        // User has clicked Yes to cancel and needs to fill in the
        case 1:
          // user is in first screen
          return (
            <Modal.Actions>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => selfServeCancelSubscription()}
                disabled={disabled}
                loading={loading}
              >
                Confirm and Cancel Your Heard Plan
              </Button>
            </Modal.Actions>
          )
        default:
          return (
            <Modal.Actions>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setCurrentCancellationScreenStep(1)
                  track('click confirm cancel')
                }}
                disabled={disabled}
                loading={loading}
              >
                Yes, I want to cancel
              </Button>
            </Modal.Actions>
          )
      }
    }
  }

  return (
    <Modal
      size={'small'}
      dimmer={'inverted'}
      className="ConfirmCancellationModal"
      open={open}
      onClose={close}
    >
      <Modal.Header>
        <Header as="h2">{renderHeader()}</Header>
      </Modal.Header>
      <Modal.Content>
        {error && <Alert type="error">{error}</Alert>}
        {renderModalBody()}
      </Modal.Content>
      <Modal.Actions>{renderActions()}</Modal.Actions>
    </Modal>
  )
}

const ReactivatePlanModal = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const dispatch = useAppDispatch()
  const subscription = useReselector(selectHeardProductSubscription)
  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState('')
  const track = useAnalyticsTrack()
  const pageView = useAnalyticsView()
  const currentUser = useReselector(getCurrentUser)

  useEffect(() => {
    pageView('reactivate plan modal')
  }, [pageView])

  const reactivatePlan = async () => {
    setDisabled(true)
    track('submit reactivate plan request')

    const result = await reactivateUserSubscription(subscription?.id)(dispatch)
    if (result) {
      sendUserCancellationMessageZap(
        currentUser,
        'reactivated_cancelling_account'
      )
      setDisabled(false)
      close()
      location.reload()
    } else {
      setError('We were unable to reactive your Plan. Please contact support.')
    }
  }

  return (
    <Modal
      size={'small'}
      dimmer={'inverted'}
      className="ReactivatePlanModal"
      open={open}
      onClose={close}
    >
      <Modal.Header>
        <Header as="h2">Reactive your Heard Plan</Header>
      </Modal.Header>
      <Modal.Content>
        {error && <Alert type="error">{error}</Alert>}
        <Text as="bodyLg">
          You have full access to Heard until your billing cycle ends on{' '}
          <Moment format={DATE_FORMATS.DISPLAY_LONG} unix>
            {subscription?.nextBillingDate}
          </Moment>
          .
        </Text>
        <br />
        <Text as="bodyLg">
          Reactivating your Heard plan means that you will be charged on your
          next billing date.
        </Text>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        <Button variant="primary" onClick={reactivatePlan} disabled={disabled}>
          Reactivate Plan
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const PlansAndBillingPanel = () => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()
  const pageView = useAnalyticsView()
  const subscription = useReselector(selectSubscription)
  const primarySubscription = useReselector(selectHeardProductSubscription)
  const membershipIsCancelling = useReselector(isCancelling)
  const membershipHasManualRequest = useReselector(hasManualRequest)
  const currentUser = useReselector(getCurrentUser)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const [reactivateOpen, setReactivateOpen] = useState(false)
  const [cancelPlanModalOpen, setCancelPlanModalOpen] = useState(false)
  const [downloadModalOpen, setDownloadModalOpen] = useState(false)
  const downloadingFiles = useReselector(selectIsFetchingForKeys, [
    DOWNLOAD_ACCOUNT_ZIP_KEY,
  ])

  useEffect(() => {
    pageView('plans and billing page')
  }, [pageView])

  const redirectToStripePortal = useCallback(async () => {
    track('manage stripe plan')

    const res = await fetchBillingPortalSession()(dispatch)
    if (res) {
      window.location.href = res.url
    }
  }, [dispatch, track])

  const downloadZipFile = async () => {
    track('download account zip file')

    const result = await downloadAccountInformation(currentUser?.id)(dispatch)
    if (result) {
      setDownloadModalOpen(true)
    }
  }

  useEffect(() => {
    dispatch(fetchSubscriptions())
    dispatch(fetchPayrollProfileIfNeeded())
  }, [dispatch])

  return (
    <Card>
      <Grid>
        <GridRowColumn>
          <ResponsiveHeader content="Plan & Billing" />
        </GridRowColumn>
        <GridRowColumn>
          <Table basic="very">
            {!isMobile && (
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Plan</Table.HeaderCell>
                  <Table.HeaderCell>Price</Table.HeaderCell>
                  <Table.HeaderCell>Billing Start Date</Table.HeaderCell>
                  <Table.HeaderCell>Next Billing Date</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            )}

            <Table.Body>
              {Object.values(subscription).map((sub) => (
                <Table.Row key={sub.id}>
                  <Table.Cell>{sub.name}</Table.Cell>
                  <Table.Cell>
                    {Object.values(sub.items).map((item) => (
                      <p key={item.id}>
                        {item.name !== SUBSCRIPTION_DEFAULT_KEY && (
                          <>
                            {item.name}
                            {item.quantity > 1 && (
                              <>&nbsp;({item.quantity} total)</>
                            )}
                            :&nbsp;
                          </>
                        )}
                        <Text>
                          <CurrencyFormatLabel
                            value={
                              centsToCurrency(item.amountInCents).multiply(
                                item.quantity
                              ).value
                            }
                          />
                        </Text>
                      </p>
                    ))}
                  </Table.Cell>
                  {isMobile && <Table.Cell />}
                  <Table.Cell>
                    {isMobile && <Text as="bodyXs"> BILLING START DATE </Text>}
                    <Moment format={DATE_FORMATS.DISPLAY_LONG} unix>
                      {sub.startDate}
                    </Moment>
                  </Table.Cell>
                  <Table.Cell>
                    {isMobile && <Text as="bodyXs"> NEXT BILLING DATE </Text>}
                    <Moment format={DATE_FORMATS.DISPLAY_LONG} unix>
                      {sub.nextBillingDate}
                    </Moment>
                  </Table.Cell>
                </Table.Row>
              ))}
              {membershipIsCancelling && (
                <Table.Row>
                  <Table.Cell>
                    <Label color="red" icon={regular('triangle-exclamation')}>
                      Subscription Ending on:{' '}
                      <Moment format={DATE_FORMATS.DISPLAY_LONG} unix>
                        {primarySubscription?.nextBillingDate}
                      </Moment>
                    </Label>
                  </Table.Cell>
                </Table.Row>
              )}
              {membershipHasManualRequest && (
                <Table.Row>
                  <Table.Cell>
                    <Label color="red" icon={regular('triangle-exclamation')}>
                      You&#39;ve requested the cancellation of your Heard plan.
                      We&#39;ll get back to you as soon as we can.
                    </Label>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </GridRowColumn>
        {membershipIsCancelling &&
          process.env.VITE_CANCELLATION_SELF_SERVE_ENABLED && (
            <Grid.Row id="reactivation">
              <Grid.Column width={4}>
                <Button
                  variant="secondary"
                  fullWidth
                  onClick={() => {
                    setReactivateOpen(true)
                    track('click reactivate plan')
                  }}
                >
                  Reactivate Plan
                </Button>
                {reactivateOpen && (
                  <ReactivatePlanModal
                    open={reactivateOpen}
                    close={() => setReactivateOpen(false)}
                  />
                )}
              </Grid.Column>
              <Grid.Column width={4}>
                <Button
                  variant="secondary"
                  fullWidth
                  onClick={downloadZipFile}
                  loading={downloadingFiles}
                  disabled={downloadingFiles}
                >
                  Download Account Information
                </Button>
                <AccountDownloadModal
                  open={downloadModalOpen}
                  close={() => setDownloadModalOpen(false)}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        {!membershipIsCancelling && (
          <Grid.Row>
            <Grid.Column width={4}>
              <Button
                variant="secondary"
                fullWidth
                onClick={redirectToStripePortal}
              >
                Change Plan
              </Button>
              <br />

              {process.env.VITE_CANCELLATION_SELF_SERVE_ENABLED && (
                <Grid.Column width={4}>
                  {!membershipHasManualRequest && (
                    <Button
                      variant="actionLink"
                      fullWidth
                      onClick={() => {
                        setCancelPlanModalOpen(true)
                        sendUserCancellationMessageZap(
                          currentUser,
                          'clicked_canceled'
                        )
                        track('click cancel plan')
                      }}
                    >
                      Cancel Plan
                    </Button>
                  )}

                  {cancelPlanModalOpen && (
                    <ConfirmCancellationModal
                      open={cancelPlanModalOpen}
                      close={() => setCancelPlanModalOpen(false)}
                    />
                  )}
                </Grid.Column>
              )}
            </Grid.Column>
          </Grid.Row>
        )}

        <InvoiceList />
        <Grid.Row />
      </Grid>
    </Card>
  )
}

export default PlansAndBillingPanel
