import { Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Card, Text, Icon, Link } from '../../../../components/BaseComponents'
import AnnualTaxFaq from './AnnualTaxFaq'
import AnnualTaxImportantDateCard from './AnnualTaxImportantDateCard'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectTaxDetailsByYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getFinancialProfile } from '../../../../selectors/user.selectors'
import { TAX_ENTITY_TYPES } from '../../taxConstants'
import {
  FORM_1040_LATE_JOINER_EXTENDED_CUTOFF,
  FORM_1120_S_LATE_JOINER_FINAL_CUTOFF,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.helpers'

const UserDisqualifiedPanel = ({ year }: { year: string }) => {
  const annualTaxDetails = useReselector(selectTaxDetailsByYear, year)
  const fp = useReselector(getFinancialProfile)

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={9}>
          <Card type="section">
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon
                      height={30}
                      icon={regular('exclamation-circle')}
                      color="forest"
                    />
                    <Text as="h2" style={{ marginLeft: '10px' }}>
                      We are unable to prepare your {year} taxes
                    </Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
              {annualTaxDetails?.newUserCutOffAt &&
                annualTaxDetails?.taxSeasonKickoffDueAt && (
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Text>
                        Customers who signed up for Heard after{' '}
                        <b>
                          {fp?.taxEntityType === TAX_ENTITY_TYPES.form_1040
                            ? FORM_1040_LATE_JOINER_EXTENDED_CUTOFF.minus({
                                days: 1,
                              }).toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)
                            : FORM_1120_S_LATE_JOINER_FINAL_CUTOFF.minus({
                                days: 1,
                              }).toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}
                        </b>{' '}
                        are not eligible for annual tax services.
                        <br />
                        <br />
                        These cut-off dates give the Heard Team enough time to
                        carefully prepare customers&apos; bookkeeping and set
                        them up for a successful tax filing.
                        <br />
                        <br />
                        If you have questions, reach out via{' '}
                        <Link to="/conversations">Conversations</Link>.
                        We&apos;re here to support you.
                      </Text>
                    </Grid.Column>
                  </Grid.Row>
                )}
            </Grid>
          </Card>
        </Grid.Column>
        <Grid.Column width={7}>
          <AnnualTaxImportantDateCard year={year} />
          <br />
          <AnnualTaxFaq />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default UserDisqualifiedPanel
