import AnnualTaxProgressOverview from './AnnualTaxProgressOverview'
import { useReselector } from '../../../../utils/sharedHooks'
import IncompleteTaxFilingFormPanel from './IncompleteTaxFilingFormPanel'
import TaxSeasonKickoffIncomplete from './TaxSeasonKickoffIncomplete'
import UserDisqualifiedPanel from './UserDisqualifiedPanel'
import NoTaxServicesPanel from './NoTaxServicesPanel'
import {
  AnnualTaxPanelDisplay,
  selectAnnualTaxDisplay,
} from '../annualTaxFilings.selector'
import NoTaxFilingPanel from './NoTaxFilingPanel'

const AnnualTaxYearPanel = ({ year }: { year: string }) => {
  const annualTaxDisplay = useReselector(selectAnnualTaxDisplay, year)

  switch (annualTaxDisplay) {
    case AnnualTaxPanelDisplay.userDisqualified:
      return <UserDisqualifiedPanel year={year} />
    case AnnualTaxPanelDisplay.noTaxFiling:
      return <NoTaxFilingPanel year={year} />
    case AnnualTaxPanelDisplay.taxSeasonKickoffIncomplete:
      return <TaxSeasonKickoffIncomplete year={year} />
    case AnnualTaxPanelDisplay.noTaxServices:
      return <NoTaxServicesPanel year={year} />
    case AnnualTaxPanelDisplay.incompleteTaxFilingForm:
      return <IncompleteTaxFilingFormPanel year={year} />
    case AnnualTaxPanelDisplay.annualTaxProgress:
    default:
      return <AnnualTaxProgressOverview year={year} />
  }
}

export default AnnualTaxYearPanel
