import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Button, GridRowColumn, Icon } from '../../components/BaseComponents'
import ConversationsList from './ConversationsList'
import { NewConversationModal } from './NewConversationHeader'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import PageHeader from '../../components/shared/PageHeader'
import TaxFyleIntroMiniCard from '../Taxes/AnnualTaxes/TaxFyle/TaxFyleIntroMiniCard'
import MessageCardList from '../Taxes/AnnualTaxes/TaxFyle/MessageCardList'
import QTEConversationAlert from './QTEConversationAlert'

const ConversationsPanel = () => {
  const [searchParams] = useSearchParams()
  const [modalOpen, setModalOpen] = useState(
    searchParams.get('newConversation') === 'true'
  )
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <Grid>
      <GridRowColumn>
        <PageHeader
          header="Conversations"
          extraContent={
            <Button fullWidth onClick={() => setModalOpen(true)}>
              <Icon icon={regular('comment')} style={{ marginRight: 10 }} />
              {isMobile ? 'New' : 'New Conversation'}
            </Button>
          }
        />
      </GridRowColumn>
      <QTEConversationAlert />
      <TaxFyleIntroMiniCard />
      <MessageCardList />
      <GridRowColumn>
        <ConversationsList />
      </GridRowColumn>
      <NewConversationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Grid>
  )
}

export default ConversationsPanel
