import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Grid, Label } from 'semantic-ui-react'

import {
  Card,
  GridRowColumn,
  Icon,
  Text,
} from '../../components/BaseComponents'
import BAAHelloSignButton from './BAAHelloSignButton'
import ConnectBusinessBankAccountButton from './ConnectBusinessBankAccountButton'
import {
  isOnboardingTaskIdent,
  OnboardingTaskIdents,
  OnboardingV2TaskIdents,
  templateDescription,
} from './config'
import UploadPrevMonthStatementsButton from './UploadPrevMonthStatementsButton'
import { useReselector } from '../../utils/sharedHooks'
import { selectUserActionItemById } from '../Dashboard/UserActionItems/userActionItems.selectors'
import { selectPlaidFinancialAccounts } from '../../selectors/financeSelectors'
import { size } from 'lodash'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import moment from 'moment'
import {
  ConnectBusinessBankAccountStatus,
  UploadPrevMonthStatementStatus,
} from '../Dashboard/UserActionItems/userActionItemStatuses'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import { useAnalyticsTrack } from '../Amplitude'

const taskDetailsMap: {
  [key in OnboardingV2TaskIdents]: {
    icon: IconDefinition
    requirements?: {
      icon: IconDefinition
      description: string
    }
    completionTime: number
  }
} = {
  [OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT]: {
    icon: light('bank'),
    requirements: {
      icon: regular('key'),
      description: "You'll need: Business bank login",
    },
    completionTime: 3,
  },
  [OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS]: {
    icon: light('file-invoice'),
    requirements: {
      icon: regular('files'),
      description: "You'll need: Business bank statement",
    },
    completionTime: 5,
  },
  [OnboardingTaskIdents.SIGN_BAA_AGREEMENT]: {
    icon: light('signature'),
    completionTime: 2,
  },
}

const taskCTAMap: {
  [key in OnboardingV2TaskIdents]: (props: {
    completedAt?: string
    onClick?: () => void
  }) => JSX.Element
} = {
  [OnboardingTaskIdents.SIGN_BAA_AGREEMENT]: BAAHelloSignButton,
  [OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS]:
    UploadPrevMonthStatementsButton,
  [OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT]:
    ConnectBusinessBankAccountButton,
}

const amplitudeValuesMap: { [key in OnboardingV2TaskIdents]: string } = {
  [OnboardingTaskIdents.SIGN_BAA_AGREEMENT]: 'sign BAA agreement',
  [OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS]: 'upload statements',
  [OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT]:
    'connect financial accounts',
}

const OnboardingTaskCard = ({ id }: { id: number }) => {
  const userActionItem = useReselector(selectUserActionItemById, id)
  const financialAccountsCount = size(
    useReselector(selectPlaidFinancialAccounts)
  )

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const track = useAnalyticsTrack()

  if (
    !userActionItem ||
    !isOnboardingTaskIdent(userActionItem.actionItem.identifier) ||
    userActionItem.actionItem.identifier ===
      OnboardingTaskIdents.SCHEDULE_ONBOARDING_MEETING
  ) {
    return null
  }

  const {
    actionItem: { description, identifier, subtext },
    completedAt,
    templateValue,
    status,
  } = userActionItem

  const TaskCTA = taskCTAMap[identifier]

  const taskDescription = templateDescription(description, templateValue)

  const taskSubtext = () => {
    const subtexts = {
      [OnboardingTaskIdents.SIGN_BAA_AGREEMENT]: {
        default: `Signed on ${moment
          .utc(completedAt)
          .format(DATE_FORMATS.DISPLAY_SIMPLE)}.`,
      },
      [OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS]: {
        default: 'Statements uploaded. Now we can start your bookkeeping.',
        [UploadPrevMonthStatementStatus.noStatement]:
          'You can upload later when you get your first statement.',
        [UploadPrevMonthStatementStatus.skipped]:
          'Upload statements so we can start your bookkeeping.',
        [UploadPrevMonthStatementStatus.uploaded]:
          'Statements uploaded. Now we can start your bookkeeping.',
      },
      [OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT]: {
        default: `You've connected ${financialAccountsCount} business account${
          financialAccountsCount === 1 ? '' : 's'
        }.`,
        [ConnectBusinessBankAccountStatus.connected]: `You've connected ${financialAccountsCount} business account${
          financialAccountsCount === 1 ? '' : 's'
        }.`,
        [ConnectBusinessBankAccountStatus.inProgress]:
          'Once you’ve opened a business account, you can connect it here.',
        [ConnectBusinessBankAccountStatus.notConnected]:
          'You can connect a business account when you’re ready.',
      },
    }
    if (!completedAt) return subtext
    if (
      status &&
      identifier === OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT &&
      Object.values<string>(ConnectBusinessBankAccountStatus).includes(status)
    ) {
      return subtexts[identifier][status as ConnectBusinessBankAccountStatus]
    }
    if (
      status &&
      identifier === OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS &&
      Object.values<string>(UploadPrevMonthStatementStatus).includes(status)
    ) {
      return subtexts[identifier][status as UploadPrevMonthStatementStatus]
    }
    return subtexts[identifier].default
  }

  const getLabel = () => {
    switch (identifier) {
      // This will work only with two labels: 'Not connected' and 'In progress'
      case OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT:
        if (
          completedAt &&
          status &&
          status !== ConnectBusinessBankAccountStatus.connected &&
          Object.values<string>(ConnectBusinessBankAccountStatus).includes(
            status
          )
        ) {
          return (
            <Label
              basic
              style={{
                color:
                  status === ConnectBusinessBankAccountStatus.notConnected
                    ? '#B54775'
                    : '#AC590C',
                textTransform: 'uppercase',
                backgroundColor:
                  status === ConnectBusinessBankAccountStatus.notConnected
                    ? '#FFF3F6'
                    : '#FFFAEA',
                border: 'none',
                borderRadius: '20px',
              }}
            >
              {status === ConnectBusinessBankAccountStatus.notConnected
                ? 'Not connected'
                : 'In progress'}
            </Label>
          )
        }
        return null
      case OnboardingTaskIdents.UPLOAD_PREV_MONTH_STATEMENTS:
        if (
          completedAt &&
          status &&
          status !== UploadPrevMonthStatementStatus.uploaded &&
          Object.values<string>(UploadPrevMonthStatementStatus).includes(status)
        ) {
          return (
            <Label
              basic
              style={{
                color:
                  status === UploadPrevMonthStatementStatus.skipped
                    ? '#B54775'
                    : '#AC590C',
                textTransform: 'uppercase',
                backgroundColor:
                  status === UploadPrevMonthStatementStatus.skipped
                    ? '#FFF3F6'
                    : '#FFFAEA',
                border: 'none',
                borderRadius: '20px',
              }}
            >
              {status === UploadPrevMonthStatementStatus.skipped
                ? 'Skipped'
                : 'No Statement'}
            </Label>
          )
        }
        return null
      default:
        return null
    }
  }

  const getTaskCompletionTime = () => (
    <>
      <Icon icon={regular('timer')} color={'oak'} size={'1x'} />
      <Text
        color={'oak'}
        as="bodySm"
        style={{
          display: 'inline',
          marginLeft: '5px',
          marginRight: '20px',
        }}
      >
        {` ${taskDetailsMap[identifier].completionTime} minute${
          taskDetailsMap[identifier].completionTime === 1 ? '' : 's'
        }`}
      </Text>
    </>
  )

  const getTaskDescription = () => (
    <>
      {taskDetailsMap[identifier].requirements?.icon && (
        <Icon
          icon={
            taskDetailsMap[identifier].requirements?.icon ??
            light('file-invoice')
          }
          color={'oak'}
          size={'1x'}
        />
      )}
      {taskDetailsMap[identifier]?.requirements?.description && (
        <Text
          color={'oak'}
          as="bodySm"
          style={{
            display: 'inline',
            marginLeft: '5px',
          }}
        >
          {taskDetailsMap[identifier]?.requirements?.description}
        </Text>
      )}
    </>
  )

  return (
    <Card
      style={{ borderRadius: 8, margin: '20px 0' }}
      type="subsection"
      backgroundColor={completedAt ? 'stone40' : 'natural'}
    >
      <Grid>
        <Grid.Row>
          {!isMobile && (
            <Grid.Column width={2}>
              <div
                style={{ height: '100%', margin: 'auto', width: 'fit-content' }}
              >
                <Icon
                  icon={
                    identifier
                      ? taskDetailsMap[identifier].icon
                      : light('check')
                  }
                  color={'oak'}
                  style={{
                    height: '100%',
                    maxWidth: 40,
                    width: '100%',
                  }}
                />
              </div>
            </Grid.Column>
          )}
          <Grid.Column width={isMobile ? 16 : completedAt ? 10 : 11}>
            <Text as="h3" style={{ marginBottom: 8 }}>
              {taskDescription} {getLabel()}
            </Text>
            <Text as="bodyLg" color={'forest'} style={{ marginBottom: '8px' }}>
              {taskSubtext()}
            </Text>
            <Text>
              {!completedAt && isMobile && (
                <Grid>
                  <GridRowColumn style={{ paddingBottom: 0 }}>
                    {getTaskCompletionTime()}
                  </GridRowColumn>
                  <GridRowColumn style={{ marginBottom: 15 }}>
                    {getTaskDescription()}
                  </GridRowColumn>
                </Grid>
              )}
              {!completedAt && !isMobile && (
                <>
                  {getTaskCompletionTime()}
                  {getTaskDescription()}
                </>
              )}
            </Text>
          </Grid.Column>
          <Grid.Column
            style={{
              alignItems: 'center',
              display: 'flex',
            }}
            width={isMobile ? 16 : completedAt ? 4 : 3}
          >
            <TaskCTA
              completedAt={completedAt}
              onClick={() => {
                track('clicked onboarding item start button', {
                  onboarding_item: amplitudeValuesMap[identifier],
                })
              }}
              {...(identifier ===
              OnboardingTaskIdents.CONNECT_A_BUSINESS_BANK_ACCOUNT
                ? { status }
                : {})}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

export default OnboardingTaskCard
