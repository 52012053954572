import React, { useEffect, useState } from 'react'
import { Divider } from 'semantic-ui-react'

import {
  Button,
  Text,
  Alert,
  Modal,
  Table,
} from '../../../components/BaseComponents'
import {
  getFetchError,
  getIsFetchingOrNotStarted,
} from '../../../reducers/fetch'
import AnnualTaxDetailsForm from './components/AnnualTaxDetailsForm'
import AnnualTaxDetailsRow from './components/AnnualTaxDetailsRow'
import { selectAllAnnualTaxDetailsSorted } from './annualTaxDetails.selector'
import {
  fetchAllAnnualTaxDetailsIfNeeded,
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  AnnualTaxDetail,
} from './annualTaxDetails.slice'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface ModalVisibility {
  open: boolean
  annualTaxDetail?: AnnualTaxDetail
}

const AnnualTaxDetailsPanel = () => {
  const dispatch = useAppDispatch()

  // This bit is responsible for making the GET request; it fires whenever its dependency array changes,
  // (currently upon component load)
  useEffect(() => {
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [dispatch])

  // Creates a state variable with default value = false
  const [modalVisibility, setModalVisibility] = useState<ModalVisibility>({
    open: false,
    annualTaxDetail: undefined,
  })

  const allAnnualTaxDetailsSorted = useReselector(
    selectAllAnnualTaxDetailsSorted
  )
  const loading = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ALL_ANNUAL_TAX_DETAILS_KEY
  )
  const error = useReselector(getFetchError, FETCH_ALL_ANNUAL_TAX_DETAILS_KEY)

  const openModal = (annualTaxDetail?: AnnualTaxDetail) =>
    setModalVisibility({ open: true, annualTaxDetail })
  const closeModal = () =>
    setModalVisibility({ open: false, annualTaxDetail: undefined })

  const renderError = (error: { message: string }) => (
    <Text>
      An error occurred, refresh page to try again. &quot;{error.message}&quot;
    </Text>
  )
  const renderLoading = () => <Text>loading...</Text>
  const renderMain = () => (
    <>
      <Modal
        closeOnDimmerClick={false}
        closeOnEscape={false}
        open={modalVisibility.open}
        onClose={closeModal}
      >
        <Modal.Header as="h2">
          {modalVisibility.annualTaxDetail
            ? `Update ${modalVisibility.annualTaxDetail.taxYear}`
            : 'Create a future entry'}
        </Modal.Header>
        <Modal.Content>
          <Divider />
          <AnnualTaxDetailsForm
            onCancel={closeModal}
            details={modalVisibility.annualTaxDetail}
          />
        </Modal.Content>
      </Modal>
      <Button
        variant="secondary"
        content="Create a future entry"
        onClick={() => openModal()}
      />
      <Alert className="noBorder" title="All dates are inclusive">
        <Text>
          Changing values can impact Tax Center content. Please double-check
          your changes before applying.
        </Text>
      </Alert>
      <Table style={{ marginBottom: '8em' }} collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Tax Year</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Tax Season Kickoff Start</Table.HeaderCell>
            <Table.HeaderCell>Tax Season Kickoff Due at</Table.HeaderCell>
            <Table.HeaderCell>1099 NEC Start</Table.HeaderCell>
            <Table.HeaderCell>1099 NEC Due at</Table.HeaderCell>
            <Table.HeaderCell>Financial Overview Due</Table.HeaderCell>
            <Table.HeaderCell>New User Cut-off Date</Table.HeaderCell>
            <Table.HeaderCell>Form 1040</Table.HeaderCell>
            <Table.HeaderCell>Form 1120 S</Table.HeaderCell>
            <Table.HeaderCell>Last Updated</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {allAnnualTaxDetailsSorted.map((details) => (
          <AnnualTaxDetailsRow
            onEditClick={() => openModal(details)}
            key={`detail-${details.taxYear}`}
            details={details}
          />
        ))}
      </Table>
    </>
  )

  return (
    <>
      <Text as="h1">Annual Tax Details</Text>
      <br />
      {loading && renderLoading()}
      {!loading && !error && renderMain()}
      {error && renderError(error)}
    </>
  )
}

export default AnnualTaxDetailsPanel
