import { useHelloSignBAA } from '../../components/shared/helloSignHelper'
import { TaskCTAButton } from './config'
import { isNil } from 'lodash'

const BAAHelloSignButton = ({
  completedAt,
  onClick,
}: {
  completedAt?: string
  onClick?: () => void
}) => {
  const {
    fetching,
    baaSigned,
    signUrlError,
    signedUrlLoading,
    openSignatureModal,
  } = useHelloSignBAA()

  return (
    <TaskCTAButton
      disabled={!isNil(completedAt) || fetching || baaSigned || signUrlError}
      completedAt={completedAt}
      completedText={signUrlError ? 'Error' : 'Done'}
      loading={fetching || signedUrlLoading}
      onClick={() => {
        openSignatureModal()
        if (onClick) onClick()
      }}
    />
  )
}

export default BAAHelloSignButton
