import axios from 'axios'
import { fetchWrapper } from '../../reducers/fetch'
import { TAX_ENTITY_TYPES_TYPE } from '../../features/Taxes/taxConstants'

export enum PRODUCT_METADATA_KEYS {
  // To differentiate between primary (core) products vs non-primary (add-on) products
  heardProductType = 'heard-product-type',

  // To store product eligibility rules based on tax entity and practice type
  eligibility = 'eligibility',
}

export const STRIPE_CANCELLATION_REASON_KEYS = {
  missingFeatures: 'missing_features',
  customerService: 'customer_service',
  other: 'other',
  lowQuality: 'low_quality',
  switchedService: 'switched_service',
  tooComplex: 'too_complex',
  tooExpensive: 'too_expensive',
  unused: 'unused',
}

export interface StripeProductMetadata {
  'heard-product-type'?: 'primary'
  eligibility?: string
}

type PracticeTypeType = 'group' | 'solo'

export type EligibilityRule = {
  taxEntityType: TAX_ENTITY_TYPES_TYPE
  practiceType: PracticeTypeType
}

export const STRIPE_PRICE_MONTH_INTERVAL = 'month'

export interface StripePrice {
  id: string
  object: 'price'
  active: boolean
  billing_scheme: string
  created: number
  currency: string
  livemode: boolean
  metadata: unknown
  nickname: string
  product: string
  recurring: {
    aggregate_usage: null
    interval: 'month' | 'year' | 'day' | 'week'
    interval_count: number
    trial_period_days: null
    usage_type: 'metered' | 'licensed'
  }
  tax_behavior: string
  tiers_mode: null
  transform_quantity: null
  type: 'one_time' | 'recurring'
  unit_amount: number
  unit_amount_decimal: string
}

export interface StripeProduct {
  id: string
  object: 'product'
  active: boolean
  attributes: unknown[]
  created: number
  default_price: StripePrice
  description: string
  features?: { name: string }[]
  images: string[]
  livemode: boolean
  metadata: StripeProductMetadata
  name: string
  statement_descriptor: string
  tax_code: string
  unit_label: string
  updated: number
  url: string
}

export type StripeLineItem = {
  priceId: string
  quantity?: number
}

export const FETCH_CHECKOUT_SESSION_KEY = 'FETCH_CHECKOUT_SESSION_KEY'
export const fetchCheckoutSession = (data: {
  userId: number
  lineItems: StripeLineItem[]
  success_url: string
  cancel_url: string
  freeTrialEnabled?: boolean
  freeTrialSearchParamValue?: string | null
  // defaults to subscription on backend
  mode?: 'payment' | 'subscription'
  metadata?: Record<string, string>
}) =>
  fetchWrapper({
    fetchKey: FETCH_CHECKOUT_SESSION_KEY,
    defaultErrorMessage: 'Unable to fetch checkout session.',
    defaultValue: null,
    fetchFunction: () =>
      axios
        // There is a LOT more data that comes back with this but we only use the url
        .post<{ url: string }>('/finances/api/v1/stripe/create_session', data)
        .then((json) => json.data),
  })

/*
  This method is used to fetch the billing portal session, which allows us
  to redirect the user to the Stripe billing / plan management portal
*/
export const fetchBillingPortalSession = () =>
  fetchWrapper({
    defaultErrorMessage: 'Unable to fetch billing portal session session.',
    fetchFunction: () =>
      axios
        // There is a LOT more data that comes back with this but we only use the url
        .post<{ url: string }>('/finances/api/v1/stripe/billing_portal_session')
        .then((json) => json.data),
  })

export const fetchPaymentMethodUpdateSession = (
  callbackPath?: string,
  cancelPath?: string
) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        // the Express session will pass the id of the user to our API, so we do not need to
        // explicitly pass it in
        .post<{ url: string }>(
          '/finances/api/v1/stripe/update_payment_method_session',
          {
            callbackPath,
            cancelPath,
          }
        )
        .then((json) => json.data),
  })

export const FETCH_STRIPE_PRODUCTS_KEY = 'FETCH_STRIPE_PRODUCTS_KEY'
export const fetchEligibleStripeProducts = () =>
  fetchWrapper({
    fetchKey: FETCH_STRIPE_PRODUCTS_KEY,
    fetchFunction: async () => {
      const json = await axios.get<StripeProduct[]>(
        '/finances/api/v1/stripe/products/eligible'
      )
      return json.data
    },
  })

/*
  This method is used to reactive a User's Plan _before_ the end date has passed
*/
export const reactivateUserSubscription = (subscriptionId?: string) =>
  fetchWrapper({
    defaultErrorMessage: 'Unable to reactivate User subscription.',
    fetchFunction: () =>
      axios
        .post('/finances/api/v1/stripe/reactivate_subscription', {
          subscriptionId,
        })
        .then((json) => json.data),
  })

/*
  Submit cancellation
*/
export const submitCancelStripeSubscriptionAtPeriodEnd =
  (cancellationDetails?: {
    cancellationComment?: string
    cancellationReason?: string
  }) =>
    fetchWrapper({
      defaultErrorMessage: 'Unable to cancel User subscription.',
      fetchFunction: () =>
        axios
          .post('/finances/api/v1/stripe/cancel_subscription', {
            cancellationDetails,
          })
          .then((json) => json.data),
    })

/*
  This method is used to submit a cancellation request if users cannot self-serve
*/
export const submitCancellationRequest = (userId?: number) =>
  fetchWrapper({
    defaultErrorMessage: 'Unable to submit cancellation request',
    fetchFunction: async () => {
      const json = await axios.post(
        `/finances/api/v1/users/${userId}/submit_cancellation_request`
      )
      return json.data
    },
  })
