import { Divider, Label, Table } from 'semantic-ui-react'
import { DateTime } from 'luxon'

import CalculationTableLink from './CalculationTableLink'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectStatusInfoForCalculation,
  selectUserTaxCalculationById,
} from '../../../../selectors/userTaxCalculation.selectors'
import {
  getUserById,
  selectPrimaryMembershipForUser,
} from '../../../../selectors/user.selectors'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import { UserWithAdminInfo } from '../../../../reducers/admin/allUsersReducer'
import { Text } from '../../../../components/BaseComponents'

const UserRecordDetails = ({ user }: { user: UserWithAdminInfo }) => {
  const membership = useReselector(selectPrimaryMembershipForUser, user?.id)
  const membershipInfo = () => {
    if (
      membership?.membershipStatus === 'trial' ||
      membership?.status === 'trial'
    ) {
      // remove membershipStatus with CORE-253
      return (
        <p>
          <b>trial start date: </b>
          {DateTime.fromISO(membership.trialStartDate ?? '').toFormat(
            DATE_FORMATS_LUXON.DISPLAY_TIMEZONE
          )}
          <br />
          <b>trial end date: </b>
          {DateTime.fromISO(membership.trialEndDate ?? '').toFormat(
            DATE_FORMATS_LUXON.DISPLAY_TIMEZONE
          )}
        </p>
      )
    }
    return (
      <p>
        <b>membership start date: </b>
        {DateTime.fromISO(membership?.startDate ?? '').toFormat(
          DATE_FORMATS_LUXON.DISPLAY_TIMEZONE
        )}
      </p>
    )
  }
  return (
    <p>
      <b>created at: </b>
      {DateTime.fromISO(user.createdAt).toFormat(
        DATE_FORMATS_LUXON.DISPLAY_TIMEZONE
      )}
      <br />
      <b>membership status: </b>
      {/* remove membershipStatus with CORE-253 */}
      {user.memberships?.map((m) => m.membershipStatus ?? m.status).join(', ')}
      <br />
      {membershipInfo()}
    </p>
  )
}

const CustomerTableCell = ({ id }: { id: number }) => {
  const calc = useReselector(selectUserTaxCalculationById, id)
  const user = useReselector(getUserById, calc?.userId)
  const { color, text } = useReselector(selectStatusInfoForCalculation, id)

  if (!calc || !user) {
    return null
  }

  const { firstName, lastName, userId } = calc
  return (
    <Table.Cell verticalAlign="top">
      <Text as="bodyMd">
        <b>
          {firstName} {lastName}
        </b>
      </Text>
      <Label style={{ borderRadius: '13px' }} color={color}>
        {text}
      </Label>
      <Divider />
      <UserRecordDetails user={user} />

      <CalculationTableLink
        url={`/admin/finances/financial-reports/${userId}/profit-loss`}
        text="Profit & Loss"
      />
      <CalculationTableLink
        url={`/admin/finances/records/${userId}`}
        text={`Profile (id: ${userId})`}
      />
    </Table.Cell>
  )
}

export default CustomerTableCell
