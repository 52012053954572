import { UserDocument } from '../../UserDocuments/userDocuments.slice'
import UserDocumentsTable, {
  GroupByType,
} from '../../UserDocuments/sharedComponents/Table/UserDocumentsTable'
import { OnboardingStatementRow } from '../../UserDocuments/sharedComponents/Table/OnboardingStatementRow'
import { sortDocumentsByNameAscWithinStatementDateDesc } from '../../UserDocuments/service'
import DeleteDocumentConfirm from '../../UserDocuments/sharedComponents/Modals/DeleteDocumentConfirm'
import { Card } from '../../../components/BaseComponents'
import { FeatureStateProvider } from '../../UserDocuments/state'
import { TableHeaderConfig } from '../../UserDocuments/sharedComponents/Table/UserDocumentsTableHeader'

const getTableColumns = (
  editable: boolean | undefined
): TableHeaderConfig[] => {
  return [
    {
      sortBy: 'fileName',
      title: 'File Name',
    },
    {
      sortBy: 'tag',
      title: 'Statement Month',
    },
    {
      sortBy: 'financialAccount',
      title: 'Financial Account',
    },
    ...(editable
      ? [
          {
            sortBy: null,
            title: 'Actions',
          },
        ]
      : []),
  ]
}

export const EditableStatementTable = ({
  documents,
  editable,
}: {
  documents: UserDocument[]
  editable?: boolean
}) => {
  const tableColumns = getTableColumns(editable)
  return (
    <FeatureStateProvider>
      <Card fullWidth type="subsection" backgroundColor="white">
        <UserDocumentsTable
          documents={documents}
          RowComponent={(props) => (
            <OnboardingStatementRow {...props} editable={editable} />
          )}
          fixed={false}
          tableOptions={{
            sortFn: sortDocumentsByNameAscWithinStatementDateDesc,
            groupBy: GroupByType.None,
          }}
          columns={tableColumns}
        />
        <DeleteDocumentConfirm />
      </Card>
    </FeatureStateProvider>
  )
}
