import { Grid } from 'semantic-ui-react'
import { Button, GridRowColumn, Text } from '../../components/BaseComponents'
import { Colors } from '../../styles/theme'
import Lottie from 'lottie-react'
import { confetti } from '../../assets/lottie/onboarding.json'

const AccordionCoverForm = ({
  loading,
  onClick,
  title,
  subtitle,
  buttonText,
}: {
  loading?: boolean
  onClick: () => void
  title: string
  subtitle: string
  buttonText: string
}) => (
  // Absolute positioning will fill the entire Accordion, including the title
  <Grid
    style={{
      position: 'absolute' as const,
      inset: 15,
      background: Colors.white,
    }}
    textAlign="center"
  >
    <GridRowColumn>
      <Lottie
        style={{
          width: '200px',
          height: '200px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        loop={false}
        animationData={confetti}
      />
    </GridRowColumn>
    <GridRowColumn>
      <Text as="display">{title}</Text>
    </GridRowColumn>
    <GridRowColumn>
      <Text as="bodyLg">{subtitle}</Text>
    </GridRowColumn>
    <GridRowColumn>
      <Button
        loading={loading}
        disabled={loading}
        onClick={onClick}
        style={{ display: 'inline' }}
      >
        {buttonText}
      </Button>
    </GridRowColumn>
    <GridRowColumn />
    <GridRowColumn />
  </Grid>
)

export default AccordionCoverForm
