import { User } from '../../reducers/auth/userReducer'
import { getStore } from '../../store'
import {
  createOrUpdateFinancialAdvisoryProfile,
  FinancialAdvisoryProfilesStatus,
} from './financialAdvisory.slice'

/**
 *
 * Intended to house logic related to advisory logics
 *
 */
export const createIntroCallScheduledAfterEffects = async ({
  currentUser,
}: {
  currentUser: User | null
  eventUri?: string
}) => {
  const store = getStore()
  if (!store) {
    return false
  }
  // Create Advisory Profile + Update to Event Scheduled
  await createOrUpdateFinancialAdvisoryProfile({
    userId: currentUser?.id,
    status: FinancialAdvisoryProfilesStatus.intro_call_scheduled,
  })(store.dispatch)
  return true
}

/**
 * Constants
 */
export const UPRISE_INBOX_EVENT_CODE = 'launch_inbox'
export const UPRISE_SESSION_EXPIRED_EVENT_CODE = 'session_expired'
