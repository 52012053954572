import { Button, GridRowColumn, Modal, Text } from '../../BaseComponents'
import { Grid, List } from 'semantic-ui-react'
import {
  FinancialAccount,
  TransactionSyncState,
} from '../../../reducers/finances/financialAccountsReducer'
import { FontWeight } from '../../../styles/theme'
import React, { useEffect } from 'react'
import { AccountBanner } from './InstitutionList'
import { updateFinancialAccount } from '../../../actions/financialAccountActions'
import { useAnalyticsTrack } from '../../../features/Amplitude'
import { useAppDispatch } from '../../../utils/typeHelpers'

const ShowAccountModal = ({
  open,
  account,
  onClose,
  setAccountBanner,
}: {
  open: boolean
  account: FinancialAccount
  onClose: () => void
  setAccountBanner: (accountBanner: AccountBanner | null) => void
}) => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()

  useEffect(() => {
    if (open) {
      track('viewed review bank account modal')
    }
  }, [open, track])

  return (
    <Modal size="small" open={open} closeIcon onClose={onClose}>
      <Modal.Header>Do you want to show this account?</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text style={{ display: 'inline' }}>
              Showing{' '}
              <span style={{ fontWeight: FontWeight.SEMIBOLD }}>
                {account.name} - {account.mask}
              </span>{' '}
              will:
            </Text>
            <List bulleted>
              <List.Item>
                Start showing transactions from this account in Heard
              </List.Item>
            </List>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="actionLink"
          style={{ width: '121px' }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            onClose()
            await updateFinancialAccount(account.id, {
              isHidden: false,
              transactionSyncState: TransactionSyncState.ENABLED,
            })(dispatch)
            setAccountBanner({ account, type: 'show' })
            track('clicked review bank account modal button', {
              button: 'show account',
            })
          }}
        >
          Show Account
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
export default ShowAccountModal
