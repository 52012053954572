import React, { useCallback, useState } from 'react'
import { Icon, Popup } from 'semantic-ui-react'

// BL
import { Transaction } from '../../../reducers/admin/allTransactions.slice'

// UI
import DeleteTransactionModule from './DeleteTransactionModule'
import EditTransactionModule from './EditTransactionModule'

interface Props {
  transaction: Transaction
}

export interface ManualTransactionChildProps {
  disabled: boolean
  onChildBeginLoading: () => void
  onChildEndLoading: () => void
  transaction: Transaction
  onUpdated?: () => void
}

const ManualTransactionActions: React.FC<Props> = ({ transaction }) => {
  const [disabled, setDisabled] = useState(false)

  const onChildBeginLoading = useCallback(() => {
    setDisabled(true)
  }, [])

  const onChildEndLoading = useCallback(() => {
    setDisabled(false)
  }, [])

  const manualIcon = () => {
    let message =
      'This transaction was manually created via import, during a reconciliation, or during a split.'

    if (
      transaction.reconciliationId !== null ||
      transaction.confirmedAt !== null
    ) {
      message += ' Editing/deleting functionality is disabled because'
      message +=
        transaction.reconciliationId !== null
          ? ' ●_it has already been reconciled'
          : ''
      message +=
        transaction.confirmedAt !== null ? ' ●_it has been confirmed' : ''
    }

    return (
      <Popup
        position="top right"
        content={message}
        trigger={<Icon name="hand paper outline" />}
      />
    )
  }

  return (
    <>
      {transaction.manuallyCreated && manualIcon()}
      {transaction.manuallyCreated &&
        transaction.reconciliationId === null &&
        transaction.confirmedAt === null && (
          <>
            <EditTransactionModule
              onChildBeginLoading={onChildBeginLoading}
              onChildEndLoading={onChildEndLoading}
              disabled={disabled}
              transaction={transaction}
            />
            <DeleteTransactionModule
              onChildBeginLoading={onChildBeginLoading}
              onChildEndLoading={onChildEndLoading}
              disabled={disabled}
              transaction={transaction}
            />
          </>
        )}
    </>
  )
}

export default ManualTransactionActions
