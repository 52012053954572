import { CSSProperties, ReactNode } from 'react'
import { omit } from 'lodash'
import {
  Checkbox as SemanticCheckbox,
  StrictCheckboxProps,
} from 'semantic-ui-react'
import styled from 'styled-components'

import { Colors, Fonts } from '../../styles/theme'

interface Props
  extends Omit<
    StrictCheckboxProps,
    'checked' | 'onChange' | 'value' | 'label'
  > {
  checked?: boolean
  error?: boolean | string
  onChange?: (checked: boolean) => void
  style?: CSSProperties
  value?: string | number | boolean
  label?: ReactNode
  // "Default" should be the variant going forward but 'old' is defaulted to for backwards compat in app
  variant?: 'default' | 'old'
}

const { black, green, lightGray, mediumGray, red, white } = Colors

const Checkbox = styled(({ onChange, value, label, ...rest }: Props) => (
  <SemanticCheckbox
    onChange={(_, e) => onChange?.(Boolean(e.checked))}
    // @ts-expect-error semantic checkbox works fine with booleans as values
    value={value}
    // If semantic UI takes a label as a ReactNode it assumes that it will be wrapped with a label tag
    // This allows us to send either a string or ReactNode as label without sending that additional wrapper
    label={<label>{label}</label>}
    {...omit(rest, 'error')}
  />
))<Props>(({ disabled, error, toggle, variant = 'old' }) => ({
  '&&&': {
    ...(!toggle && {
      minHeight: 16,
      minWidth: 16,
    }),

    '&.ui.checkbox': {
      '&.toggle': {
        '&.checked': {
          'label:before': {
            backgroundColor: `${green} !important`,
          },
          'label:after': {
            // this is the result of 64px (width of the entire toggle) -
            // 24px (width of the puck) - 4px (right padding)
            left: 36,
          },
        },
        '&:not(.checked)': {
          'label:after': {
            left: '4px',
          },
        },

        'label:before': {
          backgroundColor: `${lightGray} !important`,
          height: '32px',
          width: '64px',
        },
        'label:after': {
          boxShadow: 'none',
          height: '24px',
          width: '24px',
          top: '4px',
        },

        label: {
          paddingLeft: 70,
          paddingTop: 6,
        },
      },

      '&.radio': {
        '&.checked': {
          'label:before': {
            border: 'none',
            backgroundColor: disabled ? lightGray : green,
          },
          'label:after': {
            backgroundColor: disabled ? mediumGray : white,
          },
        },

        input: {
          height: 16,
          width: 16,
        },

        'label:before': {
          height: 16,
          width: 16,
          border: disabled ? 'none' : `1px solid ${error ? red : black}`,
          backgroundColor: disabled ? lightGray : white,
        },
        'label:after': {
          height: 16,
          width: 16,
          backgroundColor: disabled ? mediumGray : white,
        },
      },

      '&:not(.toggle, .radio)': {
        input: {
          height: 16,
          width: 16,
        },

        label: {
          ...(variant === 'default' && {
            ...Fonts.bodyLg,
            paddingLeft: 25,
          }),

          '&:before, &:after': {
            border: `1px solid ${error ? red : black}`,
            borderRadius: 2,
            height: 16,
            width: 16,
            // Line height needs to be set here to fix parent line height
            lineHeight: '17px',
            marginTop: variant === 'default' ? 5 : 'initial',
          },

          '&:after': {
            border: 'none',
            backgroundColor: disabled ? lightGray : green,
            color: disabled ? mediumGray : white,
            fontSize: 12,
          },
        },
      },
    },
  },
}))

export type { Props as CheckboxProps }

export default Checkbox
