import { Label } from 'semantic-ui-react'
import { UserWithAdminInfo } from '../../../../reducers/admin/allUsersReducer'
import {
  selectAdminUserState,
  selectPrimaryMembershipForUser,
} from '../../../../selectors/user.selectors'
import { USER_STATUS_LABEL_COLOR } from '../../../../utils/adminHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import { User } from '../../../../reducers/auth/userReducer'
import { TaxFilingUser } from '../../../../features/Taxes/AnnualTaxes/annualTaxFilings.slice'

const UserStateLabel = ({
  user,
}: {
  user?: UserWithAdminInfo | User | TaxFilingUser
}) => {
  const userState = useReselector(selectAdminUserState, user?.id)
  const membership = useReselector(selectPrimaryMembershipForUser, user?.id)

  if (!userState) {
    return null
  }

  const renderMembershipStatus = () => {
    if (userState === 'onboarding') {
      return (
        <Label>
          <b>Heard Membership Status: </b>
          Signing Up — User Has Not Paid
        </Label>
      )
    } else {
      switch (
        membership?.membershipStatus ?? // remove membershipStatus with CORE-253
        membership?.status
      ) {
        case 'paid':
          return (
            <Label color="green">
              <b>Heard Membership Status: </b>
              Paid
            </Label>
          )
        case 'cancelling':
          return (
            <Label color="orange">
              <b>Heard Membership Status: </b>
              Cancelling
            </Label>
          )
        case 'canceled':
          return (
            <Label color="red">
              <b>Heard Membership Status: </b>
              Canceled
            </Label>
          )
        case 'trial':
          return (
            <Label>
              <b>Heard Membership Status: </b>
              Trial
            </Label>
          )
        default:
          return (
            <Label>
              <b>Heard Membership Status: </b>
              {/* remove membershipStatus with CORE-253 */}
              {membership?.membershipStatus ?? membership?.status}
            </Label>
          )
      }
    }
  }
  return (
    <>
      <Label color={USER_STATUS_LABEL_COLOR[userState] ?? 'red'}>
        {userState === 'onboarding' ? 'Signing Up' : userState}
      </Label>
      {renderMembershipStatus()}
    </>
  )
}

export default UserStateLabel
