import { useMemo, useState } from 'react'
import { Radio, Grid, Modal, Loader } from 'semantic-ui-react'
import { adminUpdateUserWithId } from '../../../actions/admin/adminAllUsersActions'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import { useReselector } from '../../../utils/sharedHooks'
import { GridRowColumn, Button, Text, Input, Alert } from '../../BaseComponents'
import { selectCurrentAnnualTaxYear } from '../../../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  getIsAdministrator,
  selectIsBookkeeper,
  selectIsAccountingOpsManager,
} from '../../../selectors/user.selectors'
import { User } from '../../../reducers/auth/userReducer'
import { useAppDispatch } from '../../../utils/typeHelpers'

const USER_TITLE = 'User:'
const ADMIN_TITLE = 'Admin:'
export enum ToggleType {
  LOCK_FOR_USER = 'booksLockedForUserYear',
  LOCK_FOR_ADMIN = 'booksLockedForAdminYear',
}

// - Create a new ‘Confirmation Modal’ when Admin initiates the “User Books Locked”
//     - Admin types in the user’s name to confirm locking the books
//     - When confirmed, the User’s books will be locked. If not confirmed correctly, User’s books will not be locked
// - Confirm that we are saving the datetime of when the books are locked
// - Disable the ability to unlock User Books
const LocksBookToggleConfirmationModal = ({
  user,
  open,
  close,
  toggleType,
  toggleLockedForCurrentYear,
  lockSubmitCallback,
}: {
  open: boolean
  close: () => void
  user: Pick<
    UserWithAdminInfo,
    'id' | 'firstName' | 'lastName' | 'booksLockedForUserYear'
  >
  toggleType: ToggleType
  toggleLockedForCurrentYear: boolean
  lockSubmitCallback?: (data: User) => void
}) => {
  const dispatch = useAppDispatch()
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmValue, setConfirmValue] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const lockedConfirmed = confirmValue === `${user.firstName} ${user.lastName}`
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const isAccountingOpsManager = useReselector(selectIsAccountingOpsManager)
  const canEditUserBooksLock = user.booksLockedForUserYear !== currentTaxYear
  const userToggle = toggleType === ToggleType.LOCK_FOR_USER

  const submitLock = async () => {
    let result = null
    setSubmitting(true)
    // Accounting Ops Manager can unlock books for current year
    if (isAccountingOpsManager && toggleLockedForCurrentYear) {
      result = await adminUpdateUserWithId(user.id, {
        [toggleType]: null,
      })(dispatch)
    } else {
      result = await adminUpdateUserWithId(user.id, {
        [toggleType]: currentTaxYear,
      })(dispatch)
    }
    if (result) {
      close()
      if (lockSubmitCallback && result.data) {
        lockSubmitCallback(result.data)
      }
    } else {
      setErrorMessage('Cannot Lock books for users')
    }
    setSubmitting(false)
  }

  const submitButtonContent = useMemo(() => {
    if (!submitting) {
      return `${toggleLockedForCurrentYear ? 'Unlock' : 'Lock'} Books for ${userToggle ? 'User' : 'Admin'}`
    } else {
      return <Loader active inline size="small" />
    }
  }, [submitting, toggleLockedForCurrentYear, userToggle])

  if (isAccountingOpsManager) {
    return (
      <Modal
        size="small"
        className="locksBookToggleConfirmationModal"
        open={open}
        onClose={close}
        closeIcon
      >
        <Modal.Header>
          {toggleLockedForCurrentYear ? 'Unlock' : 'Lock'}ing{' '}
          {userToggle ? 'User' : 'Admin'} Books Lock Status for {user.id}:{' '}
          {user.firstName} {user.lastName}
        </Modal.Header>
        <Modal.Content>
          <Grid>
            {!userToggle && (
              <GridRowColumn>
                <Text as="h3">
                  Warning: You are currently{' '}
                  {toggleLockedForCurrentYear ? 'unlock' : 'lock'}ing an
                  admin&apos;s ability to modify books for this user for Tax
                  Year: {currentTaxYear}
                </Text>
              </GridRowColumn>
            )}
            {userToggle && (
              <GridRowColumn>
                <Text as="h3">
                  Warning: You are currently{' '}
                  {toggleLockedForCurrentYear ? 'unlock' : 'lock'}ing books for
                  this user for Tax Year: {currentTaxYear}
                </Text>
                <br />
                <Text>
                  If you are <b>locking</b> the books the User will be{' '}
                  <b>
                    notified via email that their {currentTaxYear} books are
                    completed. This is a user-facing change.
                  </b>
                </Text>
                <br />
                <Text>
                  If you are <b>unlocking</b> the books the User will not be
                  notified.
                </Text>
              </GridRowColumn>
            )}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <div className="flexbox">
            <Button variant="secondary" onClick={close}>
              Cancel
            </Button>
            <Button disabled={submitting} onClick={() => submitLock()}>
              {submitButtonContent}
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    )
  }

  if (toggleType === ToggleType.LOCK_FOR_ADMIN) {
    return (
      <Modal
        size="small"
        className="locksBookToggleConfirmationModal"
        open={open}
        onClose={close}
        closeIcon
      >
        <Modal.Header>
          Admin Books Lock cannot be manually triggered
        </Modal.Header>
        <Modal.Content>
          <Text>
            We automatically mark Admin Books Locked when a User approves their
            Taxfyle submission.{' '}
          </Text>
          <Text>
            If you believe this is in error, please contact Accounting Ops{' '}
          </Text>
        </Modal.Content>
        <Modal.Actions>
          <Button variant="secondary" onClick={close}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  return (
    <Modal
      size="small"
      className="locksBookToggleConfirmationModal"
      open={open}
      onClose={close}
      closeIcon
    >
      <Modal.Header>
        Locking Books for {user.id}: {user.firstName} {user.lastName}
      </Modal.Header>
      <Modal.Content>
        {errorMessage && <Alert type="error">Error: {errorMessage}</Alert>}
        {!canEditUserBooksLock && (
          <Grid>
            <GridRowColumn>
              <Text as="h3">
                Warning: This Users books are locked for tax year:{' '}
                {currentTaxYear}. You cannot unlock it automatically.
              </Text>
              <br />
              <Text>
                We prevent the ability to unlock books in the case that this
                user is in tax prep. Please contacting Accounting Ops if you
                need to unlock these books.
              </Text>
            </GridRowColumn>
          </Grid>
        )}
        {canEditUserBooksLock && (
          <Grid>
            <GridRowColumn>
              <Text as="h3">
                Warning: You are currently locking the books for this user for
                Tax Year: {currentTaxYear}
              </Text>
              <br />
              <Text>
                This means that the User will be{' '}
                <b>
                  notified via email that their {currentTaxYear} books are
                  completed. This is a user-facing change.
                </b>
              </Text>
              <br />
              <Text>
                Please ensure the User&#39;s books are truly complete before
                toggling this. Once this is toggled and locked for{' '}
                {currentTaxYear}, you will need to contact Accounting Ops to get
                it unlocked. This prevents us from toggling books back and forth
                as there are downstream consequences.
              </Text>
            </GridRowColumn>
            <br />
            <GridRowColumn>
              <Input
                description="This is case sensitive"
                label="Type User's Name to Confirm Lock on This User's Books"
                onChange={(value) => setConfirmValue(value)}
                placeholder={`${user.firstName} ${user.lastName}`}
                value={confirmValue}
              />
            </GridRowColumn>
          </Grid>
        )}
      </Modal.Content>
      <Modal.Actions>
        <div className="flexbox">
          <Button variant="secondary" onClick={close}>
            Cancel
          </Button>
          <Button
            disabled={!lockedConfirmed || !canEditUserBooksLock || submitting}
            onClick={() => submitLock()}
          >
            Lock Books for User
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}
const LockBooksToggle = ({
  user,
  id,
  noTitle,
  lockSubmitCallback,
}: {
  user: Pick<
    UserWithAdminInfo,
    | 'booksLockedForUserYear'
    | 'booksLockedForAdminYear'
    | 'id'
    | 'firstName'
    | 'lastName'
  >
  id: string
  noTitle?: boolean
  lockSubmitCallback?: (data: User) => void
}) => {
  const toggleType =
    id === 'user-lock' ? ToggleType.LOCK_FOR_USER : ToggleType.LOCK_FOR_ADMIN
  const title = id === 'user-lock' ? USER_TITLE : ADMIN_TITLE
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const [userLockToggleOpen, setUserLockToggleOpen] = useState(false)

  const isAdministrator = useReselector(getIsAdministrator)
  const isBookkeeper = useReselector(selectIsBookkeeper)
  const isAccountingOpsManager = useReselector(selectIsAccountingOpsManager)
  const toggleLockedForCurrentYear = user[toggleType] === currentTaxYear
  const notLocked = user[toggleType] === null

  const toggleDisabled = useMemo(() => {
    if (isAccountingOpsManager) {
      return false
    }
    return (
      (toggleType === ToggleType.LOCK_FOR_USER &&
        user[ToggleType.LOCK_FOR_ADMIN] === currentTaxYear) ||
      !(isBookkeeper || isAdministrator)
    )
  }, [
    isAccountingOpsManager,
    toggleType,
    user,
    currentTaxYear,
    isBookkeeper,
    isAdministrator,
  ])

  return (
    <div style={{ position: 'relative' }}>
      {!noTitle && (
        <>
          <label>
            <b>{title}</b>
          </label>
          <br />
        </>
      )}
      <Radio
        toggle
        checked={toggleLockedForCurrentYear}
        onChange={() => setUserLockToggleOpen(true)}
        disabled={toggleDisabled}
      />
      <Text as="bodyXs" color="darkGray">
        {notLocked ? 'Not locked' : `Locked for ${user[toggleType]}`}
      </Text>
      {userLockToggleOpen && (
        <LocksBookToggleConfirmationModal
          toggleLockedForCurrentYear={toggleLockedForCurrentYear}
          toggleType={toggleType}
          user={user}
          open={userLockToggleOpen}
          close={() => setUserLockToggleOpen(false)}
          lockSubmitCallback={lockSubmitCallback}
        />
      )}
    </div>
  )
}

export default LockBooksToggle
