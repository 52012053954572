import { Grid } from 'semantic-ui-react'
import { Button, GridRowColumn, Text } from '../../components/BaseComponents'
import { useCallback, useEffect, useState } from 'react'

import { useReselector } from '../../utils/sharedHooks'
import { getIsFetching } from '../../reducers/fetch'
import {
  UPDATE_ACTION_ITEM_KEY,
  fetchUserActionItemsIfNeeded,
  updateUserActionItem,
} from '../Dashboard/UserActionItems/userActionItems.slice'
import OnboardingSkipModal from './OnboardingSkipModal'
import CalendlyPopupModal from '../../components/shared/CalendlyPopupModal'
import { getUserActionItemByActionItemIdentifier } from '../Dashboard/UserActionItems/userActionItems.selectors'
import { OnboardingTaskIdents } from './config'
import { ScheduleOnboardingCallStatus } from '../Dashboard/UserActionItems/userActionItemStatuses'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import { useAnalyticsTrack } from '../Amplitude'
import { useAppDispatch } from '../../utils/typeHelpers'

const ScheduleOnboardingCard = ({
  onScheduleComplete,
}: {
  onScheduleComplete: () => void
}) => {
  const [skipModalOpen, setSkipModalOpen] = useState(false)
  const [calendlyOpen, setCalendlyOpen] = useState(false)
  const dispatch = useAppDispatch()

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const track = useAnalyticsTrack()

  useEffect(() => {
    dispatch(fetchUserActionItemsIfNeeded())
  }, [dispatch])

  const actionItem = useReselector(
    getUserActionItemByActionItemIdentifier,
    OnboardingTaskIdents.SCHEDULE_ONBOARDING_MEETING
  )

  const onDecisionMade = useCallback(() => {
    onScheduleComplete()
    setSkipModalOpen(false)
  }, [onScheduleComplete])

  const isFetching = useReselector(getIsFetching, UPDATE_ACTION_ITEM_KEY)

  const groupOnboardingUrl = process.env.VITE_ONBOARDING_MEETING_LINK

  const getScheduleButton = () => (
    <Button
      fullWidth={isMobile}
      onClick={() => {
        setCalendlyOpen(true)
        track('clicked onboarding item start button', {
          onboarding_item: 'schedule call',
        })
      }}
    >
      Schedule
    </Button>
  )

  const getSkipButton = () => (
    <Button
      fullWidth={isMobile}
      onClick={() => {
        setSkipModalOpen(true)
        track('clicked onboarding item start button', {
          onboarding_item: 'schedule call',
        })
      }}
      variant="actionLink"
      style={
        isMobile
          ? {
              padding: 16,
            }
          : {}
      }
    >
      Skip for now
    </Button>
  )

  return (
    <>
      <OnboardingSkipModal
        loading={isFetching}
        open={skipModalOpen}
        onClose={() => setSkipModalOpen(false)}
        onSkipped={async () => {
          if (actionItem) {
            await dispatch(
              updateUserActionItem(actionItem.id, {
                id: actionItem.id,
                status: ScheduleOnboardingCallStatus.skipped,
                completedAt: new Date().toISOString(),
              })
            )
          }
          onDecisionMade()
          track('completed onboarding item schedule call', {
            status: 'skipped',
          })
        }}
        subtitle="You'll have the opportunity to speak to a Heard onboarding
        specialist, and ensure that you are successfully set-up to use
        Heard to its full advantage. If you change your mind, you'll
        still be able to join an onboarding webinar at a later date."
      />
      {groupOnboardingUrl && (
        <CalendlyPopupModal
          url={groupOnboardingUrl}
          open={calendlyOpen}
          onClose={() => setCalendlyOpen(false)}
          onScheduled={async () => {
            if (actionItem) {
              await dispatch(
                updateUserActionItem(actionItem.id, {
                  id: actionItem.id,
                  status: ScheduleOnboardingCallStatus.scheduled,
                  completedAt: new Date().toISOString(),
                })
              )
              track('completed todo', {
                completed_source: 'auto-completed',
                todo_description: actionItem.actionItem.description,
              })
            }
            onDecisionMade()
            track('completed onboarding item schedule call', {
              status: 'done',
            })
          }}
        />
      )}
      <Grid>
        <GridRowColumn>
          <Text as="bodyLg">
            Our onboarding team will provide an overview of the Heard software,
            ensure your accounts are correctly synced to Heard, and share
            bookkeeping & tax best practices.
          </Text>
        </GridRowColumn>
        <Grid.Row verticalAlign="middle">
          {isMobile ? (
            <>
              <Grid.Column width={16}>{getScheduleButton()}</Grid.Column>
              <Grid.Column width={16}>{getSkipButton()}</Grid.Column>
            </>
          ) : (
            <>
              <Grid.Column width={8} />
              <Grid.Column width={4}>{getSkipButton()}</Grid.Column>
              <Grid.Column width={4}>{getScheduleButton()}</Grid.Column>
            </>
          )}
        </Grid.Row>
      </Grid>
    </>
  )
}

export default ScheduleOnboardingCard
