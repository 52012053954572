import { useEffect, useState } from 'react'
import { Grid, Loader, Icon } from 'semantic-ui-react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

import TwoFactorAuthentication from './TwoFactorAuthentication'
import {
  validateVerificationToken,
  sendVerificationEmail,
} from '../../../actions/authActions'
import './Accounts.scss'
import ChangePassword from './ChangePassword'
import { getCurrentUser } from '../../../selectors/user.selectors'
import PlansAndBillingPanel from './PlansAndBillingPanel'
import {
  Button,
  Card,
  GridRowColumn,
  Input,
  Label,
  Link,
  Modal,
  Text,
} from '../../BaseComponents'
import { useHelloSignBAA } from '../../shared/helloSignHelper'
import { NewInstitutionButton } from './NewInstitutionButton'
import { InstitutionList } from './InstitutionList'
import { ComputerOnlyHeader, ResponsiveHeader } from './ResponsiveHeader'
import { ChangeEmail } from './ChangeEmail'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { DuplicateInstitutionModal } from './DuplicateInstitutionModal'
import { PlaidLinkContextProvider } from './PlaidLinkContext'
import {
  useReselector,
  useScrollRef,
  useTimeoutRef,
} from '../../../utils/sharedHooks'
import {
  useAnalyticsTrack,
  useAnalyticsView,
} from '../../../features/Amplitude'
import { PlaidErrorModal } from './PlaidErrorModal'
import SmsConsent from '../../../features/SmsConsent/SmsConsent'
import { PlaidStatementAlert } from './PlaidStatementAlert'
import { useAppDispatch } from '../../../utils/typeHelpers'

const RenderBaaAgreement = () => {
  const { fetching, baa, openSignatureModal } = useHelloSignBAA()

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const columnStyle = isMobile ? { marginTop: '1em' } : undefined

  /* Set loader if we're fetching*/
  if (fetching) {
    return (
      <GridRowColumn width={8}>
        <Loader active inline />
      </GridRowColumn>
    )
  }

  /* Otherwise, guard against case where we cannot fetch or user does not have agreements */
  if (!baa) {
    return (
      <GridRowColumn width={8}>
        <Text>None Found</Text>
      </GridRowColumn>
    )
  }
  return (
    <Grid.Row verticalAlign="middle">
      <Grid.Column computer={7} tablet={7} mobile={16}>
        <Text>Business Associate Agreement (BAA)</Text>
      </Grid.Column>
      <Grid.Column computer={3} tablet={3} mobile={16} style={columnStyle}>
        <Label color={baa.signed ? 'green' : 'red'}>
          {baa.signed ? 'Signed' : 'Unsigned'}
        </Label>
      </Grid.Column>
      <Grid.Column computer={6} tablet={6} mobile={16} style={columnStyle}>
        {baa.signed && (
          <Link href={baa.fileUrl ?? ''}>
            {isMobile ? 'Download Agreement' : 'Download'}
          </Link>
        )}
        {!baa.signed && (
          <Button variant="link" onClick={openSignatureModal}>
            Sign Now
          </Button>
        )}
      </Grid.Column>
    </Grid.Row>
  )
}

const VerifyEmailModal = ({
  verified,
  open,
  close,
}: {
  verified: boolean
  open: boolean
  close: () => void
}) => {
  const [reverifySent, setReverifySent] = useState(false)
  const dispatch = useAppDispatch()

  const resendEmail = async () => {
    // Resend then redirect to accounts page
    await sendVerificationEmail()(dispatch)
    setReverifySent(true)
  }

  const needsVerificationContent = () => {
    if (reverifySent) {
      return (
        <>
          <Modal.Content>
            <Text as="h1">
              <Icon name="mail outline" color="black" />
              We just sent you a new verification email! Click the link in your
              email to verify.
            </Text>
          </Modal.Content>
          <Modal.Actions textAlign="center">
            <Button onClick={close}>Close</Button>
          </Modal.Actions>
        </>
      )
    }
    return (
      <>
        <Modal.Content>
          <Text as="h1">
            <Icon name="warning sign" color="yellow" />
            It looks like this verification link has expired! Please request a
            new one by clicking &#39;Resend Email Verification&#39; below.
          </Text>
        </Modal.Content>
        <Modal.Actions textAlign="center">
          <Button onClick={close}>Close and Verify Later</Button>

          <Button onClick={resendEmail}>Resend Email Verification</Button>
        </Modal.Actions>
      </>
    )
  }

  if (!verified) {
    return (
      <Modal size="small" open={open} onClose={close}>
        {needsVerificationContent()}
      </Modal>
    )
  }
  return (
    <Modal size="small" open={open} onClose={close}>
      <Modal.Content>
        <Text as="h1">
          <Icon name="check" color="green" />
          Thank you for verifying your email!
        </Text>
      </Modal.Content>
      <Modal.Actions textAlign="center">
        <Button onClick={close}>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}

const Accounts = () => {
  const dispatch = useAppDispatch()
  const [successfullyValidated, setSuccessfullyValidated] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const pageView = useAnalyticsView()
  const track = useAnalyticsTrack()
  const { token } = useParams()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { scrollRef, scrollToRef } = useScrollRef()
  const timeoutRef = useTimeoutRef()

  useEffect(() => {
    if (location.hash === '#connected-institutions') {
      // Give the page a little to load so we scroll to the proper spot
      timeoutRef.current = setTimeout(scrollToRef, 500)
    }
  }, [location.hash, scrollToRef, timeoutRef])

  useEffect(() => {
    pageView('account')
  }, [pageView])

  useEffect(() => {
    // Track channel for plaid statement migration
    const plaidStatementSource = searchParams.get('psSrc')
    if (plaidStatementSource) {
      track(`view settings from ${plaidStatementSource}`)
    }
  }, [track, searchParams])

  useEffect(() => {
    const checkToken = async () => {
      if (token) {
        const res = await validateVerificationToken(token)(dispatch)

        setSuccessfullyValidated(res)
        setModalOpen(true)
      }
    }

    checkToken()
  }, [dispatch, token])

  const currentUser = useReselector(getCurrentUser)

  return (
    <PlaidLinkContextProvider>
      <ComputerOnlyHeader content="Settings" />
      <Card>
        <Grid>
          <GridRowColumn>
            <ResponsiveHeader content="Account Details" />
          </GridRowColumn>
          <Grid.Row className="short" />
          {currentUser && (
            <>
              <Text as="h3">Email</Text>
              <Grid.Row>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <Input
                    placeholder={currentUser.email}
                    value={currentUser.email}
                    readOnly
                    error={!currentUser.verifiedAt}
                    fullWidth
                  />
                </Grid.Column>
                <ChangeEmail
                  verifiedAt={currentUser.verifiedAt}
                  sendVerificationEmail={() =>
                    dispatch(sendVerificationEmail())
                  }
                />
              </Grid.Row>
            </>
          )}
          <Text as="h3">Password</Text>
          <Grid.Row>
            <Grid.Column computer={8} tablet={8} mobile={16}>
              <Input value="********" readOnly fullWidth />
            </Grid.Column>
            <Grid.Column
              computer={4}
              tablet={4}
              mobile={16}
              verticalAlign="middle"
              style={{ margin: 4 }}
            >
              <ChangePassword />
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn verticalAlign="bottom">
            <SmsConsent />
          </GridRowColumn>
          <GridRowColumn verticalAlign="bottom">
            <TwoFactorAuthentication />
          </GridRowColumn>

          <GridRowColumn>
            <ResponsiveHeader content="Agreements" />
          </GridRowColumn>

          <RenderBaaAgreement />
        </Grid>
      </Card>
      <Card>
        <Grid>
          <span ref={scrollRef} />
          <GridRowColumn
            columnStyle={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
            }}
          >
            <ResponsiveHeader content="Connected Financial Institutions" />
            <NewInstitutionButton />
          </GridRowColumn>
          <GridRowColumn>
            <PlaidStatementAlert />
          </GridRowColumn>
          <GridRowColumn>
            <InstitutionList />
          </GridRowColumn>
        </Grid>

        <VerifyEmailModal
          verified={successfullyValidated}
          open={modalOpen}
          close={() => setModalOpen(false)}
        />
        <DuplicateInstitutionModal />
      </Card>

      {!currentUser?.admin && !currentUser?.qaDetails && (
        <PlansAndBillingPanel />
      )}
      <PlaidErrorModal />
    </PlaidLinkContextProvider>
  )
}

export default Accounts
