import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// This slice is generic because we could potentially want to know what keys we have available in
// our sidebar state. This is useful for things like selectors to this slice because we can narrow
// down the keys to the state for stricter type safety.
export interface SidebarSlice<T extends string = string> {
  initialized: boolean
  open: boolean
  sidebarState: Record<T, boolean>
  scrollYPos: number
}

export type PagesProps = {
  end?: boolean
  href?: string
  label: string
  match?: string
  newPage?: boolean
  to?: string
  icon?: IconDefinition
}

export type SidebarConfigProps = {
  icon?: IconDefinition
  label: string
  ornament?: JSX.Element
} & (
  | {
      end?: boolean
      exact?: boolean
      match?: string
      pages?: never
      to: string
      topRoute?: never
    }
  | {
      end?: never
      exact?: never
      match?: never
      pages: PagesProps[]
      to?: never
      topRoute?: never
    }
  | {
      end?: never
      exact?: never
      match?: never
      pages: PagesProps[]
      to?: never
      topRoute: string
    }
)

export const getDefaultSidebarState = (
  configProps: Record<string, SidebarConfigProps>,
  currentLocation: string,
  defaultOpen = false
) => {
  const state: Record<string, boolean> = {}

  Object.entries(configProps).forEach(([key, { pages, to, topRoute }]) => {
    const isTopLevelNav = currentLocation === to
    const isSubNav = Array.isArray(pages)
      ? pages?.some(({ match, to: subTo }) =>
          match ? currentLocation.startsWith(match) : currentLocation === subTo
        )
      : currentLocation.includes(topRoute ?? '')
    state[key] = defaultOpen || isTopLevelNav || isSubNav
  })

  return state
}

const initialState: SidebarSlice = {
  initialized: false,
  open: true,
  sidebarState: {},
  scrollYPos: 0,
}

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarState: (
      state,
      { payload }: PayloadAction<SidebarSlice['sidebarState']>
    ) => {
      if (!state.initialized) {
        state.sidebarState = payload
        state.initialized = true
      }
    },
    toggleCategory: (state, { payload: key }: PayloadAction<string>) => {
      if (state.sidebarState && key in state.sidebarState) {
        state.sidebarState[key] = !state.sidebarState[key]
      } else if (state.sidebarState) {
        state.sidebarState[key] = true
      }
    },
    setScrollYState: (state, { payload: yPos }: PayloadAction<number>) => {
      state.scrollYPos = yPos
    },
    toggleSidebar: (
      state,
      { payload: open }: PayloadAction<boolean | undefined>
    ) => {
      state.open = open ?? !state.open
    },
  },
})

export const {
  setSidebarState,
  toggleCategory,
  toggleSidebar,
  setScrollYState,
} = sidebarSlice.actions

export default sidebarSlice.reducer
