import axios from 'axios'
import { keyBy } from 'lodash'
import { Moment } from 'moment'

import {
  FinancialAccountReconciliationWithTransactions,
  receiveReconciliations,
  receiveSingleReconciliation,
} from '../../reducers/admin/allFinancialAccountReconciliationsReducer'
import { fetchIfNeededWrapper, fetchWrapper } from '../../reducers/fetch'
import { Reconciliation } from '../../reducers/admin/financialAccountsReducer'
import { getReconciliationById } from '../../selectors/reconciliations.selectors'

export interface ReconciliationUpsertModel {
  accountId: number
  endingBalanceDate: Moment
  endingBalanceInCents: number
  startingBalanceDate: Moment
  startingBalanceInCents: number
  status: string
  type: 'manual' | 'auto'
}

export interface VirtualStatementTransaction {
  transactionId: number | null
  date: string
  description: string | null
  amountInCents: number
  balanceInCents: number | null
  deltaInCents: number | null
  potentialBreak: boolean
  displayStatus: boolean
}

export interface VirtualStatementReconciliation {
  id: number
  startingBalanceInCents: number
  endingBalanceInCents: number
  startingBalanceDate: string
  endingBalanceDate: string
  status: string
  financialAccount: {
    id: number
    plaidInstitutionName: string
    name: string
    mask: string
    type: string
    subtype: string
  }
}

export interface VirtualStatementData {
  reconciliation: VirtualStatementReconciliation
  statement: VirtualStatementTransaction[]
  statementUrl: string
}

/*
  GET One
*/
export const getFetchSingleAccountRecKey = (id: number | string) =>
  `FETCH_SINGLE_ACCOUNT_REC_KEY${id}`
export const fetchSingleAccountReconciliationIfNeeded = (id: number | string) =>
  fetchIfNeededWrapper({
    fetchKey: getFetchSingleAccountRecKey(id),
    defaultErrorMessage: 'Error fetching single account reconciliation',
    shouldHandleError: false,
    defaultValueSelector: (state) => getReconciliationById(state, id),
    fetchFunction: async (dispatch) => {
      const json =
        await axios.get<FinancialAccountReconciliationWithTransactions>(
          `/finances/api/v1/admin/reconciliations/${id}`
        )
      dispatch(receiveSingleReconciliation(json.data))
      return json.data
    },
  })

/*
  Get all for User
*/

export const fetchAllFinancialAccountReconciliationsForUser = (
  userId: number
) =>
  fetchWrapper({
    defaultErrorMessage:
      'There was an error fetching all user financial account reconciliations.',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<Reconciliation[]>(
        `/finances/api/v1/admin/reconciliations/${userId}/list`
      )
      dispatch(receiveReconciliations(keyBy(json.data, 'id')))
      return json.data
    },
  })

/*
  UPSERT
*/

export const upsertAccountReconciliation = (
  id: number | undefined,
  data: ReconciliationUpsertModel
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error upserting account reconciliation',
    shouldHandleError: false,
    fetchFunction: async (dispatch) => {
      const json =
        await axios.post<FinancialAccountReconciliationWithTransactions>(
          `/finances/api/v1/admin/reconciliations/${id ? id : ''}`,
          {
            ...data,
            startingBalanceDate: data.startingBalanceDate.format('YYYY-MM-DD'),
            endingBalanceDate: data.endingBalanceDate.format('YYYY-MM-DD'),
          }
        )
      dispatch(receiveSingleReconciliation(json.data))
      return json.data
    },
  })

export const performAutoReconciliation = (
  accountId: number,
  periodStart: string | undefined,
  periodEnd: string | undefined
) =>
  fetchWrapper({
    shouldHandleError: false,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<Reconciliation>(
        `/finances/api/v1/admin/accounts/${accountId}/auto_reconciliation`,
        {
          periodStart,
          periodEnd,
        }
      )
      dispatch(receiveSingleReconciliation(json.data))
      return json.data
    },
  })

export const getVirtualStatement = (reconciliationId: number) =>
  fetchWrapper({
    shouldHandleError: false,
    fetchFunction: async () => {
      const json = await axios.get<VirtualStatementData>(
        `/finances/api/v1/admin/reconciliations/${reconciliationId}/virtual_statement`
      )
      return json.data
    },
  })
