import { CSSProperties } from 'react'
import {
  Icon,
  SemanticICONS,
  StrictTextAreaProps,
  TextArea as SemanticTextArea,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { Colors, Fonts } from '../../styles/theme'
import { InputError, LabelDescription, LabelDescriptionProps } from './Input'

export interface TextAreaProps
  extends Omit<StrictTextAreaProps, 'error' | 'onChange' | 'value'>,
    LabelDescriptionProps {
  disabled?: boolean
  error?: string | boolean
  icon?: SemanticICONS
  onChange: (value: string) => void
  placeholder?: string
  style?: CSSProperties
  value: string
}

const TextArea = styled(
  ({
    description,
    error,
    icon,
    label,
    onChange,
    afterLabel,
    required,
    ...rest
  }: TextAreaProps) => (
    <>
      <LabelDescription
        label={label}
        description={description}
        afterLabel={afterLabel}
        required={required}
      />
      <div style={{ position: 'relative' }}>
        <SemanticTextArea
          onChange={(e) => onChange(e.target.value)}
          {...rest}
        />
        {(error || icon) && <Icon name={error ? 'exclamation circle' : icon} />}
      </div>
      <InputError error={error} />
    </>
  )
)(({ error }) => ({
  '&&&': {
    backgroundColor: error ? Colors.lightRed : Colors.white,
    border: `1px solid ${error ? Colors.red : Colors.gray} !important`,
    borderRadius: 8,
    color: error ? Colors.red : Colors.black,
    ...Fonts.bodyMd,

    maxWidth: '100%',
    minWidth: '100%',
    padding: '12px 16px',
    paddingRight: error ? 36 : 16,

    '+ i.icon': {
      color: error ? Colors.red : Colors.black,
      margin: 0,
      position: 'absolute',
      right: 10,
      top: 12,
    },
  },
}))

export default TextArea
