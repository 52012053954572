import { createSelector } from 'reselect'
import { filterNulls, ReduxState } from '../../../utils/typeHelpers'
import {
  QuarterlyTaxEstimateDetail,
  QuarterlyTaxEstimateDetailStatuses,
} from './quarterlyTaxEstimateDetails.slice'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import {
  getCurrentUser,
  selectQteWizardLastStartedAt,
} from '../../../selectors/user.selectors'
import { DateTime } from 'luxon'
import { isoToUTCDateTime } from '../../../utils/dateHelpers'

export const quarterlyTaxEstimateDetails = (state: ReduxState) =>
  state.quarterlyTaxEstimateDetails.byId

/*
  Select the single "active" record if exists
*/
export const selectActiveQuarterlyTaxEstimateDetails = createSelector(
  [quarterlyTaxEstimateDetails],
  (quarterlyTaxEstimateDetails) =>
    Object.values(quarterlyTaxEstimateDetails).find(
      (d) => d.status === QuarterlyTaxEstimateDetailStatuses.active
    )
)

/*
  Select multiple records by taxYear
  Returns object where key is the tax quarter
*/
export const selectQuarterlyTaxEstimateDetailsByYear = createSelector(
  quarterlyTaxEstimateDetails,
  (_: unknown, taxYear: string | number) => taxYear,
  (quarterlyTaxEstimateDetails, taxYear) => {
    const detailsForYear = Object.values(quarterlyTaxEstimateDetails).filter(
      (d) => d.taxYear === taxYear.toString()
    )

    // Transform array into object. Key is the taxQuarter, value is the details object
    const detailsObject: { [key: string]: QuarterlyTaxEstimateDetail } = {}
    detailsForYear.forEach((d) => (detailsObject[d.taxQuarter] = d))

    return detailsObject
  }
)

/*
  Select a single record by taxYear and taxQuarter
*/

const getQteByYearAndQuarter = (
  quarterlyTaxEstimateDetails: { [p: string]: QuarterlyTaxEstimateDetail },
  taxYear: string | number | undefined,
  taxQuarter: string | number | undefined
) =>
  Object.values(quarterlyTaxEstimateDetails).find(
    (d) =>
      d.taxQuarter === taxQuarter?.toString() &&
      d.taxYear === taxYear?.toString()
  )

export const selectQuarterlyTaxEstimateDetailsByYearAndQuarter = createSelector(
  quarterlyTaxEstimateDetails,
  (_: unknown, taxYear: string | number | undefined) => taxYear,
  (_: unknown, _a: unknown, taxQuarter: string | number | undefined) =>
    taxQuarter,
  getQteByYearAndQuarter
)

export const selectQtesForImportantDates = createSelector(
  quarterlyTaxEstimateDetails,
  (_: unknown, year: string | number) => year,
  (qtes, year) =>
    filterNulls([
      getQteByYearAndQuarter(qtes, year, 4),
      getQteByYearAndQuarter(qtes, Number(year) + 1, 1),
      getQteByYearAndQuarter(qtes, Number(year) + 1, 2),
      getQteByYearAndQuarter(qtes, Number(year) + 1, 3),
    ])
)

/*
  ADMIN – Selects all records sorted by taxQuarter ASC within taxYear DESC
*/
export const selectAllQuarterlyTaxEstimateDetailsSorted = createSelector(
  [quarterlyTaxEstimateDetails],
  (quarterlyTaxEstimateDetails) => {
    return orderBy(
      Object.values(quarterlyTaxEstimateDetails),
      [(d) => Number(d.taxYear), (d) => Number(d.taxQuarter)],
      ['asc', 'asc']
    )
  }
)

export enum QTEChecklistStatuses {
  beforeChecklistOpens,
  checklistOpen,
  internalCalculationHold,
  calcsShouldBeReadyBeforeIRSPaymentDue,
  calcsMayBeReceivedAfterIRSPaymentDue,
  afterIRSPaymentDue,
}

export const DAYS_BEFORE_IRS_PAYMENT_DUE = 9

const getQuarterDetailsAsDates = ({
  details,
  now,
}: {
  details?: QuarterlyTaxEstimateDetail
  now: DateTime<true> | DateTime<false>
}) => {
  if (!details) {
    return null
  }
  const {
    newUserCutOffAt,
    calculationStartsAt,
    clientNotifiedAt,
    irsPaymentDueAt,
    bookkeepingPeriodStartsAt,
    bookkeepingPeriodEndsAt,
    irsQuarterStartsAt,
    irsQuarterEndsAt,
    bookkeepingPeriodTotalMonths,
  } = details

  // offset is includes the sign, so we need to negate it to use it in the plus() method
  const nowUTCOffset = -now.offset
  const irsPaymentDueDate = isoToUTCDateTime(irsPaymentDueAt)
  const calculationStartsAtDate = isoToUTCDateTime(calculationStartsAt)
  const clientNotifiedAtDate = isoToUTCDateTime(clientNotifiedAt)
  const newUserCutOffDate = isoToUTCDateTime(newUserCutOffAt)
  const bookkeepingPeriodStartDate = isoToUTCDateTime(bookkeepingPeriodStartsAt)
  const bookkeepingPeriodEndDate = isoToUTCDateTime(bookkeepingPeriodEndsAt)
  const irsQuarterStartDate = isoToUTCDateTime(irsQuarterStartsAt)
  const irsQuarterEndDate = isoToUTCDateTime(irsQuarterEndsAt)

  // newUserCutOff stored as 00:00:00, on the day we want to open the checklist
  // It's treated as start of day, checklist opens once it's passed. Add offset
  // so we don't open the checklist before users local day is the same day as newUserCutOff
  const newUserCutOff = newUserCutOffDate.plus({
    minutes: nowUTCOffset,
  })
  // calculationStartsAt stored as 00:00:00, on the day after want to lock the checklist
  // It's treated as deadline. Add offset so users have until the end of their day
  const initialCalculationLock = calculationStartsAtDate.plus({
    minutes: nowUTCOffset,
  })
  // clientNotifiedAt stored as 00:00:00, on the day we want to release estimates
  // We want this date to be a transition point, so in this case
  // it's considered a deadline in the same way as calculationStartsAt
  const clientNotified = clientNotifiedAtDate.plus({
    minutes: nowUTCOffset,
  })
  // irsPaymentDueAt stored as 00:00:00, on the day payment is due
  // We want to treat this as a deadline, so set it to the end of the day and add offset
  const irsPaymentDue = irsPaymentDueDate.endOf('day').plus({
    minutes: nowUTCOffset,
  })
  // We want a transition point a few days before the payment due date,
  // We want this to be a deadline like initialCalculationLock and clientNotified
  // To achieve this, subtract DAYS_BEFORE_IRS_PAYMENT_DUE days and add offset
  const aFewDaysBeforePaymentDue = irsPaymentDueDate
    .minus({ days: DAYS_BEFORE_IRS_PAYMENT_DUE })
    .plus({
      minutes: nowUTCOffset,
    })
  // irsQuarterStartDate stored as 00:00:00, it should be treated as the local start of day
  const irsQuarterStart = irsQuarterStartDate.plus({
    minutes: nowUTCOffset,
  })
  // irsQuarterEndDate stored as 00:00:00, it should be treated as a
  // deadline using the local eod
  const irsQuarterEnd = irsQuarterEndDate.endOf('day').plus({
    minutes: nowUTCOffset,
  })
  // bookkeepingPeriodStartDate stored as 00:00:00, it should be treated as the local start of day
  const bookkeepingPeriodStart = bookkeepingPeriodStartDate.plus({
    minutes: nowUTCOffset,
  })
  // bookkeepingPeriodEndDate stored as 00:00:00, it should be treated as a
  // deadline using the local eod
  const bookkeepingPeriodEnd = bookkeepingPeriodEndDate.endOf('day').plus({
    minutes: nowUTCOffset,
  })

  return {
    now,
    taxQuarter: details.taxQuarter,
    taxYear: details.taxYear,
    bookkeepingPeriodTotalMonths,
    utcNoOffset: {
      newUserCutOffDate,
      calculationStartsAtDate,
      clientNotifiedAtDate,
      irsPaymentDueDate,
      bookkeepingPeriodStartDate,
      bookkeepingPeriodEndDate,
      irsQuarterStartDate,
      irsQuarterEndDate,
    },
    withOffset: {
      newUserCutOff,
      initialCalculationLock,
      clientNotified,
      irsPaymentDue,
      aFewDaysBeforePaymentDue,
      irsQuarterStart,
      irsQuarterEnd,
      bookkeepingPeriodStart,
      bookkeepingPeriodEnd,
    },
    forDisplay: {
      newUserCutOff: DateTime.fromObject(
        {
          year: newUserCutOff.year,
          month: newUserCutOff.month,
          day: newUserCutOff.day,
        },
        { zone: now.zone }
      ),
      initialCalculationLock: DateTime.fromObject(
        {
          year: calculationStartsAtDate.year,
          month: calculationStartsAtDate.month,
          day: calculationStartsAtDate.day - 1,
        },
        { zone: now.zone }
      ),
      clientNotified: DateTime.fromObject(
        {
          year: clientNotifiedAtDate.year,
          month: clientNotifiedAtDate.month,
          day: clientNotifiedAtDate.day,
        },
        { zone: now.zone }
      ),
      aFewDaysBeforePaymentDue: DateTime.fromObject(
        {
          year: aFewDaysBeforePaymentDue.year,
          month: aFewDaysBeforePaymentDue.month,
          day: aFewDaysBeforePaymentDue.day - 1,
        },
        { zone: now.zone }
      ),
      irsPaymentDue: DateTime.fromObject(
        {
          year: irsPaymentDueDate.year,
          month: irsPaymentDueDate.month,
          day: irsPaymentDueDate.day,
        },
        { zone: now.zone }
      ),
      irsQuarterStart: DateTime.fromObject(
        {
          year: irsQuarterStart.year,
          month: irsQuarterStart.month,
          day: irsQuarterStart.day,
        },
        { zone: now.zone }
      ),
      irsQuarterEnd: DateTime.fromObject(
        {
          year: irsQuarterEnd.year,
          month: irsQuarterEnd.month,
          day: irsQuarterEnd.day,
        },
        { zone: now.zone }
      ),
      bookkeepingPeriodStart: DateTime.fromObject(
        {
          year: bookkeepingPeriodStart.year,
          month: bookkeepingPeriodStart.month,
          day: bookkeepingPeriodStart.day,
        },
        { zone: now.zone }
      ),
      bookkeepingPeriodEnd: DateTime.fromObject(
        {
          year: bookkeepingPeriodEnd.year,
          month: bookkeepingPeriodEnd.month,
          day: bookkeepingPeriodEnd.day,
        },
        { zone: now.zone }
      ),
    },
  }
}

export const selectActiveQTEDetailsAsDates = createSelector(
  selectActiveQuarterlyTaxEstimateDetails,
  (_: unknown, timestamp?: DateTime<true> | DateTime<false>) => timestamp,
  (activeQuarterDetails, timestamp) => {
    const now = timestamp ?? DateTime.now()
    return getQuarterDetailsAsDates({ details: activeQuarterDetails, now })
  }
)

export const selectIsPastTaxEstimateCalculationDate = createSelector(
  [selectActiveQTEDetailsAsDates],
  (activeQTEDetails) =>
    Boolean(
      activeQTEDetails &&
        activeQTEDetails.now >
          activeQTEDetails.withOffset.initialCalculationLock
    )
)

export const selectIsPastOrSameAsTaxEstimateIRSPaymentDate = createSelector(
  [selectActiveQTEDetailsAsDates],
  (activeQTEDetails) =>
    Boolean(
      activeQTEDetails &&
        activeQTEDetails.now >= activeQTEDetails.withOffset.irsPaymentDue
    )
)

export const selectIsSameOrAfterNewUserCutOffAt = createSelector(
  [selectActiveQTEDetailsAsDates],
  (activeQTEDetails) =>
    Boolean(
      activeQTEDetails &&
        activeQTEDetails.now >= activeQTEDetails.withOffset.newUserCutOff
    )
)

export const selectIsNewUserBeforeQuarterlyCutoff = createSelector(
  [selectActiveQuarterlyTaxEstimateDetails, getCurrentUser],
  (activeQuarterlyTaxEstimateDetails, currentUser) =>
    Boolean(
      activeQuarterlyTaxEstimateDetails?.newUserCutOffAt &&
        activeQuarterlyTaxEstimateDetails?.irsQuarterStartsAt &&
        moment(currentUser?.createdAt).isBetween(
          moment(activeQuarterlyTaxEstimateDetails.irsQuarterStartsAt),
          moment(activeQuarterlyTaxEstimateDetails.newUserCutOffAt).endOf('day')
        )
    )
)

export const selectIsNewUserAfterQuarterlyCutoff = createSelector(
  [selectActiveQuarterlyTaxEstimateDetails, getCurrentUser],
  (activeQuarterlyTaxEstimateDetails, currentUser) =>
    Boolean(
      activeQuarterlyTaxEstimateDetails?.newUserCutOffAt &&
        moment(currentUser?.createdAt).isAfter(
          moment(activeQuarterlyTaxEstimateDetails.newUserCutOffAt).endOf('day')
        )
    )
)

export const selectQuarterDetailsForQuarterBeforeActiveQuarter = createSelector(
  quarterlyTaxEstimateDetails,
  selectActiveQuarterlyTaxEstimateDetails,
  (quarterlyTaxEstimateDetails, activeQuarterlyTaxEstimateDetails) => {
    if (!activeQuarterlyTaxEstimateDetails) {
      return null
    }
    const { taxYear, taxQuarter } = activeQuarterlyTaxEstimateDetails
    const previousQuarter =
      taxQuarter === '1' ? '4' : (parseInt(taxQuarter) - 1).toString()
    const previousQuarterYear =
      taxQuarter === '1' ? (parseInt(taxYear) - 1).toString() : taxYear
    return Object.values(quarterlyTaxEstimateDetails).find(
      (d) =>
        d.taxQuarter === previousQuarter && d.taxYear === previousQuarterYear
    )
  }
)

export const selectHasUserStartedQteWizardThisQuarter = createSelector(
  [selectActiveQTEDetailsAsDates, selectQteWizardLastStartedAt],
  (activeQuarterlyTaxEstimateDetails, qteWizardLastStartedAt) =>
    Boolean(
      activeQuarterlyTaxEstimateDetails &&
        qteWizardLastStartedAt &&
        isoToUTCDateTime(qteWizardLastStartedAt) >=
          activeQuarterlyTaxEstimateDetails?.utcNoOffset.newUserCutOffDate
    )
)

export type QTEChecklistStatusAndDates = ReturnType<
  typeof selectQTEChecklistStatusAndDates
>

export const selectQTEChecklistStatusAndDates = createSelector(
  selectActiveQTEDetailsAsDates,
  (_: unknown, timestamp?: DateTime<true> | DateTime<false>) => timestamp,
  (activeQuarterDetails, timestamp) => {
    if (!activeQuarterDetails) {
      const now = timestamp ?? DateTime.now()
      return {
        status: QTEChecklistStatuses.beforeChecklistOpens,
        startDateForDisplay: now,
        endDateForDisplay: now,
        rest: null,
      }
    }
    const { now, withOffset, forDisplay } = activeQuarterDetails

    if (now < withOffset.newUserCutOff) {
      return {
        status: QTEChecklistStatuses.beforeChecklistOpens,
        startDateForDisplay: now,
        endDateForDisplay: forDisplay.newUserCutOff,
        rest: activeQuarterDetails,
      }
    } else if (now < withOffset.initialCalculationLock) {
      return {
        status: QTEChecklistStatuses.checklistOpen,
        startDateForDisplay: forDisplay.newUserCutOff,
        endDateForDisplay: forDisplay.initialCalculationLock,
        rest: activeQuarterDetails,
      }
    } else if (now < withOffset.clientNotified) {
      return {
        status: QTEChecklistStatuses.internalCalculationHold,
        startDateForDisplay: forDisplay.initialCalculationLock,
        endDateForDisplay: forDisplay.clientNotified,
        rest: activeQuarterDetails,
      }
    } else if (now < withOffset.aFewDaysBeforePaymentDue) {
      return {
        status: QTEChecklistStatuses.calcsShouldBeReadyBeforeIRSPaymentDue,
        startDateForDisplay: forDisplay.clientNotified,
        endDateForDisplay: forDisplay.aFewDaysBeforePaymentDue,
        rest: activeQuarterDetails,
      }
    } else if (now < withOffset.irsPaymentDue) {
      return {
        status: QTEChecklistStatuses.calcsMayBeReceivedAfterIRSPaymentDue,
        startDateForDisplay: forDisplay.aFewDaysBeforePaymentDue,
        endDateForDisplay: forDisplay.irsPaymentDue,
        rest: activeQuarterDetails,
      }
    }
    return {
      status: QTEChecklistStatuses.afterIRSPaymentDue,
      startDateForDisplay: forDisplay.irsPaymentDue,
      endDateForDisplay: forDisplay.irsPaymentDue,
      rest: activeQuarterDetails,
    }
  }
)
