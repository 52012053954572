import { Grid, Loader } from 'semantic-ui-react'
import { GridRowColumn, Alert } from '../../../../../components/BaseComponents'
import { useCallback, useEffect, useMemo } from 'react'
import {
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
} from '../../annualTaxFilingForms.slice'
import {
  CREATE_SKYFLOW_TOKEN_KEY,
  FETCH_ALL_SKYFLOW_SSN_TOKENS,
  UPDATE_SKYFLOW_TOKEN_KEY,
  fetchSkyflowSsnTokensIfNeeded,
} from '../../../../../reducers/skyflowSlice'
import { getCurrentUser } from '../../../../../selectors/user.selectors'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  FETCH_USER_TAX_QUESTIONNAIRE_KEY,
  SUBMIT_EXTENSION_REQUEST_KEY,
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  fetchUserTaxQuestionnaire,
  postUpdateTaxQuestionnaireResponses,
} from '../../TaxChecklist/taxChecklistQuestion.actions'
import { TaxListQuestionId } from '../../TaxChecklist/service'
import { selectIsMarriedFilingJointlyOrQualifyingWidow } from '../../TaxChecklist/taxChecklist.selectors'
import {
  useFormFlow,
  useSetScreen,
} from '../../../../../components/FormFlow/formFlow'
import { UPDATE_FINANCIAL_PROFILE_KEY } from '../../../../../actions/financialProfileActions'
import { UPDATE_USER_KEY } from '../../../../../actions/userActions'
import PageHeader from '../../../../../components/shared/PageHeader'
import {
  selectIsFetchingForKeys,
  invalidateFetch,
  selectFirstErrorMessageForKeys,
} from '../../../../../reducers/fetch'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import FilingStatusPanel from '../../TaxChecklist/Form1040/Details/FilingStatusPanel'
import LocationInfoPanel from '../../TaxChecklist/Form1040/Details/LocationInfo'
import SpousePanel from '../../TaxChecklist/Form1040/Details/SpousePanel'
import CheckYourDetailsPanel from '../../TaxChecklist/Shared/PersonalDetails/CheckYourDetailsPanel'
import { TaxChecklistResponse } from '../../TaxChecklist/taxChecklistQuestion.slice'
import { select1120sFormForYear } from '../../annualTaxFilingForms.selector'
import EstimateTaxes from './EstimateTaxes'
import {
  FETCH_TAX_ESTIMATES_KEY,
  fetchUserTaxEstimatesIfNeeded,
} from '../../../QuarterlyTaxEstimates/userTaxEstimates.slice'
import { FORM_1040_DETAILS_SCREENS } from '../../TaxChecklist/Form1040/Details'
import ReviewPanel from './Review'
import {
  FETCH_DOCUMENT_CATEGORIES_KEY,
  fetchUserDocumentCategoriesIfNeeded,
} from '../../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import {
  FETCH_USER_DOCUMENTS_KEY,
  fetchUserDocuments,
} from '../../../../UserDocuments/userDocuments.slice'
import { EstimateTaxesQuarterPayments } from '../Personal/EstimateTaxes'
import { useStartExtension } from '../SharedHooks'
import { selectExtensionSurveyComplete } from '../../annualTaxFilings.selector'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import { fetchAnnualTaxFilingsIfNeeded } from '../../annualTaxFilings.slice'
import {
  FETCH_TAX_USER_DOCUMENTS_KEY,
  fetchTaxUserDocumentsIfNeeded,
} from '../../taxUserDocuments.slice'

export enum EXTENSION_REQUEST_SCREENS {
  confirmDetails = 'confirm-details',
  locationInfo = 'location-info',
  filingStatus = 'filing-status',
  spouse = 'spouse',
  estimateTaxQuarterPayments = 'estimate-tax-quarter-payments',
  estimateTax = 'estimate-tax',
  review = 'review',
  skipNavigation = 'skip-navigation',
}

const FormCombined = () => {
  const dispatch = useAppDispatch()
  const { setScreen, currentScreen } = useSetScreen({
    backLink: '/taxes/annual/',
    reviewScreen: EXTENSION_REQUEST_SCREENS.review,
  })
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const user = useReselector(getCurrentUser)
  const filingForm1120s = useReselector(select1120sFormForYear, taxYear)
  const extensionComplete = useReselector(
    selectExtensionSurveyComplete,
    taxYear
  )

  useStartExtension(filingForm1120s?.annualTaxFilingId)

  const needsSpouseInfo = useReselector(
    selectIsMarriedFilingJointlyOrQualifyingWidow,
    TaxListQuestionId.filing_status,
    taxYear
  )

  const allFetchKeys = useMemo(
    () => [
      UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
      DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
      CREATE_SKYFLOW_TOKEN_KEY,
      UPDATE_SKYFLOW_TOKEN_KEY,
      UPDATE_USER_KEY,
      UPDATE_FINANCIAL_PROFILE_KEY(user?.financialProfile?.id),
      FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
      FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
      FETCH_ALL_SKYFLOW_SSN_TOKENS,
      FETCH_USER_TAX_QUESTIONNAIRE_KEY(taxYear),
      FETCH_TAX_ESTIMATES_KEY,
      FETCH_USER_DOCUMENTS_KEY,
      FETCH_TAX_USER_DOCUMENTS_KEY,
      FETCH_DOCUMENT_CATEGORIES_KEY,
      SUBMIT_EXTENSION_REQUEST_KEY,
    ],
    [user?.financialProfile?.id, taxYear]
  )

  const updateError = useReselector(
    selectFirstErrorMessageForKeys,
    allFetchKeys
  )
  const isFetching = useReselector(selectIsFetchingForKeys, allFetchKeys)

  const saveFormData = useCallback(
    async (
      data: Partial<TaxChecklistResponse>[] | null,
      newScreen: FORM_1040_DETAILS_SCREENS | EXTENSION_REQUEST_SCREENS | null
    ) => {
      allFetchKeys.forEach((key) => dispatch(invalidateFetch(key)))
      if (data?.length) {
        const res = await dispatch(postUpdateTaxQuestionnaireResponses(data))
        if (res) {
          if (EXTENSION_REQUEST_SCREENS.skipNavigation !== newScreen) {
            setScreen(newScreen)
          }
          return true
        }
      } else {
        if (EXTENSION_REQUEST_SCREENS.skipNavigation !== newScreen) {
          setScreen(newScreen)
        }
        return true
      }
      return false
    },
    [dispatch, setScreen, allFetchKeys]
  )

  const screenConfig = useMemo(() => {
    const props = { goToNextStep: saveFormData, goBack: setScreen, taxYear }
    const screens = [
      {
        component: () => (
          <CheckYourDetailsPanel
            {...props}
            formId={filingForm1120s?.id.toString()}
          />
        ),
        screenName: EXTENSION_REQUEST_SCREENS.confirmDetails,
        step: 0,
      },
      {
        component: () => <FilingStatusPanel {...props} />,
        screenName: EXTENSION_REQUEST_SCREENS.filingStatus,
        step: 1,
      },
      {
        component: () => <LocationInfoPanel {...props} />,
        screenName: EXTENSION_REQUEST_SCREENS.locationInfo,
        step: 1,
        order: 2,
      },
      {
        component: () => <EstimateTaxesQuarterPayments {...props} />,
        screenName: EXTENSION_REQUEST_SCREENS.estimateTaxQuarterPayments,
        step: 2,
        order: needsSpouseInfo ? 4 : 3,
      },
      {
        component: () => <EstimateTaxes {...props} />,
        screenName: EXTENSION_REQUEST_SCREENS.estimateTax,
        step: 2,
        order: needsSpouseInfo ? 5 : 4,
      },
      {
        component: () => <ReviewPanel {...props} />,
        screenName: EXTENSION_REQUEST_SCREENS.review,
        step: 3,
        order: needsSpouseInfo ? 6 : 5,
      },
    ]
    if (needsSpouseInfo) {
      screens.splice(3, 0, {
        component: () => <SpousePanel {...props} />,
        screenName: EXTENSION_REQUEST_SCREENS.spouse,
        step: 1,
        order: 3,
      })
    }
    return screens
  }, [saveFormData, setScreen, taxYear, needsSpouseInfo, filingForm1120s?.id])

  const { progressBar, content } = useFormFlow({
    steps: [
      'Confirm Details',
      `Update Details for ${taxYear}`,
      'Estimate Taxes',
      'Review',
    ],
    screens: screenConfig,
  })

  useEffect(() => {
    dispatch(fetchUserTaxQuestionnaire(taxYear))
    if (user?.id) {
      dispatch(fetchSkyflowSsnTokensIfNeeded(user.id.toString()))
    }
  }, [dispatch, taxYear, user?.id])

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingsIfNeeded())
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())

    dispatch(fetchUserTaxEstimatesIfNeeded())
    dispatch(fetchUserDocuments())
    dispatch(fetchTaxUserDocumentsIfNeeded())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch])

  useEffect(() => {
    if (extensionComplete) {
      // This navigates the user back to the tax center if extension is complete
      setScreen(null)
    } else if (!currentScreen) {
      setScreen(EXTENSION_REQUEST_SCREENS.confirmDetails, true)
    }
  }, [setScreen, currentScreen, extensionComplete])

  return (
    <>
      <PageHeader
        isMultiStep
        header={`${taxYear} Tax Return Extension Request`}
      />
      <Grid>
        <Loader loading={isFetching} />
        <GridRowColumn>{progressBar}</GridRowColumn>
        {updateError && (
          <GridRowColumn>
            <Alert type="error">{updateError}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn>{content}</GridRowColumn>
      </Grid>
    </>
  )
}

export default FormCombined
