import { Grid, Image } from 'semantic-ui-react'
import { useEffect } from 'react'
import { Text } from '../../../components/BaseComponents'
import { PlaidItem } from '../../../reducers/finances/plaidItemReducer'
import { useAnalyticsView } from '../../Amplitude'

export const LBAInterestConfirmationPage = ({
  filteredPlaidItems,
}: {
  filteredPlaidItems: PlaidItem[]
}) => {
  const pageView = useAnalyticsView()

  useEffect(() => {
    pageView('lba interest confirmation')
  }, [pageView])

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={5}>
          <Image
            src={'https://heard-images.s3.amazonaws.com/assets/green-check.png'}
            style={{
              padding: 80,
              backgroundColor: '#E4F2E6',
            }}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Text as="h2">Thanks for letting us know</Text>
          <br />
          {filteredPlaidItems.length > 0 ? (
            <>
              <Text>
                We&apos;ll reach out via Conversations with instructions for
                providing us limited bank access to accounts that support it.
                You won&apos;t need to upload bank statements for those
                accounts.
              </Text>
              <Text
                style={{
                  marginTop: 16,
                }}
              >
                You&apos;ll still need to upload bank statements for accounts at
                these banks, since they don&apos;t support bank access:
              </Text>
              <ul>
                {filteredPlaidItems.map((item) => (
                  <li key={item.id}>
                    <Text>{item.institutionName}</Text>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <Text>
              We’ll reach out via Conversations with instructions for providing
              us limited bank access to your accounts. By allowing this access,
              you won’t need to upload bank statements every month.
            </Text>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
