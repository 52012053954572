import { ReactNode, useCallback, useEffect, useMemo } from 'react'
import {
  matchPath,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'

import AccountSuspended from '../../features/AccountSuspended'
import {
  getCurrentUser,
  getIsPayrollEnabled,
  isCanceled,
  selectIsUserMembershipPendingOrUnpaid,
  selectShouldHaveSubscription,
  selectHasAppAccess,
} from '../../selectors/user.selectors'
import AdminRoute from '../AdminRoute'
import PrivateRoute from '../PrivateRoute'
import PublicRoute from '../PublicRoute'
import ExternalAdminRoute from '../ExternalAdminRoute'
import {
  ADMIN_ROUTES,
  PAYROLL_ROUTES,
  PRIVATE_ROUTES,
  PUBLIC_ROUTES,
  EXTERNAL_ADMIN_ROUTES,
  RouteConfig,
  ALL_ROUTES,
} from './config'
import { LoggedInLayout } from '../shared/BaseLayout'
import { useReselector } from '../../utils/sharedHooks'
import { fetchSubscriptions } from '../../reducers/subscription.slice'
import { useAnalyticsTrack } from '../../features/Amplitude'
import CanceledAccount from '../Finances/Accounts/CanceledAccount'
import { useAppDispatch } from '../../utils/typeHelpers'

enum RouteType {
  ADMIN_ROUTE = 'ADMIN_ROUTE',
  PRIVATE_ROUTE = 'PRIVATE_ROUTE',
  PUBLIC_ROUTE = 'PUBLIC_ROUTE',
  EXTERNAL_ADMIN_ROUTE = 'EXTERNAL_ADMIN_ROUTE',
}

const RouteTypeWrapper = {
  [RouteType.ADMIN_ROUTE]: AdminRoute,
  [RouteType.PRIVATE_ROUTE]: PrivateRoute,
  [RouteType.PUBLIC_ROUTE]: PublicRoute,
  // This allows for external (non-Heard) admins to access certain parts of Heard internal tooling (ex: TP)
  [RouteType.EXTERNAL_ADMIN_ROUTE]: ExternalAdminRoute,
}

const PUBLIC_ROUTE_PATHS = Object.keys(PUBLIC_ROUTES)

const LoggedInWrapper = ({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation()

  // Public routes don't use the LoggedInLayout
  const isPublicRoute = useMemo(
    () => PUBLIC_ROUTE_PATHS.some((path) => matchPath(path, pathname)),
    [pathname]
  )

  if (isPublicRoute) {
    return children
  }

  return <LoggedInLayout>{children}</LoggedInLayout>
}

const Router = () => {
  const userHasAppAccess = useReselector(selectHasAppAccess)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const currentUser = useReselector(getCurrentUser)
  const isPayrollEnabled = useReselector(getIsPayrollEnabled)
  const isUserMembershipPendingOrUnpaid = useReselector(
    selectIsUserMembershipPendingOrUnpaid
  )
  const isCanceledAccount = useReselector(isCanceled)

  const shouldCheckForSubscriptions = useReselector(
    selectShouldHaveSubscription
  )
  const track = useAnalyticsTrack()

  // We want to fetch subscriptions on every page change to ensure account suspended state is up to date
  useEffect(() => {
    if (shouldCheckForSubscriptions) {
      dispatch(fetchSubscriptions())
    }
  }, [shouldCheckForSubscriptions, dispatch, pathname])

  // Global Page View tracking for customers only (non-admin)
  useEffect(() => {
    track('App Page View')
  }, [pathname, track])

  useEffect(() => {
    if (currentUser && userHasAppAccess === false) {
      if (!pathname.includes('/signup') && !pathname.includes('/accounts')) {
        navigate('/signup/about-your-practice')
      }
    }
  }, [currentUser, userHasAppAccess, navigate, pathname])

  const routeMapper = useCallback(
    (type: RouteType) => {
      const RouteWrapper = RouteTypeWrapper[type]

      return ([path, { customAuth, component: RouteComponent, ...rest }]: [
        string,
        RouteConfig[string],
      ]) => (
        <Route
          element={
            <RouteWrapper
              component={RouteComponent}
              {...(customAuth &&
                currentUser && {
                  customAuth: () => customAuth(currentUser),
                })}
            />
          }
          key={`router-route-${path}`}
          path={path}
          {...rest}
        />
      )
    },
    [currentUser]
  )

  if (isCanceledAccount) {
    return (
      <LoggedInWrapper>
        <Routes>
          <>
            <Route element={<CanceledAccount />} path={'/accounts/canceled'} />
            <Route
              element={<Navigate to="/accounts/canceled" replace />}
              path="*"
            />
          </>
        </Routes>
      </LoggedInWrapper>
    )
  }

  return (
    <LoggedInWrapper>
      <Routes>
        {isUserMembershipPendingOrUnpaid ? (
          <>
            <Route
              element={<AccountSuspended />}
              path={'/accounts/suspended'}
            />
            <Route
              element={<Navigate to="/accounts/suspended" replace />}
              path="*"
            />
          </>
        ) : (
          <>
            {Object.entries(ADMIN_ROUTES).map(
              routeMapper(RouteType.ADMIN_ROUTE)
            )}
            {isPayrollEnabled &&
              Object.entries(PAYROLL_ROUTES).map(
                routeMapper(RouteType.PRIVATE_ROUTE)
              )}
            {Object.entries(PRIVATE_ROUTES).map(
              routeMapper(RouteType.PRIVATE_ROUTE)
            )}
            {Object.entries(PUBLIC_ROUTES).map(
              routeMapper(RouteType.PUBLIC_ROUTE)
            )}
            {Object.entries(EXTERNAL_ADMIN_ROUTES).map(
              routeMapper(RouteType.EXTERNAL_ADMIN_ROUTE)
            )}
          </>
        )}
      </Routes>
    </LoggedInWrapper>
  )
}

// Lives in BaseLayout and is used to display banner components specific to routes
export const BannerRouter = () => {
  return (
    <Routes>
      {Object.entries(ALL_ROUTES).map(([path, { banner: Banner }]) => {
        if (Banner) {
          return <Route path={path} key={path} element={<Banner />} />
        } else {
          return null
        }
      })}
      <Route path="*" element={null} />
    </Routes>
  )
}

export default Router
