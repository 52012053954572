import { useEffect, useState } from 'react'
import { Grid, Image, List } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { uniq } from 'lodash'

import {
  Alert,
  Button,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { UpdateYourBooksStepProps } from './UpdateYourBooks'
import TransactionCard from './TransactionCard'
import TransactionHeader from './TransactionHeader'
import AddTransactionCard from './AddTransactionCard'
import {
  useAsyncCallback,
  useReselector,
  useScrollRef,
} from '../../../../../utils/sharedHooks'
import { TransactionCategoryIdentifier } from '../../../../../reducers/admin/allTransactions.slice'
import { selectUserEoyReviewProgressForSubstepIdentifier } from '../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.selector'
import { SubStepIdentifiers } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { updateUserEoyReviewProgress } from '../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { useAppDispatch } from '../../../../../utils/typeHelpers'

const filterCategories = [
  TransactionCategoryIdentifier.insurance_health,
  TransactionCategoryIdentifier.psychotherapy_fees,
  TransactionCategoryIdentifier.continuing_education,
  TransactionCategoryIdentifier.meals_entertainment,
  TransactionCategoryIdentifier.travel_expenses,
  TransactionCategoryIdentifier.rent_and_lease,
  TransactionCategoryIdentifier.internet,
  TransactionCategoryIdentifier.communication_expenses,
  TransactionCategoryIdentifier.auto_related_expenses,
  TransactionCategoryIdentifier.equipment,
  TransactionCategoryIdentifier.furniture,
  TransactionCategoryIdentifier.computer_hardware,
]

const AddBusinessExpenseStep = ({
  goForward,
  goBack,
}: UpdateYourBooksStepProps) => {
  const dispatch = useAppDispatch()

  const progress = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.addBusinessExpenseTransactions
  )

  const initHasBusinessExpensesToAdd =
    progress?.responses?.[0]?.hasBusinessExpenseToAdd

  const initBusinessTransactionIds = progress?.responses?.[0]?.transactionIds

  const [showAddTransactionModal, setShowAddTransactionModal] = useState(false)
  const [addedTransactionIds, setAddedTransactionIds] = useState(
    initBusinessTransactionIds || []
  )
  const [editingTransactionId, setEditingTransactionId] = useState<number>()
  const { scrollRef, scrollToRef } = useScrollRef()

  const [hasBusinessExpenseToAdd, setHasBusinessExpenseToAdd] = useState(
    typeof initHasBusinessExpensesToAdd === 'boolean'
      ? initHasBusinessExpensesToAdd
      : undefined
  )

  useEffect(() => {
    if (showAddTransactionModal) {
      scrollToRef()
    }
  }, [scrollToRef, showAddTransactionModal])

  useEffect(() => {
    if (!hasBusinessExpenseToAdd) {
      setShowAddTransactionModal(false)
    }
  }, [hasBusinessExpenseToAdd])

  useEffect(() => {
    if (!showAddTransactionModal) {
      setEditingTransactionId(undefined)
    }
  }, [showAddTransactionModal])

  const saveAndContinue = useAsyncCallback(async () => {
    let success = true
    if (progress?.id) {
      success = Boolean(
        await updateUserEoyReviewProgress(progress.id, {
          responses: [
            { hasBusinessExpenseToAdd, transactionIds: addedTransactionIds },
          ],
        })(dispatch)
      )
    }
    if (success) {
      await goForward({
        completedSteps: [SubStepIdentifiers.addBusinessExpenseTransactions],
      })
    }
  })

  const addTransactionToList = (transactionId: number) => {
    setAddedTransactionIds(uniq([...addedTransactionIds, transactionId]))
  }

  const editTransaction = (transactionId: number) => {
    setShowAddTransactionModal(true)
    setEditingTransactionId(transactionId)
  }

  const deleteTransaction = (transactionId: number) => {
    setAddedTransactionIds(
      addedTransactionIds.filter((tId) => tId !== transactionId)
    )
  }

  return (
    <Grid>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/payroll.svg"
          style={{ width: 120, height: 120 }}
        />
      </GridRowColumn>
      <GridRowColumn width={10} spacer={3}>
        <Text as="h1" textAlign="center">
          Do you have <b>business expenses</b> from the following categories in
          your personal bank accounts?
        </Text>
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column width={5} />
        <Grid.Column width={3}>
          <List bulleted>
            <List.Item>Healthcare</List.Item>
            <List.Item>Therapy</List.Item>
            <List.Item>Continuing Education</List.Item>
            <List.Item>
              Meals, Lodging and Airfare for Business Travel
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={3}>
          <List bulleted>
            <List.Item>Office rent</List.Item>
            <List.Item>Phone & Internet</List.Item>
            <List.Item>Mileage</List.Item>
            <List.Item>Equipment or Furniture over $2,500</List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} />
        <Grid.Column width={2}>
          <Button
            onClick={() => setHasBusinessExpenseToAdd(true)}
            variant="selector"
            fullWidth
            active={hasBusinessExpenseToAdd === true}
          >
            Yes
          </Button>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            onClick={() => setHasBusinessExpenseToAdd(false)}
            variant="selector"
            fullWidth
            active={hasBusinessExpenseToAdd === false}
          >
            No
          </Button>
        </Grid.Column>
      </Grid.Row>
      {hasBusinessExpenseToAdd && (
        <>
          <GridRowColumn>
            <Text textAlign="center">
              Please add <b>business-related</b> transactions that aren’t
              already in Heard.
            </Text>
          </GridRowColumn>
          <GridRowColumn spacer={6} width={4}>
            <Button
              variant="secondary"
              fullWidth
              onClick={() => setShowAddTransactionModal(true)}
            >
              <Icon icon={regular('plus')} style={{ marginRight: 10 }} />
              Add Transaction
            </Button>
          </GridRowColumn>
          <Grid.Row />
          {showAddTransactionModal && (
            <>
              <GridRowColumn spacer={2} width={12}>
                <span ref={scrollRef} />
                <AddTransactionCard
                  onClose={() => setShowAddTransactionModal(false)}
                  onSave={addTransactionToList}
                  transactionId={editingTransactionId}
                  categoryType="Expenses"
                  categoryFilter={filterCategories}
                />
              </GridRowColumn>
              <Grid.Row />
            </>
          )}

          {addedTransactionIds.length > 0 && <TransactionHeader />}
          <GridRowColumn short>
            {addedTransactionIds.map((id) => (
              <TransactionCard
                key={id}
                transactionId={id}
                variant="edit"
                editAction={editTransaction}
                deleteAction={deleteTransaction}
              />
            ))}
          </GridRowColumn>
          <GridRowColumn width={10} spacer={3}>
            <Alert>
              The bookkeeping and tax teams will review your business-related
              transactions to make sure we file your taxes correctly.
            </Alert>
          </GridRowColumn>
        </>
      )}
      <FormFlowFooter
        onBack={goBack}
        onForward={saveAndContinue.callback}
        loading={saveAndContinue.loading}
        continueDisabled={
          // If selection hasn't been made
          hasBusinessExpenseToAdd === undefined ||
          // Or there are transactions to add but none have been added
          (hasBusinessExpenseToAdd && !addedTransactionIds.length) ||
          // Or continue processing
          saveAndContinue.loading
        }
      />
    </Grid>
  )
}

export default AddBusinessExpenseStep
